import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { DatePickerComp } from "../datePicker";
import NotificationAbc from "../../components/notificationFullComp";
import ChangePassword from "../../components/ChangePassword";
import Notification from "../../components/notification";
import { useSelector } from 'react-redux';
import {
  img,
  notification,
  changePassword,
  notificationFullComp,
} from "../../constant";

function TopBar(props) {
    const userState = useSelector(state=>state.user.user);
    return(
        <>    
            <div>
                <NotificationAbc
                    userName = {userState?.full_name}
                    profilePhoto = { userState?.profile_picture }
                    {...notificationFullComp}
                    changePasswordPopUp={<ChangePassword {...changePassword} />}
                    {...img}
                    notification={<Notification {...notification} />}
                />
            </div>
        </>
    )
}

TopBar.propTypes = {
  
}

function Buttons({buttons,onClickListenerOld}) {
    return(
        // TODO: buttons should be optional
        buttons.map((button,i)=>{
            if(button.type=="link"){
                return(
                    <Link key={i} 
                        to={button.url}
                        className="btn btn-secondary btn-radius mt-4">{button.isSymbolPresent ? <i className="fa fa-plus-circle"></i> : ""} 
                        {button.name}
                    </Link>
                )
            }
            else{
                if(button.type=="button"){
                    return(
                        <button key={i} 
                            onClick= {button.onClickListener}
                            className="btn btn-secondary btn-radius mt-4">{button.isSymbolPresent ? <i className="fa fa-plus-circle"></i> : ""} 
                            {button.name}
                        </button>
                    )
                }
                else{
                    return(
                        <>
                            <Link key={i} 
                                to={button.url} 
                                onClick={()=> ( button.name == "Publish" || button.name == "GENERATE" || button.name == "UPDATE" || button.type == "submit" ) ? onClickListenerOld() : ""} 
                                className="btn btn-secondary btn-radius mt-4">{button.isSymbolPresent ? <i className="fa fa-plus-circle"></i> : ""} 
                                {button.name}
                            </Link>
                        </>
                    )
                }
            }
        })
    )   
}
function PositionInterChange(props) {
    if(props.positionInterChange){
        return <>
            
            <div className="col-sm-8">
                <h2>{props.title}</h2>
                <h5>{props.subTitle}</h5>
            </div>
            <div className="col-sm-4">
                <div className="searchhrt">
                    <DatePickerComp {...props} />
                </div>
            </div>
            
        </>
    }
    else{
        return <>
            <div className="col-sm-4">
                <h2>{props.title}</h2>
                <h5>{props.subTitle}</h5>
            </div>
            <div className="col-sm-8 text-right">
                <Buttons buttons={props.buttons} onClickListenerOld={props.onClickSubmitFire}/>
            </div>
        </>
    }
}
function Header(props) {
    const onClickSubmitFire = (e)=>{
        props.submitHandler(e);
    }
    return(
        <div className="content-header">            
            <div className="container-fluid mt-3">
                <TopBar></TopBar>
                <div className="row mb-2">
                    <PositionInterChange {...props} onClickSubmitFire={onClickSubmitFire}/>
                </div>
            </div>
        </div>
    )
}
Header.propTypes = {
    buttons : PropTypes.shape([
      {
        name : PropTypes.string,
        url : PropTypes.string,
        isSymbolPresent : PropTypes.bool
      }
    ]),
    subTitle : PropTypes.string,
    title : PropTypes.string
}
Header.defaultProps = {
    title : "Constant data error ",
    subTitle : "Data for this header is not passing from constent",
    buttons: [],
    submitHandler: ()=>{console.log("default Function")}
}
export default Header;