import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {TailorOrders} from "../../pages/tailor";
import { OrderTableAction, actionSearch } from "../order/redux/order.actions";
import queryString from "query-string";

function TailorOrderContainor(params) {
    
    const querydata = queryString.parse(window.location.search);
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const OrdersState = useSelector(state=>state.orderTable);
    const data = OrdersState?.data
    var rows = data?.rows;
    var pageInfo = data?.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        toPage(1);
    }, [OrdersState.search]);
    function toPage(page, limit) {
        let search = OrdersState.search;

        let query = {};
        
        // if (querydata) query.page = page;
        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.name = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? window.location.search+'&' + enocodeQuery : '';
        
        dispatch(OrderTableAction(token,enocodeQuery));
    }
    
    function onSearch(str) {
        dispatch(
            actionSearch({
            ...OrdersState,
            search: str,
          })
        );
    }

    return(
        <TailorOrders
            enocodeQuery = {window.location.search}
            rows={rows}
            pageInfo={pageInfo}
            onSearch={onSearch}
            toPage={toPage}
        />
    )
}

export default TailorOrderContainor;
export {
    TailorOrderContainor
}