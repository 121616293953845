import {BaseService} from './base.service';
import {config} from '../environments';

class FabricService extends BaseService{
    static get(headers,enocodeQuery){
        const url = super.prepareUrl(config.endpoints.fabric,enocodeQuery);
       return  super.get(url,headers);
    }

    static distinct(headers,enocodeQuery){
        const url = super.prepareUrl(config.endpoints.fabric + "/distinct",enocodeQuery);
       return  super.get(url,headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.fabric);
       return  super.post(url,data,headers);
    }
    static getFabricType(headers, query){
        const url = super.prepareUrl(config.endpoints.fabricType, query);
       return  super.get(url,headers);
    }
    static postFabricType(data,headers){
        const url = super.prepareUrl(config.endpoints.fabricType);
       return  super.post(url,data,headers);
    }
    static put(id, data, headers) {
        const url = super.prepareUrl(config.endpoints.fabric);
        return super.put(url + `/${id}`, data, headers);
    }
    static delete(id, headers) {
        const url = super.prepareUrl(config.endpoints.fabric);
        return super.delete(url + `/${id}`,headers);
    }
}
export default FabricService;

export {FabricService};