import React from "react";
import { lineGraph, img, barChart, ratingAndInfo, tableHeaderDiscount, DiscountTableHeaderforCustomers } from "../../constant";
import Header from "../../components/header";
import LineChart from "../../components/lineGraph";
import BarChart from '../../components/barChart/index';
import RatingAndInfo from "../../components/ratingAndInfo";
import { DiscountCouponsColumsForList, DiscountColumsForCustomersList } from "../../components/table/columnsForTable";
import Table from "../../components/table";
import { urls } from "../../constants";
import queryString from "query-string";

function DiscountCouponView(props) {
    const queryObj = queryString.parse(window.location.search);
    return(
        <div  class="content-wrapper">
            <div className="row">
            <div className="col-sm-12">
                <Header 
                    title="Discount Coupons"
                    subTitle = "KHY39284723"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.DISCOUNTS,
                            isSymbolPresent : false
                        },
                        {
                            name : "EDIT",
                            url : urls.DISCOUNT_EDIT+urls.QUERY_STRING_KEY_ID+queryObj.id+"&detailPage=true",
                            isSymbolPresent : false
                        },
                    ]}
                />
            </div>
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-4">
                        <LineChart {...lineGraph} />
                    </div>
                    <div className="col-sm-4">
                        <BarChart {...barChart}/>
                    </div>
                    <div className="col-sm-4">
                        <RatingAndInfo 
                            {...ratingAndInfo} 
                            {...img}
                            title = "Coupon Owner (Tailor)"
                            fullName = "Mr. Tailor's Name"
                            // avgTimeLabel="Avg. Time:"
                            AVGtime = " 1-2 days"
                            rating = {3}
                            // locationsApplicableLabel="Tailor's Location"
                            totalnumberOfReviews ="56"
                        />
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <Table 
                    tableData={DiscountColumsForCustomersList} 
                    tableHeader={DiscountTableHeaderforCustomers} 
                    ObjectLength="" 
                    totalResultsLabel={`Used by: ${null} Customers`}
                />
            </div>
            <div className="col-sm-12">
                <Table 
                    tableData={DiscountCouponsColumsForList} 
                    tableHeader={tableHeaderDiscount} 
                    ObjectLength={null}
                    totalResultsLabel={`Total Coupons: `}/>
            </div>
            </div>
        </div>
    )
}
export default DiscountCouponView;
export { DiscountCouponView };