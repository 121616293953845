import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {OprionsForCircleChart} from './circleChartData';
import PropTypes, { array } from 'prop-types'; 

function CircleChart(props) {
    var data=[];

    return (
        <div className="mb-4">
            <div className="card complaint-card h-100" style={{paddingBottom: '79px'}}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="card-title">{props.title}</h3>
                        </div>
                        <div className="col-md-6 textM-right">
                            <h5 className="f-14 text-capitalize">{props.totalLabel} <br/><span className="f-20"><span className="text-uppercase f-20">{props.currencyName} </span> {props.total}</span></h5>
                        </div>
                    </div>
                </div>
                <div style={{overflow:"hidden"}} >
                    <div className="mb-5 float-left card-body pl-4 pr-4 pb-0 text-left circlechartnew">
                        <HighchartsReact Highcharts={Highcharts} options={OprionsForCircleChart(data,props.name)} />    
                    </div>
                    <div className="row mt-5 ">
                         {
                            props?.array.map((obj,i)=>{
                                data.push(['',obj.totalAmount])
                                return(
                                    <div className={`col-md-${props.colSize} text-center`}>
                                        <span className="dotOnlineEarning"></span><span className="text-uppercase f-5 text-capitalize"> {obj.paymentType} </span><br/>
                                        <h6><span className="f-15"><span className="text-uppercase f-15"><b>{props.currencyName}</b> </span> <b>{obj.totalAmount}</b></span></h6>
                                    </div>
                                )
                            })
                         }
                    </div>
                </div>
            </div>
        </div>
    )
}
CircleChart.propTypes = {
    cashEarningValue : PropTypes.string,
    cashEarningLabel : PropTypes.string,
    currencyName : PropTypes.string,
    onlineEarningLabel : PropTypes.string,
    onlineEarningValue : PropTypes.string,
    title : PropTypes.string,
    totalLabel : PropTypes.string,
    totalValue : PropTypes.string
  };
CircleChart.defaultProps = {
    array: []
}
export default CircleChart;
