import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { customersTableColoumComplaints } from "../../components/table/columnsForTable";
import {CustomertableHeaderComplaint,globalPropsFabric} from '../../constant';
import { urls } from "../../constants";

function Complaints(props) {
    return(
        <>    
            <div  class="content-wrapper">
                <Header 
                    title="Tailor"
                    subTitle = "Complaints"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.TAILOR_DETAILS+window.location.search,
                            isSymbolPresent : false,
                            type:'link'
                        }
                    ]}
                />
                <Table tableData={customersTableColoumComplaints} tableHeader={CustomertableHeaderComplaint} ObjectLength="2"  {...globalPropsFabric}/>
            </div>
        </>
    )
}
export default Complaints;
export {
    Complaints
}