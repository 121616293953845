import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from 'react-redux';
import BrandAddNew from "../../pages/brands/brandsAddNew";
import { addBrand } from "./redux/brandTable.actions";
import { urls } from "../../constants";
import { useHistory } from "react-router-dom";
import { uploadImage } from "../AddOffer/redux/AddOffer.actions";
import { GetFabricType } from "../fabric/redux/fabricTable.actions";

function AddBrandContainer (props) {
  const temp = 0 ;
    useEffect(()=>{
      dispatch(GetFabricType(token));
    },[temp]);
  const history = useHistory();

  const dispatch = useDispatch();
  const userState = useSelector(state=>state.user);
  const fabricTypeList = useSelector(state=>state.fabricTypeList?.fabric_types);
  const [form, setForm] = useState({});
  const token = {
    'Authorization' : `${userState.token}`
  };
  const submitHandler = (e) => {

      if( ( form.name_en !=="" ) && ( form.fabric_type_id !=="" )&& ( form.descriptions_en !=="" ) && ( form.status !=="" )&& ( form.gender !=="" )&& ( form.image_url_en !=="" ) ){
        dispatch(addBrand(form,token));
        history.push(urls.BRANDS);
      }else{
        alert( "Please fill all the boxes." ); // todo : will pass this message from constents
      }
  }

  const onChangeListener = (key,value) => {
    setForm({...form,[key]:value});
  }
  function onUploadImage(data, callBack){
    return dispatch(uploadImage(data[0], token, callBack))
  }
  return (
    <BrandAddNew
        submitHandler= {submitHandler}
        fabricTypesData = {fabricTypeList}
        onUploadImage={onUploadImage} 
        onChangeListener={onChangeListener}
        {...form}
    />
  )
}

AddBrandContainer.propTypes = {

};

export default AddBrandContainer;
export {
  AddBrandContainer
}
