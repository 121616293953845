import { handleActions } from "redux-actions";
import {  
    dashboardDataFatchSuccess,
    dashboardDataFatchError,
    dashboardSalesTrendFatchSuccess,
    dashboardSalesTrendFatchError
} from "./dashboard.action";

const defaultState = {};

const reducer = handleActions(
    {
        [dashboardDataFatchSuccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [dashboardDataFatchError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
const SalesTrendReducer = handleActions(
    {
        [dashboardSalesTrendFatchSuccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [dashboardSalesTrendFatchError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)

export{
    reducer,
    SalesTrendReducer
}