import {BaseService} from './base.service';
import {config} from '../environments';

class TopCustomerService extends BaseService{
    static get(headers,queryString){
        const url = super.prepareUrl(config.endpoints.topCustomer,queryString);
        return  super.get(url,headers);
    }
}

export default TopCustomerService;

export {TopCustomerService};
