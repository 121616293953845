import React from "react";

function ImageGrid(props) {
    const changeValue = (e)=>{
        props.onChangeListener(e.target.value);
      }
    let images = props.images;
    const Map = images?.map((row,i)=>
                    <div key={i} class="col-md-3 tailoriChked">
                        <input onChange={changeValue} checked={props.value==row.id ?true:""} class="with-gap" name="Brands_radio" type="radio" id={"Brands_"+i} value={row.id} />
                        <label for={"Brands_"+i}>
                        <div class="bespkokestailor"><img src={row.image_url_en} /></div>
                        {row.name_en}
                        </label>
                    </div>
                )
    return(
        <div class="row mt-4">
            {Map}
        </div>
    )
}
ImageGrid.defaultProps={
    images: [],
    onChangeListener: ()=>{}
}
export default ImageGrid;
export {
    ImageGrid
}