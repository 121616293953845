import {BaseService} from './base.service';
import {config} from '../environments';

class BrandsService extends BaseService{
    static get(headers,enocodeQuery){
        const url = super.prepareUrl(config.endpoints.brandsPaginate,enocodeQuery);
        return super.get(url,headers);
    }
    static getBrand(headers,enocodeQuery){
        const url = super.prepareUrl(config.endpoints.brands,enocodeQuery);
        return super.get(url,headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.brands);
        return super.post(url,data,headers);
    }
    static delete(id, headers) {
        const url = super.prepareUrl(config.endpoints.brands);
        return super.delete(url + `/${id}`,headers);
    }
    static put(id, data, headers) {
        const url = super.prepareUrl(config.endpoints.brands);
        return super.put(url + `/${id}`, data, headers);
    }
}

export default BrandsService;

export {BrandsService};
