import PropTypes from "prop-types";
import React, {useState}  from 'react';

function Row(props){
  const array = props.array;
  if(array){
    return array.map((row,index)=>{
        return(
          <div key={index} className="card-body pb-0">
            <div className="row dashtrend"> 
              <div className="col-md-7 col-7">
                  <div className="float-left">
                      <h6 className="text-uppercase text-blue">{props.fabricNameLabel+ row[props.key1]}</h6>
                      {/* <h4>{props.fabricNameLabel+row[props.key3]}</h4>
                      <h4>{props.fabricBrandLabel+row[props.key5]}</h4> */}
                  </div>
              </div>
              <div className="col-md-5 col-5">
                  <img alt="Dashboard Section" src={props.img} className="float-left mt-2 mr-2" />
                  <div className="float-left">
                      <h4>{row[props.key2]+props.currencyType}</h4>
                      {/* <p className="text-muted mb-0">{row[props.key4]}</p> */}
                  </div>
              </div>
            </div>
          </div>
        )
    })
  }
  else{
    return null;
  }
}
function HighLowFabricCost(props){
  const [forLow, setPageFor] = useState(true);
  function changePageContent() {
    setPageFor(!forLow);
  }
  return(
    <section className=" connectedSortable">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{forLow ? props.titleHigh : props.titleLow}</h3>
          <button onClick={changePageContent} class="float-right btn btn-info">{forLow ? props.low : props.high}</button>
        </div>
        
        <Row 
          array={forLow ? props.highCostFabric : props.lowCostFabric } 
          img={props.img} 
          {...props}
        />
      </div>
    </section>
  )
}

HighLowFabricCost.propTypes = {
  title: PropTypes.string,
  array: PropTypes.array,
  img: PropTypes.string
}

HighLowFabricCost.defaultProps = {
}

export default HighLowFabricCost;