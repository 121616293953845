import {BaseService} from './base.service';
import {config} from '../environments';

class OutstandingService extends BaseService{
    static get(token,queryString){
        const url = super.prepareUrl(config.endpoints.outstanding,queryString);
        return super.get(url,token);
    }
}

export default OutstandingService;

export {OutstandingService};
