import { SettingService } from "../../../services";
import { createAction } from "redux-actions";
import { 
    appSettingsFatchError,
    appSettingsFatchSuccess
} from "./settings.actionNames";

const settingsFatchSeccess = createAction(appSettingsFatchSuccess,(data)=>data);
const settingsFatchError = createAction(appSettingsFatchError,(ErrorData)=>ErrorData);

function settingsFetch(token,queryString) {
    return(dispatch)=>{
        SettingService.get(token,queryString).then((resp)=>{
            dispatch(settingsFatchSeccess(resp.data.data))
           }).catch((err)=>{
               console.log(err)
           })
    }
}

function settingAdd(data,token, callBack) {
    return(dispatch)=>{
        SettingService.post(data,token).then((resp)=>{
            callBack();
           }).catch((err)=>{
               console.log(err)
           })
    }
}

export {
    settingsFetch,
    settingAdd,
    settingsFatchSeccess,
    settingsFatchError
}