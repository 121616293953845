import {VariableCategoryService} from '../../../services';
import {createAction} from 'redux-actions';
import {appVariableAddSuccess, appVariableAddError, appVariableCategoryIdSuccess} from './AddVariable.actionNames';

const VariableAddSuccess = createAction(appVariableAddSuccess,(userData)=>userData);
const VariableAddError = createAction(appVariableAddError,(userLoginErr)=>userLoginErr);
// const VariableCategoryIdSuccess = createAction(appVariableCategoryIdSuccess, (data)=>data);

function addVariableCategory(data,token, callBack){
    return (dispatch)=>{
        VariableCategoryService.post(data,token).then((resp)=>{
             dispatch(VariableAddSuccess(resp.data.data))
             callBack();
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function updateVariableCategory(id, data, token, callBack) {
    return (dispatch) => {
        VariableCategoryService.put(id, data, token).then((resp) => {
            callBack();
        }).catch(console.log)
    }
}

function getInfo(id, token, callBack) {
    return (dispatch) => {
        VariableCategoryService.info(id, token).then((resp) => {
            callBack(resp.data.data);
        }).catch(console.log)
    }
}



export {addVariableCategory, getInfo, updateVariableCategory, VariableAddError, VariableAddSuccess}
