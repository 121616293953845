import React from 'react';
import HighLowFabricCost from "../../components/highLowFabricList";
import { highLowFabricCostConst, currency, img } from "../../constant1";

function HighLowFabricCostList(props) {

    const Obj = { key1 : "name", key2 : "price" };

    return(
        <HighLowFabricCost
            {...currency}
            {...highLowFabricCostConst}
            {...props}
            img={img.trendingUpImgURL}
            {...Obj}
        />
    )
}

export default HighLowFabricCostList;
export{
    HighLowFabricCostList
}