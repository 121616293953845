import React from "react";
import { Switch, Route } from "react-router-dom";
import DefaultPath from "./DefaultPath";
import { useSelector } from 'react-redux';
import { DashboardContainor } from "../../containers/dashboard";
// import AddBrands from "../brands/brands.new";
// import AddTailors from "../tailorTable/tailor.new";
// import AddFabrics from "../fabrics/fabrics.new";
// import { BrandTableContainer } from "../../containers/brandsTable";

const Dashboard = () => {

  const userState = useSelector(state=>state.user.user);

  return (
    <div>
      <Switch>
        {/* <Route path="/dashboard/brands/new" component={AddBrands}></Route>
        <Route path="/dashboard/tailors/new" component={AddTailors}></Route>
        <Route path="/dashboard/fabrics/new" component={AddFabrics}></Route>
        <Route path="/dashboard/brands" component={ BrandTableContainer } ></Route> */}
        <Route path="/dashboard" component={DashboardContainor}></Route>
      </Switch>
    </div>
  );
};

export default Dashboard;
