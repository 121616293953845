import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {TailorTable as TailorTablePage} from "../../pages/tailor";
import { TailorTable, tailorTableFatchSuccess, TailorUpdate } from "./redux/tailor.actions";

function TailorTableContainer(props) {
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const tailorState = useSelector(state=>state.tailor);
    var rows = tailorState?.rows;
    var pageInfo = tailorState?.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };
    useEffect(()=>{
        dispatch(
            tailorTableFatchSuccess({
            ...tailorState,
            search: "",
          })
        );
    },0)
    useEffect(()=>{
        toPage(1);
    }, [tailorState.search]);

    function toPage(page, limit) {
        let search = tailorState.search;

        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.name = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';

        dispatch(TailorTable(token, enocodeQuery));
    }
    function onSearch(str) {
        dispatch(
            tailorTableFatchSuccess({
            ...tailorState,
            search: str,
          })
        );
    }

    function onStatusChange(id,status) {
        dispatch(TailorUpdate(id, {
            status: status ? 'active' : 'inactive'
        },token, toPage, pageInfo));
    }
    return( 
        <TailorTablePage 
            userState = { userState.user }
            rows={rows} 
            pageInfo={pageInfo}
            toPage={toPage} 
            onSearch={onSearch}
            onStatusChange={onStatusChange}
        />
    )
}

export default TailorTableContainer;
export {TailorTableContainer};