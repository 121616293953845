import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { OfferTable } from "../../components/table/columnsForTable";
import { urls } from "../../constants";
import { useSelector } from "react-redux";
import {
  offerTableHeaderDataOffer,
  globalPropsFabric,
} from "../../constant";

function Offer({ deleteListener, toPage, onSearch, onStatusChange }) {

  const offer = useSelector((state) => state.offer);
  const offerPageInfo = useSelector((state) => state.offer.pageInfo);

  return (
    <>
      <div class="content-wrapper">
        <Header
          title="Offers"
          subTitle="Click to view the details"
          buttons={[
            {
              name: "ADD NEW",
              url: urls.OFFER_ADD,
              isSymbolPresent: true,
            },
          ]}
        />
        <Table
          tableData={(e) => OfferTable({ deleteListener, onStatusChange })}
          onSearch={onSearch}
          toPage={toPage}
          onDelete={deleteListener}
          tableHeader={offerTableHeaderDataOffer}
          ObjectLength={offerPageInfo?.total}
          pageInfo={offerPageInfo}
          {...globalPropsFabric}
        />
      </div>
    </>
  );
}

export default Offer;
