import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {TailorProfile} from "../../pages/tailor";
import { TailorDetail } from "./redux/tailor.actions";
import queryString from "query-string";

function TailorProfileContainor(params) {
    
    const data = queryString.parse(window.location.search);
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const TailerProfile = useSelector(state=>state.tailor?.tailerProfile);

    const token = {
        'Authorization' : `${userState.token}`
    };
    
    var temp =data.id;
    let enocodeQuery = Object.keys(data)
            .map((key) => data[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '/' + enocodeQuery : '';
    useEffect(()=>{
        dispatch(TailorDetail(token,enocodeQuery));
    },[temp])

    return(
        <TailorProfile
            id= {data.id}
            {...TailerProfile}
        />
    )
}

export default TailorProfileContainor;
export {
    TailorProfileContainor
}