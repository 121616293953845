import React from "react";

function DropDown(props) {

    return(
    <div className="form-label-group">
        <select
            disabled={props.disabled}
            className="form-control"
            onChange={(e)=>{
                props?.onChangeListener(e.target.value);
            }}
            value={props.value}
        >
            <option>Select {props.heading}</option>
            {props.options?.map((option,i) => (
                <option key={i} value={option.id ? option.id : option}>
                {option.name_en? option.name_en : option}
            </option>
            ))}
        </select>
    </div>
    )
}
DropDown.defaultProps = {
    onChangeListener: ()=>{}
}
export default DropDown;