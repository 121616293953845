import React from "react";
import { BrowserRouter,Route, Switch, Redirect} from "react-router-dom";
import Login from './pages/auth/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import {
  BrandTableContainer,
  AddBrandContainer,
  EditBrandContainer,
  EditBrandContainer as BrandDetailPage
} from "./containers/brand";
import { FabricTableContainer } from "./containers/fabric";
import { OffersTableContainer } from "./containers/offersTable";
import { DealsTableContainer } from "./containers/dealsTable";
import { AddDealContainer } from './containers/AddDeal';
import { SettingsContainer } from "./containers/settings";
import { 
  TailorTableContainer, 
  TailorProfileContainor, 
  TailorOrderContainor ,
  TailorCouponContainor,
  TailorReviewContainor,
  TailorPendingTableContainer,
  TailorPendingViewContainor
} from "./containers/tailorTable";
import {NavSideBarPage} from "./pages/HeaderAndnavSidebar";
import { AddNewFabricContainer, EditFabricContainer,EditFabricContainer as FabricDetailPage, AddFabricTypeContainor } from "./containers/fabric";
import tailorAddNew from "./pages/tailorTable/tailor.new";
import { DiscountAddAndEdit, DiscountCouponView } from "./pages/discount";
import { DiscountAddAndEditContainor, PendingDiscountDetailContainor } from "./containers/discount";
import { OfferView } from "./pages/offer";
import { AddOfferContainer } from "./containers/AddOffer";
import { Complaints, Size, ComplaintsView } from "./pages/customers";
import { TailorOrders, Coupons, TailorReview, Complaints as TailorComplaints } from "./pages/tailor";
import { TaxesContainor, TaxesAddNew_containor, TaxEdit_containor } from "./containers/tax";
import { OrderTable,OrderDetail } from "./containers/order";
import  {
  Customers,
  CustomersDetail as CustomersProfile,
  CustomersSize, 
  CustomerOrderTableContainor,
  CustomerReviewsTableContainor,
  CustomerOrderDetailContainor
}  from "./containers/customer";
import { AddVariableContainer } from "./containers/AddVariable";
import { VariableTableContainer } from "./containers/variableTable"
import { Styles, StylesAddAndEdit } from "./containers/style";
import { Discounts } from "./containers/discount";
import { urls } from "./constants/app";
import { StorageHelper } from "./helpers";
import { PayoutContainer } from "./containers/payout";
// prepare redux state from local storage
StorageHelper.prepareRedux();

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={urls.DASHBOARD} component={Dashboard} />
      </Switch>
      <Switch>
        <Route exact path={urls.ORDERS} component={ OrderTable } />
      </Switch>
      <Switch>
        <Route exact path={urls.ORDER_DETAIL} component={ OrderDetail } />
      </Switch>
      <Switch>
        <Route exact path={urls.CUSTOMERS} component={ Customers } />
      </Switch>
      <Switch>
        <Route exact path={urls.CUSTOMER_DETAIL} component={ CustomersProfile } />
      </Switch>
      <Switch>
        <Route exact path={urls.CUSTOMER_SAVED_SIZE} component={ CustomersSize } />
      </Switch>
      <Switch>
        <Route exact path={urls.CUSTOMER_ADD} component={()=><h1 className="text-center">Add page</h1>} />
      </Switch>
      <Switch>
        <Route exact path={urls.CUSTOMER_ORDERS} component={ CustomerOrderTableContainor } />
      </Switch>
      <Switch>
        <Route exact path={urls.CUSTOMER_ORDERS_DETAIL} component={ CustomerOrderDetailContainor } />
      </Switch>
      <Switch>
        <Route exact path={urls.CUSTOMER_REVIEWS} component={ CustomerReviewsTableContainor } />
      </Switch>
      <Switch>
        <Route exact path={urls.CUSTOMER_COMPLAINTS} component={ Complaints } />
      </Switch>
      <Switch>
        <Route exact path={urls.CUSTOMER_COMPLAINT_DETAILS} component={ ComplaintsView } />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILORS} component={TailorTableContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILORS_PENDING} component={TailorPendingTableContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILORS_PENDING_VIEW} component={TailorPendingViewContainor} />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILORS_PROFILE} component={TailorPendingViewContainor} />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILOR_ADD} component={tailorAddNew} />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILOR_DETAILS} component={TailorProfileContainor} />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILOR_ORDERS} component={ TailorOrderContainor } />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILOR_COUPONS} component={ TailorCouponContainor } />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILOR_REVIEWS} component={ TailorReviewContainor } />
      </Switch>
      <Switch>
        <Route exact path={urls.TAILOR_COMPLAINTS} component={ TailorComplaints} />
      </Switch>
      <Switch>
        <Route exact path={urls.LOGIN} component={Login} />
      </Switch>
      <Switch>
        <Route exact path={urls.DEALS} component={DealsTableContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.ADD_DEAL} component={AddDealContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.EDIT_DEAL} component={AddDealContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.OFFERS} component={OffersTableContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.OFFER_ADD} component={AddOfferContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.OFFER_EDIT} component={AddOfferContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.OFFER_DETAILS} component={OfferView} />
      </Switch>
      <Switch>
        <Route exact path={urls.STYLES} component={ Styles } />
      </Switch>
      <Switch>
        <Route exact path={urls.STYLE_ADD} component={ StylesAddAndEdit } />
      </Switch>
      <Switch>
        <Route exact path={urls.STYLE_EDIT} component={ StylesAddAndEdit } />
      </Switch> 
      <Switch>
        <Route exact path={urls.VARIABLES} component={ VariableTableContainer } />
      </Switch>
      <Switch>
        <Route exact path={urls.VARIABLE_ADD} component={ AddVariableContainer } />
      </Switch>
      <Switch>
        <Route exact path={urls.VARIABLE_EDIT} component={ AddVariableContainer } />
      </Switch>
      <Switch>
        <Route exact path={urls.FABRICS} component={FabricTableContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.FABRIC_ADD} component={AddNewFabricContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.FABRIC_DETAIL} component={()=><FabricDetailPage disabled={true}/>} />
      </Switch>
      <Switch>
        <Route exact path={urls.FABRIC_TYPE_ADD} component={AddFabricTypeContainor} />
      </Switch>
      <Switch>
        <Route exact path={urls.FABRIC_EDIT} component={EditFabricContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.BRANDS} component={BrandTableContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.BRAND_ADD} component={AddBrandContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.BRAND_EDIT} component={EditBrandContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.BRAND_DETAIL} component={()=><BrandDetailPage disabled={true}/>} />
      </Switch>
      <Switch>
        <Route exact path={urls.DISCOUNTS} component={Discounts} />
      </Switch>
      <Switch>
        <Route exact path={urls.DISCOUNT_PENDING} component={()=><Discounts isPandingDiscountCouponPage={true}/>} />
      </Switch>
      <Switch>
        <Route exact path={urls.DISCOUNT_ADD} component={DiscountAddAndEditContainor} />
      </Switch>
      <Switch>
        <Route exact path={urls.DISCOUNT_EDIT} component={DiscountAddAndEditContainor} />
      </Switch>
      <Switch>
        <Route exact path={urls.DISCOUNT_DETAILS} component={DiscountCouponView} />
      </Switch>
      <Switch>
        <Route exact path={urls.PENDING_DISCOUNT_DETAILS} component={PendingDiscountDetailContainor} />
      </Switch>
      <Switch>
        <Route exact path={urls.TAXES} component={TaxesContainor} />
      </Switch>
      <Switch>
        <Route exact path={urls.TAX_ADD} component={TaxesAddNew_containor} />
      </Switch>
      <Switch>
        <Route exact path={urls.TAX_EDIT} component={TaxEdit_containor} />
      </Switch>
      <Switch>
        <Route exact path={urls.MANAGE_SHIPMENT} component={SettingsContainer} />
      </Switch>
      <Switch>
        <Route exact path={urls.PAYOUT} component={PayoutContainer} />
      </Switch>
      <Switch>
        <Route exact path="/pages/404" component={ ()=><h1 className="text-center">Page Missing</h1> } />
      </Switch>

      <Switch>
        <Route path={urls.DASHBOARD} component={NavSideBarPage} />
      </Switch>
      <Switch>
        <Route exact path="/">
            <Redirect to={urls.LOGIN} />
        </Route>
      </Switch>
      
    </BrowserRouter>
  );
};

export default App;
