import React, {useState} from "react";
import { Taxes_addNew } from "../../pages/tax";
import { TaxAdd } from "./redux/tax.actions";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import { urls } from "../../constants";

function TaxesAddNew_containor(params) {

    const history = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);

    const [form, setForm] = useState({});

    const onChangeListener = (key, value)=>{
        setForm({...form,[key]:value});
    }

    const submitHandler = ()=>{
        const token = {
            'Authorization' : `${userState.token}`
        };
        dispatch( TaxAdd( form, token ) );
        history.push(urls.TAXES);
    }

    return(
        <Taxes_addNew
            submitHandler={submitHandler}
            onChangeListener={onChangeListener}
            form={form}
        />
    )
}

export default TaxesAddNew_containor;
export{
    TaxesAddNew_containor
}