import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { VariableTableColoumDataOffer } from "../../components/table/columnsForTable";
import { globalPropsFabric } from "../../constant";
import { urls } from "../../constants";
import { variableTableHeaderDataVariables } from "../../constant1";
import {useDispatch, useSelector} from 'react-redux';
import { DropDownList } from "../../components/DropDownList";

function Variables({serachField, distinctVariable, deleteListener, toPage, onSearch, onStatusChange }) {
  const pageInfo = useSelector(state=>state.variable.pageInfo);
  return (
    <>
      <div class="content-wrapper">
        <Header
          title="Variables Category"
          subTitle="Click to view the details"
          buttons={[
            {
              name: "ADD NEW",
              url: urls.VARIABLE_ADD,
              isSymbolPresent: true,
            },
          ]}
        />
        <div className="row">
          <div className="col-md-3">
              <DropDownList
                title="Variables"
                list={distinctVariable}
                onClickHandler={(val) => {
                  onSearch(val);
                }}
                active={serachField}
              />
          </div>
          <div className="col-md-9">
            <Table
              noSerach={true}
              tableData={(e) => VariableTableColoumDataOffer({ deleteListener, onStatusChange })}
              onSearch={onSearch}
              toPage={toPage}
              onDelete={deleteListener}
              tableHeader={variableTableHeaderDataVariables}
              ObjectLength={pageInfo?.total}
              pageInfo={pageInfo}
              {...globalPropsFabric}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Variables;
export { Variables };
