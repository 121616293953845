import {app} from '../../../constants';

export const appBrandTableSuccess= app.BRAND_TABLE_SUCCESSFUL;
export const appBrandTableError= app.BRAND_TABLE_ERROR;

export const appBrandAddSuccess= app.BRAND_ADD_SUCCESSFUL;
export const appBrandAddError= app.BRAND_ADD_ERROR;

export const appBrandDetailSuccess= app.BRAND_DETAIL_SUCCESSFUL;
export const appBrandDetailError= app.BRAND_DETAIL_ERROR;

export const appBrandEditSuccess= app.BRAND_EDIT_SUCCESSFUL;
export const appBrandEditError= app.BRAND_EDIT_ERROR;