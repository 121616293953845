import { handleActions } from "redux-actions";
import { 
    settingsFatchSeccess,
    settingsFatchError
} from "./settings.actions";

const defaultState = {};

const reducer = handleActions(
    {
        [settingsFatchSeccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [settingsFatchError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
export { reducer };