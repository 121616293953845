import {BaseService} from './base.service';
import {config} from '../environments';

class VariableCategoryService extends BaseService{
    static get(headers, enocodeQuery){
        
        const url = super.prepareUrl(config.endpoints.variableCategory,enocodeQuery);
       return  super.get(url, headers);
    }
    static distinct(headers, enocodeQuery){
        
        const url = super.prepareUrl(config.endpoints.variableCategory + '/distinct',enocodeQuery);
       return  super.get(url, headers);
    }

    static info(id, headers){
    
        const url = super.prepareUrl(config.endpoints.variableCategory + `/${id}`);
       return  super.get(url, headers);
    }

    static paginate(headers, enocodeQuery){
        
        const url = super.prepareUrl(config.endpoints.variableCategoryPaginate,enocodeQuery);
       return  super.get(url, headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.variableCategory);
       return  super.post(url,data,headers);
    }
    static put(id, data, headers) {
        const url = super.prepareUrl(config.endpoints.variableCategory);
       return  super.put(url + `/${id}`, data ,headers);
    }
    static delete(id, headers) {
        const url = super.prepareUrl(config.endpoints.variableCategory);
       return  super.delete(url + `/${id}`,headers);
    }
}
export default VariableCategoryService;

export {VariableCategoryService};