import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import AddNewFabric from "../../pages/fabrics/fabrics.new";
import { addFabric, GetFabricType } from "./redux/fabricTable.actions";
import { brandById as brands } from "../brand/redux/brandTable.actions";
import { urls } from "../../constants";
import { useHistory } from "react-router-dom";
import { uploadImage } from "../AddOffer/redux/AddOffer.actions";
import { TailorTable } from "../../containers/tailorTable/redux/tailor.actions";

function AddNewFabricContainer(props) {

    
    const history = useHistory();

    const [form, setForm] = useState({
      title_ar:  "null",
      descriptions_ar: "null",
      image_url_ar: "null",
      net_price: 0,
    });

    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const fabricState = useSelector(state=>state.fabric);
    const brandDetail = useSelector(state=>state.brandDetail?.brands);
    const fabricTypeList = useSelector(state=>state.fabricTypeList?.fabric_types);
    const tailorState = useSelector(state=>state.tailor);

    const token = {
      'Authorization' : `${userState.token}`
    };

    const temp = 0 ;
    useEffect(()=>{
      if(!form?.user_id) return;
      dispatch(brands(token, `?tailor_id=${form?.user_id}`));
      dispatch(GetFabricType(token, `?tailor_id=${form?.user_id}`));
    },[form?.user_id]);

    useEffect(() => {
      dispatch(TailorTable(token));
    }, [])

    const submitHandler = (e) => {
        const token = {
          'Authorization' : `${userState.token}`
        };

        console.log(form);

        if( ( form.title_en !=="" ) && ( form.descriptions_en !=="" ) && ( form.price !=="" ) && ( form.fabric_type_id !==undefined )&& ( form.brand_id !=="" ) && ( form.status !==undefined )&& ( form.image_url_en !=="" ) ){
          dispatch( addFabric( {
            ...form,
            user_id: undefined,
            tailor_id: form.user_id
          }, token,()=>{
            history.push(urls.FABRICS);
          } ) );
        }else{
          alert( "Please fill all the boxes." ); // todo : will pass this message from constents
        }
    }
    
    const onChangeListener = (key, value) =>{
      setForm({...form,[key]:value});
    }
    function onUploadImage(data, callBack){
      return dispatch(uploadImage(data[0], token, callBack))
    }
    return (
        <AddNewFabric
            tailorList={tailorState?.rows}
            onChangeListener={onChangeListener}
            submitHandler= {submitHandler}
            fabricState = { fabricState }
            brandDetail = {brandDetail}
            fabricTypesData = {fabricTypeList}
            onUploadImage={onUploadImage} 
            {...form}
        />
    )
}

AddNewFabricContainer.propTypes = {

}

export default AddNewFabricContainer