import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { customersTableColoumSaveSize } from "../../components/table/columnsForTable";
import {CustomertableHeaderSize,globalPropsFabric} from '../../constant';
import queryString from "query-string";
import { urls } from "../../constants";

function Size(props) {
    
    const data = queryString.parse(window.location.search);

    return(
        <>    
            <div  class="content-wrapper">
                <Header 
                    title="Customers"
                    subTitle = {"Measurement For - "+data.name}
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.CUSTOMER_DETAIL+urls.QUERY_STRING_KEY_CUSTOMERiD+props.customersId,
                            isSymbolPresent : false
                        }
                    ]}
                />
                <Table 
                    tableData={(e)=>customersTableColoumSaveSize(props.rows, props.relation)} 
                    tableHeader={CustomertableHeaderSize} 
                    ObjectLength={props.pageInfo?.item_count} 
                    {...globalPropsFabric}/>
            </div>
        </>
    )
}

export default Size;
export {Size};