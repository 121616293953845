import React, { useEffect, useState } from "react";
import DiscountAddAndEdit from "../../pages/discount/discount.addAndEdit";
import queryString from "query-string";
import {useDispatch, useSelector} from 'react-redux';
import { TailorTable } from "../../containers/tailorTable/redux/tailor.actions";
import { brandTable } from "../../containers/brand/redux/brandTable.actions";
import { GetDiscountByIdAction } from "../../containers/discount/redux/discount.action";
import { StyleAction } from "../../containers/style/redux/style.actions";

function DiscountAddAndEditContainor(props) {

    const dispatch = useDispatch();
    const queryObject = queryString.parse(window.location.search);
    const [fireAction, setfireAction] = useState();

    const userState = useSelector(state=>state.user);
    const tailorState = useSelector(state=>state.tailor);
    const brandList = useSelector(state=>state.brands?.rows);
    const stylesList = useSelector(state=>state.styles?.rows);
    const couponDetail = useSelector(state=>state.discountCoupons?.coupon);

    var rows = tailorState?.rows;

    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        dispatch(brandTable(token));
        dispatch(StyleAction(token));
    },[fireAction])
    useEffect(()=>{
        dispatch(TailorTable(token));
        if(queryObject.id){
            dispatch(GetDiscountByIdAction(token,"/"+queryObject.id));
        }
    },0)

    return(
        <DiscountAddAndEdit
            id={queryObject.id}
            rows={rows}
            setfireAction={(boolean)=>setfireAction(boolean)}
            brandList= {brandList}
            fabricList= {stylesList}
            couponDetail= {couponDetail}
            {...props}
        />
    )
}

export default DiscountAddAndEditContainor;
export {
    DiscountAddAndEditContainor
}