import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import {img,changePassword,notification,notificationFullComp, categoryData, discountCheckboxData,discountCheckboxApplicableForData, CustomerPageTableHeaderforMemebersComp } from '../../constant';
import Input from "../../components/input";
import SelectSection from '../../components/SelectSection';
import DropDown from "../../components/selectDropdown";
import CheckboxSection from "../../components/CheckboxSection";
import ChipSection from "../../components/ChipSection/ChipSection";
import queryString from "query-string";
import { urls } from "../../constants";
import { CheckboxSectionV1 } from "../../components/CheckboxSectionV1";
import DiscountService from "../../services/discount.service";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import TextArea from "../../components/textArea";
import { ChipSelection } from "../../components/chipSelection";
import { ImageGrid } from "../../components/imageGridShow";
import { discountValueOrPercentage, applicableForData } from "../../constant1";
import Select from 'react-select';

function DiscountAddAndEdit(props) {
    const queryObject = queryString.parse(window.location.search);
    const userState = useSelector(state=>state.user);
    const history = useHistory();

    const [form, setForm] = useState();

    useEffect(()=>{
        setForm(props?.couponDetail);
    },[props?.couponDetail]);

    const [isDiscountInPercentage , setIsDiscountInPercentage] = useState(true);
    const [isbrand , setIsBrand] = useState(true);

    let percentOrValue;
    let brandOrStyle;
    let images ;
    let percentOrValue_Key;
    let styleOrBrand_key;
    if(isbrand){
        styleOrBrand_key="brand_id"
        brandOrStyle=applicableForData[0].name_en;
        props.setfireAction(isbrand);
        images= props.brandList;
    }
    else{
        styleOrBrand_key="style_id"
        brandOrStyle=applicableForData[1].name_en;
        props.setfireAction(isbrand);
        images= props.fabricList;
    }

    if(isDiscountInPercentage || form.discount_percent){
        percentOrValue_Key= "discount_percent";
        percentOrValue=discountValueOrPercentage[0].id;
    }
    else{
        percentOrValue_Key= "discount_flat";
        percentOrValue=discountValueOrPercentage[1].id;
    }
    
    const onChangeListener = (key, value)=>{
        setForm({...form,[key]:value});
    }

    const submitHandler = ()=>{
        const headers = {
            'Authorization' : `${userState.token}`
        };
        form.is_approved= true;
        DiscountService.post(form,headers).then((resp)=>{
            history.push(urls.DISCOUNTS);
        }).catch(err=>{
            console.log(err);
        })
    }

    var backButtonURL;
    if(queryObject.detailPage){
        backButtonURL= urls.DISCOUNT_DETAILS;
    }else {
        backButtonURL= urls.DISCOUNTS;
    }
    var options=[];

    props?.rows?.map((row,i)=>{
        options.push({ value: row.id, label: row.full_name });
    })

    return(
        <div className="wrapper">    
            <div  class="content-wrapper">                
                <Header 
                    title="Discount Coupons"
                    subTitle = { props.id ? "Edit" : "Add New"}
                    buttons = {[
                        {
                            name : "BACK",
                            url : backButtonURL,
                            isSymbolPresent : false,
                            type: "link"
                        },
                        {
                            name :  props.id ? "Update" : "GENERATE",
                            isSymbolPresent : false,
                            onClickListener: submitHandler,
                            type: "button"
                        },
                    ]}
                />
                <section class="content mb-5">
        <div class="container-fluid">
                <div className="row">
                    <div className="col-sm-9">
                        {/* Chip Section */}
                        <ChipSelection 
                        >
                            <Select
                                options={options}
                                // onChange={(array)=>onChangeListener("tailor_id",array)}
                            />
                        </ChipSelection>
                    {/* Offer Applicable for */}
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckboxSection 
                                value= {brandOrStyle}
                                isbrand= {isbrand}
                                rows={applicableForData} 
                                {...discountCheckboxApplicableForData} 
                                onClickListner={(value)=>{
                                    setIsBrand(value);
                                    onChangeListener('style/brand',value);
                                }}
                            >
                                    <ImageGrid 
                                        value= {form?.[styleOrBrand_key] ? form[styleOrBrand_key]:null}
                                        images= {images} 
                                        onChangeListener={(value)=>{
                                            onChangeListener(styleOrBrand_key,value);
                                        }}
                                    />
                            </CheckboxSection>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">

                            <CheckboxSection 
                                isbrand= {isDiscountInPercentage}
                                checkBoxNumber="isDiscount"
                                value={percentOrValue}
                                displayBlock={"d-none"} 
                                rows={discountValueOrPercentage} 
                                {...discountCheckboxApplicableForData} 
                                onClickListner={(value)=>{
                                    setIsDiscountInPercentage(value);
                                }}
                            />

                        </div>
                    <div className="col-sm-4">
                            <Input value={form?.discount_flat? form?.discount_flat : form?.discount_percent } placeholder={"Discount "+percentOrValue} onChangeListener={(value)=>{
                                onChangeListener(percentOrValue_Key,value);
                            }} id="stitle" defaultValue="" />
                    </div>
                    <div className="col-sm-4"> 
                        <Input 
                            type="date"
                            value={form?.valid_from===null || form?.valid_from== undefined  ? null :new Date(form?.valid_from).toISOString().slice(0, 10)} 
                            placeholder="Valid From"
                            onChangeListener={(value)=>{
                                onChangeListener('valid_from',value);
                            }}
                            id="stitle1" 
                            defaultValue="" 
                        />
                            
                    </div>
                    <div className="col-sm-4"> 
                        <Input 
                            value={form?.valid_till===null || form?.valid_till== undefined  ? null :new Date(form?.valid_till).toISOString().slice(0, 10)} 
                            placeholder="Valid To" 
                            type="date"
                            onChangeListener={(value)=>{
                                onChangeListener('valid_till',value);
                            }}
                            id="stitle2" defaultValue="" 
                        />
                    </div>
                    <div class="col-lg-12">
                            <TextArea 
                                id="discountDes"
                                rows="4"
                                value={form?.descriptions_en}
                                placeholder="Coupon description"
                                onChangeListener={(value)=>{
                                    onChangeListener('descriptions_en',value);
                                }}
                            />
                    </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <SelectSection
                            value={form?.status}
                            onChangeListener={(value)=>{
                                onChangeListener('status',value);
                            }} 
                            heading="Status" 
                            options={['active','inactive']} />
                    </div>
                </div>
                </div></section>
            </div> 
        </div>
    )
}
DiscountAddAndEdit.propTypes = {
    id: PropTypes.string
}
export default DiscountAddAndEdit;
export {DiscountAddAndEdit};