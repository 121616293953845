const config = {
    api : "https://develop-tailoring-api.salescurve.com/api/",
    // api : "http://localhost:9000/api/",
    endpoints:{
        authLogin:"auth/local",
        style: "v1/styles",
        coupons:"v1/coupons",
        customers:"v1/customers",
        measurement: "v1/measurement",
        tailors:"v1/tailors",
        users:"v1/users",
        brandsPaginate:"v1/brands/paginate",
        brands: "v1/brands",
        fabric:"v1/fabrics",
        fabricType:"v1/fabric-types",
        offer:"v1/offers",
        order:"v1/orders",
        review: "v1/tailor-ratings",
        discount: "v1/coupons",
        deal:"v1/deals",
        category:"v1/category",
        variableCategory: "v1/variable-categories",
        variableCategoryPaginate: "v1/variable-categories/paginate",
        uploadPreSignedUrl: "v1/upload/signed-url",
        topDiscount: "v1/top-discount-coupons",
        topTailors : "v1/top-tailors",
        topCustomer: "v1/top-customers",
        pendingCoupon: "v1/coupons",
        outstanding: "v1/order-total-amounts",
        dashboard: "v1/dashboard/analytics",
        dashboardSalesTrend: "v1/dashboard/sales-trend",
        tax: "v1/taxes",
        settings: "v1/settings",
        payout:"v1/tailors/payouts"
    }

}

export default config;

export {config};