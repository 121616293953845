import {BaseService} from './base.service';
import {config} from '../environments';

class CategoryService extends BaseService{
    static get(headers,queryString){
        const url = super.prepareUrl(config.endpoints.category, queryString);
        return  super.get(url,headers);
    }
}

export default CategoryService;
export {CategoryService};