import React  from 'react';
import PropTypes from "prop-types";
import Table from "../../components/table";
import NotificationAbc from "../../components/notificationFullComp";
import Notification from "../../components/notification";
import ChangePassword from "../../components/ChangePassword";
import Header from "../../components/header";
import { TailorPendingColums } from "../../components/table/columnsForTable";
import {img,changePassword,notification,notificationFullComp,tailorDashboardHeaderData,golbalData,tablePendingTailor} from '../../constant';
import { useSelector } from 'react-redux';

function TailorPendingTable(props) {
    
    return(
        <>    
            <div  class="content-wrapper">
                <Header {...tailorDashboardHeaderData} title={"Tailors Pending"} buttons={[]}/>
                <Table 
                    tableData={()=>TailorPendingColums(props.rows)} 
                    tableHeader={tablePendingTailor} 
                    ObjectLength = {props?.pageInfo?.total} 
                    {...golbalData}
                    onSearch={props.onSearch} 
                    toPage={props.toPage}
                    pageInfo={props.pageInfo}
                />
            </div>
        </>
    )
}
TailorPendingTable.propTypes = {
  
}
export default TailorPendingTable;
export {
    TailorPendingTable
}