import {BrandsService} from '../../../services';
import {createAction} from 'redux-actions';
import { useHistory } from "react-router-dom";
import {
    appBrandTableSuccess, 
    appBrandTableError, 
    appBrandAddSuccess, 
    appBrandAddError,
    appBrandDetailSuccess,
    appBrandDetailError,
    appBrandEditSuccess,
    appBrandEditError
} from './brandTable.actionNames';

const brandTableFatchSuccess = createAction(appBrandTableSuccess,(brands)=>brands);
const brandTableFatchError = createAction(appBrandTableError,(brandsErr)=>brandsErr);

const brandAddSuccess = createAction(appBrandAddSuccess,(brandAdd)=>brandAdd);
const brandAddError = createAction(appBrandAddError,(brandAddErr)=>brandAddErr);

const brandDetailSuccess = createAction(appBrandDetailSuccess,(brandDetail)=>brandDetail);
const brandDetailError = createAction(appBrandDetailError,(brandDetailErr)=>brandDetailErr);

const brandEditSuccess = createAction(appBrandEditSuccess,(brandEdit)=>brandEdit);
const brandEditError = createAction(appBrandEditError,(brandEditErr)=>brandEditErr);

function brandTable(token,enocodeQuery){
    return (dispatch)=>{
        BrandsService.get(token,enocodeQuery).then((resp)=>{
             dispatch(brandTableFatchSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function brandById(token,enocodeQuery){
    return (dispatch)=>{
        BrandsService.getBrand(token,enocodeQuery).then((resp)=>{
             dispatch(brandDetailSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function addBrand(data,token){
    return (dispatch)=>{
        BrandsService.post(data,token).then((resp)=>{
             dispatch(brandAddSuccess(resp.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function editBrand(id,data,token, toPage, pageInfo){
    
    return (dispatch)=>{
        BrandsService.put(id,data,token).then((resp)=>{
            toPage(pageInfo.current_page);
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function brandSearch(brand) {
    return (dispatch) => {
        dispatch(brandTableFatchSuccess(brand));
    }
}

function brandDelete(id, token, toPage, pageInfo) {
    return (dispatch) => {
        BrandsService.delete(id, token).then((resp)=>{
            dispatch(brandTableFatchSuccess(resp.data))
            toPage(pageInfo.current_page)
           }).catch((err)=>{
               console.log(err)
           })
    }
}
export {
    brandTable, 
    brandSearch, 
    brandDelete, 
    brandTableFatchError, 
    brandTableFatchSuccess,
    addBrand,
    brandAddSuccess,
    brandAddError,
    brandById,
    brandDetailSuccess,
    brandDetailError,
    editBrand,
    brandEditSuccess,
    brandEditError
}
