import React, { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Order as OrderPage } from "../../pages/customers";
import { CustomersOrders } from "./redux/customer.action";
import queryString from "query-string";

function CustomerOrderTableContainor(params) {

    const queryObj = queryString.parse(window.location.search);

    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const customerOrders = useSelector(state=>state.customerOrders);
    const rows = customerOrders?.data?.rows;
    const pageInfo = customerOrders?.data?.pageInfo;
    const temp =0;
    const token = {
        'Authorization' : `${userState.token}`
    };
    let enocodeQuery = Object.keys(queryObj)
            .map((key) => key + "=" + queryObj[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';

    useEffect(()=>{
        dispatch(CustomersOrders(token, enocodeQuery));
    },[temp])

    return(
        <OrderPage
            enocodeQuery= {enocodeQuery}
            rows= {rows}
            pageInfo= {pageInfo}
        />
    )
}
export default CustomerOrderTableContainor;