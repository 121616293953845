import React  from "react";
import {useDispatch, useSelector} from 'react-redux';
import {CustomersProfile} from "../../pages/customers";
import { CustomersDetailAction } from "./redux/customer.action";
import queryString from "query-string";

function CustomersDetail(props) {

    const data = queryString.parse(window.location.search);
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const customerDetail = useSelector(state=>state.customerDetail);

    const token = {
        'Authorization' : `${userState.token}`
    };
    const queryStr = `/${data.customer_id}`;

    if(customerDetail?.data?.id != data.customer_id ){
        if(queryStr){
            dispatch(CustomersDetailAction(token,queryStr));
        }
    }
    let enocodeQuery = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';
    return(
        <CustomersProfile customerId={enocodeQuery} {...customerDetail?.data}/>
    )
}

export default CustomersDetail
export {
    CustomersDetail
}