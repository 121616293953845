import React  from 'react';
import NotificationAbc from "../../components/notificationFullComp";
import ChangePassword from "../../components/ChangePassword";
import Notification from "../../components/notification";
import { useSelector } from 'react-redux';
import {
  img,
  notification,
  changePassword,
  notificationFullComp,
} from "../../constant";

function Header(props) {
    const userState = useSelector(state=>state.user.user);
    return(
        <>    
            {/* <div>
                <NotificationAbc
                    userName = {userState?.full_name}
                    profilePhoto = { userState?.profile_picture }
                    {...notificationFullComp}
                    changePasswordPopUp={<ChangePassword {...changePassword} />}
                    {...img}
                    notification={<Notification {...notification} />}
                />
            </div> */}
        </>
    )
}
Header.propTypes = {
  
}
export default Header;