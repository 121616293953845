import { urls } from "./constants/app";
const gender = ["Men","Women"]
const category = ["Adult", "Child"]
const statusArray = ['active','inactive']
const gender1 = ["Male","Female"]
const discountValueOrPercentage = [
    {id : "percent", name_en:"Discount in %age"},
    {id : "value", name_en:"Discount by Value"}
]
const applicableForData = [
  {id : "Brand", name_en:"Brand"},
  {id : "Style", name_en:"Style"}
]
const app = {
    dataNotFind: "Data not find"
}
const kababMenuOption= {
    totalResultsLabel: "Branch Addresses: ",
    viewThis: "View",
    editThis: "Edit This",
    deleteThis: "Delete This"
}
const global={
    addressDetailsLabel: "Address details",
    addGeoLocation: "Add GEO Location"
}
const orders = {
    orderTableConsts : {
        totalResultsLabel: "Total Results: ",
        pendingOrdersLabel: "Pending Orders: ",
        viewOrderLable : "View Order",
    },
    tablePageHeaderConsts : {
        title: "Orders",
        subTitle: "Click to view the details"
    }
}
const orderTableHeader = [
    // {
    //     Header: <><input onClick={()=>document.getElementById("news1").checked=true} className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
    //     accessor: 'col1', // accessor is the "key" in the data
    // },
    {
        Header: "ORDER ID",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: "CUSTOMER ID",
        accessor: 'col2',
    },
    {
        Header: "CUSTOMER NAME",
        accessor: 'col3',
    },
    {
        Header: 'ORDE AMT.',
        accessor: 'col4',
    },
    {
        Header: 'TAILOR',
        accessor: 'col5',
    },
    {
        Header: 'PAYMENT TYPE',
        accessor: 'col6',
    },
    {
        Header: 'DELAY',
        accessor: 'col7',
    },
    {
        Header: 'ORDERED AT',
        accessor: 'col8',
    },
    {
        Header: 'STATUS',
        accessor: 'col9',
    },
    {
        Header: '',
        accessor: 'col10',
    },
]
const status = {
  activeLabel : "active",
  inactiveLabel : "inactive"
}
const orderTablePopUp = {
    viewOrderLable: "View Order"
}
const OrderPageUserInfoCompOrderTile = {
    title: "Orders",
    description: "Placed",
    linkTitle: "Sizes",
    linkDescription: "Saved"
}
const stylesPageHeader = {
    title: "Styles",
    subTitle: "Click to view the details",
    buttons: [
        {
            name : "ADD NEW",
            url : urls.STYLE_ADD,
            isSymbolPresent : true
        },
    ]
}
const stylesPageTableConst = {
    totalResultsLabel: "Total Results: ",
    statusActiveLabel: "Status Active",
    editThis: "Edit this Style",
    deleteThis: "Delete this Style", 
}
const stylePageTableHeaderDataForStyles = [
    // {
    //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
    //     accessor: 'col1', // accessor is the "key" in the data
    // },
    {
        Header: "Name",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: "CATEGORY",
        accessor: 'col2',
    },
    {
        Header: 'CREATED AT',
        accessor: 'col3',
    },
    {
        Header: 'TAILOR',
        accessor: 'col4',
    },
    {
        Header: 'STATUS',
        accessor: 'col5',
    },
    {
        Header: '',
        accessor: 'col6',
    },
    {
        Header: '',
        accessor: 'col7',
    },
  ]
const styleAddNew = {
    pageHeader: {
        title: "Style",
        subTitle: "Add New",
        buttons: [
            {
                name : "Back",
                url : urls.STYLES,
                isSymbolPresent : false
            },
            {
                name : "Publish",
                url : "#publish",
                isSymbolPresent : false
            },
        ]
    },
    inputTypeTitle: {
        placeholder: "Title of the Style",
        id: "stitle1",
        defaultValue: ""
    },
    inputTypeCost: {
        placeholder: "Style cost",
        id: "style_price",
        defaultValue: "",
        type:"number"
    },
    inputTypeTextArea: {
        placeholder: "Brief description of the Style"
    },
    selectSection: {
        heading: "Status",
    },
    variablesCheckboxData: {
        heading : "Variables",
        AddnewLabel : "Add New"
    },
    statusValues : ["active","inactive"],
}
const styleEdit = {
    title: "Style",
    subTitle: "Edit",
    buttons: [
        {
            name : "Back",
            url : urls.STYLES,
            isSymbolPresent : false
        },
        {
            name : "UPDATE",
            url : "#update",
            isSymbolPresent : false
        },
    ]
}
const brandsConst = {
    statusActiveLabel: "Status Active",
    editThis: "Edit this Brand",
    deleteThis: "Delete this Brand",
    totalResultsLabel: "Total Results: "
}
const tableHeaderBrand = [
    // {
    //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
    //     accessor: 'col1', // accessor is the "key" in the data
    // },
    {
        Header: "title",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: "fabric type",
        accessor: 'col2', // accessor is the "key" in the data
    },
    {
        Header: "CREATED AT",
        accessor: 'col3',
    },
    {
        Header: "STATUS",
        accessor: 'col4',
    },
    {
        Header: '',
        accessor: 'col5',
    },
    {
        Header: '',
        accessor: 'col6',
    },
  ]
const headerFabrid = {
    title : "Fabrics",
    subTitle : "Click to view the details",
    buttons : [
        {
            name : "Add Fabric Type",
            url : urls.FABRIC_TYPE_ADD,
            isSymbolPresent : true,
            type:"link"
        },
        {
            name : "Add New",
            url : urls.FABRIC_ADD,
            isSymbolPresent : true,
            type:"link"
        },
    ]
}
const tableHeaderFabric = [
    // {
    //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
    //     accessor: 'col1', // accessor is the "key" in the data
    // },
    {
        Header: "TITLE",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: "BRAND NAME",
        accessor: 'col2',
    },
    {
        Header: "COST ( ADULT )",
        accessor: 'col3',
    },
    {
        Header: "COST ( CHILD )",
        accessor: 'col4',
    },
    {
        Header: 'RATING',
        accessor: 'col5',
    },
    {
        Header: 'CREATED AT',
        accessor: 'col6',
    },
    {
        Header: 'TAILOR',
        accessor: 'col7',
    },
    {
        Header: 'STATUS',
        accessor: 'col8',
    },
    {
        Header: '',
        accessor: 'col9',
    },
    {
        Header: '',
        accessor: 'col10',
    },
]
const fabricPageHeaderConst = {
    totalResultsLabel : "Total Result: ",
    viewThis: "View This",
    editThis : "Edit this Fabric",
    deleteThis : "Delete this Fabric"
}
const tableHeaderDiscount = [
    // {
    //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
    //     accessor: 'col1', // accessor is the "key" in the data
    // },
    {
        Header: "COUPON CODE",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: 'tailorName',
        accessor: 'col2',
    },
    {
        Header: "coupon Description",
        accessor: 'col3',
    },
    {
        Header: 'applicable for',
        accessor: 'col4',
    },
    {
        Header: 'Percentage/Value',
        accessor: 'col5',
    },
    {
        Header: 'valid from',
        accessor: 'col6',
    },
    {
        Header: 'valid tilll',
        accessor: 'col7',
    },
    {
        Header: 'created at',
        accessor: 'col8',
    },
    {
        Header: 'status',
        accessor: 'col9',
    },
    {
        Header: '',
        accessor: 'col10',
    },
    {
        Header: '',
        accessor: 'col11',
    }
]
const discountConst = {
    totalResultsLabel : "Total Result: ",
    editThis : "Edit this Discount",
    deleteThis : "Delete this Discount"
}
const discountPageHeader = {
    title: "Discount Coupons",
    subTitle: "Click to view the details",
    buttons: [
        {
            name : "ADD NEW",
            url : urls.DISCOUNT_ADD,
            isSymbolPresent : true
        }
    ]
}
const pendingDiscountPageHeader = {
    title: "Discount Coupons Pending",
    subTitle: "Click to view the details",
    buttons: [
        // {
        //     name : "ADD NEW",
        //     url : urls.DISCOUNT_ADD,
        //     isSymbolPresent : true
        // }
    ]
}

const variableTableHeaderDataVariables = [
    // {
    //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
    //     accessor: 'col1', // accessor is the "key" in the data
    // },
    {
        Header: "NAME",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: 'GENDER',
        accessor: 'col2',
    },
    {
        Header: 'TAILOR',
        accessor: 'col3',
    },
    {
        Header: 'CREATED AT',
        accessor: 'col4',
    },
    {
        Header: 'STATUS',
        accessor: 'col5',
    },
    {
        Header: '',
        accessor: 'col6',
    },
    {
        Header: '',
        accessor: 'col7',
    },
]

const CustomertableHeaderOrder = [
    // {
    //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
    //     accessor: 'col1', // accessor is the "key" in the data
    // },
    {
        Header: "ORDER ID",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: "CUSTOMER ID",
        accessor: 'col2',
    },
    {
        Header: "ORDER AMT.",
        accessor: 'col3',
    },
    {
        Header: 'TAILOR',
        accessor: 'col4',
    },
    {
        Header: 'DELIVERY DATE',
        accessor: 'col5',
    },
    {
        Header: 'DELIVERY DELAY',
        accessor: 'col6',
    },
    {
        Header: 'CREATED AT',
        accessor: 'col7',
    },
    {
        Header: 'STATUS',
        accessor: 'col8',
    },
    // {
    //     Header: '',
    //     accessor: 'col10',
    // },
    {
        Header: '',
        accessor: 'col9',
    },
]
const customerOrdersConst = {
  totalResultsLabel : "Total Result: ",
  pendingOrdersLabel : "Pending Orders: ",
  view: "View Order"
}

const TailorPagesHeaderForOrder = [
    // {
    //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
    //     accessor: 'col1', // accessor is the "key" in the data
    // },
    {
        Header: "ORDER ID",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: "CUSTOMER ID",
        accessor: 'col2',
    },
    {
        Header: "CUSTOMER NAME",
        accessor: 'col3',
    },
    {
        Header: "ORDER AMT.",
        accessor: 'col4',
    },
    {
        Header: 'TAILOR',
        accessor: 'col5',
    },
    {
        Header: 'PAYMENT TYPE',
        accessor: 'col6',
    },
    {
        Header: 'DELAY',
        accessor: 'col7',
    },
    {
        Header: 'CREATED AT',
        accessor: 'col8',
    },
    {
        Header: 'STATUS',
        accessor: 'col9',
    },
    // {
    //     Header: '',
    //     accessor: 'col11',
    // },
    {
        Header: '',
        accessor: 'col11',
    },
]
const TailorPageHeaderforCouponTable = [
    // {
    //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
    //     accessor: 'col1', // accessor is the "key" in the data
    // },
    {
        Header: "COUPON CODE",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: "CATEGORY",
        accessor: 'col2',
    },
    {
        Header: 'APPLICABLE FOR',
        accessor: 'col3',
    },
    {
        Header: 'VALUE',
        accessor: 'col4',
    },
    {
        Header: 'CREATED AT',
        accessor: 'col5',
    },
    {
        Header: 'STATUS',
        accessor: 'col6',
    },
    {
        Header: '',
        accessor: 'col7',
    },
    // {
    //     Header: '',
    //     accessor: 'col8',
    // },
]

const TailorPageHeaderForReviewTable = [
    {
        Header: "REVIEW TITLE",
        accessor: 'col1', // accessor is the "key" in the data
    },
    {
        Header: "RATING",
        accessor: 'col2', // accessor is the "key" in the data
    },
    {
        Header: "CUSTOMER NAME",
        accessor: 'col3',
    },
    {
        Header: "CREATED AT",
        accessor: 'col4',
    },
    // {
    //     Header: '',
    //     accessor: 'col5',
    // },
]
const pendingTailorConst = {
    title : "Tailors Pending for Approval",
    label : "Tailors",
    status : "Pending",
    buttonLabel : "view all",
}
const img = {
    percentageImgURL : "https://i.ibb.co/YTNXSh2/percentage.jpg",
    trendingUpImgURL : "https://i.ibb.co/YNZ8bkD/trending-up.jpg",
    ratingAndInfo : "https://i.ibb.co/n1H6jyc/user.jpg",
    navSideBar1 : "https://i.ibb.co/QpJQHFQ/menu-left-alt.jpg",
    navSideBar2 : "https://i.ibb.co/jJ8Ct7c/close.jpg",
    dotImg : "https://i.ibb.co/JjNg5Qv/dot.jpg",
    fabric : "https://i.ibb.co/tPd1pvW/fabric.jpg"
}
const currency = {
    currencyType: " AED"
}
const MostOrderedFabricConst = {
    title: "Most Ordered Fabric",
    fabricTypeLabel: "FABRIC TYPE - ",
    fabricNameLabel: "Fabric Name - ",
    fabricBrandLabel: "Fabric Brand - "
}
const highLowFabricCostConst = {
    titleLow: "Low Cost Fabric",
    titleHigh: "High Cost Fabric",
    fabricNameLabel: "Fabric Name - ",
    high: "High",
    low: "Low",
}
const OrderComingFromLocationConst = {
    title: "Order Coming From Location",
    persantageSign: " %"
}
const mostOrderedStyleConst = {
    title: "Most Ordered Styles"
}
const localStorageKey = {
    key: "user"
}
const FabricTypeCheckboxData = {
    heading : "Fabric Types",
    AddnewLabel : "Add New",
    checkBoxNumber:2
}
const tableHeaderTaxes = [
    {
        Header: "Id",
        accessor: 'col1',
    },
    {
        Header: 'NAME',
        accessor: 'col2',
    },
    {
        Header: 'PERCENTAGE (%)',
        accessor: 'col3',
    },
    {
        Header: 'CREATED AT',
        accessor: 'col4',
    },
    {
        Header: 'STATUS',
        accessor: 'col5',
    },
    {
        Header: '',
        accessor: 'col6',
    },
    {
        Header: '',
        accessor: 'col7',
    },
]
const tableHeaderPayout = [
    {
        Header: "Tailor Name",
        accessor: 'col1',
    },
    {
        Header: 'Total Earning',
        accessor: 'col2',
    },
    {
        Header: 'Current Outstanding',
        accessor: 'col3',
    },
    {
        Header: 'khayyaty Commission',
        accessor: 'col4',
    },
    {
        Header: 'Payout Date',
        accessor: 'col5',
    },
    {
        Header: 'Status',
        accessor: 'col6',
    },
    {
        Header: '',
        accessor: 'col7',
    },
    {
        Header: '',
        accessor: 'col8',
    },
]
const taxesConst = {
    tableConsts : {
        totalResultsLabel: "Total Results: ",
        editThis: "Edit This Tax",
        deleteThis: "Delete This Tax"
    },
    tablePageHeaderConsts : {
        title: "Taxes",
        subTitle: "Click to view the details",
        buttons: [
            {
                name : "ADD NEW",
                url : urls.TAX_ADD,
                isSymbolPresent : true
            },
        ]
    }
    
}
const payoutConst = {
    tableConsts : {
        totalResultsLabel: "Total Results: ",
        editThis: "Edit This Tax",
        deleteThis: "Delete This Tax"
    },
    tablePageHeaderConsts : {
        title: "Payout",
        subTitle: "Click to view the details",
    }
    
}
const taxesAddPageConst = {
    selectSection : {
        array: ['active','inactive'],
        heading: "Status"
    },
    inputTypeText : {
        placeholder: "Title of Tax"
    },
    inputTypeTextArea: {
        placeholder: "Brief Description of the Tax"
    },
    inputTypeNumber: {
        placeholder: "Tax Percentage ( % )"
    },
    texesAddPageHeaderConsts : {
        title: "Taxes",
        subTitle: "Add New",
        buttons: [
            {
                name : "Back",
                url : urls.TAXES,
                isSymbolPresent : false,
                type: "link"
            },
            {
                name : "Publish",
                isSymbolPresent : false,
                type: "button"
            }
        ]
    }
    
}
const taxEditPageConst = {
    selectSection : {
        array: ['active','inactive'],
        heading: "Status"
    },
    inputTypeText : {
        placeholder: "Title of Tax"
    },
    inputTypeTextArea: {
        placeholder: "Brief Description of the Tax"
    },
    inputTypeNumber: {
        placeholder: "Tax Percentage ( % )"
    },
    texEditPageHeaderConsts : {
        title: "Taxes",
        subTitle: "Edit",
        buttons: [
            {
                name : "Back",
                url : urls.TAXES,
                isSymbolPresent : false,
                type: "link"
            },
            {
                name : "Update",
                isSymbolPresent : false,
                type: "button"
            }
        ]
    }
}
const MeasurementTypeConst = {
    title : "Measurement Type",
    valueKey1 : "AI",
    valueKey2 : "BMI",
    valueKey3 : "MANUAL"
}
const PendingDiscountCouponDetailPageConst = {
    bodyConst: {
        brandDetailLabel: "Brand",
        couponForLabel: "Coupon For",
        couponTypeLabel: "Discount in",
        percentageLabel : "Percentage",
        percentageSign : " %",
        valueLabel : "Value",
        discountPercentLabel: "Discount",
        validFromLabel: "Valid From",
        validToLabel: "Valid To",
        couponDescriptionLabel: "Coupon Description",
        userInfo: {
            title: "Coupon Owner (Tailor)",
            avgTimeLabel : "Avg. Time: ",
            daysLabel: "days",
            ImgAlt: "tailor image"
        }
    },
    headerConst : {
        title: "Discount Coupon Pending",
        subTitle: "Details",
        buttons: [
            {
                name : "Back",
                url : urls.DISCOUNT_PENDING,
                isSymbolPresent : false,
                type: "link"
            },
            {
                name : "Approve",
                isSymbolPresent : false,
                type: "button"
            }
        ]
    },
    abc:"vipin"
}
export {
    app,
    orders,
    orderTableHeader,
    status,
    orderTablePopUp,
    OrderPageUserInfoCompOrderTile,
    stylesPageHeader,
    stylesPageTableConst,
    stylePageTableHeaderDataForStyles,
    styleAddNew,
    headerFabrid,
    tableHeaderFabric,
    fabricPageHeaderConst,
    brandsConst,
    tableHeaderBrand,
    styleEdit,
    tableHeaderDiscount,
    discountConst,
    discountPageHeader,
    variableTableHeaderDataVariables,
    CustomertableHeaderOrder,
    customerOrdersConst,
    TailorPagesHeaderForOrder,
    TailorPageHeaderforCouponTable,
    TailorPageHeaderForReviewTable,
    pendingTailorConst,
    img,
    MostOrderedFabricConst,
    currency,
    OrderComingFromLocationConst,
    mostOrderedStyleConst,
    localStorageKey,
    FabricTypeCheckboxData,
    tableHeaderTaxes,
    taxesConst,
    taxesAddPageConst,
    taxEditPageConst,
    highLowFabricCostConst,
    MeasurementTypeConst,
    gender,
    category,
    statusArray,
    gender1,
    global,
    kababMenuOption,
    payoutConst,
    tableHeaderPayout,
    discountValueOrPercentage,
    pendingDiscountPageHeader,
    PendingDiscountCouponDetailPageConst,
    applicableForData
}