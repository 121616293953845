import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Offer } from "../../pages/offer";
import { offerTable, offerDelete, offerSearch} from "./redux/offersTable.actions";
import { offerUpdate } from '../AddOffer/redux/AddOffer.actions';

function OffersTableContainer(props) {
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const offer = useSelector(state=>state.offer);
    
    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        dispatch(
          offerSearch({
            ...offer,
            search: "",
          })
        );
    },0)
    useEffect(()=>{
        toPage(1);
    }, [offer.search]);

    function deleteListener(id) {
        dispatch(offerDelete(id, token));
    }

    function toPage(page, limit) {
        let search = offer.search;

        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.name = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';

        dispatch(offerTable(token, enocodeQuery));
    }

    function offerUpdateListener(id, data) {
        dispatch(offerUpdate(id, data, token,toPage(offer.pageInfo.current_page)));
    }

    function onSearch(str) {
        dispatch(
          offerSearch({
            ...offer,
            search: str,
          })
        );
      }
    
      function onStatusChange(id) {
        return (status) => {
            offerUpdateListener(id, {
                status: status ? 'active' : 'inactive'
            });
        }
      }

    return <Offer userState = { userState.user } onStatusChange={onStatusChange} onSearch={onSearch}  deleteListener={deleteListener} toPage={toPage}/>
}

export default OffersTableContainer;
export {OffersTableContainer};