import React  from 'react';
import PropTypes from "prop-types";
import Table from "../../components/table";
import Header from "../../components/header";
import { FabricColums } from "../../components/table/columnsForTable";
import { headerFabrid, tableHeaderFabric, fabricPageHeaderConst } from "../../constant1";
import { DropDownList } from "../../components/DropDownList";

function Fabrics(props) {
    
    return(
        <>    
            <div  class="content-wrapper">
                <Header {...headerFabrid}/>
                <div className="row">
                    <div className="col-md-3">
                        <DropDownList
                            title="Fabrics"
                            list={props?.distinctFabricList}
                            onClickHandler={(val) => {
                                props.onSearch(val);
                            }}
                            active={props.serachField}
                        />
                    </div>
                    <div className="col-md-9">
                        <Table 
                            toPage={props.toPage}
                            noSerach={true} 
                            onSearch={()=>{}}
                            tableData={()=>FabricColums(props.rows,props.deleteListener,props.onStatusChange)} 
                            tableHeader={tableHeaderFabric}
                            ObjectLength={props.pageInfo?.total}
                            {...fabricPageHeaderConst}
                            pageInfo={props.pageInfo}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
Fabrics.propTypes = {
  toPage: PropTypes.func,
  onSearch: PropTypes.func,
  rows: PropTypes.array,
}

export default Fabrics;