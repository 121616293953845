import {TopDiscount} from '../../../services';
import {createAction} from 'redux-actions';
import {appCouponListSuccess,appCouponListError} from './discount.actionNames';

const couponListFatchSuccess = createAction(appCouponListSuccess,(userData)=>userData);
const couponListFatchError = createAction(appCouponListError,(userLoginErr)=>userLoginErr);

function coupon(token,queryString){
    return (dispatch)=>{
        TopDiscount.get(token,queryString).then((resp)=>{
             dispatch(couponListFatchSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

export {coupon, couponListFatchError, couponListFatchSuccess}
