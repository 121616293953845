import React from "react";
import { Header } from "../../components/header";
import { payoutConst, tableHeaderPayout } from "../../constant1";
import Table from "../../components/table";
import columnsForPayout from "./columnsForPayout";

function PayoutPage(props) {
    return(
      <div class="content-wrapper">
            <Header {...payoutConst.tablePageHeaderConsts} />
            <Table
              tableHeader={tableHeaderPayout} 
              tableData= {()=>columnsForPayout(props.rows, props.deleteListener, props.onStatusChange)}
              totalResultsLabel={payoutConst.tableConsts.totalResultsLabel}
              ObjectLength={props.pageInfo?.total}
              onSearch={props.onSearch} 
              toPage={props.toPage}
              pageInfo={props.pageInfo}
            /> 
        </div>
    )
}

export default PayoutPage;
export{
    PayoutPage
}