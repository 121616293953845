import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'; 

function Login({login, submitHandler}){
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const onEmailChange = (event)=>{
        setEmail(event.target.value);
    }

    const onPasswordChange = (event)=>{
        setPassword(event.target.value);
    }
    const onSubmit = ()=>{
        if(submitHandler){
            submitHandler(email, password)
        }
    }
  return(
    <div className="row">
      <div className="col-md-11">
          <h2 className="mb-2 mt-5">{login.heading}</h2>
          <p className="f-14 mb-3">{login.text}</p>
      </div>

      <div className="col-lg-11">
          <div className="form-label-group">
              <input type="email" id="EMAIL" value={email} onChange={onEmailChange}  className="form-control" placeholder={login.emailPlaceholder} required />
              <label htmlFor="EMAIL">{login.emailPlaceholder}</label>
          </div>
      </div>

      <div className="col-lg-11">
          <div className="form-label-group">
              <input type="password" id="PASSWORD" value={password} onChange={onPasswordChange} className="form-control" placeholder={login.passwordPlaceholder} required />
              <label htmlFor="PASSWORD">{login.passwordPlaceholder}</label>
              <Link to={login.forgotPasswordURL} className="forgetAbso text-muted">{login.labelForgot}</Link>
          </div>
      </div>

      <div className="col-md-12">
          <button className="btn btn-primary mt-3" onClick={onSubmit}>{login.label}</button>
      </div>        

    </div>
  )
}
Login.propTypes = {
    login : PropTypes.shape({
        heading : PropTypes.string,
        text : PropTypes.string,
        emailPlaceholder : PropTypes.string.isRequired,
        passwordPlaceholder : PropTypes.string.isRequired,
        labelForgot : PropTypes.string, 
        label : PropTypes.string,
        forgotPasswordURL : PropTypes.string ,
        buttonURL : PropTypes.string
      }),
   submitHandler: PropTypes.func   
  };
export default Login;