import React from "react";
import PropTypes from "prop-types";

function DescriptionAndTile(props) {
  return (
    <div className="card complaint-card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-4 col-4">
            <h3 className="card-title"> {props.titleLabel}</h3>
          </div>
          <div className="col-md-8 col-8 text-right">
            <h5 className="f-18 font-weight-medium mt-2">
              {props.orderNumber}
            </h5>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-3">
            <div className="order-bxwhiite">
              {props.styleImage ? (
                <img src={props.styleImage} style={{ height: "100%" }} />
              ) : (
                "No Image"
              )}
            </div>
            <p>
              {props.styleLabel}:{" "}
              <span className="font-weight-bold">{props.styleValue}</span>
            </p>
          </div>
          <div className="col-md-3">
            <div className="order-bxwhiite">
              {props.fabricImage ? (
                <img src={props.fabricImage} style={{ height: "100%" }} />
              ) : (
                "No Image"
              )}
            </div>
            <p className="mb-1">
              {props.fabricLabel}:{" "}
              <span className="font-weight-bold">{props.fabricValue}</span>
            </p>
            <p className="mb-1">
              {props.typeLabel}:{" "}
              <span className="font-weight-bold">{props.typeValue}</span>
            </p>
            <p>
              {props.costLabel}:{" "}
              <span className="font-weight-bold">{props.costValue}</span>
            </p>
          </div>
          {props?.variableList?.map((variableObj) => {
            return (
              <div className="col-md-3">
                <div className="order-bxwhiite">
                  {variableObj?.variable?.image_url_en ? (
                    <img
                      src={variableObj?.variable?.image_url_en}
                      style={{ height: "100%" }}
                    />
                  ) : (
                    "No Image"
                  )}
                </div>
                <p>
                  {variableObj?.variable_category?.name_en + " Style"}:{" "}
                  <span className="font-weight-bold">
                    {variableObj?.variable?.name_en}{" "}
                    {variableObj?.variable_category?.name_en == "Tarbosh"
                      ? "( Qty :" + variableObj?.quantity + " )"
                      : ""}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

DescriptionAndTile.propTypes = {
  costValue: PropTypes.string,
  costLabel: PropTypes.string,
  fabricLabel: PropTypes.string,
  frontStyleLabel: PropTypes.string,
  frontStyleValue: PropTypes.string,
  fabricValue: PropTypes.string,
  orderNumber: PropTypes.string,
  styleLabel: PropTypes.string,
  styleValue: PropTypes.string,
  sideLinesLabel: PropTypes.string,
  sideLinesValue: PropTypes.string,
  stichesValue: PropTypes.string,
  stichesLabel: PropTypes.string,
  titleLabel: PropTypes.string,
  typeLabel: PropTypes.string,
  tarboshLabel: PropTypes.string,
  typeValue: PropTypes.string,
  tarboshValue: PropTypes.string,
};
export default DescriptionAndTile;
