import React from 'react';
import MostOrderedFabric from "../../components/listForMostOrderdFabric";
import { MostOrderedFabricConst, currency, img } from "../../constant1";

function MostOrderedFabricContainer(props) {

    const Obj = { key1 : "fabric_type_name", key2 : "sum_price" , key3 : "title_en", key4:"cust_count", key5:"brand_name"};

    return(
        <MostOrderedFabric
            {...MostOrderedFabricConst}
            {...currency}
            {...props}
            img={img.trendingUpImgURL}
            {...Obj}
        />
    )
}

export default MostOrderedFabricContainer;
export{
    MostOrderedFabricContainer
}