import React, { useEffect, useState } from "react";
import { Styles as StylePage } from "../../pages/style";
import { StyleAction, actionSearch, styleDelete, updateStyle, distinctStyle } from "./redux/style.actions";
import {useDispatch, useSelector} from 'react-redux';

function Styles(props) {
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const styleData = useSelector(state=>state.styles);
    const [distictStyleList, setDistinctStyle] = useState([]);
    var rows = styleData?.rows;
    var pageInfo = styleData?.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        dispatch(
            distinctStyle(token, setDistinctStyle)
        )
        // dispatch(
        //     actionSearch({
        //     ...styleData,
        //     search: "",
        //   })
        // );
    },[])

    useEffect(() => {
        if(!distictStyleList[0]?.name) return;
        dispatch(
            actionSearch({
            ...styleData,
            search: distictStyleList[0]?.name,
          })
        );
    }, [distictStyleList])

    useEffect(()=>{
        toPage(1);
    }, [styleData.search]);

    function toPage(page, limit) {
        let search = styleData.search;

        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.name = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';
        
        dispatch(StyleAction(token, enocodeQuery));
    }
    function deleteListener(id) {
        dispatch(styleDelete(id, token));
    }
    function onSearch(str) {
        dispatch(
            actionSearch({
            ...styleData,
            search: str,
          })
        );
    }

    function onStatusChange(id,status) {
        dispatch(updateStyle(id, {
            status: status ? 'active' : 'inactive'
        },token, toPage, pageInfo));
    }
    return(
        <StylePage
            serachField={styleData?.search}
            distictStyleList={distictStyleList}
            rows = {rows}
            pageInfo = {pageInfo}
            onSearch={onSearch}
            toPage={toPage}
            deleteListener={deleteListener}
            onStatusChange={onStatusChange}
        />
    )
}

export default Styles;