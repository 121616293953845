import { OutstandingService } from "../../../services";
import { createAction } from "redux-actions";
import { appOutstandingSuccess, appOutstandingError } from "./outstanding.actionNames";

const OutstandingFatchSuccess = createAction(appOutstandingSuccess,(data)=>data);
const OutstandingFatchError = createAction(appOutstandingError,(ErrorData)=>ErrorData);

function OutstandingAction(token,queryString) {
    return((dispatch)=>{
        OutstandingService.get(token,queryString).then((resp)=>{
            dispatch(OutstandingFatchSuccess(resp.data.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}
export {
    OutstandingAction,
    OutstandingFatchSuccess,
    OutstandingFatchError
}