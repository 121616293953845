import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {TailorReview} from "../../pages/tailor";
import { CustomersReviews } from "../customer/redux/customer.action";

function TailorReviewContainor(params) {
    
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const reviews = useSelector(state=>state.reviews?.data);
    var rows = reviews?.rows;
    var pageInfo = reviews?.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };
    
    var temp =0;
    let enocodeQuery = window.location.search
    useEffect(()=>{
        dispatch(CustomersReviews(token,enocodeQuery));
    },[temp])

    return(
        <TailorReview
            enocodeQuery = {window.location.search}
            rows={rows}
            pageInfo={pageInfo}
        />
    )
}

export default TailorReviewContainor;
export {
    TailorReviewContainor
}