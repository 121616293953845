import React, { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { OrderTable as OrderTablePage } from "../../pages/orders";
import { OrderTableAction, orderTableSuccess, actionSearch } from "./redux/order.actions";

function OrderTable(props) {
    const dispatch = useDispatch();

    const userState = useSelector(state=>state.user);
    const orders = useSelector(state=>state.orderTable);
    var rows = orders.data?.rows;
    var pageInfo = orders.data?.pageInfo;
    
    const token = {
        'Authorization' : `${userState.token}`
    };
    
    useEffect(()=>{
        dispatch(
            actionSearch({
            ...orders.data,
            search: "",
          })
        );
    },0)
    
    useEffect(()=>{
        toPage(1);
    }, [orders.search]);

    function toPage(page, limit) {
        let search = orders.search;

        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.id = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';
        
        dispatch(OrderTableAction(token, enocodeQuery));
    }
    function onSearch(str) {
        dispatch(
            actionSearch({
            ...orders.data,
            search: str,
          })
        );
    }

    return(
        <OrderTablePage 
            toPage={toPage} 
            onSearch={onSearch} 
            rows={rows} 
            pageInfo={pageInfo}
        />
    )
}

export default OrderTable;