import {TopTailor} from '../../../services';
import {createAction} from 'redux-actions';
import {appTailorListSuccess,appTailorListError} from './tailor.actionNames';

const tailorListFatchSuccess = createAction(appTailorListSuccess,(tailorData)=>tailorData);
const tailorListFatchError = createAction(appTailorListError,(tailorLoginErr)=>tailorLoginErr);

function tailor(token,queryString){
    return (dispatch)=>{
        TopTailor.get(token,queryString).then((resp)=>{
             dispatch(tailorListFatchSuccess(resp.data.data));
            }).catch((err)=>{
                console.log(err);
            })
    }
}

export {tailor, tailorListFatchError, tailorListFatchSuccess}