import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { TailorPageColumsForCoupons } from "../../components/table/columnsForTable";
import {globalPropsFabric} from '../../constant';
import { urls } from "../../constants";
import { TailorPageHeaderforCouponTable } from "../../constant1";
function Coupons(props) {
    return(
        <>    
            <div  class="content-wrapper">
                <Header 
                    title="Tailor"
                    subTitle = "Coupons"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.TAILOR_DETAILS+props.enocodeQuery,
                            isSymbolPresent : false,
                            type:'link'
                          }
                    ]}
                />
                <Table 
                    tableData={()=>TailorPageColumsForCoupons(props.rows)} 
                    tableHeader={TailorPageHeaderforCouponTable} 
                    ObjectLength={props.pageInfo?.item_count} 
                    {...globalPropsFabric}
                />
            </div>
        </>
    )
}
export default Coupons;
export {Coupons}