import React, { useState } from "react";
import DatePicker from "react-datepicker";

const StartDate = (props) => {
    const [startDate, setStartDate] = useState(()=>{
        if(props.positionInterChange){
            props.liftStateUpForHeader({
                start : new Date(),
                isStart : true
            });
        }
        return new Date();
    });
    return (
            <DatePicker selected={startDate} dateFormat="dd MMM , yyyy" onChange={date => {
                    setStartDate(date);
                        if(props.positionInterChange)props.liftStateUpForHeader({
                        start : date,
                        isStart : true
                    });
                }}
            />
    );
};
const EndDate = (props) => {
    var extendedOneMonth;
    const [endDate, setEndDate] = useState(()=>{
        var d = new Date()
        var currentMonth = d.getMonth();
        extendedOneMonth = new Date(d.setMonth(currentMonth+1));
        if(props.positionInterChange){
            props.liftStateUpForHeader({
                end : extendedOneMonth,
                isStart : false
            });
        }
        return extendedOneMonth;
    });
    return (
        <DatePicker selected={endDate} dateFormat="dd MMM , yyyy" onChange={date => {
            setEndDate(date);
                if(props.positionInterChange)props.liftStateUpForHeader({
                    end : date,
                    isStart : false
                });
            }} 
        />
    );
};
function DatePickerComp(props) {

    return(
        <>
            <div className="row mt-4">
                <div className="">
                <span className="text-muted f-10"><strong>{props.labelFrom}</strong></span>
                <StartDate {...props} className="mr-1 ml-1"/>
                </div>
                <div className=" ">
                <span className="text-muted f-10"><strong>{props.labelTo}</strong></span>
                <EndDate {...props} className="mr-1 ml-1"/>
                </div>
            </div>
            
            
        </>
    )
}

export default DatePickerComp ;
export {DatePickerComp};