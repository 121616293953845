import React, {useState} from 'react';
import propTypes from 'prop-types'; 



const ChipSection = (props) => {

    const[inputTextState,setInputTextState] = useState();
    const[listItemState,setListItemState] = useState({list:[]});

    function onChangeHandler(e){
        setInputTextState(e.target.value);
    }

    function removeHandler(e, index){
        e.preventDefault();
        
        const currrentList = [];
        Array.prototype.push.apply(currrentList, listItemState.list);

        //let currrentList = [...listItemState.list];


        currrentList.splice(index,1);
        setListItemState({list:currrentList});
        props.onChangeListener(currrentList);
    }

    function enterHandler(e) {
        
        if (e.key === 'Enter') {
            
            if( inputTextState !== ""){
                
                //let currrentList = [...listItemState.list];
                const currrentList = [];
                Array.prototype.push.apply(currrentList, listItemState.list);


                let length = currrentList.length;
                currrentList.push({id:length, text:e.target.value,apiUrl:''});
                
                //const uniqueList = setUnqiueList(currrentList);
                //setListItemState({list:uniqueList});
                
                setListItemState({list:currrentList});
                setInputTextState('');
                props.onChangeListener(currrentList);
            }
        }

    } // End Function

    const Chip = (props) => {
        return (<li>{props.text}<a onClick={props.clicked} href={"/"}><i className="mdi mdi-close"></i></a></li>);   
    }
    
    const Chips = (props) => {
        return props.list.map( (singleChip) => {
            return <Chip clicked={ (e) => removeHandler(e,singleChip.id)} key={singleChip.id} text={singleChip.text} apiUrl={singleChip.apiUrl} />
        });
    }

    Chip.propTypes = {
        text: propTypes.string,
        clicked: propTypes.func
    } 

    Chips.propTypes = {
        list: propTypes.array
    } 

    return (
        <div className="card">
            <div className="card-header">
                <h6 className="card-title">{props.title}</h6>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <div className="form-label-group">
                            <input type="text" id="typelocation" placeholder={props.placeholder} onChange={(e)=>onChangeHandler(e)} onKeyDown={enterHandler} value={inputTextState} className="form-control" required=""/>
                            <label for="typelocation">{props.placeholder}</label>
                        </div>
                        <div className="tailorTags mb-3">
                            <ul>
                                <Chips list={listItemState.list} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ChipSection.propTypes = {
    title: propTypes.string,
    placeholder: propTypes.string
}
ChipSection.defaultProps = {
    onChangeListener: ()=>{}
}


export default ChipSection;


/* const setUnqiueList = (objArr)=>{
    let mymap = new Map(); 

    const unique = objArr.filter(el => { 
        const val = mymap.get(el.text); 
        if(val) { 
            if(el.id < val) { 
                mymap.delete(el.text); 
                mymap.set(el.text, el.id); 
                return true; 
            } else { 
                return false; 
            } 
        } 
        mymap.set(el.text, el.id); 
        return true; 
    }); 

    return unique;

}  */
// End SetUniqueList
