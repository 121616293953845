import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../components/input';
import TextArea from '../../components/textArea';
import SelectSection from '../../components/SelectSection'
import Header from "../../components/header";
import { addNewBrand } from "../../constants";
import ImageUpload from "../../components/imageUpload";
import { imageUpload } from "../../constant";
import { FabricTypeCheckboxData, gender, statusArray } from "../../constant1";
import { DropDown } from "../../components/selectDropdown";

function AddNewBrand(props) {
    addNewBrand.buttons[1].onClickListener = props.submitHandler;
    return (
      <div className="wrapper">    
        <div  class="content-wrapper">
            <Header 
              {...addNewBrand }
            /> 
        <section class="content mb-5">
        <div class="container-fluid">
            <div className='row'>
                <div className="col-sm-8 connectedSortable">
                    <div className="row">
                        <div className="col-sm-12">
                            <Input 
                                value={props.name_en}
                                placeholder="Title of the brand" 
                                id="stitle1"  
                                defaultValue="" 
                                onChangeListener={(value)=>{
                                    props.onChangeListener('name_en',value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <DropDown  
                                value={props.fabric_type_id} 
                                {...FabricTypeCheckboxData} 
                                options={props.fabricTypesData}  
                                onChangeListener={(value)=>{
                                    props.onChangeListener('fabric_type_id',value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <TextArea 
                                value={props.descriptions_en} 
                                rows="10" 
                                placeholder="Brief description of the brand"
                                onChangeListener={(value)=>{
                                    props.onChangeListener('descriptions_en',value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 connectedSortable">
                    <SelectSection 
                        value={props.status} 
                        heading="Status" 
                        options={statusArray} 
                        onChangeListener={(value)=>{
                            props.onChangeListener('status',value);
                        }}
                    />
                    <SelectSection 
                        value={props.gender}
                        heading="Gender" 
                        options={gender} 
                        onChangeListener={(value)=>{
                            props.onChangeListener('gender',value);
                        }}
                    />
                    <ImageUpload
                        image= {props.image_url_en}
                        {...imageUpload}
                        onChangeListener={(e) => {
                            props.onUploadImage(e, (url) => {
                                props.onChangeListener('image_url_en',url);
                            //   props.onChangeListener({
                            //     image_url_en: url,
                            //     image_url_ar: url,
                            //   });
                            });
                          }} 
                    />
                </div>
            </div>
          </div>
          </section></div>
        </div>
    )
}

AddNewBrand.propTypes = {
    submitHandler: PropTypes.func,
    setTitle: PropTypes.func,
    setDescription: PropTypes.func,
    setStatus: PropTypes.func
}

export default AddNewBrand

