import {BaseService} from './base.service';
import {config} from '../environments';

class DealService extends BaseService{
    static get(headers, page, limit, search){
        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.name = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';
        
        const url = super.prepareUrl(config.endpoints.deal, enocodeQuery);
       return  super.get(url, headers, query);
    }
    static info(id, data,headers){
        const url = super.prepareUrl(config.endpoints.deal);
       return  super.get(url + `/${id}`,data,headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.deal);
       return  super.post(url,data,headers);
    }
    static put(id, data, headers) {
        const url = super.prepareUrl(config.endpoints.deal);
       return  super.put(url + `/${id}`, data ,headers);
    }
    static delete(id, headers) {
        const url = super.prepareUrl(config.endpoints.deal);
       return  super.delete(url + `/${id}`,headers);
    }
}
export default DealService;

export {DealService};