import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Deals } from "../../pages/deals";
import { dealTable, dealDelete, dealTableFetchSuccess} from "./redux/dealsTable.actions";
import { dealUpdate } from '../AddDeal/redux/AddDeal.actions';

function DealsTableContainer(props) {
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const deals = useSelector(state=>state.deal);
    var pageInfo= deals?.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };
    useEffect(()=>{
        dispatch(
            dealTableFetchSuccess({
            ...deals,
            search: "",
          })
        );
    },0)
    useEffect(()=>{
        toPage(1);
    }, [deals.search]);

    function deleteListener(id) {
        dispatch(dealDelete(id, token));
    }

    function toPage(page, limit) {
        let name = deals.search;
        dispatch(dealTable(token, page, limit, name));
    }

    function dealUpdateListener(id, data) {
        dispatch(dealUpdate(id, data, token, toPage,pageInfo));
    }

    function onSearch(str) {
        dispatch(
            dealTableFetchSuccess({
            ...deals,
            search: str,
          })
        );
      }
    
      function onStatusChange(id) {
        return (id, status) => {
            dealUpdateListener(id, {
                status: status ? 'active' : 'inactive'
            });
        }
      }

    return <Deals userState = { userState.user } onStatusChange={onStatusChange} onSearch={onSearch}  deleteListener={deleteListener} toPage={toPage}/>
}

export default DealsTableContainer;
export {DealsTableContainer};