import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux';
import {EditFabricPage} from "../../pages/fabrics";
import { fabricTable as fabricDetailAction, EditFabric, GetFabricType, distinctFabrics } from "./redux/fabricTable.actions";
import { brandById as brands } from "../brand/redux/brandTable.actions";
import { urls } from "../../constants";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { uploadImage } from "../AddOffer/redux/AddOffer.actions";
import { TailorTable } from "../../containers/tailorTable/redux/tailor.actions";

function EditFabricContainer(props) {

    const history = useHistory();

    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const fabricDetail = useSelector(state=>state.fabric?.data);
    const brandDetail = useSelector(state=>state.brandDetail?.brands);
    const fabricTypeList = useSelector(state=>state.fabricTypeList?.fabric_types);

    const tailorState = useSelector(state=>state.tailor);

    const [form, setForm] = useState({...fabricDetail});
    
    const token = {
      'Authorization' : `${userState.token}`
    };

    const temp = 0 ;
    const id = queryString.parse(window.location.search).id;

    useEffect(()=>{

      dispatch(fabricDetailAction(token,"/"+id));
      dispatch(TailorTable(token));

    },[temp]);

    useEffect(()=>{
      if(!form?.user_id) return;
      dispatch(brands(token, `?tailor_id=${form?.user_id}`));
      dispatch(GetFabricType(token, `?tailor_id=${form?.user_id}`));
    },[form?.user_id]);

    useEffect(()=>{
      if( fabricDetail )
      setForm({...fabricDetail});
    },[fabricDetail]);


    const onChangeListener = (key, value)=>{
      setForm({...form,[key]:value});
    }

    const submitHandler = ()=>{
      dispatch( EditFabric( fabricDetail?.id, form, token ) );
      console.log(form);
      history.push(urls.FABRICS);
    }

    function onUploadImage(data, callBack){
      return dispatch(uploadImage(data[0], token, callBack));
    }

    return (
        <EditFabricPage
            {...props}
            tailorList={tailorState.rows}
            onChangeListener={onChangeListener}
            submitHandler= {submitHandler}
            fabricState = { fabricDetail }
            brandDetail = {brandDetail}
            fabricTypesData = {fabricTypeList}
            onUploadImage={onUploadImage} 
            id={id}
            {...form}
        />
    )
}

EditFabricContainer.propTypes = {

}

export default EditFabricContainer