import React, {useState, useEffect} from "react";
import Header from "../../components/header";
import Input from "../../components/input";
import TextArea from "../../components/textArea";
import SelectSection from "../../components/SelectSection";
import CatogeryCheckBox from "../../components/CheckboxSection";
import { styleAddNew, styleEdit } from "../../constant1";
import PropTypes from "prop-types";
import ImageUpload from "../../components/imageUpload";
import { imageUpload } from "../../constant";
import { AddVariableModal } from "./addVariable.modal";
import { ChipSelection } from "../../components/chipSelection";

function StyleVariableSection({
  variableCategoryObj,
  variableList,
  removeVariable,
  onChangeListener,
  setSelectedVariableCategory
}) {
  return (
    <section class="col-lg-12 adminbox">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title float-left">
            {variableCategoryObj.name_en} Style
          </h3>
          <a
            class="f-24 mt-2 text-danger mb-0 float-right"
            onClick={(e) => removeVariable(variableCategoryObj)}
          >
            Remove Selected
          </a>
        </div>
        <div class="card-body">
          <div class="row">
            {variableList?.map((variableObj, i) => {
              if (variableObj.variable_category?.id != variableCategoryObj.id || variableObj.deleted)
                return;
              return (
                <div class="col-md-3 tailoriChked">
                  <input
                    class="filled-in"
                    name="group1"
                    type="checkbox"
                    checked={variableObj.remove}
                    onChange={(e) => {
                      onChangeListener(variableObj, e.target.checked);
                    }}
                    id={variableCategoryObj.id + "_" + variableObj.id + "i"}
                  />
                  <label
                    htmlFor={
                      variableCategoryObj.id + "_" + variableObj.id + "i"
                    }
                  >
                    <div class="bespkokestailor">
                      {variableObj.image_url_en && (
                        <img src={variableObj.image_url_en} />
                      )}
                    </div>
                    {variableObj.name_en}
                  </label>
                </div>
              );
            })}

            <div class="col-md-3 tailoriChked">
              <div class="plus-icon" data-toggle="modal" data-target="#addvariable">
                <div class="text-center">
                  <div class="upload-btn-wrapper">
                    <button class="uploadBtn" onClick={e => setSelectedVariableCategory(variableCategoryObj)}>
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function StyleAddNewPage(props) {
  const id = props.id;
  var headerconst;

  const [selectedVariableCategory, setSelectedVariableCategory] = useState();

  if (id) {
    headerconst = styleEdit;
  } else {
    headerconst = styleAddNew.pageHeader;
  }

  function removeVariable(variableCategoryObj) {
    props.onChangeListener({
      variables: props.style.variables.map((variableObj) => {
        return {
          ...variableObj,
          deleted: 
          variableObj.variable_category?.id == variableCategoryObj.id ? variableObj.remove : variableObj.deleted,
        };
      }),
    });
  }

  function addVariable(variableObj) {
    props.onChangeListener({
      variables: [
        ...props.style.variables,
        {
          ...variableObj
        },
      ],
    });
  }
  return (
    <div class="content-wrapper">
      <Header {...headerconst} submitHandler={props.onSubmitHandler} />
      <AddVariableModal variableCategoryObj={selectedVariableCategory} onImageUpload={props.onUploadImage} onSubmitHandler={addVariable}/>
      <section class="content mb-5">
        <div class="container-fluid">
      <div className="row">
        <div className="col-sm-9">
          <div className="row">
            <div className="col-sm-8">
              <div className="row">
                <div class="col-lg-12">
                  <Input
                    {...styleAddNew.inputTypeTitle}
                    value={props.style.name_en}
                    onChangeListener={(name) =>
                      props.onChangeListener({
                        name_en: name,
                        name_ar: name,
                      })
                    }
                  />
                </div>
                <div class="col-lg-12">
                  <Input
                    {...styleAddNew.inputTypeCost}
                    value={props.style.price}
                    onChangeListener={(price) =>
                      props.onChangeListener({
                        price: price,
                        price: price,
                      })
                    }
                  />
                </div>
                <div class="col-lg-12">
                  <TextArea
                    rows="11"
                    {...styleAddNew.inputTypeTextArea}
                    value={props.style.descriptions_en}
                    onChangeListener={(name) =>
                      props.onChangeListener({
                        descriptions_en: name,
                        descriptions_ar: name,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <ImageUpload
                {...imageUpload}
                title="Style Image"
                image={props?.style?.image_url_en}
                onChangeListener={(e) => {
                  props.onUploadImage(e, (url) => {
                    props.onChangeListener({
                      image_url_en: url,
                      image_url_ar: url,
                    });
                  });
                }}
              />
            </div>
          </div>
          {props?.variableCategoryList?.map((variableCategoryObj) => {
            return (
              <StyleVariableSection
                variableCategoryObj={variableCategoryObj}
                variableList={props?.style?.variables}
                removeVariable={removeVariable}
                setSelectedVariableCategory={setSelectedVariableCategory}
                onChangeListener={(selectedVariableObj, val) => {
                  props.onChangeListener({
                    variables: [
                      ...props.style.variables.map(
                        (obj) => {
                          return {
                            ...obj,
                            remove: obj.id != selectedVariableObj.id ? obj.remove : val
                          }
                        }
                      ),
                    ],
                  });
                }}
              />
            );
          })}
        </div>
        <div className="col-sm-3">
          {/* <ChipSelection array={props.tailorList}/> */}
          <SelectSection
            {...styleAddNew.selectSection}
            options={props.tailorList}
            heading="Tailor"
            value={props.style?.user_id}
            onChangeListener={(val) => {
              props.onChangeListener({
                user_id: val,
              });
            }}
          />
          <SelectSection
            {...styleAddNew.selectSection}
            options={styleAddNew.statusValues}
            value={props.style?.status}
            onChangeListener={(val) => {
              props.onChangeListener({
                status: val,
              });
            }}
          />
          <SelectSection
            heading="Gender"
            options={["Men", "Women"]}
            value={props.style?.gender}
            onChangeListener={(e) => props.onChangeListener({ gender: e })}
          />
          <SelectSection
            heading="Category"
            options={props.categoryList?.categories || []}
            value={props.style?.category_id}
            onChangeListener={(val) => {
              props.onChangeListener({
                category_id: val,
              });
            }}
          />
          
        </div>
      </div>
      </div></section>
    </div>
  );
}

StyleAddNewPage.propTypes = {
  id: PropTypes.number,
};

export default StyleAddNewPage;
export { StyleAddNewPage };
