import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { urls } from "../../constants";

function CheckboxSection(props){
    // const [ value, setValue ] = useState(false);

    // const defalutValue=props.value;
    // useEffect(()=>{
    //     setValue(props.value);
    //     props.onClickListner(props.value);
    //   },[defalutValue])

    const OnClickFunction = (e)=>{
        console.log("done");
        props.onClickListner(!props.isbrand);
    }
    return(
        <div className="card">
            <div className={"card-header pl-4 pt-3 pb-3 "+props.displayBlock}>
                <div className="row">
                    <div className="col-sm-6" >
                        <h6 className="text-capitalize card-title">{props.heading}</h6>
                    </div>
                    <div className="col-sm-6 pt-2 text-right" >
                        {/* <Link to={urls.BRAND_ADD} className="text-capitalize text-dark text-bold">{props.AddnewLabel}</Link> */}
                    </div>
                </div>
            </div>
            <div className="card-body pt-2">
            {
                props.rows?.map(( row,index )=>{
                return  <div class="" key={index} className={props.class} >
                            <input 
                                   type="radio"
                                   onClick={OnClickFunction} 
                                   className="with-gap" 
                                   name={"radio"+props.checkBoxNumber} 
                                   value={row.id}
                                   id={"radio"+index+props.checkBoxNumber}
                                   checked={props.value==row.id ?true:""}
                                    // checked={props.value}
                            />
                            <label for={"radio"+index+props.checkBoxNumber}>{ row?.name_en}</label>
                            
                        </div>
                })
            }
            {props.children}
            </div>
        </div>
    )
}
CheckboxSection.propTypes = {
        heading : PropTypes.string,
        AddnewLabel : PropTypes.string,
        rows: PropTypes.array,
        class : PropTypes.string,
        onClickListner : PropTypes.func,       
}
CheckboxSection.defaultProps = {
    rows: [],
    onClickListner: ()=>{}
}

export default CheckboxSection;