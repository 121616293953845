import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Input from '../../components/input';
import TextArea from '../../components/textArea';
import SelectSection from '../../components/SelectSection';
import OfferAddAndEdit from "../../pages/offer/offer.addAndEdit";
import { addOffer, offerUpdate, uploadImage, offerInfo } from "../AddOffer/redux/AddOffer.actions";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { urls } from "../../constants/app";

function AddOfferContainer(props) {

    const userState = useSelector(state => state.user);
    const dispatch = useDispatch();

    const token = {
        'Authorization' : `${userState.token}`
    };

    const [image, setImage] = useState("");

    const [id, setId] = useState();

    const [offer, setOffer] = useState({});

    useEffect(() => {
        const query = queryString.parse(window.location.search);
        if (query?.id) {
          setId(query?.id);
          getOffer(query?.id);
        }
    }, []);

    function getOffer(id) {
        return dispatch(offerInfo(id, token, setOffer));
    }

    function addOfferListener(data) {
        return dispatch(addOffer(data, token));
    }

    function updateOfferListener(id, data) {
        return dispatch(offerUpdate(id, data, token));
    }

    function onUploadImage(data) {
        debugger;
        return dispatch(uploadImage(data[0], token, (resp)=> {setImage(resp); console.log("url ====", resp)}))
    }

    return (
        <OfferAddAndEdit offer={offer} image={image} addOfferListener={addOfferListener} updateOfferListener={updateOfferListener} onUploadImage={onUploadImage}></OfferAddAndEdit>
    )
}

AddOfferContainer.propTypes = {

}

export default AddOfferContainer

