import React  from 'react';
import PropTypes from "prop-types";
import Table from "../../components/table";
import Header from "../../components/header";
import {BrandColums} from "../../components/table/columnsForTable";
import {brandsConst, tableHeaderBrand } from "../../constant1";

function Brands(props) {
    return(
        <> 
            <div class="content-wrapper">
                <Header 
                title = "Brands"
                subTitle = "Click to view the details"
                buttons = {[
                    {
                        name : "Add New",
                        url : props.urls.BRAND_ADD,
                        isSymbolPresent : true
                    }
                ]}
                />
                <Table 
                    tableData= {()=>BrandColums( props.rows, props.deleteListener, props.onStatusChange )} 
                    tableHeader= {tableHeaderBrand} 
                    pageInfo= {props.pageInfo}
                    onSearch= {props.onSearch}
                    toPage= {props.toPage}
                    ObjectLength= {props.pageInfo?.item_count} 
                    totalResultsLabel= {brandsConst.totalResultsLabel}
                />
            </div>
        </>
    )
}
Brands.propTypes = {
    rows: PropTypes.array,
    pageInfo: PropTypes.object,
    onSearch: PropTypes.func,
    toPage: PropTypes.func,
    deleteListener: PropTypes.func,
}
export default Brands;