import { app } from "../../../constants/app";

export const appCustomerTableSuccess = app.CUSTOMER_TABLE_SUCCESSFUL;
export const appCustomerTableError= app.CUSTOMER_TABLE_ERROR;

export const appCustomerDetailSuccess = app.CUSTOMER_DETAIL_SUCCESSFUL;
export const appCustomerDetailError = app.CUSTOMER_DETAIL_ERROR;

export const appMemberMeasurementSuccess = app.MEMBER_MEASUREMENT_SUCCESSFUL;
export const appMemberMeasurementError = app.MEMBER_MEASUREMENT_ERROR;

export const appCustomerOrdersSuccess = app.CUSTOMER_ORDERS_SUCCESSFUL;
export const appCustomerOrdersError = app.CUSTOMER_ORDERS_ERROR;

export const appCustomerReviewsSuccess = app.CUSTOMER_REVIEWS_SUCCESSFUL;
export const appCustomerReviewsError = app.CUSTOMER_REVIEWSERROR;