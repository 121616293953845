import React, { useState, useEffect} from "react";

const OnOffSwitch = (props) => {
  
  const status = props.status;
  var boolean;

  if (status === props.statusConst.activeLabel) {
    boolean = true;
  } else {
    if (status === props.statusConst.inactiveLabel) {
      boolean = false;
    } else {
      console.log("some error in toogleSwitch components.");
    }
  }
  function changeHandler() {
    props.onChangeListener(props.id,!boolean);
  }

  return (
    <>
      <div className="toogleSwitch">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={boolean}
            onChange={changeHandler}
          />

          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
}; // End OnOffSwitch
OnOffSwitch.defaultProps = {
  onChangeListener : ()=>{}
}
export default OnOffSwitch;
