import { handleActions } from "redux-actions";
import { stylesFatchSuccess, stylesFatchError } from "./style.actions";

const defaultState = {};
// TODO: it looks like over engineering
const reducerStyles = handleActions(
  {
    [stylesFatchSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [stylesFatchError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);

export { reducerStyles };
