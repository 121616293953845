import { urls } from "./constants";

const activeDeactiveSelection = {
  option1Label : "Option 1",
  option2Label : "Option 2",
  statusLabel : "Status",
  selectStatusLabel : "Select status"
}
const barChart = {
    applicableForLabel : "applicable for",
    applicableForValue : "guest user , registered user",
    categoryLabel : "category",
    categoryValue : "women",
    coustomerDiscountLabel : "coustomer discount",
    currencyLabel : "aed",
    couponValueLabel : "coupon value",
    coustomerDiscountValue : "15",
    couponValue : "15",
    maxOrderValueLabel : "max. order value",
    title : "coupon used",
    value : "200"
}
const coupon = {
  title : "Coupons Pending for Approval",
  label : "coupons",
  status : "Pending",
  buttonLabel : "view all",
  url:urls.DISCOUNT_PENDING
}

const complaint = {
  title : "New Complaints",
  value : 20,
  label : "Complaints",
  status : "Pending",
  buttonLabel : "view all",
  url:"/discounts"
}
const complaintTile = {
  fromKey : "From",
  fromValue : "Mr. Deepak Singh (Registered User)",
  headerRightText : "KHY32470329840",
  issueKey : "Issue",
  issueValue : "Sizing",
  issueSubDivisionKey : "Issue Sub Division",
  issueSubDivisionValue : "Wrong Measurement",
  message : `Feedback occurs when outputs of a system are routed back as inputs as part of a chain of cause-and-effect that forms a circuit or loop. 
            The system can then be said to feed back into itself. 
            The notion of cause-and-effect has to be handled carefully when applied to feedback systems: 
            Simple causal reasoning about a feedback system is difficult because the first system influences the second and second system influences the first, 
            leading to a circular argument. This makes reasoning based upon cause and effect tricky, and it is necessary to analyze the system as a whole.`,
  subject : "Feedback occurs when outputs of a system are routed back as inputs",
  sendByUser : true
}
const chipSection = {
  title : "Locations (Serving in)",
  placeHolder : "Type location here, comma separated"
}
const circleChart = {
  title : "Earning",
  totalLabel : "total",
  currencyName : "aed",
  valueKey1 : "online earnings",
  valueKey2 : "cash earnings",
}
const categoryData = [
  {value : "Men", name_en:"Men"},
  {value : "Women", name_en: "Women"},
  {value : "Kids", name_en:"Kids"}
]
const changePassword = {
  heading : "Change Password",
  labelNewPassword: "New Password",
  labelConfirmNewPassword: "Confirm New Password",
  labelSubmitButton: "Submit",
  classErrorMessage: "errorMessage",
  errorMessage: "Error Message ( can changed by props )"
}
const descriptionAndTile = {
  titleLabel : "Order details",
  styleLabel : "Style",
  fabricLabel : "Fabric",
  typeLabel : "Type",
  costLabel : "Cost",
  frontStyleLabel : "Front Style",
  sideLinesLabel : "Side Lines",
  stichesLabel : "Stiches",
  tarboshLabel : "Tarbosh",
  titleLabel : "Order Details",
  styleValue : "Emirati",
  fabricValue : "Brand Name",
  typeValue : "Fabric Name",
  costValue : "AED 299.00 / meter",
  frontStyleValue : "Emirati",
  sideLinesValue : "Plain",
  stichesValue : "8 Stiches",
  tarboshValue : "Style 1",
  orderNumber : "NK32874623"
}
const descriptionAndCategory = {
  categoryLabel : "Category",
  categoryValue : "Men",
  couponUsedLabel : "Coupon Used",
  couponUsedDeatil : "KY329847 (20% Off)",
  delayLabel : "Delay",
  delayTime : "+3 Days",
  giftingLabel : "Gifting",
  giftingValue : "Yes",
  id : "NK32874623",
  measurementForLabel : "Measurement for",
  name : "Thobe",
  subCategoryLabel : "Sub Cateogry"
}
const img = {
  percentageImgURL : "https://i.ibb.co/YTNXSh2/percentage.jpg",
  trendingUpImgURL : "https://i.ibb.co/YNZ8bkD/trending-up.jpg",
  ratingAndInfo : "https://i.ibb.co/n1H6jyc/user.jpg",
  navSideBar1 : "https://i.ibb.co/QpJQHFQ/menu-left-alt.jpg",
  navSideBar2 : "https://i.ibb.co/jJ8Ct7c/close.jpg",
  dotImg : "https://i.ibb.co/JjNg5Qv/dot.jpg",
  fabric : "https://i.ibb.co/tPd1pvW/fabric.jpg"
}
const imageGrid = [
  {
    label : "front image",
    url : "https://i.ibb.co/M5ngMXp/front.jpg"
  },
  {
    label : "back image",
    url : "https://i.ibb.co/9w4T2rb/back.jpg"
  },
  {
    label : "side image",
    url : "https://i.ibb.co/r4Mhjtf/side.jpg"
  }
]
const imageUpload = {
  descriptionText1 : "This image will be published on Website as well as on the Mobile App.",
  descriptionText2 : "Image size should be 1280px x 720px.",
  buttonLabel : "UPLOAD IMAGE",
  title : "Image Upload"
}
const login = {
  heading : "ADMIN PANEL",
  text : "Login with your Email address and Password to access the admin panel for mobile app & website.",
  emailPlaceholder : "Email",
  passwordPlaceholder : "Password",
  labelForgot : "FORGOT ?", 
  label : "Login",
  forgotPasswordURL : "/forgot/password" ,
  buttonURL : "/dashboard"
}
const listTailor=[
  {
    title:"Top Tailors",
    coupenCode:"KHY2398749238",
    name:"Tailor name1",
    discountPercentage : "43.65%",
    time:"since last week"
  },
  {
    coupenCode:"ZHY2398749238",
    name:"Tailor name2",
    discountPercentage : "33.65%",
    time:"since two week"
  },
  {
    coupenCode:"IHY2398749238",
    name:"Tailor name3",
    discountPercentage : "23.65%",
    time:"since last week"
  },
  {
    coupenCode:"JHY2398749238",
    name:"Tailor name4",
    discountPercentage : "13.65%",
    time:"since one week"
  },
  {
    coupenCode:"lHY2398749238",
    name:"Tailor name5",
    discountPercentage : "03.65%",
    time:"since last week"
  }
]
const lineGraph = {
  dateFormate : "dd MMM , yyyy",
  title : "Sales Trend",
  discription : "Customers Order Trend Statistics",
}
const datePickerConst = {
  labelFrom : "From ",
  labelTo : " to "
}
const navSideBar = {
  dashboard : "dashboard",
  orders : "orders",
  customers : "customers",
  tailors : "tailors",
  style : "style",
  variable : "variable category",
  fabric : "fabric",
  offers : "offers",
  tailorPending: "tailor pending",
  discountPending: "discount pending",
  discountCoupon : "discount coupon",
  brands : "brands",
  deals: "deals",
  settings: "settings",
  manage_shipment: "manage shipment",
  payOut: "payout"
}
const notification = {
  numberOfNotification : 4
}
const ratingAndInfo = {
  title : "Tailor Info",
  name : "Mr. Deepak Singh",
  avgTimeLabel : "Avg. Time: ",
  labelDays: " days",
  locationsApplicableLabel : "Locations Applicable (static)",
  ordersLabel : "Orders",
  deliveredLabel : "Delivered",
  pendingLabel : "Pending"
}
const starRating = {
  givenRating : 3,
  totalStars : 5,
  numberOfReviewer : 56
}
const statusIndicator = {
  isActive : false,
  activeLabel : "Active",
  inactiveLabel : "Inactive"
}
const tableHeader = [
  {
      Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "FABRIC BRAND",
      accessor: 'col2', // accessor is the "key" in the data
  },
  {
      Header: "CREATED AT",
      accessor: 'col3',
  },
  {
      Header: "STATUS",
      accessor: 'col4',
  },
  {
      Header: '',
      accessor: 'col5',
  },
  {
      Header: '',
      accessor: 'col6',
  },
]
const tableHeaderBrand = [
  {
      Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "FABRIC BRAND",
      accessor: 'col2', // accessor is the "key" in the data
  },
  {
      Header: "CREATED AT",
      accessor: 'col3',
  },
  {
      Header: "STATUS",
      accessor: 'col4',
  },
  {
      Header: '',
      accessor: 'col5',
  },
  {
      Header: '',
      accessor: 'col6',
  },
]
const tableHeaderTailor = [
  // {
  //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
  //     accessor: 'col1', // accessor is the "key" in the data
  // },
  {
      Header: "TAILOR ID",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "TAILOR NAME",
      accessor: 'col2',
  },
  {
      Header: "RATING",
      accessor: 'col3',
  },
  {
      Header: 'AVG. TIME',
      accessor: 'col4',
  },
  {
      Header: 'TOTAL EARNED',
      accessor: 'col5',
  },
  {
      Header: 'TOTAL OUTSTAING',
      accessor: 'col6',
  },
  {
      Header: 'ORDERS',
      accessor: 'col7',
  },
  {
      Header: 'COUSTOMERS',
      accessor: 'col8',
  },
  {
      Header: 'STATUS',
      accessor: 'col9',
  },
  {
      Header: '',
      accessor: 'col10',
  },
  {
      Header: '',
      accessor: 'col11',
  },
]
const tablePendingTailor = [
  // {
  //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
  //     accessor: 'col1', // accessor is the "key" in the data
  // },
  {
      Header: "TAILOR ID",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "TAILOR NAME",
      accessor: 'col2',
  },
  {
      Header: 'Email',
      accessor: 'col3',
  },
  {
      Header: 'AVG. TIME',
      accessor: 'col4',
  },
  {
      Header: 'Khayyaty Commission',
      accessor: 'col5',
  },
  {
      Header: '',
      accessor: 'col6',
  },
]
const tableData = [
  {
    fabricBrandName : "Fabric brand",
    createdAt : "Created At",
    isActive : true,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
  {  
    fabricBrandName : "Fabric brand",
    createdAt : "Created At",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive" 
  }
]
const tailorProfileHeader = {
  title : "Tailor",
  subTitle : "Profile",
  buttons : [
    {
      name : "BACK",
      url : "/back",
      isSymbolPresent : false,
    },
    {
      name : "ORDERS",
      url : "/orders",
      isSymbolPresent : false
    },
    {
      name : "COUPONS",
      url : "/coupons",
      isSymbolPresent : false,
    },
    {
      name : "REVIEWS",
      url : "/reviews",
      isSymbolPresent : false
    },
    {
      name : "COMPLAINS",
      url : "/complains",
      isSymbolPresent : false,
    },
  ]
}
const tableDataBrand = [
  {
    fabricBrandName : "Fabric brand",
    createdAt : "Created At",
    isActive : true,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
  {  
    fabricBrandName : "Fabric brand",
    createdAt : "Created At",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive" 
  }
]
const tableDataTailor = [
  {
    tailorId : "KHV3209840239",
    name : "Tailor's Full Name",
    rating : {
      givenRating : 2,
      totalStars : 5,
      numberOfReviewer : 56
    },
    AvgTime : "1-2 days",
    totalEarned : "AEX 5799.0",
    totalOutstaing : "AED 399.00",
    orders : 17,
    customers : 10,
    isActive : true,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
  {
    tailorId : "KHV3209840239",
    name : "Tailor's Full Name",
    rating : {
      givenRating : 3,
      totalStars : 5,
      numberOfReviewer : 56
    },
    AvgTime : "1-2 days",
    totalEarned : "AEX 5799.0",
    totalOutstaing : "AED 399.00",
    orders : 17,
    customers : 10,
    isActive : true,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },{
    tailorId : "KHV3209840239",
    name : "Tailor's Full Name",
    rating : {
      givenRating : 1,
      totalStars : 5,
      numberOfReviewer : 56
    },
    AvgTime : "1-2 days",
    totalEarned : "AEX 5799.0",
    totalOutstaing : "NIL",
    orders : 17,
    customers : 10,
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },{
    tailorId : "KHV3209840239",
    name : "Tailor's Full Name",
    rating : {
      givenRating : 5,
      totalStars : 5,
      numberOfReviewer : 56
    },
    AvgTime : "1-2 days",
    totalEarned : "AEX 5799.0",
    totalOutstaing : "AED 399.00",
    orders : 17,
    customers : 10,
    isActive : true,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },{
    tailorId : "KHV3209840239",
    name : "Tailor's Full Name",
    rating : {
      givenRating : 4,
      totalStars : 5,
      numberOfReviewer : 56
    },
    AvgTime : "1-2 days",
    totalEarned : "AEX 5799.0",
    totalOutstaing : "NIL",
    orders : 17,
    customers : 10,
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },{
    tailorId : "KHV3209840239",
    name : "Tailor's Full Name",
    rating : {
      givenRating : 2,
      totalStars : 5,
      numberOfReviewer : 56
    },
    AvgTime : "1-2 days",
    totalEarned : "AEX 5799.0",
    totalOutstaing : "AED 399.00",
    orders : 17,
    customers : 10,
    isActive : true,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },{
    tailorId : "KHV3209840239",
    name : "Tailor's Full Name",
    rating : {
      givenRating : 0,
      totalStars : 5,
      numberOfReviewer : 56
    },
    AvgTime : "1-2 days",
    totalEarned : "AEX 5799.0",
    totalOutstaing : "AED 399.00",
    orders : 17,
    customers : 10,
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
]
const header = {
  title : "Dashboard",
  subTitle : "Welcome back , Abdul",
  buttons : [
    {
      name : "Back",
      url : "/back",
      isSymbolPresent : false,
    },
    {
      name : "Add New",
      url : "/addNew",
      isSymbolPresent : true
    }
  ]
}
const brandDashboardHeaderData = {
  title : "Brands",
  subTitle : "Click to view the details",
  buttons : [
    {
      name : "Add New",
      url : urls.BRAND_ADD,
      isSymbolPresent : true
    }
  ]
}
const tailorDashboardHeaderData = {
  title : "Tailors",
  subTitle : "Click to view the details",
  buttons : [
    {
      name : "Add New",
      url : urls.TAILOR_ADD,
      isSymbolPresent : true
    }
  ]
}
const headerTailor = {
  title : "Brands",
  subTitle : "Click to view the details",
  buttons : [
    {
      name : "Back",
      url : "/back",
      isSymbolPresent : false,
    },
    {
      name : "Add New",
      url : "/addNew",
      isSymbolPresent : true
    }
  ]
}
const orderInfoTile = {
  title : "User Info",
  name : "Mr. Deepak singh",
  genderType : "Adult, Male",
  address : "Address, State, Country",
  placedOrderValue : 14,
  labelOrders : "Orders",
  labelPlaced : "Placed",
  sizeSavedValue : 2,
  labelSizes : "Sizes",
  labelSaved : "Saved"
}

const headerDashboard = {
  title : "Dashboard",
  welcomeMessage : "Welcome back, ",
}
const notificationFullComp = {
  name : "Abdul Rahman",
  labelChangePassword : "Change Password",
  labelLogout : "Logout"
}
const golbalData = {
  statusActiveLabel : "Status Active",
  viewThis: "View This",
  editThisBrandLabel : "Edit this Brand",
  deleteThisBrandLabel : "Delete this Brand",
  totalResultsLabel : "Total Results: ",
  tableResultsValue : 58
}
const tableDataFabrics = [
  {
    fabricBrandLabel : "FABRIC BRAND",
    createdAtLabel : "CREATED AT",
    statusLabel : "STATUS",

    fabricBrandName : "Fabric brand",
    createdAt : "Created At",
    isActive : true,
    activeLabel : "Active",
    inactiveLabel : "Inactive",
    
    statusActiveLabel : "Status Active",
    editThisBrandLabel : "Edit this Brand",
    deleteThisBrandLabel : "Delete this Brand"
  },
  {  
    fabricBrandName : "Fabric brand",
    createdAt : "Created At",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive" 
  }
]
const globalProps = {
  totalResultsLabel : "Total Results : ",
  totalResultsValue : 58
}
const tableHeaderDiscount = [
  {
      Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "COUPON CODE",
      accessor: 'col2', // accessor is the "key" in the data
  },
  {
      Header: "VALID UPTO",
      accessor: 'col3',
  },
  {
      Header: 'APPLICABLE FOR',
      accessor: 'col4',
  },
  {
      Header: 'VALUE',
      accessor: 'col5',
  },
  {
      Header: 'CREATED AT',
      accessor: 'col6',
  },
  {
      Header: 'STATUS',
      accessor: 'col7',
  },
  {
      Header: '',
      accessor: 'col8',
  },
  {
      Header: '',
      accessor: 'col9',
  },
]
const CustomertableHeaderOrder = [
  {
      Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "ORDER ID",
      accessor: 'col2', // accessor is the "key" in the data
  },
  {
      Header: "CUSTOMER ID",
      accessor: 'col3',
  },
  {
      Header: "ORDER AMT.",
      accessor: 'col4',
  },
  {
      Header: 'TAILOR',
      accessor: 'col5',
  },
  {
      Header: 'DELIVERY DATE',
      accessor: 'col6',
  },
  {
      Header: 'DELIVERY DELAY',
      accessor: 'col7',
  },
  {
      Header: 'CREATED AT',
      accessor: 'col8',
  },
  {
      Header: 'STATUS',
      accessor: 'col9',
  },
  {
      Header: '',
      accessor: 'col10',
  },
  {
      Header: '',
      accessor: 'col11',
  },
]
const CustomertableHeaderReview = [
  {
      Header: "REVIEW TITLE",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "RATING",
      accessor: 'col2', // accessor is the "key" in the data
  },
  {
      Header: "TAILOR",
      accessor: 'col3',
  },
  {
      Header: "CREATED AT",
      accessor: 'col4',
  },
  // {
  //     Header: '',
  //     accessor: 'col5',
  // },
]
const CustomertableHeaderSize = [
  {
      Header: "Realtion",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "TYPE",
      accessor: 'col2',
  },
  {
      Header: "shoulder (in cm)",
      accessor: 'col3',
  },
  {
      Header: "HEIGHT (in cm)",
      accessor: 'col4', // accessor is the "key" in the data
  },
  {
      Header: "WEIGHT (in kg)",
      accessor: 'col5',
  },
  {
      Header: "AGE (in yrs)",
      accessor: 'col6',
  },
  {
      Header: "body length (in cm)",
      accessor: 'col7',
  },
  {
      Header: 'chest size (in cm)',
      accessor: 'col8',
  },
  {
      Header: 'sleeve length (in cm)',
      accessor: 'col9',
  },
  {
      Header: 'CREATED AT',
      accessor: 'col10',
  },
]
const CustomertableDataSize = [
  {
    measurementFor : "Tobe (Saudi)",
    type : "A.I. Tool",
    height : "172",
    weight : "78",
    age : "42",
    createdAt : "12 Oct 2020"
  },
  {
    measurementFor : "Tobe (Emirati)",
    type : "Manual",
    height : "172",
    weight : "75",
    age : "42",
    createdAt : "21 Oct 2020"
  }
]
const CustomertableHeaderComplaint = [
  // {
  //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
  //     accessor: 'col1', // accessor is the "key" in the data
  // },
  {
      Header: "COMPLAINTS TITLE",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "ISSUE",
      accessor: 'col2',
  },
  {
      Header: "ISSUE SUB DIVISION",
      accessor: 'col3',
  },
  {
      Header: "CUSTOMER ID",
      accessor: 'col4',
  },
  {
      Header: "PRIORITY",
      accessor: 'col5',
  },
  {
      Header: "CREATED AT",
      accessor: 'col6',
  },
  {
      Header: "STATUS",
      accessor: 'col7',
  },
  {
      Header: '',
      accessor: 'col8',
  },
]
const CustomertableDataComplaint = [
  {
    title : 'Fusce at nisi eget dolor rhoncus... ',
    issue : "Sizing",
    issueSubDivision : "Wrong Measurement",
    customerId:"KHY898985943",
    priority : "high",
    createdAt : "14 Oct 2020",
    status : "active"
  },
  {
    title : 'Fusce at nisi eget dolor rhoncus... ',
    issue : "Sizing",
    issueSubDivision : "Wrong Measurement",
    customerId:"KHY898985943",
    priority : "low",
    createdAt : "14 Oct 2020",
    status : "inactive"
  }
]
const CustomertableDataReview = [
  {
    title : 'Fusce at nisi eget dolor rhoncus Facilis is Fusce at nisi eget dolor rhoncus Facilis is ',
    rating : 4,
    name : "Tailor's Name",
    createdAt : "14 Oct 2020"
  },
  {
    title : 'Fusce at nisi eget dolor rhoncus Facilis is Fusce at nisi eget dolor rhoncus Facilis is ',
    rating : 2,
    name : "Tailor's Name",
    createdAt : "14 Oct 2020"
  },
  {
    title : 'Fusce at nisi eget dolor rhoncus Facilis is Fusce at nisi eget dolor rhoncus Facilis is ',
    rating : 0,
    name : "Tailor's Name",
    createdAt : "14 Oct 2020"
  },
]
const DiscountTableHeaderforCustomers = [
  {
      Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "CUSTOMER NAME",
      accessor: 'col2', // accessor is the "key" in the data
  },
  {
      Header: "CONTACT NO.",
      accessor: 'col3',
  },
  {
      Header: "ORDER VALUE",
      accessor: 'col4',
  },
  {
      Header: 'ORDER PLACED ON',
      accessor: 'col5',
  },
  {
      Header: 'STATUS',
      accessor: 'col6',
  },
  {
      Header: '',
      accessor: 'col7',
  }
]
const CustomerPageTableHeaderforMemebersComp = [
  {
      Header: "MEMBER NAME",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "RELATION",
      accessor: 'col2',
  },
  {
      Header: "CATEGORY",
      accessor: 'col3',
  },
  {
      Header: 'CREATED AT',
      accessor: 'col4',
  },
  {
      Header: '',
      accessor: 'col5',
  }
]
const CustomerPageTableHeaderforAddressComp = [
  // {
  //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
  //     accessor: 'col1', // accessor is the "key" in the data
  // },
  {
      Header: "FULL NAME",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "PHONE NUMBER",
      accessor: 'col2',
  },
  {
      Header: "STATE",
      accessor: 'col3',
  },
  {
    Header: "COUNTRY",
    accessor: 'col4',
  },
  {
      Header: 'CREATED AT',
      accessor: 'col5',
  }
]
const CustomerPageTableDataforMemebersComp = [
  {
    name : "Member Name1",
    relation : "Father",
    category : "Adult, Male",
    createdAt : "14 Oct 2020",
    status : "active"
  },
  {
    name : "Member Name2",
    relation : "Son",
    category : "Kid, Male",
    createdAt : "14 Oct 2020",
    status : "inactive"
  },
  {
    name : "Member Name3",
    relation : "Son",
    category : "Kid, Male",
    createdAt : "14 Oct 2020",
    status : "inactive"
  },
  {
    name : "Member Name4",
    relation : "Wife",
    category : "Adult, Female",
    createdAt : "14 Oct 2020",
    status : "active"
  },
]
const DiscountTableDataforCustomers = [
  {
    name : "Customer Name",
    phoneNumber : "00 9876543210",
    orderValue : "AED 359.00",
    createdAt : "14 Oct 2020",
    status : "active",
    viewOrder : "VIEW ORDER"
  },
  {
    name : "Customer Name",
    phoneNumber : "00 9876543210",
    orderValue : "AED 359.00",
    createdAt : "14 Oct 2020",
    status : "inactive",
    viewOrder : "VIEW ORDER"
  },
  {
    name : "Customer Name",
    phoneNumber : "00 9876543210",
    orderValue : "AED 359.00",
    createdAt : "14 Oct 2020",
    status : "inactive",
    viewOrder : "VIEW ORDER"
  },
  {
    name : "Customer Name",
    phoneNumber : "00 9876543210",
    orderValue : "AED 359.00",
    createdAt : "14 Oct 2020",
    status : "active",
    viewOrder : "VIEW ORDER"
  }
]
const tableDataFabricsPage = [
  {
    fabricBrand : "Fabric Brand",
    fabricTitle : "Fabric Title",
    fabricCost : "AED 229.00",
    rating : {
      givenRating : 1,
      totalStars : 5,
      numberOfReviewer : 56
    },
    createdAt : "14 Oct 2020",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
  {
    fabricBrand : "Fabric Brand",
    fabricTitle : "Fabric Title",
    fabricCost : "AED 229.00",
    rating : {
      givenRating : 3,
      totalStars : 5,
      numberOfReviewer : 56
    },
    createdAt : "14 Oct 2020",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
  {
    fabricBrand : "Fabric Brand",
    fabricTitle : "Fabric Title",
    fabricCost : "AED 229.00",
    rating : {
      givenRating : 2,
      totalStars : 5,
      numberOfReviewer : 56
    },
    createdAt : "14 Oct 2020",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
  {
    fabricBrand : "Fabric Brand",
    fabricTitle : "Fabric Title",
    fabricCost : "AED 229.00",
    rating : {
      givenRating : 0,
      totalStars : 5,
      numberOfReviewer : 56
    },
    createdAt : "14 Oct 2020",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  }
]  
const tableDataDiscount = [
  {
    couponCode : "KHY39284723",
    category : "Men,Kids",
    owner : "Khayaty",
    applicableFor : "New Users, Registered Users",
    value : "20%",
    createdAt : "14 Oct 2020",
    status : "active"
  },
  {
    couponCode : "KHY39284723",
    category : "Women",
    owner : "Tailor (Tailor's Name)",
    applicableFor : "Guest Users",
    value : "100%",
    createdAt : "14 Oct 2020",
    status : "inactive"
  }
]   
const CustomertableDataOrder = [
  {
    orderId : "KHY39284723",
    customerID : "KHY39284723",
    orderAMT : "AED 999.00",
    tailor : "Tailor's Name",
    deliveryDate : "14 oct 2020",
    deliveryDelay : "+2 Days",
    createdAt : "14 Oct 2020",
    status : "active"
  },
  {
    orderId : "KHY39284723",
    customerID : "KHY39284723",
    orderAMT : "AED 999.00",
    tailor : "Tailor's Name",
    deliveryDate : "14 oct 2020",
    deliveryDelay : "No Delay",
    createdAt : "14 Oct 2020",
    status : "inactive"
  },
  {
    orderId : "KHY39284723",
    customerID : "KHY39284723",
    orderAMT : "AED 999.00",
    tailor : "Tailor's Name",
    deliveryDate : "14 oct 2020",
    deliveryDelay : "+10 Days",
    createdAt : "14 Oct 2020",
    status : "active"
  },
  {
    orderId : "KHY39284723",
    customerID : "KHY39284723",
    orderAMT : "AED 999.00",
    tailor : "Tailor's Name",
    deliveryDate : "14 oct 2020",
    deliveryDelay : "No Delay",
    createdAt : "14 Oct 2020",
    status : "active"
  }
]  
const offerTableDataOffer = [
  {
    title : "20% off Vestibulum blandit viverra",
    category : "Men,Kids",
    tailor : "Tailor Name (+12 others)",
    applicableFor : "New Users, Registered Users",
    createdAt : "14 Oct 2020",
    status : "active"
  },
  {
    title : "10% off Vestibulum blandit viverra",
    category : "Women",
    tailor : "Tailor Name",
    applicableFor : "All",
    createdAt : "14 Oct 2020",
    status : "inactive"
  }
]  
const globalPropsFabric = {
  totalResultsLabel : "Total Result: ",
  pedingOrders : "Pending Orders:",
  tableResultsValue : "50",
  editThisOrderLabel : "Edit this Brand",
  deleteThisOrderLabel : "Delete this Brand"
}
const globalPropsOffer = {
  tableResultsLabel : "Total Result: ",
  tableResultsValue : "50",
  editThisOfferLabel : "Edit This",
  deleteThisOfferLabel : "Delete This"
}
const globalPropsDeal = {
  tableResultsLabel : "Total Result: ",
  tableResultsValue : "50",
  editThisDealLabel : "Edit this Deal",
  deleteThisDealLabel : "Delete this Deal"
}
const headerFabrid = {
  title : "Fabrics",
  subTitle : "Click to view the details",
  buttons : [
    {
      name : "Add New",
      url : urls.PRODUCT_FABRIC_ADD,
      isSymbolPresent : true
    }
  ]
}
const tableHeaderTotelOrders = [
  {
      Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "ORDER ID",
      accessor: 'col2', // accessor is the "key" in the data
  },
  {
      Header: "CUSTOMER NAME",
      accessor: 'col3',
  },
  {
      Header: "PAYMENT TYPE",
      accessor: 'col4',
  },
  {
      Header: 'ORDER AMT.',
      accessor: 'col5',
  },
  {
      Header: 'CATEGORY',
      accessor: 'col6',
  },
  {
      Header: 'DELIVERY DATE',
      accessor: 'col7',
  },
  {
      Header: 'DELAY',
      accessor: 'col8',
  },
  {
      Header: 'ORDERED AT',
      accessor: 'col9',
  },
  {
      Header: 'STATUS',
      accessor: 'col10',
  },
  {
      Header: '',
      accessor: 'col11',
  }
]
const tableDataTotelOrders = [
  {
    orderId : "KHV3209840239",
    name : "Customer Name",
    onlinePayment : true,
    LabelOnline : "Online",
    LabelCash : "Cash",
    orderAMT : "AEX 5799.0",
    category : "Adult, Male",
    deliveryDate : "14 Oct 2020",
    delay : "+8 Days",
    orderedAt : "14 Oct 2020",
    status : 0,
    pendingLabel : "Pending",
    cuttingLabel : "Cutting",
    shippedLabel : "Shipped"
  },
  {
    orderId : "KHV3209840239",
    name : "Customer Name",
    onlinePayment : true,
    LabelOnline : "Online",
    LabelCash : "Cash",
    orderAMT : "AEX 5799.0",
    category : "Adult, Male",
    deliveryDate : "14 Oct 2020",
    delay : "No Delay",
    orderedAt : "14 Oct 2020",
    status : 0,
    pendingLabel : "Pending",
    cuttingLabel : "Cutting",
    shippedLabel : "Shipped"
  },
  {
    orderId : "KHV3209840239",
    name : "Customer Name",
    onlinePayment : false,
    LabelOnline : "Online",
    LabelCash : "Cash",
    orderAMT : "AEX 5799.0",
    category : "Adult, Male",
    deliveryDate : "14 Oct 2020",
    delay : "+8 Days",
    orderedAt : "14 Oct 2020",
    status : 1,
    pendingLabel : "Pending",
    cuttingLabel : "Cutting",
    shippedLabel : "Shipped"
  },
  {
    orderId : "KHV3209840239",
    name : "Customer Name",
    onlinePayment : true,
    LabelOnline : "Online",
    LabelCash : "Cash",
    orderAMT : "AEX 5799.0",
    category : "Adult, Male",
    deliveryDate : "14 Oct 2020",
    delay : "+8 Days",
    orderedAt : "14 Oct 2020",
    status : 2,
    pendingLabel : "Pending",
    cuttingLabel : "Cutting",
    shippedLabel : "Shipped"
  }
]
const globalDataOrderTable = {
  tableResultsLabel : "Total Orders: ",
  tableResultsValue : 24,
  editThisOrderLabel : "Edit this Order",
  deleteThisOrderLabel : "Delete this Order"
}
const tailorDetailPageTableHeader = [
  // {
  //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
  //     accessor: 'col1', // accessor is the "key" in the data
  // },
  {
      Header: "branch name",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "address",
      accessor: 'col2',
  },
  {
      Header: "city",
      accessor: 'col3',
  },
  {
      Header: 'state',
      accessor: 'col4',
  },
  {
      Header: 'country',
      accessor: 'col5',
  },
  {
      Header: 'Status',
      accessor: 'col6',
  },
  {
      Header: 'created At',
      accessor: 'col7',
  },
  // {
  //     Header: 'status',
  //     accessor: 'col7',
  // },
    // {
    //     Header: '',
    //     accessor: 'col8',
    // },
    // {
    //     Header: '',
    //     accessor: 'col9',
    // },
]
const tableDataTotalDiscount = [
  {
    couponCode : "KHV3209840239",
    category : "Men, Kids",
    applicableFor : "Guest Users",
    value : "20%",
    createdAt : "14 Oct 2020",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
  {
    couponCode : "KHV3209840239",
    category : "Women",
    applicableFor : "New Users, Registered Users",
    value : "10%",
    createdAt : "14 Oct 2020",
    isActive : true,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
  {
    couponCode : "KHV3209840239",
    category : "Men",
    applicableFor : "All",
    value : "10%",
    createdAt : "14 Oct 2020",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
  {
    couponCode : "KHV3209840239",
    category : "Kids",
    applicableFor : "Guest Users",
    value : "15%",
    createdAt : "14 Oct 2020",
    isActive : false,
    activeLabel : "Active",
    inactiveLabel : "Inactive"
  },
]
const brandsCheckboxData = {
  heading : "Brand",
  AddnewLabel : "Add New",
  checkBoxNumber:1
}
const styleCheckboxData = {
  heading : "Style",
  AddnewLabel : "Add New"
}
const variablesCheckboxData = {
  heading : "Variables",
  AddnewLabel : "Add New"
}
const tailorCheckboxData = {
  heading : "Applicable Category"
}
const status = {
  activeLabel : "active",
  inactiveLabel : "inactive"
}
const discountCheckboxCatogeryData = {
  heading : "Category",
  class : "p-2"
}
const discountCheckboxApplicableForData = {
  heading : "Create new discount coupon",
  class : "d-inline-block pt-3"
}

const applicableForData = [
  {value : "Brand", name_en:"Brand"},
  {value : "Style", name_en:"Style"}
]
const totalStartsOfRating = {
  totalStars : 5
}
const globalPropsDiscount = {
  tableResultsLabel : "Total Result: ",
  tableResultsValue : "2",
  editThisDiscountLabel : "Edit this Discount",
  deleteThisDiscountLabel : "Delete this Discount"
}
const customersConst = {
  title : 'Customer',
  tableResultsLabel : "Family Members:",
  memberLable : "Member",
  labelSizes : "Sizes",
}
const customersPageOrderAndInfoCompConst = {
  title : "User Info",
  labelOrders : "Orders",
  labelPlaced : "Placed",
  labelSizes : "Sizes",
  labelSaved : "Saved"
}
const tableHeaderCustomers = [
  // {
  //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
  //     accessor: 'col1', // accessor is the "key" in the data
  // },
  {
      Header: "CUSTOMER ID",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "CUSTOMER NAME",
      accessor: 'col2',
  },
  {
      Header: "CONTACT NO.",
      accessor: 'col3',
  },
  {
      Header: 'TOTAL SPENT',
      accessor: 'col4',
  },
  {
      Header: 'STATUS',
      accessor: 'col5',
  },
  {
      Header: '',
      accessor: 'col6',
  },
  {
      Header: '',
      accessor: 'col7',
  },
]
const tableDataCustomers = [
  {
    id : "KHY938934KDF9D",
    name : "Customer Full Name",
    phoneNumber : "+91 9876543210",
    totalSpent : "AED 399.00",
    status : "active"
  },
  {
    id : "KHY938934KDF9D",
    name : "Customer Full Name",
    phoneNumber : "+91 9876543210",
    totalSpent : "AED 399.00",
    status : "inactive"
  },
  {
    id : "KHY938934KDF9D",
    name : "Customer Full Name",
    phoneNumber : "+91 9876543210",
    totalSpent : "AED 399.00",
    status : "inactive"
  },
  {
    id : "KHY938934KDF9D",
    name : "Customer Full Name",
    phoneNumber : "+91 9876543210",
    totalSpent : "AED 399.00",
    status : "active"
  },
]
const offerTableHeaderDataOffer = [
  // {
  //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
  //     accessor: 'col1', // accessor is the "key" in the data
  // },
  {
      Header: "OFFER TITLE",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "CATEGORY",
      accessor: 'col2',
  },
  {
      Header: "TAILOR(S)",
      accessor: 'col3',
  },
  {
      Header: 'APPLICABLE FOR',
      accessor: 'col4',
  },
  {
      Header: 'CREATED AT',
      accessor: 'col5',
  },
  {
      Header: 'STATUS',
      accessor: 'col6',
  },
  {
      Header: '',
      accessor: 'col7',
  },
  {
      Header: '',
      accessor: 'col8',
  },
]
const dealTableHeaderData = [
  // {
  //     Header: <><input className="filled-in" name="group1" type="checkbox" id="news"/><label for="news"></label></>,
  //     accessor: 'col1', // accessor is the "key" in the data
  // },
  {
      Header: "OFFER TITLE",
      accessor: 'col1', // accessor is the "key" in the data
  },
  {
      Header: "GENDER",
      accessor: 'col2',
  },
  {
      Header: "TAILOR(S)",
      accessor: 'col3',
  },
  {
      Header: 'CREATED AT',
      accessor: 'col4',
  },
  {
      Header: 'STATUS',
      accessor: 'col5',
  },
  {
      Header: '',
      accessor: 'col6',
  },
  {
      Header: '',
      accessor: 'col7',
  },
]
const priorityConst = {
  highLable : "high",
  lowLable : "low",
  mediumLable : "medium"
}
const appconst = {
  tableResultsLabel : "Total Result: ",
  editThisLabel : "Edit this",
  deleteThis : "Delete this",
  deactivateThis : "Deactivate this",
  view : "View"
}
const favouriteTailors = {
  title : "Favourite Tailors",
  AVGTime : "AVG. Time: "
}
const favouriteFabrics = {
  title : "Favourite Fabrics",
  currencyLabel : "AED ",
  unit : "/meter"
}
const billingAndShippingAddressConst = {
  billingAddressLabel : "Billing Address",
  shippingAddressLabel : "Shipping Address",
  gustinLabel : "GUSTIN: "
}
const topDiscountConst = {
  title : 'Top Discount Coupons',
  offPersantage : "% off"
}
const paymentType = {
  paymentCash: "Cash",
  paymentOnline: "Online"
}
export {
  activeDeactiveSelection,
  barChart,
  coupon,
  complaint,
  complaintTile,
  chipSection,
  circleChart,
  categoryData,
  changePassword,
  descriptionAndTile,
  descriptionAndCategory,
  header,
  img,
  imageGrid,
  imageUpload,
  login,
  listTailor,
  lineGraph,
  navSideBar,
  orderInfoTile,
  notification,
  ratingAndInfo,
  starRating,
  statusIndicator,
  tableData,
  headerDashboard,
  notificationFullComp,
  tableDataFabrics,
  globalProps,
  tableHeader,
  golbalData,
  brandDashboardHeaderData,
  tableDataBrand,
  tableHeaderBrand,
  tailorProfileHeader,
  headerTailor,
  tailorDashboardHeaderData,
  tableDataTailor,
  tableHeaderTailor,
  tableHeaderTotelOrders,
  tableDataTotelOrders,
  globalDataOrderTable,
  tailorDetailPageTableHeader,
  tableDataTotalDiscount,
  tableDataFabricsPage,
  globalPropsFabric,
  tableHeaderDiscount,
  tableDataDiscount,
  discountCheckboxCatogeryData as discountCheckboxData,
  discountCheckboxApplicableForData,
  applicableForData,
  brandsCheckboxData,
  status,
  totalStartsOfRating,
  tailorCheckboxData,
  globalPropsDiscount,
  DiscountTableHeaderforCustomers,
  DiscountTableDataforCustomers,
  tableHeaderCustomers,
  tableDataCustomers,
  customersConst,
  appconst,
  customersPageOrderAndInfoCompConst,
  CustomerPageTableHeaderforMemebersComp,
  CustomerPageTableDataforMemebersComp,
  favouriteTailors,
  favouriteFabrics,
  billingAndShippingAddressConst,
  offerTableHeaderDataOffer,
  offerTableDataOffer,
  globalPropsOffer,
  priorityConst,
  CustomertableHeaderOrder,
  CustomertableDataOrder,
  CustomertableHeaderReview,
  CustomertableDataReview,
  CustomertableHeaderComplaint,
  CustomertableDataComplaint,
  CustomertableHeaderSize,
  CustomertableDataSize,
  styleCheckboxData,
  variablesCheckboxData,
  datePickerConst,
  topDiscountConst,
  paymentType,
  dealTableHeaderData,
  globalPropsDeal,
  CustomerPageTableHeaderforAddressComp,
  tablePendingTailor
}