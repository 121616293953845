import {FabricService} from '../../../services';
import {createAction} from 'redux-actions';
import {appFabricAddSuccess,appFabricAddError} from './AddFabric.actionNames';

const fabricAddSuccess = createAction(appFabricAddSuccess,(userData)=>userData);
const fabricAddError = createAction(appFabricAddError,(userLoginErr)=>userLoginErr);

function addFabric(data,token){
    return (dispatch)=>{
        FabricService.post(data,token).then((resp)=>{
             dispatch(fabricAddSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function FabricById(id,token){
    return (dispatch)=>{
        FabricService.get(token,).then((resp)=>{
             dispatch(fabricAddSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

export {addFabric, fabricAddError, fabricAddSuccess}
