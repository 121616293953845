import React from "react";

function BillingAndShippingAddress(props) {
    return(
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-6">
                        <h3 className="card-title">{props.billingAddressLabel}</h3>
                    </div>
                    <div className="col-6">
                        <h3 className="card-title">{props.shippingAddressLabel}</h3>
                    </div>
                </div>
            </div>
            <div className="card-body pl-4 pr-4">
                <div className="row">
                    <div className="col-6">
                        <p className="font-weight-normal f-12">{props.billingAddress}</p>
                    </div>
                    <div className="col-6">
                        <p className="font-weight-normal f-12">{props.shippingAddress}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillingAndShippingAddress ;
export {BillingAndShippingAddress};