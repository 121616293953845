import React from "react";
import { Link } from 'react-router-dom';
import StatusIndicator from "../../components/statusIndicator";
import OnOffSwitch from "../../components/Switch/OnOffSwitch";
import { urls } from "../../constants/app";
import { taxesConst } from "../../constant1";

const constObj = taxesConst.tableConsts;

const columnsForTaxes = (rows, deleteListener, onStatusChange) => {
    if(rows){
        return(
            rows.map((row)=>(
                {
                    // col1: <>
                    //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    //         <label for="news1"></label>
                    //     </>,
                    col1: <span className="text-bold text-dark">{row.tailor_name}</span>,
                    col2: <span className="font-weight-light">{row.tailor_earning}</span>,
                    col3: row.current_outstanding,
                    col4: row.khayyaty_commission,
                    col5: new Date(row.payout_date).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                    col6: row.status,
                    // col7: <div className="dropdown">
                    //         <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                    //             <i className="fa fa-ellipsis-v"></i>
                    //         </a>
                    //         <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                    //             <ul>
                    //                 {/* <li className="strong"><a href="#">{golbalData.statusActiveLabel}</a></li> */}
                    //                 <li><Link to={urls.TAX_EDIT+urls.QUERY_STRING_KEY_ID+row.id}>{constObj.editThis}</Link></li>
                    //                 <li><a onClick={(e)=>deleteListener(row.id)} href="#">{constObj.deleteThis}</a></li>
                    //             </ul>
                    //         </div>
                    //     </div>
                }
            ))
        )
    }
    else return [];
}

export default columnsForTaxes;