import React from "react";
import { Link } from 'react-router-dom';
import StatusIndicator from "../../components/statusIndicator";
import OnOffSwitch from "../../components/Switch/OnOffSwitch";
import { urls } from "../../constants/app";
import { status, taxesConst } from "../../constant1";

const constObj = taxesConst.tableConsts;

const columnsForTaxes = (rows, deleteListener, onStatusChange) => {
    if(rows){
        return(
            rows.map((row)=>(
                {
                    // col1: <>
                    //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    //         <label for="news1"></label>
                    //     </>,
                    col1: row.id,
                    col2: row.name,
                    col3: row.percent,
                    col4: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                    col5: <StatusIndicator {...row} statusConst = {status}/>,
                    col6: <OnOffSwitch {...row} statusConst = {status} onChangeListener={onStatusChange}/>,
                    col7: <div className="dropdown">
                            <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                                <i className="fa fa-ellipsis-v"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                                <ul>
                                    {/* <li className="strong"><a href="#">{golbalData.statusActiveLabel}</a></li> */}
                                    <li><Link to={urls.TAX_EDIT+urls.QUERY_STRING_KEY_ID+row.id}>{constObj.editThis}</Link></li>
                                    <li><a onClick={(e)=>deleteListener(row.id)} href="#">{constObj.deleteThis}</a></li>
                                </ul>
                            </div>
                        </div>
                }
            ))
        )
    }
    else return [];
}

export default columnsForTaxes;