import {OfferService} from '../../../services';
import {createAction} from 'redux-actions';
import {appOfferTableSuccess, appOfferTableError} from './offersTable.actionNames';

const offerTableFetchSuccess = createAction(appOfferTableSuccess,(userData)=>userData);
const offerTableFetchError = createAction(appOfferTableError,(userLoginErr)=>userLoginErr);

function offerTable(token, enocodeQuery){
    return (dispatch)=>{
        OfferService.get(token, enocodeQuery).then((resp)=>{
             dispatch(offerTableFetchSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

// TODO: offer delete need not to in reducers since not action performed particular

function offerDelete(id, token) {
    return (dispatch) => {
        OfferService.delete(id, token).then((resp) => {
            dispatch(offerTable(token));
        }).catch((error) => {
            console.log(error);
        })
    }
}

function offerSearch(offer) {
    return (dispatch) => {
        dispatch(offerTableFetchSuccess(offer));
    }
}



export {offerTable ,offerSearch, offerDelete, offerTableFetchError, offerTableFetchSuccess}
