import {config} from '../environments';
import {BaseService} from './base.service';


class TaxesService extends BaseService{
    static get(headers,queryString){
        const url = super.prepareUrl(config.endpoints.tax,queryString);
        return super.get(url,headers);
    }
    static post(data, headers) {
        const url = super.prepareUrl(config.endpoints.tax);
        return super.post(url,data,headers);
    }
    static put(id, data, headers) {
        const url = super.prepareUrl(config.endpoints.tax);
        return super.put(url + `/${id}`, data, headers);
    }
    static delete(id, headers) {
        const url = super.prepareUrl(config.endpoints.tax);
        return super.delete(url + `/${id}`,headers);
    }
}

export default TaxesService;

export {TaxesService};
