import React from 'react';
import List from "../../components/list";
import { mostOrderedStyleConst, img, currency } from "../../constant1";

function MostOrderedStylesContainor(props) {

    const Obj = { key1 : "name_en", key2 : "price" , key3 : "full_name" , key4 : "cust_count" };
    return(
        <List
            {...mostOrderedStyleConst}
            {...props}
            img={img.trendingUpImgURL}
            {...Obj}
            {...currency}
        />
    )
}

export default MostOrderedStylesContainor;
export{
    MostOrderedStylesContainor
}