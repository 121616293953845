import {config} from '../environments';
import {BaseService} from './base.service';


class PendingCouponService extends BaseService{
    static get(headers,queryString){
        const url = super.prepareUrl(config.endpoints.pendingCoupon,queryString);
        return super.get(url,headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.pendingCoupon);
        return super.post(url,data,headers);
    }
}

export default PendingCouponService;

export {PendingCouponService};
