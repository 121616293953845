import React from "react";
import Table from "../../components/table";
import NotificationAbc from "../../components/notificationFullComp";
import Notification from "../../components/notification";
import ChangePassword from "../../components/ChangePassword";
import Header from "../../components/header";
import { DealTable } from "../../components/table/columnsForTable";
import { urls } from "../../constants";
import { useSelector } from "react-redux";
import {
  img,
  changePassword,
  notification,
  notificationFullComp,
  dealTableHeaderData,
  offerTableHeaderDataOffer,
  globalPropsFabric,
} from "../../constant";

function Deals({ deleteListener, toPage, onSearch, onStatusChange }) {

  // const offer = useSelector((state) => state.deal);
  const offerPageInfo = useSelector((state) => state.deal.pageInfo);

  return (
    <>
      <div class="content-wrapper">

        <Header
          title="Deals"
          subTitle="Click to view the details"
          buttons={[
            {
              name: "ADD NEW",
              url: urls.ADD_DEAL,
              isSymbolPresent: true,
            },
          ]}
        />
        <Table
          tableData={(e) => DealTable({ deleteListener, onStatusChange })}
          onSearch={onSearch}
          toPage={toPage}
          onDelete={deleteListener}
          tableHeader={dealTableHeaderData}
          ObjectLength={offerPageInfo?.total || 0}
          pageInfo={offerPageInfo}
          {...globalPropsFabric}
        />
      </div>
    </>
  );
}

export default Deals;
