import React from 'react';
import PropTypes from "prop-types";
import { localStorageKey } from "../../constant1";
import { urls } from "../../constants";

function logOut(params) {
    localStorage.removeItem(localStorageKey.key);
    window.location.href=urls.LOGIN;
}

const NotificationAbc = (props) => {
    return (
        <>
            <div className="row mb-2">
                <div className="col-md-1 col-1">
                <a className="d-block d-sm-none" data-widget="pushmenu" href="#" role="button"><img src="images/menu-left-alt.svg" /></a>
                </div>
                <div className="col-md-3 col-11 user-profile offset-md-8">              	 
                    <div className="float-right dropdown">
                        <a href="#" data-toggle="dropdown" aria-expanded="true"><img alt="img" src={props.profilePhoto}  className="img-circle mr-2" width="30"   style={{height: '30px'}}/> <p>{props.userName} <img src={props.dotImg} className="ml-1 float-none" /></p></a>
                        <div className="dropdown-menu dropdown-menu-md tableaction useraction-dropdown dropdown-menu-right" style={{left: "inherit",right: "0px"}}>
                            <ul>
                            <li><a href="#" data-toggle="modal" data-target="#forgetpassword">{props.labelChangePassword}</a></li>
                            <li><a onClick={logOut}>{props.labelLogout}</a></li>
                            </ul>
                        </div>
                    </div>
                    {props.notification}
                </div> 
            </div> 
            {props.changePasswordPopUp}
        </>
    );
}
NotificationAbc.propTypes = {
   name : PropTypes.string,
   labelChangePassword : PropTypes.string,
   labelLogout : PropTypes.string
}
export default NotificationAbc;