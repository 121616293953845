import {DealService} from '../../../services';
import {createAction} from 'redux-actions';
import {appDealAddSuccess, appDealAddError} from './AddDeal.actionNames';

const dealAddFatchSuccess = createAction(appDealAddSuccess,(userData)=>userData);
const dealAddFatchError = createAction(appDealAddError,(userLoginErr)=>userLoginErr);

function addDeal(data,token, callback){
    return (dispatch)=>{
        DealService.post(data,token).then((resp)=>{
             dispatch(dealAddFatchSuccess(resp.data.data))
             callback();
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function dealUpdate(id, data, token,callback,pageInfo) {
    return (dispatch) => {
        DealService.put(id, data, token).then((resp) => {
            callback(pageInfo?.current_page);
        }).catch(console.log)
    }
}



export {addDeal, dealUpdate, dealAddFatchError, dealAddFatchSuccess}
