import {useDispatch, useSelector} from 'react-redux';
import React from 'react';
import {useHistory} from 'react-router-dom';
import LoginForm from '../../components/login'
import {login} from '../../constant';
import {loginReq} from './redux/login.actions'

function LoginContainer(){
    const dispatch = useDispatch()
    const userState = useSelector(state=>state.user);
    const history = useHistory();
    
    const submitHandler=(email, password)=>{
        dispatch(loginReq(email,password,'admin'));
    }
    // user already logged-in
    if(userState.token){
        // TODO: dashboard url need to be received from props
        history.push('/dashboard');
        return "";
    }else{
        return <LoginForm login={login} submitHandler={submitHandler}></LoginForm>
    }
    
    
}

export default LoginContainer;
export {LoginContainer};


