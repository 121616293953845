import { handleActions } from "redux-actions";
import { offerTableFetchSuccess, offerTableFetchError } from "./offersTable.actions";

const defaultState = {};
// TODO: it looks like over engineering
const reducer = handleActions(
  {
    [offerTableFetchSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [offerTableFetchError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);

export { reducer };