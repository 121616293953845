import React from "react";
import OrderInfo from "../../components/orderInfoTile";
import Header from "../../components/header";
import {
  billingAndShippingAddressConst,
  descriptionAndCategory,
  descriptionAndTile,
  imageGrid,
  orderInfoTile,
  ratingAndInfo,
  activeDeactiveSelection,
} from "../../constant";
import { BillingAndShippingAddress } from "../../components/billingAndShippingAddress/basicStructure";
import DescriptionAndCategory from "../../components/descriptionAndCategorySection";
import DescriptionAndTile from "../../components/descriptionAndTile";
import ImageGride from "../../components/imageGrid";
import RatingAndInfo from "../../components/ratingAndInfo";
import ActiveDeactiveSelection from "../../components/statusActiveDeactiveSection";
import { OrderTile } from "../../components/orderTile";
import { OrderPageUserInfoCompOrderTile, orderDetailPageHeaderConstForOrder } from "../../constant1";
import { urls } from "../../constants";

function View(props) {
  return (
    <div class="content-wrapper">
      <Header
        title="Orders"
        subTitle={props.id}
        buttons={[
          {
            name: "Back",
            url: props.orderPage? urls.ORDERS: urls.CUSTOMER_ORDERS+"?customer_id="+props.customerId ,
            isSymbolPresent: false,
            type:"link"
          },
        ]}
      />
      <section class="content mb-5">
        <div class="container-fluid">
          
      <div className="row">
        <div className="col-sm-9">
          <div className="row">
            <div className="col-sm-5">
              <OrderInfo
                profileImage={props.customer?.profile_picture}
                {...orderInfoTile}
                name={props.customer?.full_name}
                genderType={props.customer?.gender}
                address={[
                  props.customer?.addresses[0]?.address_line_1 +
                    " " +
                    props.customer?.addresses[0]?.address_line_2 +
                    ",",
                  <br />,
                  props.customer?.addresses[0]?.city?.name_en +
                    "," +
                    props.customer?.addresses[0]?.state?.name_en +
                    "," +
                    props.customer?.addresses[0]?.country?.order,
                ]}
              >
                <OrderTile
                  {...OrderPageUserInfoCompOrderTile}
                  titleValue={props.customer?.order_placed}
                  TitleAndDescription={[
                    OrderPageUserInfoCompOrderTile.title,
                    <br />,
                    OrderPageUserInfoCompOrderTile.description,
                  ]}
                  linkUrl={urls.ORDERS+urls.QUERY_STRING_KEY_CUSTOMERiD+props.customer_id}
                  linkTitleValue={props.customer?.saved_sizes}
                  linkTitleAndDescription={[
                    OrderPageUserInfoCompOrderTile.linkTitle,
                    <br />,
                    OrderPageUserInfoCompOrderTile.linkDescription,
                  ]}
                />
              </OrderInfo>
            </div>
            <div className="col-sm-7">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card complaint-card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-md-4 col-4">
                          <h3 className="card-title">Outstanding</h3>
                        </div>
                        <div className="col-md-8 col-8 text-right">
                          <h5 className="f-20 mt-2">
                            <strong>AED {props.net_price}</strong>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <h6 className="f-12 font-weight-medium">
                            Bespoke Request
                          </h6>
                          <h5 className="f-16 font-weight-medium">
                            AED {props.price}
                          </h5>
                        </div>
                        <div className="col-6">
                          <h6 className="f-12 font-weight-medium">
                            Shipping & Taxes
                          </h6>
                          <h5 className="f-16 font-weight-medium">
                            AED {props.shiping_cost}
                          </h5>
                        </div>
                        <div className="col-6 text-right">
                          <h6 className="f-12 font-weight-medium">Payment</h6>
                          <h5 className="f-16 font-weight-medium">
                            {props.payment_type}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <BillingAndShippingAddress
                    {...billingAndShippingAddressConst}
                    billingAddress={[
                      props.billing_address?.address_line_1,
                      " ",
                      props.billing_address?.address_line_2,
                      <br />,
                      props.billing_address?.city?.name_en,
                      ",",
                      props.billing_address?.state?.name_en,
                      ",",
                      props.billing_address?.country?.name_en,
                    ]}
                    shippingAddress={[
                      props.shiping_address?.address_line_1,
                      " ",
                      props.shiping_address?.address_line_2,
                      <br />,
                      props.shiping_address?.city?.name_en,
                      ",",
                      props.shiping_address?.state?.name_en,
                      ",",
                      props.shiping_address?.country?.name_en,
                    ]}
                  />
                </div>
              </div>
            </div>
            
            <div className="col-sm-12">
              {/* <ImageGride {...descriptionAndTile} data={imageGrid} /> */}
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <RatingAndInfo
            rating={props.tailor?.tailor_more_infos[0]?.rating? props.tailor?.tailor_more_infos[0]?.rating:0}
            totalnumberOfReviews={props.tailor?.rating_count}
            AVGtime={
              props.tailor?.tailor_more_infos[0]?.avg_time +
              ratingAndInfo.labelDays
            }
            fullName={props.tailor?.full_name}
            img={props.tailor?.profile_picture}
            {...ratingAndInfo}
          />
          <ActiveDeactiveSelection
            value={props.order_status?.name_en}
            {...activeDeactiveSelection}
            objData={["Ordered", "Accepted", "Cutting", "Stitches", "Shipped", "Delivered", "Refunded", "Canceled", "Pending"]}
            disable={true}
          />
        </div>
        {props?.order_details?.map((orderItem, i) => {
              return orderItem.order_type == 'Order' || (orderItem.order_type == 'Gift' && !orderItem?.orders_gifts[0]?.gift_voucher)   ?
               (
                <div className="row">
                  <div class="col-md-9">
                    <div className="col-sm-12">
                      <DescriptionAndCategory
                        {...descriptionAndCategory}
                        id={"KHYITEM" + orderItem?.id}
                        name={orderItem?.style?.name_en}
                        giftingValue={orderItem?.orders_gifts?.length ? 'Yes' : 'No'}
                        categoryValue={orderItem?.gender}
                        subCategoryValue={orderItem?.category?.name_en}
                        delayTime={'No Delay'}
                        couponUsedDeatil={props?.discount_coupon?.code || "None"}
                      />
                    </div>
                    <div className="col-sm-12">
                      <DescriptionAndTile
                        {...descriptionAndTile}
                        orderNumber={"KHYITEM" + orderItem?.id}
                        styleValue={orderItem?.style?.name_en}
                        styleImage={orderItem?.style?.image_url_en}
                        fabricValue={orderItem?.fabric?.title_en}
                        typeLabel="Brand Name"
                        typeValue={orderItem?.brand?.name_en}
                        costValue={"AED " + orderItem?.fabric?.price + " / meter"}
                        fabricImage={orderItem?.fabric?.image_url_en}
                        variableList={orderItem?.orders_styles}
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title">Measurements</h3>
                      </div>

                      <div class="card-body">
                        <div class="bdmeasumt">
                          <label>
                            Height:{" "}
                            {orderItem?.measurement?.height}{" "}
                            Kg
                          </label>
                        </div>
                        <div class="bdmeasumt">
                          <label>
                            Weight:{" "}
                            {orderItem?.measurement?.weight}{" "}
                            kg
                          </label>
                        </div>
                        <div class="bdmeasumt">
                          <label>
                            Age: {orderItem?.measurement?.age}{" "}
                            yr.
                          </label>
                        </div>
                        <div class="bdmeasumt">
                          <label>
                            Should Size:{" "}
                            {orderItem?.measurement?.shoulder_size}{" "}
                            inch
                          </label>
                        </div>
                        <div class="bdmeasumt">
                          <label>
                            Chest Size:{" "}
                            {orderItem?.measurement?.chest_size}{" "}
                            inch
                          </label>
                        </div>
                        <div class="bdmeasumt">
                          <label for="stitle">
                            Sleeve Size:{" "}
                            {orderItem?.measurement?.sleeve_length}{" "}
                            inch
                          </label>
                        </div>
                        <div class="bdmeasumt">
                          <label>
                            Body Length:{" "}
                            {orderItem?.measurement?.body_length}{" "}
                            inch
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : "";
            })}
      </div>
      
      </div>
      </section>
    </div>
  );
}

export default View;
export { View };
