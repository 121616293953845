import { handleActions } from "redux-actions";
import { 
  brandTableFatchError, 
  brandTableFatchSuccess, 
  brandAddSuccess, 
  brandAddError, 
  brandDetailSuccess,
  brandDetailError,
  brandEditSuccess,
  brandEditError
} from "./brandTable.actions";

const defaultState = {};
// TODO: it looks like over engineering
const brandsReducer = handleActions(
  {
    [brandTableFatchSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [brandTableFatchError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);
const addReducer = handleActions(
  {
    [brandAddSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [brandAddError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);
const detailReducer = handleActions(
  {
    [brandDetailSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [brandDetailError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);
const EditReducer = handleActions(
  {
    [brandEditSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [brandEditError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);

export { brandsReducer, addReducer, detailReducer, EditReducer };