import { handleActions } from "redux-actions";
import { userLoginError, userLoginSuccessfully } from "./login.actions";

const defaultState = {};
// TODO: it looks like over engineering
const reducer = handleActions(
  {
    [userLoginSuccessfully]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [userLoginError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);

export { reducer };
