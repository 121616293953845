import React from "react";
import { lineGraph, notificationFullComp, changePassword, img, notification, barChart, CustomerPageTableHeaderforMemebersComp,CustomerPageTableHeaderforAddressComp, customersPageOrderAndInfoCompConst, billingAndShippingAddressConst } from "../../constant";
import NotificationAbc from "../../components/notificationFullComp";
import Notification from "../../components/notification";
import ChangePassword from "../../components/ChangePassword";
import Header from "../../components/header";
import LineChart from "../../components/lineGraph";
import BarChart from '../../components/barChart/index';
import OrderInfo from "../../components/orderInfoTile";
import { customersProfileTableColoumMembers, customersProfileTableColoumAddresses } from "../../components/table/columnsForTable";
import Table from "../../components/table";
// import { FavouriteTailorsList } from "../../components/FavouriteTailorsList";
// import { FavouriteFabricsList } from "../../components/favouriteFabricsList";
import { BillingAndShippingAddress } from "../../components/billingAndShippingAddress/basicStructure";
import { urls } from "../../constants/app";
import { OrderTile } from "../../components/orderTile";
import { OrderPageUserInfoCompOrderTile, app } from "../../constant1";

function View(props) {

    return(
        <div  class="content-wrapper">
             
                <Header 
                    title="Customer"
                    subTitle = "Profile"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.CUSTOMERS,
                            isSymbolPresent : false,
                            type:'link'
                        },
                        {
                            name : "ORDERS",
                            url : urls.CUSTOMER_ORDERS+props.customerId,
                            isSymbolPresent : false,
                            type:'link'
                        },
                        {
                            name : "REVIEWS",
                            url : urls.CUSTOMER_REVIEWS+props.customerId,
                            isSymbolPresent : false,
                            type:'link'
                        },
                        {
                            name : "COMPLAINTS",
                            url : urls.CUSTOMER_COMPLAINTS+props.customerId,
                            isSymbolPresent : false,
                            type:'link'
                        }
                    ]}
                />
            <section class="content mb-5">
        <div class="container-fluid">
            
                <div className="row">
                    <div className="col-sm-4">
                        <OrderInfo 
                            profileImage={props.profile_picture}
                            {...customersPageOrderAndInfoCompConst}
                            name = {props.full_name}
                            genderType = {props.gender}
                            address= { ( props?.addresses ? [
                                props?.addresses[0]?.address_line_1+" "+
                                props?.addresses[0]?.address_line_2,<br/>,
                                props?.addresses[0]?.city.name_en+","+
                                props?.addresses[0]?.state.name_en+","+
                                props?.addresses[0]?.country.name_en
                            ] : app.dataNotFind) }
                        >
                            <OrderTile 
                                TitleAndDescription={[OrderPageUserInfoCompOrderTile.title,<br/>,OrderPageUserInfoCompOrderTile.description]}
                                linkTitleAndDescription= {[OrderPageUserInfoCompOrderTile.linkTitle,<br/>,OrderPageUserInfoCompOrderTile.linkDescription]}
                                titleValue={props.order_count}
                                linkTitleValue={props.measurement_count}
                            />
                        </OrderInfo > 
                    </div>
                    {/* <div className="col-sm-4">
                        <LineChart {...lineGraph} />
                    </div> */}
                    <div className="col-sm-4">
                        <BarChart {...barChart}/>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <Table 
                    tableData={() => customersProfileTableColoumMembers(props.family_members,props.customerId)} 
                    tableHeader={CustomerPageTableHeaderforMemebersComp} 
                    ObjectLength={(props.family_members)?.length}
                    totalResultsLabel="Family Members: "
                />
            </div>
            <div className="col-sm-12">
                <Table 
                    tableData={() => customersProfileTableColoumAddresses(props.addresses)} 
                    tableHeader={CustomerPageTableHeaderforAddressComp} 
                    ObjectLength={(props.addresses)?.length}
                    totalResultsLabel="Addresses: "
                />
            </div>
            </section>
        </div>
    )
}
export default View;
export { View };