import React from "react";
import PropTypes from "prop-types";

function ImageUpload(props){
    let button;
    if (props.disabled) {
      button="";
    } else {
      button = 
      <div className="text-center">
          <div className="upload-btn-wrapper">
              <button className="uploadBtn">{props.buttonLabel}</button>
              <input type="file" name="myfile" onChange={e => {props.onChangeListener && props.onChangeListener(e.target.files)}}/>
          </div>
      </div>;
    }
    return(
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">{props.title}</h3>
            </div>

            <div className="card-body">
                <div className="order-bxwhiite">
                    {props.image ? (
                        <img src={props.image} style={{ height: "100%" }} />
                    ) : (
                        "No Image"
                    )}
                </div>
                <p>{props.descriptionText1} <strong>{props.descriptionText2}</strong>
                </p>
                {button}
            </div>
        </div>
    )
}

ImageUpload.propTypes = {
    buttonLabel : PropTypes.string,
    descriptionText1 : PropTypes.string,
    descriptionText2 : PropTypes.string,
    title : PropTypes.string
}
export default ImageUpload;