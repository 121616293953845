import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { tailorPageColoumForReviewsTable } from "../../components/table/columnsForTable";
import {globalPropsFabric} from '../../constant';
import { urls } from "../../constants";
import { TailorPageHeaderForReviewTable } from "../../constant1";

function TailorReview(props) {
    return(
        <>    
            <div  class="content-wrapper">
                <Header 
                    title="Tailor"
                    subTitle = "Review"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.TAILOR_DETAILS+props.enocodeQuery,
                            isSymbolPresent : false,
                            type:'link'
                          }
                    ]}
                />
                <Table 
                    tableData={()=>tailorPageColoumForReviewsTable(props.rows)} 
                    tableHeader={TailorPageHeaderForReviewTable} 
                    ObjectLength={props.pageInfo?.item_count} 
                    {...globalPropsFabric}
                />
            </div>
        </>
    )
}
export default TailorReview;
export {
    TailorReview
}