import React from "react";
import PropTypes from "prop-types";

function ComplaintTile(props) {
    return(
        <div>
            <div className={props.sendByUser ? "card complaint-card": "card complaint-card-rec"}>
                <div className="card-header">
                    <div className="complaintHead">
                        <div><span>{props.fromKey}</span>: <span>{props.fromValue}</span></div>
                        <span>{props.headerRightText}</span>
                    </div>

                </div>
                <div className="card-body">
                    <div className="mb-3 f-14">
                        <span className="mr-3">{props.issueKey}: <strong>{props.issueValue}</strong></span>
                        <span>{props.issueSubDivisionKey}: <strong>{props.issueSubDivisionValue}</strong></span>
                    </div>
                    <p className="complaintTitle">{props.subject}</p>
                    <p className="theme-text m-0">{props.message}</p>
                </div>
            </div>
        </div>
    )
}
ComplaintTile.propTypes = {
    fromKey : PropTypes.string,
    fromValue : PropTypes.string,
    headerRightText : PropTypes.string,
    issueKey : PropTypes.string,
    issueValue : PropTypes.string,
    issueSubDivisionKey : PropTypes.string,
    issueSubDivisionValue : PropTypes.string,
    message : PropTypes.string,
    subject : PropTypes.string,
    sendByUser : PropTypes.bool
}
ComplaintTile.defaultProps = {
    subject : "",
    sendByUser : true
}
export default ComplaintTile;