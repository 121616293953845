import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import CircleChart from "../../components/circleChart";
import { circleChart } from "../../constant";
import { OutstandingAction } from "./redux/outstanding.action";

function Outstanding(props) {

    let queryString = `?startDate=${props.start?.startDate}&endDate=${props.end?.endDate}`
    
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const outstandingState = useSelector(state=>state.outstanding);

    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        dispatch(OutstandingAction(token,queryString));
    },[queryString])
    
    var online=0;
    var cash = 0;
    var total = 0 ;

    outstandingState?.amounts?.map((row)=>{
        total = total+row.totalAmount;
        return "";
    })
    return(
        <CircleChart 
            {...circleChart} 
            array={outstandingState?.amounts} 
            cash={cash}
            online={online}
            total={total}
            colSize = {6}
            name= "Earning"
        />
    )
}

export default Outstanding;