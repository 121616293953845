import {BaseService} from './base.service';
import {config} from '../environments';

class DashboardServices extends BaseService{
    static get(token,queryString){
        const url = super.prepareUrl(config.endpoints.dashboard,queryString);
        return super.get(url,token);
    }
    static getSalestrend(token,queryString){
        const url = super.prepareUrl(config.endpoints.dashboardSalesTrend,queryString);
        return super.get(url,token);
    }
}

export default DashboardServices;

export {DashboardServices};
