import { handleActions } from "redux-actions";
import { orderTableError, orderTableSuccess, orderDetailError, orderDetailSuccess } from "./order.actions";

const defaultState = {};
// TODO: it looks like over engineering
const reducerOrders = handleActions(
  {
    [orderTableSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [orderTableError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);
const reducerOrdersDetail = handleActions(
  {
    [orderDetailSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [orderDetailError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);

export { reducerOrders, reducerOrdersDetail };
