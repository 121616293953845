import React from 'react';
import { LoginContainer } from "../../../containers/login";
const Login = () => {
    
    return (
        <div className="wrapper">
            <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="login-box mt-5 mb-3">
                    <div className="row">
                        <div className="col-sm-5 col-md-5">
                            {/* TODO: Image should be passed as props */}
                            <img src={process.env.PUBLIC_URL + '/assets/images/farm-img.png'} alt="A Farmer" width="400" className="d-none d-sm-block left-img border-rounded" />
                        </div>
                        <div className="col-sm-7 col-md-7">
                            <div className="pl-5 pr-5 pt-3 pb-2">
                                <img src={process.env.PUBLIC_URL + '/assets/images/logo.svg'} alt="A Logo"className="float-right" width="80px" />
                                <div className="clearfix"></div>                                 
                                    <LoginContainer />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    );
}
    
export default Login;