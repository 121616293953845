import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../components/input';
import TextArea from '../../components/textArea';
import SelectSection from '../../components/SelectSection'
import ImageUploadTile from "../../components/imageUpload";
import ChipSections from "../../components/ChipSection/ChipSection";
import { imageUpload, tailorCheckboxData, categoryData } from "../../constant";
import {CheckboxSectionV1} from "../../components/CheckboxSectionV1";
import { DropDown } from "../../components/selectDropdown";
import { gender, gender1, global } from "../../constant1";

function AddTailorContainer(props) {
    return (
        <div class="wrapper">
            <div class="content-wrapper">
                {props.children}
                <section class="content mb-5">
                    <div class="container-fluid">
                        <div class="row">
                            <section class="col-lg-9 connectedSortable">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <Input 
                                            defaultValue="" 
                                            type="text" 
                                            id="companyName" 
                                            class="form-control" 
                                            placeholder="Company Name" 
                                            required="" 
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('companyName',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <Input 
                                            defaultValue="" 
                                            type="text" 
                                            id="ownerName" 
                                            class="form-control" 
                                            placeholder="Owner Name" 
                                            required="" 
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('ownerName',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <DropDown 
                                            heading="Gender"
                                            options={gender1}
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('Gender',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <Input 
                                            defaultValue="" 
                                            type="text" 
                                            id="tradingLicense" 
                                            placeholder="Trading License"
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('tradingLicense',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <DropDown 
                                            heading="Category"
                                            options={gender}
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('Category',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div class="col-lg-5">
                                                <DropDown 
                                                heading="Number" 
                                            />
                                            </div>
                                            <div className="col-md-7">
                                                    <Input 
                                                    defaultValue="" 
                                                    type="text" 
                                                    id="countryFocal" 
                                                    placeholder="Focal point"
                                                    onChangeListener={(value)=>{
                                                        props.onChangeListener('tradingLicense',value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                    <div class="row">
                                            <div class="col-lg-5">
                                            <DropDown 
                                                heading="Number" 
                                            />
                                            </div>
                                            <div className="col-md-7">
                                        <Input 
                                            defaultValue="" 
                                            type="text" 
                                            id="countryNumber" 
                                            placeholder="Phone Number"
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('tradingLicense',value);
                                            }}
                                        />
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <Input 
                                            defaultValue="" 
                                            type="email" 
                                            id="email" 
                                            placeholder="Email address"
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('email',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <Input 
                                            defaultValue="" 
                                            type="password" 
                                            id="password" 
                                            placeholder="Password"
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('password',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-12">
                                        <p class="f-20 text-black font-weight-extbold">{global.addressDetailsLabel}</p>
                                    </div>
                                    <div class="col-lg-12">
                                        <TextArea 
                                            id="addressLine1" 
                                            placeholder="Flat/House No. Line 1" 
                                            required=""
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('addressLine1',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-12">
                                        <TextArea 
                                            id="addressLine2" 
                                            placeholder="Flat/House No. Line 2" 
                                            required=""
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('addressLine2',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <Input 
                                            type="text" 
                                            id="PoBoxNumber" 
                                            class="form-control" 
                                            placeholder="PO Box No" 
                                            required=""
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('PoBoxNumber',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <DropDown 
                                            heading="City"
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('City',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <DropDown 
                                            heading="state"
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('state',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <DropDown 
                                            heading="Country"
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('country',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6 mb-4">
                                        <button data-toggle="modal" data-target="#addlocation" class="btn btn-dark rounded-0 btn-sm mb-3">
                                            <i class="fa fa-map-marker" aria-hidden="true"></i> 
                                                {global.addGeoLocation}</button>
                                            <p></p>
                                    </div>
                                </div>
                            </section>
                            <div className="col-sm-3">
                        <SelectSection 
                            heading="Status" 
                            options={['Active','Inactive']} />
                        
                    </div>
                            {/* <section class="col-lg-4 connectedSortable">

                                <SelectSection 
                                    heading="Status" 
                                    options={['active','inactive']}
                                />

                                <CheckboxSectionV1 
                                    rows={categoryData} 
                                    {...tailorCheckboxData} 
                                />
                        
                                <ImageUploadTile {...imageUpload} />
                                
                            </section>     */}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

AddTailorContainer.propTypes = {

}

export default AddTailorContainer;