import {UserService} from '../../../services';
import {createAction} from 'redux-actions';
import {appAdminLoginSuccessful,appAdminLoginError} from './login.actionNames';
import { localStorageKey } from "../../../constant1";

const userLoginSuccessfully = createAction(appAdminLoginSuccessful,(userData)=>userData);
const userLoginError = createAction(appAdminLoginError,(userLoginErr)=>userLoginErr);

function loginReq(userName, password, role){
    return (dispatch)=>{
        UserService.auth(userName,password,role).then((resp)=>{        
            // TODO: A service required for local storage
             localStorage.setItem(localStorageKey.key,JSON.stringify(resp.data.data));
             dispatch(userLoginSuccessfully(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

export {loginReq, userLoginError, userLoginSuccessfully}
