import React from "react";
import DefaultPath from "../../pages/Dashboard/DefaultPath";

function DashboardContainor(props) {
    return(
        <DefaultPath/>
    )
}

export default DashboardContainor;
export{
    DashboardContainor
}