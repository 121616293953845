import React, { useEffect } from "react";
import { Discount } from "../../pages/discount";
import {useDispatch, useSelector} from 'react-redux';
import { 
    DiscountTableAction, 
    DiscountTableFatchSuccess, 
    updateDiscountCouponAction ,
    DeleteAction
} from "./redux/discount.action";

function Discounts(props) {
    
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const discountCoupons = useSelector(state=>state.discountCoupons);
    const rows = discountCoupons?.rows;
    const pageInfo = discountCoupons?.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        toPage(1);
    }, [discountCoupons.search]);

    function toPage(page, limit) {
        let search = discountCoupons.search;

        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.id = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';
        
        dispatch(DiscountTableAction(token, enocodeQuery));
    }
    function onSearch(str) {
        dispatch(
            DiscountTableFatchSuccess({
            ...discountCoupons.data,
            search: str,
          })
        );
    }

    function onStatusChange(id,status) {
        dispatch(updateDiscountCouponAction(id, {
            status: status ? 'active' : 'inactive'
        },token, toPage, pageInfo));
    }
    function Delete(id) {
       dispatch(DeleteAction( id,token, ()=>toPage(pageInfo.current_page) ));
    }

    return(
        <Discount 
            rows={rows} 
            pageInfo={pageInfo} 
            toPage={toPage} 
            onSearch={onSearch}
            onStatusChange={onStatusChange}
            Delete= {Delete}
            {...props}
        />
    )
}
export default Discounts;
export {
    Discounts
}