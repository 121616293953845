import React from "react";
import CircleChart from "../../components/circleChart";
import { MeasurementTypeConst } from "../../constant1";

function MeasurementType(props) {
    let key = []
    if(props.dataObj){
        key = Object.keys(props.dataObj)
    }
    return(
        <CircleChart 
            {...MeasurementTypeConst} 
            array={[
                {paymentType:key[2],totalAmount:props.dataObj?.manual },
                {paymentType:key[0],totalAmount:props.dataObj?.ai },
                {paymentType:key[1],totalAmount:props.dataObj?.bmi },
            ]}
            colSize = {4}
            name= "Measurement Type"
        />
    )
}

export default MeasurementType;
export{
    MeasurementType
}