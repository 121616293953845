import React from 'react';
import { ViewAll as PendingTailorViewAll } from "../../components/view_all";
import { pendingTailorConst, img } from "../../constant1";
import { urls } from "../../constants";

function PendingTailorContainor(props) {
    return(
        <PendingTailorViewAll
            {...pendingTailorConst}
            // img={img.percentageImgURL}
            {...props}
            url={urls.TAILORS_PENDING}
        />
    )
}

export default PendingTailorContainor;
export{
    PendingTailorContainor
}