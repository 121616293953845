import React, { useEffect }  from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Customers as CustomersTable} from "../../pages/customers/customers";
import { CustomersAction, CustomersFatchSuccess, CustomersStatusUpdate } from "./redux/customer.action";

function Customers(props) {
    
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const customers = useSelector(state=>state.customers);
    const rows = customers?.data?.rows;
    const pageInfo = customers?.data?.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };
    
    useEffect(()=>{
        dispatch(
            CustomersFatchSuccess({
            ...customers,
            search: "",
          })
        );
    },0)

    useEffect(()=>{
        toPage(1);
    }, [customers.search]);
    
    function toPage(page, limit) {
        let search = customers.search;

        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.name = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';
        
        dispatch(CustomersAction(token, enocodeQuery));
    }
    function onSearch(str) {
        dispatch(
            CustomersFatchSuccess({
            ...customers,
            search: str,
          })
        );
    }
    function onStatusChange(id,status) {
        dispatch(CustomersStatusUpdate(id, {
            status: status ? 'active' : 'inactive'
        },token, toPage, pageInfo));
    }
    return(
        <CustomersTable
            toPage={toPage} 
            onSearch={onSearch} 
            rows={rows} 
            pageInfo={ pageInfo }
            onStatusChange={onStatusChange}
        />
    )
}

export default Customers
export {
    Customers
}