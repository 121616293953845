import { TaxesService } from "../../../services";
import { createAction } from "redux-actions";
import { 
    appTaxesFatchSuccess, 
    appTaxesFatchError,
    appTaxesByIdFatchSuccess,
    appTaxesByIdFatchError
} from "./tax.actionNames";

const taxesFatchSeccess = createAction(appTaxesFatchSuccess,(data)=>data);
const taxesFatchError = createAction(appTaxesFatchError,(ErrorData)=>ErrorData);

const taxFatchByIdSeccess = createAction(appTaxesByIdFatchSuccess,(data)=>data);
const taxFatchByIdError = createAction(appTaxesByIdFatchError,(ErrorData)=>ErrorData);

function taxesFatchAction(token,queryString) {
    return(dispatch)=>{
        TaxesService.get(token,queryString).then((resp)=>{
            dispatch(taxesFatchSeccess(resp.data.data))
           }).catch((err)=>{
               console.log(err)
           })
    }
}
function taxByIdAction(token,queryString) {
    return(dispatch)=>{
        TaxesService.get(token,queryString).then((resp)=>{
            dispatch(taxFatchByIdSeccess(resp.data.data))
           }).catch((err)=>{
               console.log(err)
           })
    }
}
function taxDelete(id, token, toPage, pageInfo) {
    return (dispatch) => {
        TaxesService.delete(id, token).then((resp)=>{
            toPage(pageInfo.current_page)
           }).catch((err)=>{
               console.log(err)
           })
    }
}
function TaxAdd(data, token) {

    return (dispatch) => {
        TaxesService.post(data, token).then((resp)=>{
           }).catch((err)=>{
               console.log(err)
           })
    }
}
function taxEdit(id,data,token, toPage, pageInfo){

    return (dispatch)=>{
        TaxesService.put(id,data,token).then((resp)=>{
            toPage(pageInfo.current_page);
            }).catch((err)=>{
                console.log(err)
            })
    }
}
export {
    taxesFatchAction,
    taxesFatchSeccess,
    taxesFatchError,
    taxDelete,
    TaxAdd,
    taxByIdAction,
    taxFatchByIdSeccess,
    taxFatchByIdError,
    taxEdit
}