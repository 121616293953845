import { PayoutService } from "../../../services";
import { createAction } from "redux-actions";
import { 
    appPayoutFatchSuccess,
    appPayoutFatchError
} from "./payout.actionNames";

const payoutFatchSeccess = createAction(appPayoutFatchSuccess,(data)=>data);
const payoutFatchError = createAction(appPayoutFatchError,(ErrorData)=>ErrorData);

function payoutFatchAction(token,queryString) {
    return(dispatch)=>{
        PayoutService.get(token,queryString).then((resp)=>{
            dispatch(payoutFatchSeccess(resp.data.data))
           }).catch((err)=>{
               console.log(err)
           })
    }
}
// function taxByIdAction(token,queryString) {
//     return(dispatch)=>{
//         TaxesService.get(token,queryString).then((resp)=>{
//             dispatch(taxFatchByIdSeccess(resp.data.data))
//            }).catch((err)=>{
//                console.log(err)
//            })
//     }
// }
// function taxDelete(id, token, toPage, pageInfo) {
//     return (dispatch) => {
//         TaxesService.delete(id, token).then((resp)=>{
//             toPage(pageInfo.current_page)
//            }).catch((err)=>{
//                console.log(err)
//            })
//     }
// }
// function TaxAdd(data, token) {

//     return (dispatch) => {
//         TaxesService.post(data, token).then((resp)=>{
//            }).catch((err)=>{
//                console.log(err)
//            })
//     }
// }
// function taxEdit(id,data,token){

//     return (dispatch)=>{
//         TaxesService.put(id,data,token).then((resp)=>{
            
//             }).catch((err)=>{
//                 console.log(err)
//             })
//     }
// }
export {
    payoutFatchAction,
    payoutFatchSeccess,
    payoutFatchError
}