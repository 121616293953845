import { urls } from "../constants";

const addNewFabric = {
    title:"Fabric",
    subTitle:"Add New",
    buttons: [
        {name:"Back",url: urls.FABRICS},
        {name:"Publish",url: "#"}
    ]
}
const editFabric = {
    title:"Fabric",
    subTitle:"Edit",
    buttons: [
        {name:"Back",url: urls.FABRICS,type:"link"},
        {name:"UPDATE",type:"button"}
    ]
}
const detailFabric = {
    title:"Fabric",
    subTitle:"Detail",
    buttons: [
        {name:"Back",url: urls.FABRICS,type:"link"},
        {name:"edit",url: "#",type:"link"}
    ]
}
const addNewFabricType = {
    title:"Manage Fabrics Type",
    subTitle:"Add New",
    buttons: [
        {name:"Back",url: urls.FABRICS, type:"link"},
        {name:"Publish",url: "#", type:"button" }
    ]
}

export {addNewFabric, editFabric, addNewFabricType,detailFabric}