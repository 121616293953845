import { DashboardServices } from "../../../services";
import { createAction } from "redux-actions";
import { 
    appDashboardDataFatchSuccess,
    appDashboardDataFatchError,
    appSalesTrendFatchSuccess,
    appSalesTrendFatchError
 } from "./dashboard.actionNames";

 const dashboardDataFatchSuccess = createAction(appDashboardDataFatchSuccess,(dataSuccess)=>dataSuccess);
 const dashboardDataFatchError = createAction(appDashboardDataFatchError,(dataError)=>dataError);

 const dashboardSalesTrendFatchSuccess = createAction(appSalesTrendFatchSuccess,(dataSuccess)=>dataSuccess);
 const dashboardSalesTrendFatchError = createAction(appSalesTrendFatchError,(dataError)=>dataError);

function DashboardDataFatchAction(token,enocodeQuery) {
    return((dispatch)=>{
        DashboardServices.get(token,enocodeQuery).then((resp)=>{
            dispatch(dashboardDataFatchSuccess(resp.data?.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}
function DashboardSalesTrendFatchAction(token,enocodeQuery) {
    return((dispatch)=>{
        DashboardServices.getSalestrend(token,enocodeQuery).then((resp)=>{
            dispatch(dashboardSalesTrendFatchSuccess(resp.data?.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}

export{
    DashboardDataFatchAction,
    dashboardDataFatchSuccess,
    dashboardDataFatchError,
    DashboardSalesTrendFatchAction,
    dashboardSalesTrendFatchSuccess,
    dashboardSalesTrendFatchError
}