import React,{ useState } from 'react'
import PropTypes from 'prop-types'
import { AddTailorContainer } from "../../containers/AddTailor";
import {addTailor} from '../../containers/AddTailor/redux/AddTailor.actions';
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/header";

import {
    addNewTailor
  } from "../../constants";

function TailorNew(props) {
  
  const [form, setForm] = useState({});

  const dispatch = useDispatch();
  const userState = useSelector(state=>state.user);
  
  const submitHandler = (e) => {

      const token = {
        'Authorization' : `${userState.token}`
      };

      // let data = {
      //   "title" :  title,
      //   "descriptions_en" : description,
      //   "status" : status,
      //   "catogery" : catogery,
      //   "servicServingIn" : location,
      //   "avrageMakingTime" : AVGmakingTime,
      //   "avrageCost" : AVGcost
      // }

      if( ( form.title !=="" ) && ( form.description !=="" ) && ( form.status !=="" ) && ( form.catogery !=="" ) && ( form.location !=="" ) && ( form.AVGmakingTime !=="" ) && ( form.AVGcost !=="" ) ){
        dispatch( addTailor(form,token) );
      }else{
        alert( "Please fill all the boxes." ); // todo : will pass this message from constents
      }
    }
    const onChangeListener = (key, value)=>{
      setForm({...form,[key]:value});
    }
    return (
      <AddTailorContainer
        onChangeListener={onChangeListener}
        form={form}
      >
        <Header
          submitHandler = {submitHandler}
          title={addNewTailor.title}
          subTitle={addNewTailor.subTitle}
          buttons={[addNewTailor.buttonBack,addNewTailor.buttonPublish]}
        />
      </AddTailorContainer>
    )
}

TailorNew.propTypes = {

}

export default TailorNew;