import React, {useState} from 'react';
import propTypes from 'prop-types'; 

const ErrorMessage = (props) => {
  return (<> <span className={props.classErrorMessage}>{props.errorMessage}</span> </>);
}

const ChangePassword = (props) => {

  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const[hasError,setHasError] = useState(false);
  const[errorMessage,setErrorMessage] = useState();

  const newPasswordChangeHandler = (event)=>{
    setNewPassword(event.target.value);
  }

  const confirmNewPasswordChangeHandler = (event)=>{
    setConfirmNewPassword(event.target.value);
  }
  
  const handleSubmit = (event) => {
    
    event.preventDefault();

    const newPwd = newPassword;
    const confNewPwd =  confirmNewPassword;

    
    if( typeof newPwd === "undefined" || newPwd === "" || confNewPwd === "undefined"|| confNewPwd === "" ){
      
      setErrorMessage("Required Field is empty");
      setHasError(true);
    }
    else if( newPwd !== confNewPwd ){
      setErrorMessage("Password & Confirm New Password not Matched.");  
      setHasError(true);
    }
    else{
      setErrorMessage("");  
      setHasError(false);
    }

  }

  return (
    <> 
            <div className="modal fade rightModal" id="forgetpassword" tabIndex="-1" role="dialog" aria-labelledby="loginpopupTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-slideout" role="document">
            
              <div className="modal-content bg-light">
              <form className="needs-validation" noValidate>
                <div className="modal-header pt-5 pl-5 pr-5 border-0">
                  <div className="pt-3">
                  <button type="button" className="close search-btn addaddressbtn" data-dismiss="modal" aria-label="Close">
                    <img alt="Header Section" src={process.env.PUBLIC_URL + '/assets/images/close.svg'} width="70px" />
                  </button>
                  <div className="">              
                      <h3>{props.heading}</h3>
                  </div>
                  
                  </div>
                </div>
                <div className="modal-body pt-3 pr-5 pl-5">
                  
                  <div className="row">

                        { hasError ? <ErrorMessage classErrorMessage={props.classErrorMessage} errorMessage={errorMessage} /> : null }
                        <div className="col-md-12">
                          <div className="form-label-group">
                                <input type="password" id="newPassword" name="newPassword" onChange={(event) => newPasswordChangeHandler(event)} value={newPassword}  className="form-control"    />
                                <label htmlFor="newPassword">{props.labelNewPassword}</label>
                            </div>                
                        </div>
                        <div className="col-md-12">
                          <div className="form-label-group">
                                <input type="password"  name="confirmNewPassword" id="confirmNewPassword" onChange={(event) => confirmNewPasswordChangeHandler(event)}  value={confirmNewPassword} className="form-control"   />
                                <label htmlFor="confirmNewPassword">{props.labelConfirmNewPassword}</label>
                            </div>                
                        </div> 

                        <div className="col-md-12 text-center mt-4">
                          <button   type="button" onClick={(event) => handleSubmit(event) } className="btn btn-secondary btn-radius" >{props.labelSubmitButton}</button>
                        </div>
                    
                    </div>                  
                </div>
                </form>
              </div>
              
            </div>
        </div> 
    </>
);



} // End Change Password 

ChangePassword.propTypes = {
  heading : propTypes.string,
  labelNewPassword: propTypes.string,
  labelConfirmNewPassword: propTypes.string,
  labelSubmitButton: propTypes.string

} 

ErrorMessage.propTypes = {
  classErrorMessage: propTypes.string,
  errorMessage: propTypes.string
} 




export default ChangePassword;