import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import List from "../../components/list";
import { listTailor , img } from "../../constant";
import { tailor } from "./redux/tailor.actions";

function TailorContainer(props) {
    
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const tailorState = useSelector(state=>state.tailorList.tailors);

    let queryString = `?startDate=${props.start?.startDate}&endDate=${props.end?.endDate}`
    
    const token = {
        'Authorization' : `${userState.token}`
    };
    useEffect(()=>{
        dispatch(tailor(token,queryString));
    },[queryString])
    
    const Obj = { key1 : "tailorId", key2 : "tailorName", key3 : "totalAmount" };
    
    return <List
                title={ 'Top Tailors' } // pass this as props
                { ...Obj }
                array={tailorState}
                img={img.trendingUpImgURL}
            />;
}

export default TailorContainer;
export {TailorContainer};