import {BaseService} from './base.service';
import {config} from '../environments';

class OrderService extends BaseService{
    static get(headers,queryString){
        const url = super.prepareUrl(config.endpoints.order,queryString);
        return  super.get(url,headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.order);
       return  super.post(url,data,headers);
    }
}

export default OrderService;
export {OrderService};