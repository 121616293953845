import { handleActions } from "redux-actions";
import { tailorListFatchError, tailorListFatchSuccess } from "./tailor.actions";

const defaultState = {};
// TODO: it looks like over engineering
const reducer = handleActions(
  {
    [tailorListFatchSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [tailorListFatchError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);

export { reducer };