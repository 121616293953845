import {PendingCouponService} from '../../../services';
import {createAction} from 'redux-actions';
import {appPendingSuccess, appPendingError} from './pending.actionNames';

const PendingFetchSuccess = createAction(appPendingSuccess,(userData)=>userData);
const PendingFetchError = createAction(appPendingError,(userLoginErr)=>userLoginErr);

function PendingCoupon(token,queryString){
    return (dispatch)=>{
        PendingCouponService.get(token,queryString).then((resp)=>{
             dispatch(PendingFetchSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

export {PendingCoupon, PendingFetchError, PendingFetchSuccess}
