function OprionsForCircleChart(data,name) {
const OptionsCircleChart = {
  
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false
        },
        colors: ['#ccc', '#172B4D','#F5E9B3'],
        title: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          y: 60
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 270,
            center: ['20%', '40%'],
            size: '60%'
          }
        },
        series: [{
          type: 'pie',
          name: name,
          innerSize: '84%',
          data
        }]
    }
    return OptionsCircleChart;
}
  export{
    OprionsForCircleChart
  } ;