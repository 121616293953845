import React, { useState, useEffect } from "react";
import { StyleAddAndEdit } from "../../pages/style";
import queryString from "query-string";
import { StyleInfo , getCategory, addStyle, updateStyle} from "./redux/style.actions";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import { variableList } from "../variableTable/redux/variableTable.actions"
import { uploadImage } from "../AddOffer/redux/AddOffer.actions";
import { urls } from "../../constants/app";

function StylesAddNew(props) {
    const userState = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const tailorState = useSelector(state=>state.tailor);

    const token = {
        Authorization: `${userState.token}`,
      };
    
    const [id, setId] = useState(null);

    const [style, setStyle] = useState({
        variables: []
    });
    const [variableCategoryList, setVariableCategoryList] = useState({});
    const [categoryList, setCategoryList] = useState({});

    useEffect(() => {
        getCategoryList();
        const query = queryString.parse(window.location.search);
        if (query?.id) {
          setId(query?.id);
          getStyleInfo(query?.id);
        }
    }, [])
    
    useEffect(() => {
        getVariableCategoryList();
    }, [style.user_id, style.gender]);

    function getCategoryList() {
        dispatch(getCategory(token, "", setCategoryList));
    }

    function getVariableCategoryList() {
        if (!style?.user_id) return;
        let query = {
            status: 'active'
        };

        if (style?.gender) query.gender = style.gender;
        if (style?.user_id) query.tailor_id = style?.user_id;

        let enocodeQuery = Object.keys(query)
          .map((key) => key + "=" + query[key])
          .join("&");
        enocodeQuery = enocodeQuery ? "?" + enocodeQuery : "";

        dispatch(variableList(token, enocodeQuery, setVariableCategoryList))
    }

    function getStyleInfo(id) {
        dispatch(StyleInfo(id, token, setStyle));
    }

    function onChangeListener(data) {
        console.log(data);
        setStyle({
            ...style,
            ...data
        })
    }

    function onUploadImage(data, callBack){
        debugger;
        return dispatch(uploadImage(data[0], token, callBack))
    }

    function onSubmitHandler() {
        let sendData = {};

        if(!style?.name_en?.length || !style?.descriptions_en?.length || !style?.gender || !style?.category_id || !style?.image_url_en) {
            alert("Please fill required fields");
        }

        sendData = {
            name_en: style.name_en,
            name_ar: style.name_ar,
            category_id: style.category_id,
            gender: style.gender,
            image_url_en: style.image_url_en,
            image_url_ar: style.image_url_ar,
            descriptions_en: style.descriptions_en,
            descriptions_ar: style.descriptions_ar,
            status: style.status,
            variableList: [],
            price: style.price,
            tailor_id: style.user_id
        };

        sendData.variableList = style.variables?.map((variableObj) => {
            return {
                id: variableObj.id || undefined,
                name_en: variableObj.name_en,
                name_ar: variableObj.name_ar,
                variable_category_id: variableObj.variable_category?.id || 1,
                image_url_en: variableObj.image_url_en,
                image_url_ar: variableObj.image_url_ar,
                descriptions_en: variableObj.descriptions_en,
                descriptions_ar: variableObj.descriptions_ar,
                status: variableObj.status,
                deleted: variableObj.deleted,
                price: variableObj.price || 0
            }
        })

        console.log(sendData);

        if(id) {
            dispatch(updateStyle(id, sendData, token, () => {
                history.push({
                    pathname: urls.STYLES
                })
            }))
        } else {
            dispatch(addStyle(sendData, token, () => {
                history.push({
                    pathname: urls.STYLES
                })
            }));
        }
    }

    return(
        <StyleAddAndEdit 
            tailorList={tailorState?.rows}
            onSubmitHandler={onSubmitHandler} 
            onUploadImage={onUploadImage} 
            onChangeListener={onChangeListener} 
            style={style} 
            variableCategoryList={variableCategoryList?.variable_category} 
            categoryList={categoryList}
            id={id}
        />
    )
}

export default StylesAddNew;