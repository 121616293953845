import { handleActions } from "redux-actions";
import { 
    CustomersFatchSuccess, 
    CustomersFatchError, 
    CustomersDetailFatchSuccess, 
    CustomersDetailFatchError,
    memberMeasurementSuccess,
    memberMeasurementError,
    customerOrdersSuccess,
    customerOrdersError,
    customerReviewsSuccess,
    customerReviewsError
} from "./customer.action";

const defaultState = {};

const reducerCustomers = handleActions(
    {
        [CustomersFatchSuccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [CustomersFatchError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
const reducerCustomerDetail = handleActions(
    {
        [CustomersDetailFatchSuccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [CustomersDetailFatchError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
const reducerMemberMeasurement = handleActions(
    {
        [memberMeasurementSuccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [memberMeasurementError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
const reducerCustomerOrders = handleActions(
    {
        [customerOrdersSuccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [customerOrdersError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
const reducerCustomerReviews = handleActions(
    {
        [customerReviewsSuccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [customerReviewsError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
export { 
    reducerCustomers,
    reducerCustomerDetail,
    reducerMemberMeasurement,
    reducerCustomerOrders,
    reducerCustomerReviews
};