import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { customersTableColoumReviews } from "../../components/table/columnsForTable";
import {CustomertableHeaderReview,globalPropsFabric} from '../../constant';
import { urls } from "../../constants";

function CustomerTailorReview(props) {
    return(
        <>    
            <div  class="content-wrapper">
                <Header 
                    title="Customers"
                    subTitle = "Tailor Review"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.CUSTOMER_DETAIL+props.queryString,
                            isSymbolPresent : false,
                            type:"link"
                          }
                    ]}
                />
                <Table 
                    tableData={()=>customersTableColoumReviews(props.rows)} 
                    tableHeader={CustomertableHeaderReview} 
                    ObjectLength="2"  
                    {...globalPropsFabric}
                />
            </div>
        </>
    )
}
export default CustomerTailorReview;
export {
    CustomerTailorReview
}