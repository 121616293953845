import { urls } from "../constants";
const addNewTailor = {
    title:"Tailor",
    subTitle:"Add New",
    buttonBack:{name:"Back",url: urls.TAILORS},
    buttonPublish:{name:"Publish",url: urls.TAILORS},
}
const tailorProfile = {
    title:"Tailor",
    subTitle:"Profile",
}

export {addNewTailor, tailorProfile}