import { handleActions } from "redux-actions";
import { 
  fabricTableFatchError, 
  fabricTableFatchSuccess,
  fabricTypeAddSuccess,
  fabricTypeAddError,
  fabricTypeGetSuccess,
  fabricTypeGetError
} from "./fabricTable.actions";

const defaultState = {};
// TODO: it looks like over engineering
const reducer = handleActions(
  {
    [fabricTableFatchSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [fabricTableFatchError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);
const fabricTypeAddreducer = handleActions(
  {
    [fabricTypeAddSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [fabricTypeAddError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);
const fabricTypeGetreducer = handleActions(
  {
    [fabricTypeGetSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [fabricTypeGetError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);

export { 
  reducer,
  fabricTypeAddreducer,
  fabricTypeGetreducer
};