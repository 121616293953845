import { handleActions} from 'redux-actions';

import {appLoaded} from './app.actions';

const defaultState={
        isAppLoaded:false
}
const reducer = handleActions({
    [appLoaded]: (state,action) => ({...state, isAppLoaded:action.payload.isLoaded})
},defaultState)

export {reducer}