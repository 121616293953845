import {app} from '../../../constants';

export const appFabricTableSuccess= app.FABRIC_TABLE_SUCCESSFUL;
export const appFabricTableError= app.FABRIC_TABLE_ERROR;

export const appFabricAddSuccess= app.FABRIC_ADD_SUCCESSFUL;
export const appFabricAddError= app.FABRIC_ADD_ERROR;

export const appFabricTypeGetSuccess= app.FABRIC_TYPE_GET_SUCCESSFUL;
export const appFabricTypeGetError= app.FABRIC_TYPE_GET_ERROR;

export const appFabricTypeAddSuccess= app.FABRIC_TYPE_ADD_SUCCESSFUL;
export const appFabricTypeAddError= app.FABRIC_TYPE_ADD_ERROR;

export const appFabricEditSuccess= app.FABRIC_EDIT_SUCCESSFUL;
export const appFabricEditError= app.FABRIC_EDIT_ERROR;