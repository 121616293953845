import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

function SelectSection(props) {
  const [ value, setValue ] = useState();
  const defalutValue=props.value;

  useEffect(()=>{
    setValue(props.value);
    props.onChangeListener(props.value);
  },[defalutValue])
  
  const OnChangeFunction = (e)=>{
    if((e.target.value).split(" ")[0] === "Select"){
      setValue(null)
      props.onChangeListener(null);
    }
    else{
      setValue(e.target.value)
      props.onChangeListener(e.target.value);
    }
  }
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">{props.heading}</h3>
      </div>

      <div className="card-body">
        <div className="form-label-group">
          <select
            disabled={props.disabled}
            onChange={OnChangeFunction}
            value={value}
            className="form-control"
            id="type"
            placeholder="EMAIL"
            required=""
          > 
            <option>Select {props.heading}</option>
            {props.options.map((option,i) => (
              <option key={i} value={option?.id ? option?.id : option}>
                {option?.name_en || option?.full_name || option}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

SelectSection.propTypes = {
  heading: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChangeListener: PropTypes.func,
  options: PropTypes.array
};

SelectSection.defaultProps = {
  options: [],
  onChangeListener: ()=>{}
}

export default SelectSection;
