import React from 'react';
import propTypes from 'prop-types'; 

const CardList = ({title, list, radio, value, onChangeHandler}) => {


    return (
        <div className="row">
            <div className="col-12">    
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title float-left">{title}</h3>
                        </div>
                        <div class="card-body">
                            <div className="row">
                                {
                                    list?.map(fabricObj => {
                                        if(radio) {
                                            return (
                                                <div class="col-md-2 tailoriChked">
                                                    <input class="with-gap" name={title + "_radio"} type="radio" value={fabricObj.id} id={title + "_" + fabricObj.id} checked={value == fabricObj.id} onClick={e => onChangeHandler && onChangeHandler(e.target.value)}/>
                                                    <label htmlFor={title + "_" + fabricObj.id}>
                                                        <div class="bespkokestailor">
                                                            <img src={fabricObj?.image_url_en}/>
                                                        </div>
                                                        {fabricObj?.name_en}
                                                    </label>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div class="col-md-3 tailoriChked order-bxwhiite">
                                                <div class="bespkokestailor">
                                                    <img src={fabricObj?.image_url_en}/>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div> 
            </div>
        </div>
    );

}


CardList.propTypes = {
    title: propTypes.string,
} 

export default CardList;