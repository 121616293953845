import React from 'react';

import PropTypes from 'prop-types';
import Input from '../../components/input';
import TextArea from '../../components/textArea';
import SelectSection from '../../components/SelectSection'
import ImageUpload from "../../components/imageUpload";
import CatogeryCheckBox from "../../components/CheckboxSection";
import Header from "../../components/header";
import {
    addNewFabricType
} from "../../constants";
import { imageUpload, brandsCheckboxData } from "../../constant";

function AddFabricType(props) {

    const inputChangeListener = (text)=>{
        props.setTitle(text);
    }
    // const inputAreaChangeListener = (text)=>{
    //     props.setDescription(text);
    // }
    const selectOptionChangeListener = (id)=>{
        props.setStatus(id);
    }
    const checkboxClickListener = (id)=>{
        props.setImgUrl(id);
    }
    addNewFabricType.buttons[1].onClickListener = ()=>{
        props.submitHandler();
    }
    return (
        <div class="content-wrapper"> 
              <Header
                {...addNewFabricType}
              />
             <section class="content mb-5">
        <div class="container-fluid">
                <div className='row'>
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-12">
                                <Input onChangeListener={inputChangeListener} id="stitle1" placeholder="Title of the fabric Type" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">   
                            <div className="col-sm-6">
                                <ImageUpload 
                                    {...props}
                                    {...imageUpload} 
                                    onChangeListener={(e) => {
                                      props.onUploadImage(e, (url) => {
                                        props.onChangeListener({
                                          image_url_en: url,
                                          image_url_ar: url,
                                        });
                                      });
                                    }}
                                />
                            </div> 
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <SelectSection onChangeListener={selectOptionChangeListener} value={'active'} heading="Status" options={['active','inactive']} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
        </div>
    )
}

AddFabricType.propTypes = {

}

export default AddFabricType

