import React  from 'react';
import NavSideBar from "../../components/navSidebar";
import {
  img,
  navSideBar
} from "../../constant";

function NavSideBarPage(props) {
    return(
        <>    
            <div>
                <NavSideBar label={navSideBar} img={img}/>
            </div>
        </>
    )
}
NavSideBarPage.propTypes = {
  
}
export default NavSideBarPage;