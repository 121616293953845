import {config} from '../environments';
import {HttpClient} from '../httpClient'
import BaseService from './base.service';

class UserService extends BaseService{
    static auth(userName, password, role){
        const url = super.prepareUrl(config.endpoints.authLogin);
        const data = {
            userName,
            password,
            role
        }
        return HttpClient.post(url,data);
    }

    static get(headers){
        const url = super.prepareUrl(config.endpoints.users);
        super.get(url,headers)
    }
}


export default UserService;
export {UserService}