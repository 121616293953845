import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import { OrderView } from "../../pages/orders";
import { OrderDetailAction } from "./redux/order.actions";
import queryString from "query-string";

function OrderDetail(props) {

    const data = queryString.parse(window.location.search);
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const orderDetail = useSelector(state=>state.orderDetail);

    const token = {
        'Authorization' : `${userState.token}`
    };

    const queryStr = `/${data.id}`;

    if(orderDetail?.data?.order.id != data.id ){
        if(queryStr){
            dispatch(OrderDetailAction(token,queryStr));
        }
    }

    return(
        <OrderView orderPage={true} {...orderDetail?.data?.order}/>
    )
}

export default OrderDetail;