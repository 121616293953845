import React, { useEffect } from "react";
import PendingDiscountCouponView from "../../pages/discount/pendingDiscountDetail";
import queryString from "query-string";
import { TailorTable } from "../../containers/tailorTable/redux/tailor.actions";
import { GetDiscountByIdAction, updateDiscountCouponAction } from "../../containers/discount/redux/discount.action";
import {useDispatch, useSelector} from 'react-redux';
import { PendingDiscountCouponDetailPageConst } from "../../constant1";
import { useHistory } from "react-router-dom";
import { urls } from "../../constants";

function PendingDiscountDetailContainor(props) {

    const history = useHistory();
    const queryObject = queryString.parse(window.location.search);
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);

    const pendingCouponObj = useSelector(state=>state.discountCoupons?.coupon);

    const token = {
        'Authorization' : `${userState.token}`
    };
    let enocodeQuery= `/${queryObject.id}`

    useEffect(()=>{
        dispatch(GetDiscountByIdAction(token,enocodeQuery));
    },0)
    const discountApproved =()=>{
        dispatch(updateDiscountCouponAction(queryObject.id,{is_approved:true},token,()=>{
            history.push(urls.DISCOUNT_PENDING);
        }));
    }
    return(
        <PendingDiscountCouponView
            {...PendingDiscountCouponDetailPageConst}
            {...pendingCouponObj}
            discountApproved={discountApproved}
        />
    )
}

export default PendingDiscountDetailContainor;
export {
    PendingDiscountDetailContainor
}