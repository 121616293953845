import {BaseService} from './base.service';
import {config} from '../environments';

class OfferService extends BaseService{
    static get(headers, enocodeQuery){
        const url = super.prepareUrl(config.endpoints.offer,enocodeQuery);
       return  super.get(url, headers);
    }
    static info(id, headers, enocodeQuery){
        const url = super.prepareUrl(config.endpoints.offer,enocodeQuery);
       return  super.get(url + `/${id}`, headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.offer);
       return  super.post(url,data,headers);
    }
    static put(id, data, headers) {
        const url = super.prepareUrl(config.endpoints.offer);
       return  super.put(url + `/${id}`, data ,headers);
    }
    static delete(id, headers) {
        const url = super.prepareUrl(config.endpoints.offer);
       return  super.delete(url + `/${id}`,headers);
    }
}
export default OfferService;

export {OfferService};