import { handleActions } from "redux-actions";
import { 
    payoutFatchSeccess,
    payoutFatchError
} from "./payout.actions";

const defaultState = {};

const reducer = handleActions(
    {
        [payoutFatchSeccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [payoutFatchError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
// const reducerGetById = handleActions(
//     {
//         [taxFatchByIdSeccess] : (state, action) => {
//             return Object.assign({}, { ...state }, { ...action.payload }); 
//         },
//         [taxFatchByIdError] : (state, action) => {
//             return Object.assign({}, { ...state }, { ...action.payload }); 
//         }
//     },
//     defaultState
// )
// export { reducer, reducerGetById };
export{
    reducer
}