import { urls } from "../constants";

const addNewBrand = {
    title:"Brand",
    subTitle:"Add New",
    buttons: [
        {name:"Back",url: urls.BRANDS,type:"link"},
        {name:"Publish",url: "#",type:"button"}
    ]
}
const editBrand = {
    title:"Brand",
    subTitle:"Edit",
    buttons: [
        {name:"Back",url: urls.BRANDS,type:"link"},
        {name:"UPDATE",url: "#",type:"button"}
    ]
}
const detailBrand = {
    title:"Brand",
    subTitle:"Detail",
    buttons: [
        {name:"Back",url: urls.BRANDS,type:"link"},
        {name:"edit",url: "#",type:"button",type:"link"}
    ]
}

export {
    addNewBrand,
    editBrand,
    detailBrand
}