import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import CouponViewAll from "../../components/view_all";
import { PendingCoupon } from "./redux/pending.action";
import {
    coupon,
    img
} from "../../constant";

function PendingCouponCont(props) {
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const pendingCouponState = useSelector(state=>state.pendingCoupon);
    let rows= pendingCouponState?.rows;
    let pageInfo= pendingCouponState?.pageInfo;

    let pendingCouponCount=0;
    rows?.map((row, i)=>{
        if(row.is_approved===null || row.is_approved===false){
            console.log(i+row.is_approved);
            ++pendingCouponCount;
        }
    })
    // let queryString = `?startDate=2021-01-01&endDate=2021-02-29`
    let queryString = `?startDate=${props.start?.startDate}&endDate=${props.end?.endDate}`
    
    const token = {
        'Authorization' : `${userState.token}`
    };
    useEffect(()=>{
        dispatch(PendingCoupon(token,queryString));
    },[queryString])
    
    return(
        <CouponViewAll 
            {...coupon} 
            value={pendingCouponState?.pending_coupons}
        />
    )
}

export default PendingCouponCont;