import {config} from '../environments';
import {BaseService} from './base.service';


class TailorService extends BaseService{
    static get(headers,queryString){
        const url = super.prepareUrl(config.endpoints.tailors,queryString);
        return super.get(url,headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.tailors);
        return super.post(url,data,headers);
    }
    static put(id, data, headers){
        const url = super.prepareUrl(config.endpoints.tailors);
        return super.put(url + `/${id}`,data,headers);
    }
}

export default TailorService;

export {TailorService};
