import React from "react";
import PropTypes from "prop-types";

function DescriptionAndCategory(props) {
    return(
        <div className="card complaint-card">
            <div className="card-header">
            <div className="row">
                <div className="col-md-4 col-4">
                    <h3 className="card-title">{props.measurementForLabel}: {props.name}</h3>
                </div>
                <div className="col-md-8 col-8 text-right">
                    <h5 className="f-18 font-weight-medium mt-2">{props.id}</h5>
                </div>
            </div>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-12">
                    <p className="mb-0 mr-4 float-left">{props.categoryLabel}: <span className="font-weight-bold">{props.categoryValue}</span></p>
                    <p className="mb-0 mr-4 float-left">{props.subCategoryLabel}: <span className="font-weight-bold">{props.subCategoryValue}</span></p>
                    <p className="mb-0 mr-4 float-left">{props.giftingLabel}: <span className="font-weight-bold">{props.giftingValue}</span></p>
                    <p className="mb-0 mr-4 float-left">{props.couponUsedLabel}: <span className="font-weight-bold">{props.couponUsedDeatil}</span></p>
                    <p className="mb-0">{props.delayLabel}: <span className="font-weight-bold text-red">{props.delayTime}</span></p>
                </div> 
            </div>
        </div>
    </div>
    )
}
DescriptionAndCategory.propTypes = {
    categoryLabel : PropTypes.string,
    categoryValue : PropTypes.string,
    couponUsedLabel : PropTypes.string,
    couponUsedDeatil : PropTypes.string,
    delayLabel : PropTypes.string,
    delayTime : PropTypes.string,
    giftingLabel : PropTypes.string,
    giftingValue : PropTypes.string,
    id : PropTypes.string,
    measurementForLabel : PropTypes.string,
    name : PropTypes.string,
    subCategoryLabel : PropTypes.string,
    subCategoryValue : PropTypes.string
}
export default DescriptionAndCategory;