import {OrderService} from '../../../services';
import {createAction} from 'redux-actions';
import {appOrderTableSuccess, appOrderTableError, appOrderDetailSuccess, appOrderDetailError} from './order.actionNames';

const orderTableSuccess = createAction(appOrderTableSuccess,(orderTableData)=>orderTableData);
const orderTableError = createAction(appOrderTableError,(orderTableErr)=>orderTableErr);

const orderDetailSuccess = createAction(appOrderDetailSuccess,(OrderDetailData)=>OrderDetailData);
const orderDetailError = createAction(appOrderDetailError,(orderDetailErr)=>orderDetailErr);

function OrderTableAction(token,queryString){
    return (dispatch)=>{
        OrderService.get(token,queryString).then((resp)=>{
                dispatch(orderTableSuccess(resp.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function OrderDetailAction(token,queryString){
    return (dispatch)=>{
        OrderService.get(token,queryString).then((resp)=>{
             dispatch(orderDetailSuccess(resp.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function actionSearch(style) {
    return orderTableSuccess(style);
}

export {
    OrderTableAction, 
    OrderDetailAction, 
    orderTableError, 
    orderTableSuccess, 
    orderDetailError, 
    orderDetailSuccess,
    actionSearch
}