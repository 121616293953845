import React  from 'react';
import PropTypes from "prop-types";
import Table from "../../components/table";
import Header from "../../components/header";
import { TailorColums } from "../../components/table/columnsForTable";
import {img,changePassword,notification,notificationFullComp,tailorDashboardHeaderData,golbalData,tableHeaderTailor} from '../../constant';
import { useSelector } from 'react-redux';

function TailorTable(props) {
    
    return(
        <>    
            <div  class="content-wrapper">
                <Header {...tailorDashboardHeaderData}/>
                <Table 
                    tableData={()=>TailorColums(props.rows, props.onStatusChange)} 
                    tableHeader={tableHeaderTailor} 
                    ObjectLength = {props.pageInfo?.total} 
                    {...golbalData}
                    onSearch={props.onSearch} 
                    toPage={props.toPage}
                    pageInfo={props.pageInfo}
                />
            </div>
        </>
    )
}
TailorTable.propTypes = {
  
}
export default TailorTable;
export {
    TailorTable
}