import {OfferService, UploadService} from '../../../services';
import {createAction} from 'redux-actions';
import {appOfferAddSuccess, appOfferAddError} from './AddOffer.actionNames';

const OfferAddFatchSuccess = createAction(appOfferAddSuccess,(userData)=>userData);
const OfferAddFatchError = createAction(appOfferAddError,(userLoginErr)=>userLoginErr);

function addOffer(data,token){
    return (dispatch)=>{
        OfferService.post(data,token).then((resp)=>{
             dispatch(OfferAddFatchSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function offerInfo(id,token, callBack){
    return (dispatch)=>{
        OfferService.info(id,token).then((resp)=>{
                callBack(resp.data.data)
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function offerUpdate(id, data, token,callBack) {
    return (dispatch) => {
        OfferService.put(id, data, token).then((resp) => {
            callBack();
        }).catch(console.log)
    }
}

function uploadImage(data, token, callBack) {
    return (dispatch) => {
        UploadService.upload(data, token).then((resp) => {
            callBack(resp);
        }).catch(console.log)
    }
}

export {addOffer, uploadImage,offerInfo, offerUpdate, OfferAddFatchError, OfferAddFatchSuccess}
