import { DiscountService } from "../../../services";
import { createAction } from "redux-actions";
import { appDiscountTableSuccess, appDiscountTableError } from "./discount.actionNames";

const DiscountTableFatchSuccess = createAction(appDiscountTableSuccess,(data)=>data);
const DiscountTableFatchError = createAction(appDiscountTableError,(ErrorData)=>ErrorData);

function DiscountTableAction(token,enocodeQuery) {
    return((dispatch)=>{
        DiscountService.get(token,enocodeQuery).then((resp)=>{
            dispatch(DiscountTableFatchSuccess(resp.data.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}
function GetDiscountByIdAction(token,enocodeQuery) {
    return((dispatch)=>{
        DiscountService.get(token,enocodeQuery).then((resp)=>{
            dispatch(DiscountTableFatchSuccess(resp.data.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}
function updateDiscountCouponAction(id,data,token, callBack){
    return (dispatch)=>{
        DiscountService.put(id,data,token).then((resp)=>{
                callBack();
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function DeleteAction(id,token, callBack){
    return (dispatch)=>{
        DiscountService.del(id,token).then((resp)=>{
                callBack();
            }).catch((err)=>{
                console.log(err)
            })
    }
}
export {
    DiscountTableAction,
    DiscountTableFatchSuccess,
    DiscountTableFatchError,
    updateDiscountCouponAction,
    GetDiscountByIdAction,
    DeleteAction
}