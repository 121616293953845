import React  from 'react';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

function ViewAll(props){
  return(
    <section className="connectedSortable">
      <div className="card">
          <div className="card-header">
              <h3 className="card-title">{props.title}</h3>
          </div>
          <div className="card-body pt-0">
              <div className="row dashmainBox">
                  <div className="col-md-12">
                      <h1 className="dashTitle font-weight-light float-left">{props?.value <10 && props?.value!==0 ? "0"+props?.value : props?.value}</h1>
                      <div className="float-left dashtrend mt-5 pt-5 ml-2">
                          <h6 className="text-uppercase text-blue">{props.label}</h6>
                          <h4>{props.status}</h4>
                      </div>
                  </div>
                  <div className="col-md-6 col-6">
                      <Link to={props.url} className="btn btn-info btn-radius">{props.buttonLabel}</Link>
                  </div> 
                  {/* <img alt="Dashboard Section"  src={props.img}  className="dashmainBoximg"   /> */}
              </div>

          </div>
      </div>
    </section>
  )
}
ViewAll.defaultProps = {
    value:0
}
export default ViewAll;
export{
    ViewAll
}