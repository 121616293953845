import React, { useState, useEffect } from "react";
import ImageUpload from "../../components/imageUpload";
import { imageUpload } from "../../constant";
import Input from "../../components/input";

function AddVariableModal({
  variableCategoryObj,
  onImageUpload,
  onSubmitHandler,
}) {
  const [variableState, setVariableState] = useState({
    name_en: "",
    name_ar: "",
    variable_category: variableCategoryObj,
    image_url_en: "",
    image_url_ar: "",
    descriptions_en: "",
    descriptions_ar: "",
    status: "active",
  });

  useEffect(() => {
    setVariableState({
      name_en: "",
      name_ar: "",
      variable_category: variableCategoryObj,
      image_url_en: "",
      image_url_ar: "",
      descriptions_en: "",
      descriptions_ar: "",
      status: "active",
    });
  }, [variableCategoryObj]);

  function submit() {
    onSubmitHandler(variableState);
    console.log(variableState);
  }

  return (
    <div
      class="modal fade rightModal"
      id="addvariable"
      tabindex="-1"
      role="dialog"
      aria-labelledby="loginpopupTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-slideout" role="document">
        <div class="modal-content bg-light">
          <form class="needs-validation" novalidate>
            <div class="modal-header pt-5 pl-5 pr-5 border-0">
              <div class="pt-3">
                <button
                  type="button"
                  class="close search-btn addaddressbtn"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="/assets/images/close.svg" width="70px" />
                </button>
                <div class="">
                  <h2>{variableCategoryObj?.name_en} Variable</h2>
                  <h5>Add New</h5>
                </div>
              </div>
            </div>

            <div class="modal-body pt-3 pr-5 pl-5">
              <div class="row">
                <Input
                  id="variabletitle"
                  class="form-control"
                  placeholder="Variable Title"
                  value={variableState.name_en}
                  onChangeListener={(val) => {
                    setVariableState({
                      ...variableState,
                      name_en: val,
                      name_ar: val,
                    });
                  }}
                />
                <Input
                  type="text"
                  id="variabledesc"
                  class="form-control"
                  placeholder="Variable Description"
                  value={variableState.descriptions_en}
                  onChangeListener={(val) => {
                    setVariableState({
                      ...variableState,
                      descriptions_en: val,
                      descriptions_ar: val,
                    });
                  }}
                />
                <Input
                  type="text"
                  id="variableprice"
                  class="form-control"
                  placeholder="Price"
                  value={variableState.price}
                  onChangeListener={(val) => {
                    setVariableState({
                      ...variableState,
                      price: val
                    });
                  }}
                />

                <div class="col-md-12">
                  <ImageUpload
                    {...imageUpload}
                    title={variableCategoryObj?.name_en + " Image"}
                    image={variableState.image_url_en}
                    onChangeListener={(e) => {
                      onImageUpload(e, (url) => {
                        setVariableState({
                          ...variableState,
                          image_url_en: url,
                          image_url_ar: url,
                        });
                      });
                    }}
                  />
                </div>

                {variableState.name_en &&
                  variableState.descriptions_en &&
                  variableState.image_url_en && (
                    <div class="col-md-12 text-center mt-4">
                      <a
                        data-dismiss="modal"
                        onClick={submit}
                        class="btn btn-secondary btn-radius"
                      >
                        Submit
                      </a>
                    </div>
                  )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export { AddVariableModal };
export default AddVariableModal;
