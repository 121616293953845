import {TailorService} from '../../../services';
import {createAction} from 'redux-actions';
import {appTailorAddSuccess,appTailorAddError} from './AddTailor.actionNames';

const tailorAddSuccess = createAction(appTailorAddSuccess,(userData)=>userData);
const tailorAddError = createAction(appTailorAddError,(userLoginErr)=>userLoginErr);

function addTailor(data,token){
    return (dispatch)=>{
        TailorService.post(data,token).then((resp)=>{
             dispatch(tailorAddSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

export {addTailor, tailorAddError, tailorAddSuccess}
