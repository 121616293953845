import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { CustomersTableColoumCustomers } from "../../components/table/columnsForTable";
import {tableHeaderCustomers,globalPropsFabric} from '../../constant';

function Customers(props) {
    return(
        <>    
            <div  class="content-wrapper">
                <Header 
                    title="Customers"
                    subTitle = "Click to view the details"
                />
                <Table 
                    toPage={props.toPage} 
                    tableData={(e)=>CustomersTableColoumCustomers(props.rows,props.onStatusChange)} 
                    tableHeader={tableHeaderCustomers} 
                    ObjectLength={props.pageInfo?.total}
                    pageInfo={props.pageInfo}
                    onSearch={props.onSearch}
                    {...globalPropsFabric}
                />
            </div>
        </>
    )
}

export default Customers;
export{
    Customers
}