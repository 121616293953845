const app = {
    APP_LOADED:"app.loaded",
    ADMIN_LOGIN_REQ:"app.admin.loginReq",
    ADMIN_LOGIN_SUCCESSFUL:"app.admin.loginSuccessful",
    ADMIN_LOGIN_ERROR:"app.admin.loginError",
    COUPON_LIST_SUCCESSFUL:"app.admin.couponList",
    COUPON_LIST_ERROR:"app.admin.couponError",
    TAILOR_LIST_SUCCESSFUL:"app.admin.TailorList",
    TAILOR_LIST_ERROR:"app.admin.TailorError",
    BRAND_TABLE_SUCCESSFUL:"app.admin.BrandTable",
    BRAND_TABLE_ERROR:"app.admin.BrandError",
    FABRIC_TABLE_SUCCESSFUL:"app.admin.FabricTable",
    FABRIC_TABLE_ERROR:"app.admin.FabricError",
    FABRIC_ADD_SUCCESSFUL:"app.admin.FabricAdd",
    FABRIC_ADD_ERROR:"app.admin.FabricAddError",
    FABRIC_TYPE_GET_SUCCESSFUL:"app.admin.FabricTypeget",
    FABRIC_TYPE_GET_ERROR:"app.admin.FabricTypegetError",
    FABRIC_TYPE_ADD_SUCCESSFUL:"app.admin.FabricTypeAdd",
    FABRIC_TYPE_ADD_ERROR:"app.admin.FabricTypeAddError",
    FABRIC_EDIT_SUCCESSFUL: "app.admin.FabricEdit",
    FABRIC_EDIT_ERROR: "app.admin.FabricEditError",
    TAILOR_TABLE_SUCCESSFUL:"app.admin.TailorTable",
    TAILOR_TABLE_ERROR:"app.admin.TailorTableError",
    TAILOR_DETAIL_SUCCESSFUL:"app.admin.TailorDetail",
    TAILOR_DETAIL_ERROR:"app.admin.TailorDetailError",
    BRAND_ADD_SUCCESSFUL:"app.admin.BrandAdd",
    BRAND_ADD_ERROR:"app.admin.BrandAddError",
    BRAND_DETAIL_SUCCESSFUL:"app.admin.BrandDetail",
    BRAND_DETAIL_ERROR:"app.admin.BrandDetailError",
    BRAND_EDIT_SUCCESSFUL:"app.admin.BrandEdit",
    BRAND_EDIT_ERROR:"app.admin.BrandEditError",
    TAILOR_ADD_SUCCESSFUL:"app.admin.TailorAdd",
    TAILOR_ADD_ERROR:"app.admin.TailorAddError",
    OFFER_TABLE_SUCCESSFUL:"app.admin.OfferTable",
    OFFER_TABLE_ERROR:"app.admin.OfferError",
    OFFER_ADD_SUCCESSFUL:"app.admin.OfferAdd",
    OFFER_ADD_ERROR:"app.admin.OfferAddError",
    TOP_CUSTOMER_LIST_SUCCESSFUL:"app.admin.CustomerList",
    TOP_CUSTOMER_LIST_ERROR:"app.admin.CustomerListError",
    OUTSTANDING_SUCCESS: "app.admin.Outstanding",
    OUTSTANDING_ERROR: "app.admin.OutstandingError",
    PENDING_SUCCESS: "app.admin.Pending",
    PENDING_ERROR: "app.admin.PendingError",
    OFFER_DELETED_SUCCESSFUL:"app.admin.offerDeletedSuccessful",
    OFFER_DELETED_ERROR:"app.admin.OfferDeletedSuccessful",
    OFFER_UPDATED_SUCCESSFUL:"app.admin.OfferUpdatedSuccessful",
    OFFER_UPDATED_ERROR:"app.admin.OfferUpdatedError",
    ORDER_TABLE_SUCCESSFUL:"app.admin.OrderTableSuccessful",
    ORDER_TABLE_ERROR:"app.admin.OrderTableError",
    PRODUCTS_FATCH_SUCCESSFUL:"app.admin.ProductsFatchSuccessful",
    PRODUCTS_FATCH_ERROR:"app.admin.ProductsFatchError",
    ORDER_DETAIL_SUCCESSFUL:"app.admin.OrderDetailSuccessful",
    ORDER_DETAIL_ERROR:"app.admin.OrderDetailError",
    CUSTOMER_TABLE_SUCCESSFUL:"app.admin.CustomerTableSuccessful",
    CUSTOMER_TABLE_ERROR:"app.admin.CustomerTableError",
    CUSTOMER_DETAIL_SUCCESSFUL:"app.admin.CustomerDetailSuccessful",
    CUSTOMER_DETAIL_ERROR:"app.admin.CustomerDetailError",
    CUSTOMER_ORDERS_SUCCESSFUL:"app.admin.CustomerOrdersSuccessful",
    CUSTOMER_ORDERS_ERROR:"app.admin.CustomerOrdersError",


    CUSTOMER_REVIEWS_SUCCESSFUL:"app.admin.CustomerReviewsSuccessful",
    CUSTOMER_REVIEWSERROR:"app.admin.CustomerReviewsError",

    DISCOUNT_TABLE_SUCCESSFUL:"app.admin.DiscountTableSuccessful",
    DISCOUNT_TABLE_ERROR:"app.admin.DiscountTableError",
    MEMBER_MEASUREMENT_SUCCESSFUL: "app.admin.MemberMeasurementSuccessful",
    MEMBER_MEASUREMENT_ERROR: "app.admin.MemberMeasurementError",
    DEAL_TABLE_SUCCESSFUL:"app.admin.DealTable",
    DEAL_TABLE_ERROR:"app.admin.DealError",
    DEAL_DELETED_SUCCESSFUL:"app.admin.offerDeletedSuccessful",
    DEAL_DELETED_ERROR:"app.admin.OfferDeletedSuccessful",
    DEAL_ADD_SUCCESSFUL:"app.admin.DealAddSuccessful",
    DEAL_ADD_ERROR:"app.admin.DealAddError",
    STYLES_FATCH_SUCCESSFUL:"app.admin.StylesFatchSuccess",
    STYLES_FATCH_ERROR:"app.admin.StylesFatchError",

    VARIABLE_CATEGORY_FETCH_SUCCESS: "app.admin.VariableCatFetchSuccess",
    VARIABLE_CATEGORY_FETCH_ERROR: "app.admin.VariableCatFetchError",
    VARIABLE_CATEGORY_DELETE_SUCCESS: "app.admin.VariableCatDeleteSuccess",
    VARIABLE_CATEGORY_DELETE_ERROR: "app.admin.VariableCatDeleteError",
    VARIABLE_CATEGORY_ADD_SUCCESS: "app.admin.VariableCatAddSuccess",
    VARIABLE_CATEGORY_ADD_ERROR: "app.admin.VariableCatAddError",
    VARIABLE_CATEGORY_UPDATE_SUCESS: "app.admin.VariableCatUpdateSuccess",
    VARIABLE_CATEGORY_UPDATE_ERROR: "app.admin.VariableCatUpdateError",
    VARIABLE_CATEGORY_ID_SUCCESS: "app.admin.VariableCategoryIdSuccess",
    VARIABLE_CATEGORY_ID_ERROR: "app.admin.VariableCategoryIdError",
    DASHBOARD_DATA_FATCH_SUCCESS: "app.admin.DashboardDataFatchSuccess",
    DASHBOARD_DATA_FATCH_ERROR: "app.admin.DashboardDataFatchError",
    TAXES_FATCH_SUCCESS: "app.admin.TaxesDataFatchSuccess",
    TAXES_FATCH_ERROR: "app.admin.TaxesDataFatchError",
    TAX_BY_ID_FATCH_SUCCESS: "app.admin.TaxGetByIdSuccess",
    TAX_BY_ID_FATCH_ERROR: "app.admin.TaxGetByIdError",
    SETTINGS_FETCH_SUCCESS: "app.admin.settingsSuccess",
    SETTINGS_FETCH_ERROR: "app.admin.settingsError",
    PAYOUT_FETCH_SUCCESS: "app.admin.payoutFatchSuccess",
    PAYOUT_FETCH_ERROR: "app.admin.payoutError",
    SALES_TREND_FATCH_SUCCESS: "app.admin.SalesTrendFatchSuccess",
    SALES_TREND_FATCH_ERROR: "app.admin.SalesTrendFatchError",
}

const urls = {
    MANAGE_SHIPMENT: "/dashboard/manage-shipment",
    QUERY_STRING_KEY_ID: "?id=",
    QUERY_STRING_KEY_PAGE: "?page=",
    QUERY_STRING_KEY_CUSTOMERiD: "?customer_id=",
    QUERY_STRING_KEY_TAILORiD: "?tailor_id=",
    LOGIN : '/login',
    DASHBOARD: '/dashboard',
    ORDERS:'/dashboard/orders',
    ORDER_DETAIL: '/dashboard/order/view',
    CUSTOMERS: '/dashboard/customers',
    CUSTOMER_ADD: '/dashboard/customers/add',
    CUSTOMER_DETAIL: '/dashboard/customer/details',
    CUSTOMER_SAVED_SIZE: '/dashboard/customer/saved/size',
    CUSTOMER_ORDERS: '/dashboard/customer/orders',
    CUSTOMER_ORDERS_DETAIL: '/dashboard/customer/orders/detail',
    CUSTOMER_REVIEWS: '/dashboard/customer/reviews',
    CUSTOMER_COMPLAINTS: '/dashboard/customer/complaints',
    CUSTOMER_COMPLAINT_DETAILS: '/dashboard/customer/complaint/details',
    TAILORS: '/dashboard/tailors',
    TAILORS_PENDING: '/dashboard/tailors/pending',
    TAILORS_PENDING_VIEW: '/dashboard/tailors/pending/view',
    TAILORS_PROFILE: '/dashboard/tailors/profile/view',
    TAILOR_ADD: '/dashboard/tailors/add',
    TAILOR_DETAILS: '/dashboard/tailor/details',
    TAILOR_ORDERS: '/dashboard/tailor/orders',
    TAILOR_COUPONS: '/dashboard/tailor/coupons',
    TAILOR_REVIEWS: '/dashboard/tailor/review',
    TAILOR_COMPLAINTS: '/dashboard/tailor/complaints',
    STYLES: '/dashboard/product/styles',
    STYLE_ADD: '/dashboard/product/style/add',
    STYLE_EDIT: '/dashboard/product/style/edit',
    VARIABLES: '/dashboard/product/styles/variables',
    VARIABLE_ADD: '/dashboard/product/styles/variable/add',
    VARIABLE_EDIT: '/dashboard/product/styles/variable/edit',
    FABRICS: '/dashboard/fabrics',
    FABRIC_ADD: '/dashboard/fabric/add',
    FABRIC_DETAIL: '/dashboard/fabric/detail',
    FABRIC_TYPE_ADD: '/dashboard/fabric/type/add',
    FABRIC_EDIT: '/dashboard/fabric/edit',
    BRANDS: '/dashboard/brands',
    BRAND_ADD: '/dashboard/brand/add',
    BRAND_EDIT: '/dashboard/brand/edit',
    BRAND_DETAIL: '/dashboard/brand/detail',
    OFFERS: '/dashboard/offers',
    DEALS: '/dashboard/deals',
    ADD_DEAL: '/dashboard/deals/add',
    EDIT_DEAL: '/dashboard/deals/edit',
    DEAL_DETAIL: "/dashboard/deals/edit",
    OFFER_ADD: '/dashboard/offer/add',
    OFFER_EDIT: '/dashboard/offer/edit',
    OFFER_DETAILS: '/dashboard/offer/details',
    DISCOUNTS: "/dashboard/discounts",
    DISCOUNT_DETAILS: "/dashboard/discount/details",
    PENDING_DISCOUNT_DETAILS: "/dashboard/discount/pending/details",
    DISCOUNT_ADD: "/dashboard/discount/add",
    DISCOUNT_EDIT: "/dashboard/discount/edit",
    DISCOUNT_PENDING: "/dashboard/discount/pending",
    TAXES: "/dashboard/settings",
    TAX_ADD: "/dashboard/tax/add",
    TAX_EDIT: "/dashboard/tax/edit",
    PAYOUT: "/dashboard/payout"

}


export default app;
export {app, urls}
