import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/input";
import TextArea from "../../components/textArea";
import SelectSection from "../../components/SelectSection";
import OfferAddAndEdit from "../../pages/offer/offer.addAndEdit";
import { AddNewVariable } from "../../pages/variable";
import {
  addVariableCategory,
  getInfo,
  updateVariableCategory,
} from "../AddVariable/redux/AddVariable.actions";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { urls } from "../../constants/app";

function AddVariableContainer(props) {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const token = {
    Authorization: `${userState.token}`,
  };

  const [id, setId] = useState(null);

  const tailorState = useSelector(state=>state.tailor);

  const [variableCategory, setVariableCategory] = useState({
    name_en: "",
    name_ar: "",
    quantity: false,
    multiple: false,
    gender: "Men",
    status: "inactive",
  });

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query?.id) {
      setId(query?.id);
      getCategoryInfo(query?.id);
    }
  }, []);

  async function getCategoryInfo(id) {
    return dispatch(getInfo(id, token, setVariableCategory));
  }

  function addListener() {
    return dispatch(
      addVariableCategory({
        ...variableCategory,
        tailor_id: variableCategory.user_id,
        user_id: undefined
      }, token, () => {
        history.push({
          pathname: urls.VARIABLES,
        });
      })
    );
  }

  function updateListener() {
    return dispatch(
      updateVariableCategory(
        id,
        {
          name_en: variableCategory.name_en,
          name_ar: variableCategory.name_ar,
          quantity: variableCategory.quantity,
          multiple: variableCategory.multiple,
          gender: variableCategory.gender,
          status: variableCategory.status,
          tailor_id: variableCategory.user_id
        },
        token,
        () => {
          history.push({
            pathname: urls.PRODUCT_VARIABLES,
          });
        }
      )
    );
  }

  function onChange(data) {
    setVariableCategory({
      ...variableCategory,
      ...data,
    });
  }

  function onSubmitHandler() {
    if (!variableCategory.name_en.length || !variableCategory.name_ar.length) {
      return alert("Please fill required fields");
    }
    if (id) {
      updateListener();
    } else {
      addListener();
    }
  }

  return (
    <AddNewVariable
      tailorList={tailorState?.rows}
      data={variableCategory}
      onChange={onChange}
      onSubmitHandler={onSubmitHandler}
    ></AddNewVariable>
  );
}

AddVariableContainer.propTypes = {};

export default AddVariableContainer;
