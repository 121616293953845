import { userLoginSuccessfully } from '../containers/login/redux/login.actions';
import {store} from '../reduxCore';

export class StorageHelper{
    static KEY = 'user';
    static setUser(user){
        localStorage.setItem(StorageHelper.KEY,user);
    }
    static prepareRedux(){
        try {
            const user = JSON.parse(localStorage.getItem(StorageHelper.KEY));
           store.dispatch(userLoginSuccessfully(user));
        } catch (error) {
            console.log(error)
        }
    }
}


export default StorageHelper;