import {BaseService} from './base.service';
import {config} from '../environments';

class DiscountService extends BaseService{
    static get(headers,enocodeQuery){
        const url = super.prepareUrl(config.endpoints.discount,enocodeQuery);
       return  super.get(url,headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.discount);
       return  super.post(url,data,headers);
    }
    static put(id, data, headers){
        const url = super.prepareUrl(config.endpoints.discount);
        return super.put(url + `/${id}`,data,headers);
    }
    static del(id, headers){
        const url = super.prepareUrl(config.endpoints.discount);
        return super.delete(url + `/${id}`,headers);
    }
}

export default DiscountService;

export {DiscountService};
