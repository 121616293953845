import {HttpClient} from '../httpClient';
import {config} from '../environments';

class BaseService{

    static get(url, token){
        return HttpClient.get(url, token);
    }
    static post(url, data, headers){    
        return HttpClient.post(url,data,headers);
    }
    static prepareUrl(endpoint,queryString){
        if(queryString !== undefined){
            return `${config.api}${endpoint}${queryString}`; 
        }
        else{
            return `${config.api}${endpoint}`; 
        }
    }    
    static delete(url, headers){    
        return HttpClient.delete(url,headers);
    }
    static put(url,data, headers){    
        return HttpClient.put(url,data,headers);
    }
}

export default BaseService;
export {BaseService};