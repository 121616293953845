import React, {useEffect, useState} from "react";
import Select from 'react-select';

function Options(props){
    const array = props.array;
    if(array){
        return array?.map((row,i)=>{
            return(
                <option onChange={()=>console.log("done")} onClick={()=>console.log("done")} key={i} value={row.id}>{row.full_name}</option>
            )
        })
    }
    else return null;
}

function ChipSelection(props) {
    
    return(
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h6 className="card-title">Select Tailor</h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <div class="form-label-group">
                                    {/* <select 
                                        id="type"
                                        class="form-control select2" 
                                        multiple style={{width: '100%'}}
                                    >
                                        <Options array={props.array}/>
                                    </select> */}
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChipSelection;
export{
    ChipSelection
}