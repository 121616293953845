import React from 'react';
import List from "../../components/list";
import { OrderComingFromLocationConst,img } from "../../constant1";

function OrderComingFromLocationContainor(props) {

    const Obj = { key1 : "country", key2 : "order_count" , key3 : "city", key4 : "percent"};
    return(
        <List
            {...OrderComingFromLocationConst}
            {...props}
            {...Obj}
            img={img.trendingUpImgURL}
        />
    )
}

export default OrderComingFromLocationContainor;
export{
    OrderComingFromLocationContainor
}