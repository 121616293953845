import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { customersTableColoumOrders } from "../../components/table/columnsForTable";
import { urls } from "../../constants";
import { CustomertableHeaderOrder, customerOrdersConst } from "../../constant1";

function Order(props) {
    return(
        <>    
            <div  class="content-wrapper">
                <Header 
                    title="Customers"
                    subTitle = "Orders"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.CUSTOMER_DETAIL+props.enocodeQuery,
                            isSymbolPresent : false,
                            type:'link'
                        }
                    ]}
                />
                <Table 
                    tableData={(e)=>customersTableColoumOrders(props.rows, props.enocodeQuery)} 
                    tableHeader={CustomertableHeaderOrder} 
                    ObjectLength={props.pageInfo?.item_count} 
                    // pedingOrderValue={"null"} 
                    {...customerOrdersConst}
                />
            </div>
        </>
    )
}

export default Order;
export { Order };