import {FabricService} from '../../../services';
import {createAction} from 'redux-actions';
import {
    appFabricTableSuccess, 
    appFabricTableError, 
    appFabricAddSuccess, 
    appFabricAddError,
    appFabricEditSuccess,
    appFabricEditError,
    appFabricTypeAddSuccess,
    appFabricTypeAddError,
    appFabricTypeGetSuccess,
    appFabricTypeGetError
} from './fabricTable.actionNames';

const fabricTableFatchSuccess = createAction(appFabricTableSuccess,(fabricTable)=>fabricTable);
const fabricTableFatchError = createAction(appFabricTableError,(fabricTableErr)=>fabricTableErr);

const fabricAddSuccess = createAction(appFabricAddSuccess,(fabricAddData)=>fabricAddData);
const fabricAddError = createAction(appFabricAddError,(fabricAddDataErr)=>fabricAddDataErr);

const fabricTypeGetSuccess = createAction(appFabricTypeGetSuccess,(fabricTypeGetData)=>fabricTypeGetData);
const fabricTypeGetError = createAction(appFabricTypeGetError,(fabricTypeGetDataErr)=>fabricTypeGetDataErr);

const fabricTypeAddSuccess = createAction(appFabricTypeAddSuccess,(fabricAddTypeData)=>fabricAddTypeData);
const fabricTypeAddError = createAction(appFabricTypeAddError,(fabricAddTypeDataErr)=>fabricAddTypeDataErr);

const fabricEditSuccess = createAction(appFabricEditSuccess,(fabricEditData)=>fabricEditData);
const fabricEditError = createAction(appFabricEditError,(fabricEditErr)=>fabricEditErr);

function fabricTable(token,enocodeQuery){
    return (dispatch)=>{
        FabricService.get(token,enocodeQuery).then((resp)=>{
             dispatch(fabricTableFatchSuccess(resp.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function distinctFabrics(token,callBack){
    return (dispatch)=>{
        FabricService.distinct(token).then((resp)=>{
              callBack(resp.data.data);
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function addFabric(data,token, callback){
    return (dispatch)=>{
        FabricService.post(data,token).then((resp)=>{
             dispatch(fabricAddSuccess(resp.data.data))
             callback();
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function GetFabricType(token, query){
    return (dispatch)=>{
        FabricService.getFabricType(token, query).then((resp)=>{
             dispatch(fabricTypeGetSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err);
            })
    }
}

function addFabricType(data,token){
    return (dispatch)=>{
        FabricService.postFabricType(data,token).then((resp)=>{
             dispatch(fabricTypeAddSuccess(resp.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function EditFabric(id,data,token, toPage, pageInfo){

    return (dispatch)=>{
        FabricService.put(id,data,token).then((resp)=>{
             dispatch(fabricEditSuccess(resp.data))
            }).then(()=>{
             toPage(pageInfo.current_page)
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function fabricDelete(id, token, toPage, pageInfo) {
    return (dispatch) => {
        FabricService.delete(id, token).then((resp)=>{
            dispatch(fabricTableFatchSuccess(resp.data))
            toPage(pageInfo.current_page)
           }).catch((err)=>{
               console.log(err)
           })
    }
}
export {
    distinctFabrics,
    fabricTable, 
    fabricTableFatchError, 
    fabricTableFatchSuccess, 
    addFabric, 
    fabricAddSuccess, 
    fabricAddError,
    fabricEditSuccess,
    fabricEditError,
    EditFabric,
    fabricDelete,
    addFabricType,
    fabricTypeAddSuccess,
    fabricTypeAddError,
    GetFabricType,
    fabricTypeGetSuccess,
    fabricTypeGetError
}
