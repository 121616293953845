import React from "react";
import { Header } from "../../components/header";
import { taxesConst, tableHeaderTaxes } from "../../constant1";
import Table from "../../components/table";
import Input from "../../components/input";

function SettingsPage(props) {
  return (
    <div class="content-wrapper">
      <Header 
          title="Manage Shipment"
          subTitle="Manage Shipment"
          submitHandler={props.onSubmit}
          buttons={ [
              {
                  name : "Update",
                  url : "/dashboard/manage-shipment",
                  isSymbolPresent : false,
                  type: "submit"
              },
          ]}
      />
      <section class="content mb-5">
      <div class="container-fluid">
        
      <div class="row">
        <div class="col-md-6">
          <Input
            id={"shipping_cost"}
            value={props?.settings?.shipping_cost}
            onChangeListener={(val) => {
              props.onChnageSettings({
                shipping_cost: val,
              });
            }}
            placeholder="Shipping Cost"
          />
        </div>
        <div class="col-md-6">
          <Input
            id={"min_cost_for_shipping"}
            value={props?.settings?.min_cost_for_shipping}
            onChangeListener={(val) => {
              props.onChnageSettings({
                min_cost_for_shipping: val,
              });
            }}
            placeholder={"Shipping Cost neglect from price"}
          />
        </div>
      </div>
      
      </div>
      </section>
    </div>
  );
}

export default SettingsPage;
export { SettingsPage };
