import React  from 'react';
import { img,circleChart,lineGraph,ratingAndInfo, tailorDetailPageTableHeader} from "../../constant";
import Header from "../../components/header";
import RatingAndInfo from "../../components/ratingAndInfo";
import LineGraph from "../../components/lineGraph";
import CircleGraph from "../../components/circleChart";
import Table from "../../components/table";
import { tailorProfilePageAddressTable } from "../../components/table/columnsForTable";
import { 
    tailorProfile
} from "../../constants/index";
import { urls } from "../../constants";
import { kababMenuOption } from "../../constant1";

function TailorProfile(props) {

    var addressesLength;
    if(props.addresses){
        addressesLength= props.addresses.length;
    }

    return(
        <div  class="content-wrapper">
            <Header 
                {...tailorProfile}
                buttons={[
                    {name:"Back",url: urls.TAILORS,type:"link"},
                    {name:"Profile",url: urls.TAILORS_PROFILE+urls.QUERY_STRING_KEY_TAILORiD+props?.id+"&isTailorPage="+true, type:"link"},
                    {name:"ORDERS",url: urls.TAILOR_ORDERS+urls.QUERY_STRING_KEY_TAILORiD+props?.id,type:"link"},
                    {name:"COUPONS",url: urls.TAILOR_COUPONS+urls.QUERY_STRING_KEY_TAILORiD+props?.id,type:"link"},
                    {name:"REVIEWS",url: urls.TAILOR_REVIEWS+urls.QUERY_STRING_KEY_TAILORiD+props?.id,type:"link"},
                    {name:"COMPLAINTS",url: urls.TAILOR_COMPLAINTS+urls.QUERY_STRING_KEY_TAILORiD+props?.id,type:"link"}
                ]}
            />
           <section class="content">
        <div class="container-fluid">
                <div className="row">
                    <div className="col-4">
                        <RatingAndInfo 
                            {...ratingAndInfo} 
                            img={props?.profile_picture}
                            fullName = {props?.full_name}
                            AVGtime={
                                props.tailor_more_infos? props.tailor_more_infos[0]?.avg_time + ratingAndInfo.labelDays:null
                            }
                            rating={props.tailor_more_infos? props.tailor_more_infos[0]?.rating:0}
                            totalnumberOfReviews={props.rating_count}
                        />
                    </div>
                    <div className="col-4">
                        <LineGraph {...lineGraph}/>
                    </div>
                    <div className="col-4">
                        <CircleGraph {...circleChart}/>
                    </div>
                </div>   
            </div>
            </section>
            <Table 
                tableData={()=>tailorProfilePageAddressTable(props.addresses,kababMenuOption)} 
                tableHeader={tailorDetailPageTableHeader} 
                {...kababMenuOption}
                ObjectLength={addressesLength}
            /> 
    </  div>
    )
}

export default TailorProfile;