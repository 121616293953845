import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import List from "../../components/list";
import { customer } from "./redux/customer.actions";
import { img } from "../../constant";

function CustomersList(props) {
    let queryString = `?startDate=${props.start?.startDate}&endDate=${props.end?.endDate}`

    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const customerList = useSelector(state=>state.TopCustomerList);

    const token = {
        'Authorization' : `${userState.token}`
    };
    useEffect(()=>{
        dispatch(customer(token,queryString));
    },[queryString])

    const Obj = { key1 : "customerId", key2 : "totalAmount" , key3 : "customerName"};
    
    return(
        <List
            title={"Top Customers"}
            img={img.trendingUpImgURL}
            { ...Obj }
            array={customerList.customers}
        />
    )
}

export default CustomersList;