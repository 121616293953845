import {config} from '../environments';
import {BaseService} from './base.service';


class TopDiscount extends BaseService{
    static get(headers,queryString){
        const url = super.prepareUrl(config.endpoints.topDiscount,queryString);
        return super.get(url,headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.topDiscount);
        return super.post(url,data,headers);
    }
}

export default TopDiscount;

export {TopDiscount};
