import React from 'react';
import { PropTypes } from "prop-types";

function RatingStar(props){
  let fullStar= (props.rating+"").split(".")[0];
  let halfStar= (props.rating+"").split(".")[1];
  var rows = [];
  var index;
  for ( index = 0; index < props.totalStars; ++index ) {
    if( index < fullStar ){
      rows.push(<i className="fa fa-star" key={index}></i>);
    }
    else{
      if( halfStar ){
        halfStar=false;
        rows.push(<i className="fa fa-star-half-o" key={index}></i>);
      }
      else {
        rows.push(<i className="fa fa-star-o" key={index}></i>);
      }
    } 
  }
  
  if(props.totalnumberOfReviews){
    rows.push(" ("+props.totalnumberOfReviews+ ") ");
    return rows;
  }
  else{
    return rows;
  }
}
RatingStar.propType = {
  rating : PropTypes.number,
  totalStars : PropTypes.number
}

export default RatingStar ;