import React from 'react';

import PropTypes from 'prop-types';
import Input from '../../components/input';
import TextArea from '../../components/textArea';
import SelectSection from '../../components/SelectSection'
import ImageUpload from "../../components/imageUpload";
import CatogeryCheckBox from "../../components/CheckboxSection";
import { DropDown } from "../../components/selectDropdown";
import Header from "../../components/header";
import {
    editFabric,
    detailFabric
} from "../../constants";
import { imageUpload, brandsCheckboxData } from "../../constant";
import { FabricTypeCheckboxData, gender, category } from "../../constant1";
import { urls } from "../../constants";

function AddNewFabric(props) {
    editFabric.buttons[1].onClickListener = props.submitHandler;
    let header;
    if(props.disabled){
        editFabric.buttons[0].url= urls.FABRIC_DETAIL+urls.QUERY_STRING_KEY_ID+props.id;
        detailFabric.buttons[1].url= urls.FABRIC_EDIT+urls.QUERY_STRING_KEY_ID+props.id;
        header = detailFabric;
    }
    else header= editFabric;
    console.log(props?.brand?.name_en);
    return (
        <div  class="content-wrapper"> 
              <Header
                {...header}
              />
             <section class="content mb-5">
        <div class="container-fluid">
                <div className='row'>
                    <div className="col-sm-8">
                        <div className="row">
                            
                            <div class="col-lg-12">
                                <Input 
                                    disabled={props.disabled}
                                    value={props.title_en}
                                    type="text" 
                                    id="stitle1" 
                                    placeholder="Title of the fabric" 
                                    defaultValue="" 
                                    onChangeListener={(value)=>{
                                        props.onChangeListener('title_en',value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                            <Input 
                                disabled={props.disabled}
                                value={props.price}
                                type="number" 
                                id="stitle2" 
                                placeholder="Fabric cost for Adult" 
                                defaultValue="" 
                                onChangeListener={(value)=>{
                                    props.onChangeListener('price',value);
                                }}
                            />
                            </div>
                            <div className="col-sm-6">
                            <Input 
                                disabled={props.disabled}
                                value={props.price}
                                type="number" 
                                id="stitle2" 
                                placeholder="Fabric cost for Child" 
                                defaultValue="" 
                                onChangeListener={(value)=>{
                                    props.onChangeListener('price',value);
                                }}
                            />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <DropDown 
                                    disabled={props.disabled}
                                    value={props.fabric_type_id}
                                    {...FabricTypeCheckboxData} 
                                    options={props.fabricTypesData} 
                                    onChangeListener={(value)=>{
                                        props.onChangeListener('fabric_type_id',value);
                                    }}
                                />
                            </div>
                            <div className="col-sm-6">
                                <DropDown 
                                    disabled={props.disabled}
                                    value= {props?.brand_id}
                                    {...brandsCheckboxData} 
                                    options={props.brandDetail} 
                                    onChangeListener={(value)=>{
                                        props.onChangeListener('brand_id',value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div class="col-lg-12">
                                <TextArea 
                                    disabled={props.disabled}
                                    value={props.descriptions_en}
                                    rows="10" 
                                    placeholder="Brief description of the fabric"
                                    onChangeListener={(value)=>{
                                        props.onChangeListener('descriptions_en',value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <SelectSection
                                    disabled={true || props.disabled}
                                    onChangeListener={(val) => props.onChangeListener('user_id',val)}
                                    heading="Tailor"
                                    options={props.tailorList}
                                    value={props.user_id}
                                />
                            </div>
                            <div className="col-sm-12">
                                <SelectSection 
                                    disabled={props.disabled}
                                    value={props.status}
                                    heading="Status" 
                                    options={['active','inactive']} 
                                    onChangeListener={(value)=>{
                                        props.onChangeListener('status',value);
                                    }}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ImageUpload 
                                    disabled={props.disabled}
                                    image= {props.image_url_en}
                                    {...imageUpload}
                                    onChangeListener={(e) => {
                                      props.onUploadImage(e, (url) => {
                                        props.onChangeListener('image_url_en',url);
                                        // props.onChangeListener({
                                        //   image_url_en: url,
                                        //   image_url_ar: url,
                                        // });
                                      });
                                    }} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
        </div>
    )
}

AddNewFabric.propTypes = {

}

export default AddNewFabric

