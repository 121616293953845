import {TailorService} from '../../../services';
import {createAction} from 'redux-actions';
import {
    appTailorTableSuccess,
    appTailorTableError,
    appTailorDetailSuccess,
    appTailorDetailError
} from './tailor.actionNames';

const tailorTableFatchSuccess = createAction(appTailorTableSuccess,(userData)=>userData);
const tailorTableFatchError = createAction(appTailorTableError,(userLoginErr)=>userLoginErr);

const tailorDetailSuccess = createAction(appTailorDetailSuccess,(tailorData)=>tailorData);
const tailorDetailError = createAction(appTailorDetailError,(tailorDataErr)=>tailorDataErr);

function TailorTable(token, enocodeQuery){
    return (dispatch)=>{
        TailorService.get(token, enocodeQuery).then((resp)=>{
             dispatch(tailorTableFatchSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function TailorDetail(token, enocodeQuery){
    return (dispatch)=>{
        TailorService.get(token, enocodeQuery).then((resp)=>{
             dispatch(tailorDetailSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function TailorUpdate(id, data, token, callBack,pageInfo){
    return (dispatch)=>{
        TailorService.put(id, data, token).then((resp)=>{
             callBack(pageInfo.current_page);
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function TailorApprove(id, data, token,callBack){
    return (dispatch)=>{
        TailorService.put(id, data, token).then((resp)=>{
            callBack();
            }).catch((err)=>{
                console.log(err)
            })
    }
}

export {
    TailorTable, 
    TailorUpdate,
    tailorTableFatchError, 
    tailorTableFatchSuccess,
    TailorDetail,
    tailorDetailSuccess,
    tailorDetailError,
    TailorApprove
}
