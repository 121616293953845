import { handleActions } from "redux-actions";
import { DiscountTableFatchSuccess, DiscountTableFatchError} from "./discount.action";

const defaultState = {};

const reducer = handleActions(
    {
        [DiscountTableFatchSuccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [DiscountTableFatchError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
export { reducer };