import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { DiscountCouponsColumsForTable } from "../../components/table/columnsForTable";
import { tableHeaderDiscount, discountConst, discountPageHeader, pendingDiscountPageHeader } from "../../constant1";

function Discount(props) {
    let header;
    if(props.isPandingDiscountCouponPage){
        header = pendingDiscountPageHeader;
    }
    else{
        header = discountPageHeader;
    }
    return(
        <>    
            <div  class="content-wrapper">
                <Header {...header}/>
                <Table 
                    onSearch={props.onSearch} 
                    toPage={props.toPage}
                    tableData={()=>DiscountCouponsColumsForTable(props.rows,props.isPandingDiscountCouponPage, props.onStatusChange, props.Delete)} 
                    tableHeader={tableHeaderDiscount} 
                    ObjectLength={props.pageInfo?.total}
                    pageInfo={props.pageInfo}
                    {...discountConst}/>
            </div>
        </>
    )
}
export default Discount;
export {Discount}