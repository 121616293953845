import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { img } from "../../constant";
import { urls } from "../../constants/app";

// TODO: prop name should be labels instead of label
function NavSideBar({ label }) {
  const [active, setActive] = useState(0);
  useEffect(() => {
   const trees = window.$('[data-widget="treeview"]');
   trees.Treeview('init');
   }, []);
  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary">
        <div className="text-center leftmenuaction">
          <a href="/"
            className="nav-link menuexpico"
            data-widget="pushmenu"
            role="button"
          >
            <img alt="Left Menu Section" src={img.navSideBar1} />
          </a>
          <a  href="/"
            className="nav-link menucloico"
            data-widget="pushmenu"
            role="button"
          >
            <img alt="Left Menu Section" src={img.navSideBar2} />
          </a>
        </div>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link
                  to={urls.DASHBOARD}
                  className={active === 0 ? "nav-link active" : "nav-link"}
                  onClick={() => setActive(0)}
                >
                  <i className="nav-icon  fas khy khy-dashboard"></i>
                  <p className="text-capitalize">{label.dashboard}</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={urls.ORDERS}
                  className={active === 1 ? "nav-link active" : "nav-link"}
                  onClick={() => setActive(1)}
                >
                  <i className="nav-icon fas khy khy-orders"></i>
                  <p className="text-capitalize">{label.orders}</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={urls.CUSTOMERS}
                  className={active === 2 ? "nav-link active" : "nav-link"}
                  onClick={() => setActive(2)}
                >
                  <i className="nav-icon fas khy khy-customers "></i>
                  <p className="text-capitalize">{label.customers}</p>
                </Link>
              </li>
              <li className="nav-item has-treeview">
                <Link 
                  className={active === 3 ? "nav-link active" :"nav-link"} 
                >
                  <i className="nav-icon fas khy khy-tailor "></i>
                  <p className="text-capitalize">Tailors <i className="fa fa-chevron-left right"></i></p>
                </Link>
                  <ul class="nav nav-treeview text-capitalize">
                    <li class="nav-item">
                    <Link
                          to={urls.TAILORS}
                          className={active === 3 ? "nav-link active" : "nav-link"}
                          onClick={() => setActive(3)}
                        >
                        <p><i class="fa fas fa-users mr-1"></i> {label.tailors}</p>
                      </Link>
                    </li>
                    
                    <li class="nav-item">
                    <Link
                          to={urls.TAILORS_PENDING}
                          className={active === 12 ? "nav-link active" : "nav-link"}
                          onClick={() => setActive(3)}
                        >
                        <p><i class="fa fas fa-envelope-o mr-1"></i> {label.tailorPending}</p>
                      </Link>
                    </li>
                  </ul>
              </li>


              <li className="nav-item has-treeview">
                <Link 
                  className={active === 4 ? "nav-link active" :"nav-link"} 
                >
                  <i className="nav-icon fas khy khy-products "></i>
                  <p className="text-capitalize">Masters <i className="fa fa-chevron-left right"></i></p>
                </Link>
                  <ul class="nav nav-treeview text-capitalize">
                      <li className="nav-item">
                        <Link
                          to={urls.STYLES}
                          className={active === 4 ? "nav-link active" : "nav-link"}
                          onClick={() => setActive(4)}
                        >
                          <p><i class="fa fas fa-cubes mr-1"></i> {label.style}</p>
                        </Link> 
                      </li>
                      <li className="nav-item">
                        <Link
                          to={urls.VARIABLES}
                          className={active === 5 ? "nav-link active" : "nav-link"}
                          onClick={() => setActive(4)}
                        >
                          <p><i class="fa fas fa-desktop mr-1"></i> {label.variable}</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={urls.FABRICS}
                          className={active === 6 ? "nav-link active" : "nav-link"}
                          onClick={() => setActive(4)}
                        >
                          <p><i class="fa fas fa-files-o mr-1"></i> {label.fabric}</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={urls.BRANDS}
                          className={active === 7 ? "nav-link active" : "nav-link"}
                          onClick={() => setActive(4)}
                        >
                          <p><i class="fa fas fa-leaf mr-1"></i> {label.brands}</p>
                        </Link>
                      </li>
                  </ul>
              </li>
              
              

              <li className="nav-item">
                <Link 
                  className={active === 8 ? "nav-link active" :"nav-link"} 
                >
                  <i className="nav-icon fas khy khy-discount "></i>
                  <p className="text-capitalize">Offers <i className="fa fa-chevron-left right"></i></p>
                </Link>
                  <ul class="nav nav-treeview text-capitalize">
                      <li class="nav-item">
                      <Link
                          to={urls.OFFERS}
                          className={"nav-link"}
                          onClick={() => setActive(8)}
                        >
                          <p><i class="fa fas fa-ticket mr-1"></i> {label.offers}</p>
                        </Link>
                      </li>
                      
                      <li class="nav-item">
                      <Link
                          to={urls.DEALS}
                          className={"nav-link"}
                          onClick={() => setActive(8)}
                        >
                          <p><i class="fa fas fa-tags mr-1"></i> {label.deals}</p>
                        </Link>
                      </li>
                      <li class="nav-item">
                      <Link
                            to={urls.DISCOUNTS}
                            className={"nav-link"}
                            onClick={() => setActive(8)}
                          >
                          <p><i class="fa fas fa-rupee mr-1"></i> {label.discountCoupon}</p>
                        </Link>
                      </li>
                      <li class="nav-item">
                        <Link
                            to={urls.DISCOUNT_PENDING}
                            className={"nav-link"}
                            onClick={() => setActive(8)}
                        >
                          <p><i class="fa fas fa-envelope-o mr-1"></i> {label.discountPending}</p>
                        </Link>
                      </li>
                    </ul>
              </li>


              <li className="nav-item has-treeview">
                <Link 
                  className={active === 11 ? "nav-link active" :"nav-link"} 
                >
                  <i className="nav-icon fas khy khy-offers"></i>
                  <p className="text-capitalize">{label.settings} <i className="fa fa-chevron-left right"></i></p>
                </Link>
                  <ul class="nav nav-treeview text-capitalize">
                      <li className="nav-item">
                        <Link
                          to={urls.TAXES}
                          className={active === 11 ? "nav-link active" : "nav-link"}
                          onClick={() => setActive(11)}
                        >
                          <p className="text-capitalize"><i class="fa fas fa-list-alt mr-1"></i>  Taxes</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={urls.MANAGE_SHIPMENT}
                          className={active === 12 ? "nav-link active" : "nav-link"}
                          onClick={() => setActive(11)}
                        >
                          <p className="text-capitalize"><i class="fa fas fa-truck mr-1"></i> {label.manage_shipment}</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={urls.PAYOUT}
                          className={active === 12 ? "nav-link active" : "nav-link"}
                          onClick={() => setActive(11)}
                        >
                          <p className="text-capitalize"><i class="fa fas fa-truck mr-1"></i> {label.payOut}</p>
                        </Link>
                      </li>
                  </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}
NavSideBar.propTypes = {
  label: {
    dashboard: PropTypes.string,
    orders: PropTypes.string,
    customers: PropTypes.string,
    tailors: PropTypes.string,
    discount: PropTypes.string,
    offers: PropTypes.string,
    discountCoupon: PropTypes.string,
  },
};
export default NavSideBar;
