import React, { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { CustomerTailorReview as CustomerTailorReviewPage } from "../../pages/customers";
import { CustomersReviews } from "./redux/customer.action";
import queryString from "query-string";

function CustomerReviewsTableContainor(params) {

    const queryObj = queryString.parse(window.location.search);

    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const customerReviewsState = useSelector(state=>state.reviews);
    const rows = customerReviewsState?.data?.rows;
    const pageInfo = customerReviewsState?.data?.pageInfo;
    const temp =0;
    
    const token = {
        'Authorization' : `${userState.token}`
    };

    let enocodeQuery = Object.keys(queryObj)
            .map((key) => key + "=" + queryObj[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';

    useEffect(()=>{
        dispatch(CustomersReviews(token, enocodeQuery));
    },[temp])

    return(
        <CustomerTailorReviewPage
            queryString = {enocodeQuery}
            rows={rows}
            pageInfo ={pageInfo}
        />
    )
}
export default CustomerReviewsTableContainor;