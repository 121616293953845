import React from "react";
import { Link } from 'react-router-dom';
import StatusIndicator from "../../components/statusIndicator";
import { status } from "../../constant1";
import { urls } from "../../constants/app";

const OrdersColums = (viewOrderLable,rows=[],) => {

    if( rows ){
        return rows.map((row,i)=>(
            {
                // col1: <>
                //         <input className="filled-in" name="group" type="checkbox" id={"news"+i}/>
                //         <label for={"news"+i}></label>
                //     </>,
                col1: <Link className="text-dark" to={urls.ORDER_DETAIL+urls.QUERY_STRING_KEY_ID+row.id}><span className="text-dark text-bold">{row.id}</span></Link>,
                col2: <span className="font-weight-normal">{row.customer?.id}</span>,
                col3: row.customer?.full_name,
                col4: row.price,
                col5: row.tailor?.full_name,
                col6: row.payment_type,
                col7: <strong><span className={row.deliveryDelay ? "text-red" : "text-green"}>{row.deliveryDelay || "No Delay"}</span></strong>,
                col8: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                col9: row?.order_status?.name_en,
                col10: <div className="dropdown">
                        <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                            <i className="fa fa-ellipsis-v"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                            <ul>
                                <li className="strong"><Link to={urls.ORDER_DETAIL+urls.QUERY_STRING_KEY_ID+row.id} >{viewOrderLable}</Link></li>
                            </ul>
                        </div>
                    </div>
            }
        ))
    }
    else return [];
}
export default OrdersColums;