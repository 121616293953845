import {BaseService} from './base.service';
import {config} from '../environments';

class UploadService extends BaseService{
    static async upload(data, headers, enocodeQuery){
        try {
            const url = super.prepareUrl(config.endpoints.uploadPreSignedUrl);
            const signedURl = await super.post(url, {
                "file_name": data.name,
                "file_type": data.type
            }, headers);
            let uploadURL = signedURl?.data?.data?.url;
            await super.put(uploadURL, data);
            return uploadURL.split("?")[0]
        } catch (error) {
            console.log(error);
        }
    }
}
export default UploadService;

export {UploadService};