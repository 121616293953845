import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function TextArea(props) {
  const [value,setValue] = useState (props.value);
  const defalutValue=props.value;

  useEffect(()=>{
    setValue(props.value);
    props.onChangeListener(props.value);
  },[defalutValue])

  const valueChange = (e)=>{
    setValue(e.target.value);
    props.onChangeListener(e.target.value);
  }
  return (
      <div className="form-label-group">
        <textarea
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          value={ props.value}
          placeholder={props.placeholder}
          onChange={valueChange}
          type="text"
          id={props.id}
          rows={props.rows}
          className="form-control"
        ></textarea>
        <label for={props.id}>{props.placeholder}</label>
      </div>
  );
}

TextArea.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeListner: PropTypes.func,
};
TextArea.defaultProps = {
  onChangeListener: ()=>{}
}

export default TextArea;
