import {BaseService} from './base.service';
import {config} from '../environments';

class StyleService extends BaseService{
    static get(headers,enocodeQuery){
        const url = super.prepareUrl(config.endpoints.style,enocodeQuery);
        return  super.get(url,headers);
    }
    static distinct(headers,enocodeQuery){
        const url = super.prepareUrl(config.endpoints.style + '/distinct',enocodeQuery);
        return  super.get(url,headers);
    }
    static info(id, headers){
        const url = super.prepareUrl(config.endpoints.style);
        return  super.get(url + `/${id}`,headers);
    }
    static delete(id, headers) {
        const url = super.prepareUrl(config.endpoints.style);
       return  super.delete(url + `/${id}`,headers);
    }
    static post(data, headers) {
        const url = super.prepareUrl(config.endpoints.style);
       return  super.post(url ,data,headers);
    }
    static put(id, data, headers) {
        const url = super.prepareUrl(config.endpoints.style);
       return  super.put(url + `/${id}`,data,headers);
    }
}

export default StyleService;

export {StyleService};
