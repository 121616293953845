const OptionsBarChart = {
    chart: {
        type: 'bar'
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: ['Coupon Used', 'Discounted Value', 'Order Value']
    },
    yAxis: {
        min: 0,
    title: {
        text: 'T'
    }
    },
    legend: {
        reversed: true
    },
    plotOptions: {
        series: {
            stacking: 'normal'
        }
    },
    series: [{
        name: '',
        data: [0, 0, 3]
    }, {
        name: '',
        data: [0, 3, 0]
    }, {
    name: '',
    data: [3, 0, 0]
    }]
}
export{
OptionsBarChart
} ;