import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { tailorPageColoumForOrders } from "../../components/table/columnsForTable";
import {globalPropsFabric} from '../../constant';
import { TailorPagesHeaderForOrder } from "../../constant1";
import { urls } from "../../constants";

function TailorOrders(props) {

    return(
        <>    
            <div  class="content-wrapper">
                <Header 
                    title="Tailor"
                    subTitle = "Orders"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.TAILOR_DETAILS+props.enocodeQuery,
                            isSymbolPresent : false,
                            type:'link'
                          }
                    ]}
                />
                <Table 
                    onSearch={props.onSearch}
                    toPage={props.toPage}
                    tableData={()=>tailorPageColoumForOrders(props.rows,props.enocodeQuery)} 
                    tableHeader={TailorPagesHeaderForOrder} 
                    ObjectLength={props.pageInfo?.item_count} 
                    pageInfo={props.pageInfo}
                    {...globalPropsFabric}
                />
            </div>
        </>
    )
}

export default TailorOrders;
export {TailorOrders};