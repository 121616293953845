import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { customersTableColoumComplaints } from "../../components/table/columnsForTable";
import {CustomertableHeaderComplaint,globalPropsFabric} from '../../constant';
import { urls } from "../../constants";
import queryString from "query-string";

function Complaints(props) {
    const data = queryString.parse(window.location.search);
    return(
        <>    
            <div  class="content-wrapper">
                <Header 
                    title="Customer"
                    subTitle = "Complaints"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.CUSTOMER_DETAIL+urls.QUERY_STRING_KEY_CUSTOMERiD+data.customer_id,
                            isSymbolPresent : false,
                            type:"link"
                          }
                    ]}
                />
                <Table tableData={()=>customersTableColoumComplaints(data.customer_id)} tableHeader={CustomertableHeaderComplaint} ObjectLength="2"  {...globalPropsFabric}/>
            </div>
        </>
    )
}
export default Complaints;
export {
    Complaints
}