import React, { useState, useEffect } from "react";
import NotificationAbc from "../../components/notificationFullComp";
import Notification from "../../components/notification";
import ChangePassword from "../../components/ChangePassword";
import Header from "../../components/header";
import {
  img,
  changePassword,
  notification,
  notificationFullComp,
  categoryData,
  discountCheckboxData,
  discountCheckboxApplicableForData,
  applicableForData,
  imageUpload,
} from "../../constant";
import Input from "../../components/input";
import SelectSection from "../../components/SelectSection";
import CheckboxSection from "../../components/CheckboxSection";
import ChipSection from "../../components/ChipSection/ChipSection";
import queryString from "query-string";
import TextArea from "../../components/textArea";
import ImageUpload from "../../components/imageUpload";
import { useDispatch, useSelector } from "react-redux";
import { urls } from "../../constants";

function HeaderCondition(props) {
  if (props.id) {
    return (
      <Header
        title="Offer"
        subTitle="Edit"
        submitHandler={props.submitHandler}
        buttons={[
          {
            name: "BACK",
            url: urls.OFFERS,
            isSymbolPresent: false,
          },
          {
            name: "UPDATE",
            url: urls.OFFERS,
            isSymbolPresent: false,
          },
        ]}
      />
    );
  } else {
    return (
      <Header
        title="Offer"
        subTitle="Add New"
        submitHandler={props.submitHandler}
        buttons={[
          {
            name: "BACK",
            url: urls.OFFERS,
            isSymbolPresent: false,
          },
          {
            name: "GENERATE",
            url: urls.OFFERS,
            isSymbolPresent: false,
          },
        ]}
      />
    );
  }
}

function OfferAddAndEdit({
  addOfferListener,
  updateOfferListener,
  onUploadImage,
  image,
  offer
}) {
  const [title_en, setTitle_en] = useState("test");
  const [title_ar, setTitle_ar] = useState("test");
  const [description_en, setDescription_en] = useState("test");
  const [description_ar, setDescription_ar] = useState("test");
  const [status, setStatus] = useState("active"); // todo : pass it from the props where from we will pass data to Status selection

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const query = queryString.parse(window.location.search);

  useEffect(() => {
    setTitle_en(offer?.name_en || "");
    setTitle_ar(offer?.name_ar || "");
    setDescription_en(offer?.descriptions_en || "");
    setDescription_ar(offer?.descriptions_ar || "");
    setStatus(offer?.status || "active");
  }, [offer])

  const submitHandler = (e) => {

    let data = {
      name_en: title_en,
      name_ar: title_ar,
      descriptions_en: description_en,
      descriptions_ar: description_ar,
      status: status,
      discount_percent: 10,
      discount_flat: 100,
      max_limit: 50,
      image_url_en: "test",
      image_url_ar: "test",
      applicable_to: "All"
    };
    debugger;

    if (
      title_en !== "" &&
      title_ar !== "" &&
      description_en !== "" &&
      description_ar !== "" &&
      status !== ""
    ) {
      if(query?.id) {
        updateOfferListener(query.id, data)
      } else {
        addOfferListener(data);
      }
    } else {
      // todo : will pass this message from constents
      alert("Please fill all the boxes."); 
      e?.preventDefault(e);
    }
  };

  
  return (
    <div className="wrapper">
      <div class="content-wrapper">
        <HeaderCondition id={query?.id} submitHandler={submitHandler} />
        <section class="content mb-5">
        <div class="container-fluid">
        <div className="row">
          <div className="col-sm-9"> 
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                  <div className="col-md-12">
                  <Input
                    placeholder="Title of the Offer (En)"
                    id="stitle"
                    value={title_en}
                    onChangeListener={(val) => {
                      setTitle_en(val)
                    }}
                  />
                  </div>
                  </div>
                </div>
                <div className="col-md-6">
                <div className="row">
                <div className="col-md-12">
                  <Input
                    placeholder="Title of the Offer (Ar)"
                    id="stitle"
                    value={title_ar}
                    onChangeListener={(val) => setTitle_ar(val)}
                  />
                  </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                <div className="row">
                <div className="col-md-12">
                  <TextArea
                    placeholder="Brief description of the Offer (En)"
                    defaultValue={description_en}
                    onChangeListener={(val) => setDescription_en(val)}
                  />
                  </div>
                  </div>
                </div>
                <div className="col-md-6">
                <div className="row">
                <div className="col-md-12">
                  <TextArea
                    placeholder="Brief description of the Offer (Ar)"
                    defaultValue={description_ar}
                    onChangeListener={(val) => setDescription_ar(val)}
                  />
                  </div>
                  </div>
                </div>
              </div> 
            <div className="row">
              <div className="col-sm-12">
                <CheckboxSection
                  rows={applicableForData}
                  {...discountCheckboxApplicableForData}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <SelectSection
              heading="Status"
              value={status}
              options={["active", "inactive"]}
              onChangeListener={(value) => setStatus(value)}
            />
            <CheckboxSection rows={categoryData} {...discountCheckboxData} />
            <ImageUpload {...imageUpload} image={image} onChangeListener={onUploadImage} />
          </div>
        </div>
      </div>
      </section></div>
    </div>
  );
}
export default OfferAddAndEdit;
export { OfferAddAndEdit };
