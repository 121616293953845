import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {ChartData, Options} from './lineGraphData';
import PropTypes from 'prop-types'; 
import { DatePickerComp } from "../datePicker";

function LineGraph(props){
    return (
        <section className="connectedSortable mb-4">
            
            <div className="card h-100">
                <div className="card-header border-0 pb-0 dashmainbor">
                <h3 className="card-title float-none">{props.title}</h3>
                <p className="f-14">{props.discription}</p>        
                </div>
                <div className="card-body pt-0">
                {/* <div className="searchhrt">
                    <DatePickerComp {...props}/>
                </div> */}
                    <HighchartsReact
                        style={{"height":"200px"}}
                        highcharts={Highcharts}
                        options={ChartData(props.chartData)}
                    />
                </div>
            </div>
        </section>
    )
}
LineGraph.propTypes = {
    dateFormate : PropTypes.string,
    discription : PropTypes.string,
    labelFrom : PropTypes.string,
    labelTo : PropTypes.string,
    title : PropTypes.string
  };
export default LineGraph;