import React from "react";
import { Link } from 'react-router-dom';
// import {golbalData , tableDataTotelOrders , globalDataOrderTable, globalPropsDeal , globalPropsTotalDiscountCoupons , globalPropsFabric, tableDataDiscount, status,totalStartsOfRating, globalPropsDiscount, DiscountTableDataforCustomers, tableDataCustomers,customersConst, CustomerPageTableDataforMemebersComp, appconst, offerTableDataOffer, globalPropsOffer, CustomertableDataOrder, CustomertableDataReview, CustomertableDataComplaint, priorityConst, CustomertableDataSize } from "../../constant";
import StatusIndicator from "../../components/statusIndicator";
import OnOffSwitch from "../../components/Switch/OnOffSwitch";
import RatingStar from "../../components/ratingStar";
import { useSelector } from 'react-redux';
import { Priority } from "../../components/priority";
import { urls } from "../../constants/app";
import {stylesPageTableConst,fabricPageHeaderConst, customerOrdersConst  } from "../../constant1";
import {
    golbalData, 
    tableDataTotelOrders, 
    globalDataOrderTable, 
    globalPropsTotalDiscountCoupons, 
    tableDataDiscount, 
    status,
    totalStartsOfRating, 
    globalPropsDiscount, 
    DiscountTableDataforCustomers, 
    customersConst, 
    appconst, 
    globalPropsOffer, 
    CustomertableDataOrder, 
    CustomertableDataReview, 
    CustomertableDataComplaint, 
    priorityConst, 
    CustomertableDataSize,
    globalPropsDeal
} from "../../constant";

const BrandColums = (rows, deleteListener, onStatusChange) => {
    if(rows){
        return(
            rows.map((row)=>(
                {
                    
                    // col1: <>
                    //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    //         <label for="news1"></label>
                    //     </>,
                    col1: <Link to={urls.BRAND_DETAIL+urls.QUERY_STRING_KEY_ID+row.id}><span className="text-dark text-bold">{row.name_en}</span></Link>,
                    col2: <span className="font-weight-normal">{row.fabrics_type?.name_en}</span>,
                    col3: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                    col4: <StatusIndicator {...row} statusConst = {status}/>,
                    col5: <OnOffSwitch {...row} statusConst = {status} onChangeListener={onStatusChange}/>,
                    col6: <div className="dropdown">
                            <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                                <i className="fa fa-ellipsis-v"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                                <ul>
                                    <li className="strong"><Link to={urls.BRAND_DETAIL+urls.QUERY_STRING_KEY_ID+row.id}>{golbalData.viewThis}</Link></li>
                                    <li><Link to={urls.BRAND_EDIT+urls.QUERY_STRING_KEY_ID+row.id}>{golbalData.editThisBrandLabel}</Link></li>
                                    <li><a onClick={(e)=>deleteListener(row.id)} href="#">{golbalData.deleteThisBrandLabel}</a></li>
                                </ul>
                            </div>
                        </div>
                }
            ))
        )
    }
    else return [];
}
const TailorColums = (rows=[], onStatusChange) => {
    if(rows){
    return rows.map((row)=>(
        {
            
            // col1: <>
            //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
            //         <label for="news1"></label>
            //     </>,
            col1: <Link className="text-bold text-dark" to={urls.TAILOR_DETAILS+urls.QUERY_STRING_KEY_TAILORiD+row.id}>{row.id}</Link>,
            col2: row.full_name,
            col3: <RatingStar rating = {row.tailor_more_infos[0]?.rating} totalnumberOfReviews={row?.tailor_more_infos[0]?.rate} {...totalStartsOfRating} />,
            col4: row.tailor_more_infos[0]?.avg_time,
            col5: <span className="text-bold">{null}</span>,
            col6: <span className="text-bold">{ (null === "NIL") ? null : <span className="text-red">{null}</span>}</span>,
            col7: null,
            col8: null,
            col9: <StatusIndicator {...row} statusConst = {status}/>,
            col10: <OnOffSwitch {...row} statusConst = {status} onChangeListener={onStatusChange}/>,
            col11: <div className="dropdown">
                    <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                        <ul>
                            <li className="strong"><Link to={urls.TAILOR_DETAILS+urls.QUERY_STRING_KEY_TAILORiD+row.id}>{golbalData.viewThis}</Link></li>
                            {/* <li><Link to={urls.TAILOR_DETAILS+urls.QUERY_STRING_KEY_ID+row?.tailor_portfolios[0]?.tailor_id}>{golbalData.editThisBrandLabel}</Link></li>
                            <li><a href="#">{golbalData.deleteThisBrandLabel}</a></li> */}
                        </ul>
                    </div>
                </div>
        }
    ))
    }
    else return [];
}

const TailorPendingColums = (rows=[]) => {
    if(rows){
    return rows.map((row)=>(
        {
            col1: <Link className="text-bold text-dark" to={urls.TAILORS_PENDING_VIEW+urls.QUERY_STRING_KEY_TAILORiD+row.id}>{row.id}</Link>,
            col2: row.full_name,
            col3: row.email,
            col4: row.tailor_more_infos[0]?.avg_time,
            col5: row.tailor_more_infos[0]?.commission,
            col6: <div className="dropdown">
                    <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                        <ul>
                            <li className="strong"><Link to={urls.TAILORS_PENDING_VIEW+urls.QUERY_STRING_KEY_TAILORiD+row.id}>{golbalData.viewThis}</Link></li>
                            <li><Link to={urls.TAILORS_PENDING_VIEW+urls.QUERY_STRING_KEY_ID+row.id+"&isEditPage"+"="+true}>{golbalData.editThisBrandLabel}</Link></li>
                            <li><a href="#">{golbalData.deleteThisBrandLabel}</a></li>
                        </ul>
                    </div>
                </div>
        }
    ))
    }
    else return [];
}

const OrdersColums = () => (
    tableDataTotelOrders.map((row)=>(
        {
            
            col1: <>
                    <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    <label for="news1"></label>
                </>,
            col2: row.orderId,
            col3: row.name,
            col4:  `${ ( row.onlinePayment ) ? row.LabelOnline : row.LabelCash }`,
            col5: row.orderAMT,
            col6: row.category,
            col7: row.deliveryDate,
            col8: <span className={ ( row.delay === "No Delay" ) ? "" : "text-red" }>{row.delay}</span>,
            col9: row.orderedAt,
            col10: <span><i className="fa fa-circle text-pending"></i> {row.pendingLabel}</span>,
            col11: <div className="dropdown">
                    <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                        <ul>
                            <li className="strong"><a href="#">{golbalData.statusActiveLabel}</a></li>
                            {/* <li><a href="brand-edit.html">{globalDataOrderTable.editThisOrderLabel}</a></li> */}
                            <li><a href="#">{globalDataOrderTable.deleteThisOrderLabel}</a></li>
                        </ul>
                    </div>
                </div>
        }
    ))
)
const tailorProfilePageAddressTable = (data=[],kababMenuOption) => (
    data.map((row)=>(
        {
            // col1: <>
            //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
            //         <label for="news1"></label>
            //     </>,
            col1: row.branch_name,
            col2: row.geo_address,
            col3: row.city?.name_en,
            col4: row.state?.name_en,
            col5: row.country?.name_en,
            col6: row.active ? <span className="text-green text-bold">Active</span>:<span className="text-red text-bold">Inactive</span>,
            col7: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
            // col7: <StatusIndicator {...row} statusConst = {status}/>,
            // col8: <div className="dropdown">
            //         <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
            //             <i className="fa fa-ellipsis-v"></i>
            //         </a>
            //         <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
            //             <ul>
            //                 <li className="strong"><Link to="#">{kababMenuOption.viewThis}</Link></li>
            //                 <li><Link to="#">{kababMenuOption.editThis}</Link></li>
            //                 <li><Link to="#">{kababMenuOption.deleteThis}</Link></li>
            //             </ul>
            //         </div>
            //     </div>
        }
    ))
)
const FabricColums = (data=[],deleteListener,onStatusChange) => {
    if(data){
    return data.map((row)=>(
        {
            // col1: <>
            //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
            //         <label for="news1"></label>
            //     </>,
            col1: <Link to={urls.FABRIC_DETAIL+urls.QUERY_STRING_KEY_ID+row.id}><span className="text-bold text-dark">{row.title_en}</span></Link>,
            col2: <span className="font-weight-normal">{row.brand.name_en}</span>,
            col3: "N-A",
            col4: "N-A",
            col5: <RatingStar rating={row.rating} {...totalStartsOfRating} />,
            col6: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
            col7: row?.user?.full_name,
            col8: <StatusIndicator {...row} statusConst = {status}/>,
            col9: <OnOffSwitch {...row} statusConst = {status} onChangeListener={onStatusChange}/>,
            col10: <div className="dropdown">
                    <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                        <ul>
                            <li className="strong"><Link to={urls.FABRIC_DETAIL+urls.QUERY_STRING_KEY_ID+row.id}>{fabricPageHeaderConst.viewThis}</Link></li>
                            <li><Link to={urls.FABRIC_EDIT+urls.QUERY_STRING_KEY_ID+row.id}>{fabricPageHeaderConst.editThis}</Link></li>
                            <li><a onClick={(e)=>{deleteListener(row.id)}}>{fabricPageHeaderConst.deleteThis}</a></li>
                        </ul>
                    </div>
                </div>
        }
    ))
    }
    else return [];
}
const DiscountCouponsColumsForTable = (rows=[],isPandingDiscountCouponPage=false,onStatusChange, Delete) => {
    return rows.map((row)=>{  
        if(isPandingDiscountCouponPage && !row.is_approved){
            return(
                {
                    // Pending Discount
                    // col1: <>
                    //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    //         <label for="news1"></label>
                    //     </>,
                    col1: <Link to={urls.PENDING_DISCOUNT_DETAILS+urls.QUERY_STRING_KEY_ID+row.id} className="text-bold text-dark">{row.code}</Link>,
                    col2: "N-A",
                    col3: row.descriptions_en,
                    col4: row.applicable_to,
                    col5: "N-A",
                    col6: new Date(row.valid_from?row.valid_from:"N-A").toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                    col7: new Date(row.valid_till?row.valid_till:"N-A").toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                    col8: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                    col9: row.is_approved==null || row.is_approved ?"Pending":"Approved",
                    col10: <div className="dropdown">
                            <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                                <i className="fa fa-ellipsis-v"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                                <ul>
                                    <li className="strong"><Link to={urls.PENDING_DISCOUNT_DETAILS+urls.QUERY_STRING_KEY_ID+row.id}>{golbalData.viewThis}</Link></li>
                                    {/* <li><Link to={urls.DISCOUNT_EDIT+urls.QUERY_STRING_KEY_ID+row.id}>{globalPropsDiscount.editThisDiscountLabel}</Link></li> */}
                                    {/* <li><span >{globalPropsDiscount.deleteThisDiscountLabel}</span></li> */}
                                </ul>
                            </div>
                        </div>
                    
                }
            ) 
        }
        else{
            if(!isPandingDiscountCouponPage){
                return(
                    {
                        // col1: <>
                        //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                        //         <label for="news1"></label>
                        //     </>,
                        col1: <Link to={urls.DISCOUNT_DETAILS+urls.QUERY_STRING_KEY_ID+row.id} className="text-bold text-dark">{row.code}</Link>,
                        col2: "N-A",
                        col3: row.descriptions_en,
                        col4: row.applicable_to,
                        col5: "N-A",
                        col6: new Date(row.valid_from?row.valid_from:"N-A").toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                        col7: new Date(row.valid_till?row.valid_till:"N-A").toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                        col8: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                        col9: <StatusIndicator {...row} statusConst = {status}/>,
                        col10: <OnOffSwitch {...row} statusConst = {status} onChangeListener={onStatusChange} />,
                        col11: <div className="dropdown">
                                <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                                    <i className="fa fa-ellipsis-v"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                                    <ul>
                                        <li className="strong"><Link to={urls.DISCOUNT_DETAILS+urls.QUERY_STRING_KEY_ID+row.id}>{golbalData.viewThis}</Link></li>
                                        <li><Link to={urls.DISCOUNT_EDIT+urls.QUERY_STRING_KEY_ID+row.id}>{globalPropsDiscount.editThisDiscountLabel}</Link></li>
                                        <li><a onClick={()=>Delete(row.id)}>{globalPropsDiscount.deleteThisDiscountLabel}</a></li>
                                    </ul>
                                </div>
                            </div>
                        
                    }
                ) 
            }
            else return [];
        }
    })
}
const DiscountCouponsColumsForList = () => (
    tableDataDiscount.map((row)=>(
        {
            
            col1: <>
                    <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    <label for="news1"></label>
                </>,
            col2: row.couponCode,
            col3: row.category,
            col4: row.owner,
            col5: row.applicableFor,
            col6: row.value,
            col7: row.createdAt,
            col8: <StatusIndicator {...row} statusConst = {status}/>,
            col9: <OnOffSwitch {...row} statusConst = {status}/>,
            col10: <div className="dropdown">
                    <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                        <ul>
                            <li className="strong"><a href="#">{golbalData.statusActiveLabel}</a></li>
                            <li><Link to="discount/edit?id=false">{globalPropsDiscount.editThisDiscountLabel}</Link></li>
                            <li><a href="#">{globalPropsDiscount.deleteThisDiscountLabel}</a></li>
                        </ul>
                    </div>
                </div>
        }
    ))
)
const DiscountColumsForCustomersList = () => (
    DiscountTableDataforCustomers.map((row)=>(
        {
            
            col1: <>
                    <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    <label for="news1"></label>
                </>,
            col2: row.name,
            col3: row.phoneNumber,
            col4: row.orderValue,
            col5: row.createdAt,
            col6: <StatusIndicator {...row} statusConst = {status}/>,
            col7: <Link to="#VIEW ORDER">{row.viewOrder}</Link> 
        }
    ))
)

const VariableTableColoumDataOffer = ({ deleteListener, onStatusChange }) => {
    const variable = useSelector(state=>state.variable);
    if(variable?.rows) {
        return variable.rows.map((row)=>(
            {
                
                // col1: <>
                //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                //         <label for="news1"></label>
                //     </>,
                col1: <Link className="text-bold text-dark" to={`${urls.VARIABLE_EDIT}?id=${row.id}`}>{row.name_en}</Link>,
                col2: row?.gender,
                col3: row?.user?.full_name,
                col4: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                col5: <StatusIndicator {...row} statusConst = {status}/>,
                col6: <OnOffSwitch {...row} statusConst = {status} id={row.id} onChangeListener={onStatusChange(row.id)}/>,
                col7: <div className="dropdown">
                        <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                            <i className="fa fa-ellipsis-v"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                            <ul>
                                {/* <li className="strong"><a href="#">{golbalData.statusActiveLabel}</a></li> */}
                                <li><Link to={`${urls.VARIABLE_EDIT}?id=${row.id}`}>{globalPropsOffer.editThisOfferLabel}</Link></li>
                                <li><a onClick={e => deleteListener(row.id)}>{globalPropsOffer.deleteThisOfferLabel}</a></li>
                            </ul>
                        </div>
                    </div>
            }
        ))
    } else return [];
    
}


const offerTableColoumDataOffer = () => (
    DiscountTableDataforCustomers.map((row)=>(
        {
            
            col1: <>
                    <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    <label for="news1"></label>
                </>,
            col2: <Link className="text-bold text-dark" to={"offer/view?id=false"}>{row.title}</Link>,
            col3: row.category,
            col4: row.tailor,
            col5: row.applicableFor,
            col6: row.createdAt,
            col7: <StatusIndicator {...row} statusConst = {status}/>,
            col8: <OnOffSwitch {...row} statusConst = {status}/>,
            col9: <div className="dropdown">
                    <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                        <ul>
                            <li className="strong"><a href="#">{golbalData.statusActiveLabel}</a></li>
                            <li><Link to="offer/edit?id=false">{globalPropsOffer.editThisOfferLabel}</Link></li>
                            <li><a href="#">{globalPropsOffer.deleteThisOfferLabel}</a></li>
                        </ul>
                    </div>
                </div>
        }
    ))
)

const OfferTable = ({deleteListener, onStatusChange}) => {
    const offer = useSelector(state=>state.offer.rows);
    if (offer) {
        return (
            offer.map((row)=>(
                {
                    
                    // col1: <>
                    //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    //         <label for="news1"></label>
                    //     </>,
                    col1: <Link className="text-bold text-dark" to={urls.OFFER_DETAILS+urls.QUERY_STRING_KEY_ID+"willEnter"}>{row["name_en"]}</Link>,
                    col2: row.category,
                    col3: row.tailor,
                    col4: row.applicable_to,
                    col5: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                    col6: <StatusIndicator {...row} statusConst = {status}/>,
                    col7: <OnOffSwitch {...row} statusConst = {status} id={row.id} onChangeListener={onStatusChange(row.id)}/>,
                    col8: <div className="dropdown">
                            <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                                <i className="fa fa-ellipsis-v"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                                <ul>
                                    <li className="strong"><Link to={urls.OFFER_DETAILS+urls.QUERY_STRING_KEY_ID+"willEnter"}>{golbalData.viewThis}</Link></li>
                                    <li><Link to={urls.OFFER_EDIT + urls.QUERY_STRING_KEY_ID + row.id}>{globalPropsOffer.editThisOfferLabel}</Link></li>
                                    <li><a onClick={e => deleteListener(row.id)}>{globalPropsOffer.deleteThisOfferLabel}</a></li>
                                </ul>
                            </div>
                        </div>
                }
            ))
        )
    } else return [];
    
}
const DealTable = ({deleteListener, onStatusChange}) => {
    const deal = useSelector(state=>state.deal.rows);
    if (deal) {
        return (
            deal.map((row)=>(
                {
                    // col1: <>
                    //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    //         <label for="news1"></label>
                    //     </>,
                    col1: <Link className="text-bold text-dark" to={urls.DEAL_DETAIL + urls.QUERY_STRING_KEY_ID + row.id}>{row["name_en"]}</Link>,
                    col2: row.tailor.full_name,
                    col3: row.gender,
                    col4: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                    col5: <StatusIndicator {...row} statusConst = {status}/>,
                    col6: <OnOffSwitch {...row} statusConst = {status} id={row.id} onChangeListener={onStatusChange(row.id)}/>,
                    col7: <div className="dropdown">
                            <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                                <i className="fa fa-ellipsis-v"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                                <ul>
                                    {/* <li className="strong"><Link to={urls.DEAL_DETAIL+urls.QUERY_STRING_KEY_ID+"willEnter"}>{golbalData.statusActiveLabel}</Link></li> */}
                                    <li><Link to={urls.DEAL_DETAIL + urls.QUERY_STRING_KEY_ID + row.id}>{globalPropsDeal.editThisDealLabel}</Link></li>
                                    <li><a onClick={e => deleteListener(row.id)}>{globalPropsDeal.deleteThisDealLabel}</a></li>
                                </ul>
                            </div>
                        </div>
                }
            ))
        )
    } else return [];
    
}
const CustomersTableColoumCustomers = (data=[],onStatusChange) => {
    if(data){
        return data.map((row)=>(
            {
                // col1: <>
                //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                //         <label for="news1"></label>
                //     </>,
                col1: <Link className="text-bold text-dark" to={urls.CUSTOMER_DETAIL+urls.QUERY_STRING_KEY_CUSTOMERiD+row.id}>{row.id}</Link>,
                col2: <span className="font-weight-normal">{row.full_name}</span>,
                col3: row.phone,
                col4: <div className="text-bold">{row.totalSpent}</div>,
                col5: <StatusIndicator {...row} statusConst = {status}/>,
                col6: <OnOffSwitch {...row} statusConst = {status} onChangeListener={onStatusChange}/>,
                col7: <div className="dropdown">
                        <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                            <i className="fa fa-ellipsis-v"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                            <ul>
                                <li className="strong"><Link to={urls.CUSTOMER_DETAIL+urls.QUERY_STRING_KEY_ID+row.id}>{appconst.view +" "+customersConst.title }</Link></li>
                                {/* <li><Link to="customer/edit?id=false">{appconst.editThisLabel+" "+customersConst.title}</Link></li> */}
                                {/* <li><a>{appconst.deactivateThis+" "+customersConst.title}</a></li> */}
                            </ul>
                        </div>
                    </div>
            }
        ))
    }
    else return []
}
const customersProfileTableColoumMembers = (dataObj, customerId) => {
    if( dataObj != undefined){
        return dataObj.map((row)=>(
            {
                col1: <Link className="text-bold text-dark" to={urls.CUSTOMER_SAVED_SIZE+customerId+"&name="+row.full_name+"&id="+row.id+"&relation="+row.relation}>{row.full_name}</Link>,
                col2: row.relation,
                col3: row.category?.name_en ,
                col4: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                col5: <div className="dropdown">
                        <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                            <i className="fa fa-ellipsis-v"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                            <ul>
                                <li className="strong"><Link to={urls.CUSTOMER_SAVED_SIZE+customerId+"&name="+row.full_name+"&id="+row.id}>{appconst.view +" "+customersConst.labelSizes }</Link></li>
                                {/* <li><Link to="#edit">{appconst.editThisLabel+" "+customersConst.memberLable}</Link></li>
                                <li><a href="#del">{appconst.deleteThis+" "+customersConst.memberLable}</a></li> */}
                            </ul>
                        </div>
                    </div>
            }
        ))
    }
    else return []
}
const customersProfileTableColoumAddresses = (dataObj) => {
    if( dataObj != undefined){
        return dataObj.map((row)=>(
            {
                col1: <span className="text-bold text-dark" >{row.full_name}</span>,
                col2: row.phone,
                col3: row.state?.name_en ,
                col4: row.country?.name_en,
                col5: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
            }
        ))
    }
    else return []
}
const customersTableColoumOrders = (rows=[], enocodeQuery) => {
    if(rows){
        return( rows.map((row)=>(
            {
                col1: <Link to={urls.CUSTOMER_ORDERS_DETAIL+enocodeQuery+"&id="+row.id} className="text-dark text-bold" >{row.id}</Link>,
                col2: <span className="font-weight-normal">{row.customer?.id}</span>,
                col3: <strong>{row.net_price}</strong>,
                col4: <strong>{row.tailor?.full_name}</strong>,
                col5: row.delivery_date?new Date(row.delivery_date).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }):"",
                col6: <strong><span className={row.deliveryDelay ? "text-red" : "text-green"}>{row.deliveryDelay || "No Delay"}</span></strong>,
                col7: row.created_at?new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }):"",
                col8: row.order_status?.name_en,
                col9: <div className="dropdown">
                        <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                            <i className="fa fa-ellipsis-v"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                            <ul>
                                <li className="strong"><Link to={urls.CUSTOMER_ORDERS_DETAIL+enocodeQuery+"&id="+row.id}>{customerOrdersConst.view }</Link></li>
                                {/* <li><Link to="#edit">{customerOrdersConst.editThis}</Link></li>
                                <li><a href="#del">{appconst.deleteThis+" "+customersConst.memberLable}</a></li> */}
                            </ul>
                        </div>
                    </div>
            }
        )))
    }
}
const customersTableColoumReviews= (rows=[]) => (
    rows.map((row)=>(
        {
            col1: <strong>{row.review}</strong>,
            col2: <RatingStar rating = {row.rating} {...totalStartsOfRating} />,
            col3: row.tailor?.full_name,
            col4: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
            // col5: <div className="dropdown">
            //         <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
            //             <i className="fa fa-ellipsis-v"></i>
            //         </a>
            //         <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
            //             <ul>
            //                 <li className="strong"><Link to="#xyz">{appconst.view +" "+customersConst.labelSizes }</Link></li>
            //                 <li><Link to="#edit">{appconst.editThisLabel+" "+customersConst.memberLable}</Link></li>
            //                 <li><a href="#del">{appconst.deleteThis+" "+customersConst.memberLable}</a></li>
            //             </ul>
            //         </div>
            //     </div>
        }
    ))
)
const customersTableColoumComplaints= (customerId) => (
    CustomertableDataComplaint.map((row)=>(
        {
            // col1: <>
            //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
            //         <label for="news1"></label>
            //     </>,
            col1: <Link className="text-bold text-dark" to={urls.CUSTOMER_COMPLAINT_DETAILS+urls.QUERY_STRING_KEY_CUSTOMERiD+customerId}>{row.title}</Link>,
            col2: row.issue,
            col3: row.issueSubDivision,
            col4: row.customerId,
            col5: <Priority data={row} const={priorityConst}/>,
            col6: row.createdAt,
            col7: <StatusIndicator {...row} statusConst = {status}/>,
            col8: <div className="dropdown">
                    <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                        <ul>
                            <li className="strong"><Link to={urls.CUSTOMER_COMPLAINT_DETAILS+urls.QUERY_STRING_KEY_CUSTOMERiD+customerId}>{appconst.view +" "+customersConst.labelSizes }</Link></li>
                            <li><Link to="#edit">{appconst.editThisLabel+" "+customersConst.memberLable}</Link></li>
                            <li><a href="#del">{appconst.deleteThis+" "+customersConst.memberLable}</a></li>
                        </ul>
                    </div>
                </div>
        }
    ))
)
const customersTableColoumSaveSize= (rows=[], relation) => {
    if(rows){
        return rows.map((row)=>(
        {
            col1: relation,
            col2: <span className="font-weight-normal">{row.type}</span>,
            col3: row.shoulder_size,
            col4: row.height,
            col5: row.weight,
            col6: row.age,
            col7: row.body_length,
            col8: row.chest_size,
            col9: row.sleeve_length,
            col10: row.created_at? new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }):"",
        }
    ))
    }
}
const OrderTableColoumOrders = () => (
    CustomertableDataOrder.map((row)=>(
        {
            
            col1: <>
                    <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                    <label for="news1"></label>
                </>,
            col2: <Link className="text-dark" to="/dashboard/orders/view">{row.orderId}</Link>,
            col3: row.customerID,
            col4: <strong>{row.orderAMT}</strong>,
            col5: <strong>{row.tailor}</strong>,
            col6: row.deliveryDate,
            col7: <strong><span className={row.deliveryDelay==="No Delay" ? "" : "text-red"}>{row.deliveryDelay}</span></strong>,
            col8: row.createdAt,
            col9: <StatusIndicator {...row} statusConst = {status}/>,
            col10: <OnOffSwitch {...row} statusConst = {status}/>,
            col11: <div className="dropdown">
                    <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                        <ul>
                            <li className="strong"><Link to="#xyz">{appconst.view +" "+customersConst.labelSizes }</Link></li>
                            <li><Link to="#edit">{appconst.editThisLabel+" "+customersConst.memberLable}</Link></li>
                            <li><a href="#del">{appconst.deleteThis+" "+customersConst.memberLable}</a></li>
                        </ul>
                    </div>
                </div>
        }
    ))
)
const stylePageTableColoumDataForstyles = (data=[],deleteListener, onStatusChange) => (
    data.map((row)=>(
        {
            
            // col1: <>
            //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
            //         <label for="news1"></label>
            //     </>,
            col1: <Link className="text-bold text-dark" to={urls.STYLE_EDIT+urls.QUERY_STRING_KEY_ID+row.id}>{row.name_en}</Link>,
            col2: <> {row.gender} - {row.category?.name_en} </>,
            col3: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
            col4: row.user?.full_name,
            col5: <StatusIndicator {...row} statusConst = {status}/>,
            col6: <OnOffSwitch {...row} statusConst = {status} onChangeListener={onStatusChange}/>,
            col7: <div className="dropdown">
                    <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                        <ul>
                            {/* <li className="strong"><a href="#">{stylesPageTableConst.statusActiveLabel}</a></li> */}
                            <li><Link to={urls.STYLE_EDIT+urls.QUERY_STRING_KEY_ID+row.id}>{stylesPageTableConst.editThis}</Link></li>
                            <li><a onClick={(e)=>deleteListener(row.id)}>{stylesPageTableConst.deleteThis}</a></li>
                        </ul>
                    </div>
                </div>
        }
    ))
)

const tailorPageColoumForOrders = (rows=[], enocodeQuery) => {
    if(rows){
        return( rows.map((row)=>(
            {
                
                // col1: <>
                //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
                //         <label for="news1"></label>
                //     </>,
                // col2: <Link to={urls.CUSTOMER_ORDERS_DETAIL+enocodeQuery+"&id="+row.order_id} className="text-dark text-bold" >{row.order_id}</Link>, 
                col1: row.id,
                col2: row.customer?.id,
                col3: row.customer?.full_name,
                col4: <strong>{row.net_price}</strong>,
                col5: <strong>{row.tailor?.full_name}</strong>,
                col6: row.payment_type,
                col7: <strong><span className={row.deliveryDelay ? "text-red" : "text-green"}>{row.deliveryDelay || "No Delay"}</span></strong>,
                col8: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
                col9: row.order_status?.name_en,
                // col11: <OnOffSwitch {...row} statusConst = {status}/>,
                col10: <div className="dropdown">
                        <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
                            <i className="fa fa-ellipsis-v"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
                            <ul>
                                <li className="strong"><Link to="#xyz">{customerOrdersConst.view }</Link></li>
                                {/* <li><Link to="#edit">{customerOrdersConst.editThis}</Link></li>
                                <li><a href="#del">{appconst.deleteThis+" "+customersConst.memberLable}</a></li> */}
                            </ul>
                        </div>
                    </div>
            }
        )))
    }
}

const TailorPageColumsForCoupons = (rows=[]) => (
    rows.map((row)=>(
        { 
            // col1: <>
            //         <input className="filled-in" name="group1" type="checkbox" id="news1"/>
            //         <label for="news1"></label>
            //     </>,
            // col2: <Link className="text-bold text-dark">{row.code}</Link>,
            col1: row.code,
            col2: "missing",
            col3: row.applicable_to,
            col4: row.discount_percent,
            col5: "missing",
            col6: <StatusIndicator {...row} statusConst = {status}/>,
            col7: <OnOffSwitch {...row} statusConst = {status}/>,
            // col8: <div className="dropdown">
            //         <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
            //             <i className="fa fa-ellipsis-v"></i>
            //         </a>
            //         <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
            //             <ul>
            //                 {/* <li className="strong"><Link to={urls.DISCOUNT_DETAILS+urls.QUERY_STRING_KEY_ID+"willEnter"}>{golbalData.statusActiveLabel}</Link></li> */}
            //                 {/* <li><Link to={urls.DISCOUNT_EDIT+urls.QUERY_STRING_KEY_ID+"willEnter"}>{globalPropsDiscount.editThisDiscountLabel}</Link></li> */}
            //                 {/* <li><a href="#">{globalPropsDiscount.deleteThisDiscountLabel}</a></li> */}
            //             </ul>
            //         </div>
            //     </div>
        }
    ))
)

const tailorPageColoumForReviewsTable= (rows=[]) => (
    rows.map((row)=>(
        {
            col1: <strong>{row.review}</strong>,
            col2: <RatingStar rating = {row.rating} {...totalStartsOfRating} />,
            col3: row.user?.full_name,
            col4: new Date(row.created_at).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }),
            // col5: <div className="dropdown">
            //         <a className="admintabledrop" data-toggle="dropdown" href="#" aria-expanded="true">
            //             <i className="fa fa-ellipsis-v"></i>
            //         </a>
            //         <div className="dropdown-menu dropdown-menu-md tableaction dropdown-menu-right" style={{left: "inherit", right: "0px"}}>
            //             <ul>
            //                 <li className="strong"><Link to="#xyz">{appconst.view +" "+customersConst.labelSizes }</Link></li>
            //                 <li><Link to="#edit">{appconst.editThisLabel+" "+customersConst.memberLable}</Link></li>
            //                 <li><a href="#del">{appconst.deleteThis+" "+customersConst.memberLable}</a></li>
            //             </ul>
            //         </div>
            //     </div>
        }
    ))
)
export{
    BrandColums,
    TailorColums,
    OrdersColums,
    tailorProfilePageAddressTable,
    FabricColums,
    DiscountCouponsColumsForTable,
    DiscountCouponsColumsForList,
    DiscountColumsForCustomersList,
    CustomersTableColoumCustomers,
    customersProfileTableColoumMembers,
    OfferTable,
    offerTableColoumDataOffer,
    customersTableColoumOrders,
    customersTableColoumReviews,
    customersTableColoumComplaints,
    customersTableColoumSaveSize,
    OrderTableColoumOrders,
    DealTable,
    stylePageTableColoumDataForstyles,
    VariableTableColoumDataOffer,
    tailorPageColoumForOrders,
    TailorPageColumsForCoupons,
    tailorPageColoumForReviewsTable,
    customersProfileTableColoumAddresses,
    TailorPendingColums
};
