import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { reducer as appReducer} from './redux/app.reducers';
import {reducer as loginReducer} from './containers/login/redux/login.reducers';
import {reducer as discountReducer} from './containers/topDiscount/redux/discount.reducers';
import {reducer as tailorReducer} from './containers/tailorsList/redux/tailor.reducers';
import { brandsReducer, addReducer, detailReducer, EditReducer } from "./containers/brand/redux/brandTable.reducers";
import { reducer as fabricReducer,fabricTypeAddreducer, fabricTypeGetreducer } from "./containers/fabric/redux/fabricTable.reducers";
import { reducer as tailorTableReducer } from "./containers/tailorTable/redux/tailor.reducers";
import { reducer as fabricAddReducer  } from "./containers/AddFabric/redux/AddFabric.reducers";
import { reducer as tailorAddReducer } from "./containers/AddTailor/redux/AddTailor.reducers";
import { reducer as offerTableReducer } from "./containers/offersTable/redux/offersTable.reducers";
import { reducer as dealTableReducer } from "./containers/dealsTable/redux/dealsTable.reducers";
import { reducer as topCustomerListReducer } from "./containers/top-customer/redux/customer.reducers";
import { reducer as outstandingReducer } from "./containers/dashboardOutStanding/redux/outstanding.reducers";
import { reducer as pendingCouponReducer } from "./containers/pendingCoupon/redux/pending.reducers";
import { reducerOrders, reducerOrdersDetail } from "./containers/order/redux/order.reducers";
import { reducerCustomers, reducerCustomerDetail, reducerMemberMeasurement, reducerCustomerOrders, reducerCustomerReviews } from "./containers/customer/redux/customer.reducers";
import { reducerStyles } from "./containers/style/redux/style.reducers";
import { reducer as discountCoupons } from "./containers/discount/redux/discount.redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as addDealReducer } from "./containers/AddDeal/redux/AddDeal.reducers";
import { reducer as variableTableReducer } from "./containers/variableTable/redux/variableTable.reducers";
import { reducer as dashboardReducer, SalesTrendReducer } from "./containers/dashboard/redux/dashboard.reducers";
import { reducer as taxReducer, reducerGetById } from "./containers/tax/redux/tax.reducers";
import { reducer as settingsReducer} from "./containers/settings/redux/settings.reducers";
import { reducer as payoutList } from "./containers/payout/redux/payout.reducers";

const rootReducer = combineReducers({
    app:appReducer,
    user: loginReducer,
    styles: reducerStyles,
    discountCoupons : discountCoupons,
    topDiscount: discountReducer,
    tailorList: tailorReducer,
    fabric: fabricReducer,
    fabricTypeList: fabricTypeGetreducer,
    fabricTypeAdd: fabricTypeAddreducer,
    fabricAdd: fabricAddReducer,
    tailor: tailorTableReducer,
    brands: brandsReducer,
    brandAdd: addReducer,
    brandDetail: detailReducer,
    brandEdit: EditReducer,
    tailorAdd: tailorAddReducer,
    offer: offerTableReducer,
    TopCustomerList: topCustomerListReducer,
    outstanding: outstandingReducer,
    pendingCoupon: pendingCouponReducer,
    orderTable: reducerOrders,
    orderDetail: reducerOrdersDetail,
    customers: reducerCustomers,
    customerDetail : reducerCustomerDetail,
    memberSize : reducerMemberMeasurement,
    customerOrders: reducerCustomerOrders,
    reviews: reducerCustomerReviews,
    deal: dealTableReducer,
    dealAdd: addDealReducer,
    variable: variableTableReducer,
    dashboardData: dashboardReducer,
    taxesData: taxReducer,
    taxDetail: reducerGetById,
    settings: settingsReducer,
    payoutList: payoutList,
    salesTrendData: SalesTrendReducer
 });
const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(thunk)));

export default store;
export { store }