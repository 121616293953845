import React, { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { OrderView } from "../../pages/orders";
import { OrderDetailAction } from "../order/redux/order.actions";
import queryString from "query-string";

function CustomerOrderDetailContainor(params) {

    const queryObj = queryString.parse(window.location.search);

    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const customerOrdersDetail = useSelector(state=>state.orderDetail);
    const customerOrdersDetailData = customerOrdersDetail?.data?.order;

    const temp =0;

    const token = {
        'Authorization' : `${userState.token}`
    };
    let enocodeQuery = Object.keys(queryObj)
            .map((key) => key + "=" + queryObj[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';

    let queryId = queryObj.id

    useEffect(()=>{
        dispatch(OrderDetailAction(token, "/"+queryId));
    },[temp])
    
    return(
        <OrderView
            customerId={queryObj.customer_id}
            {...customerOrdersDetailData}
        />
    )
}
export default CustomerOrderDetailContainor;