import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from 'react-redux';
import EditBrand from "../../pages/brands/brandsEdit";
import { editBrand, brandById } from "./redux/brandTable.actions";
import queryString from "query-string";
import { urls } from "../../constants";
import { useHistory } from "react-router-dom";
import { uploadImage } from "../AddOffer/redux/AddOffer.actions";
import { GetFabricType } from "../fabric/redux/fabricTable.actions";

function EditBrandContainer (props) {

  const history = useHistory();
  const queryObj = queryString.parse(window.location.search);
  const id = queryObj.id;
  const [form, setForm] = useState({});
  const dispatch = useDispatch();

  const userState = useSelector(state=>state.user);
  const brandDetail = useSelector(state=>state.brandDetail);
  const fabricTypeList = useSelector(state=>state.fabricTypeList?.fabric_types);

  const token = {
    'Authorization' : `${userState.token}`
  };
  const submitHandler = (e) => {

      if( ( form.name_en !=="" ) && ( form.fabric_type_id !=="" )&& ( form.descriptions_en !=="" ) && ( form.status !=="" )&& ( form.gender !=="" )&& ( form.image_url_en !=="" ) ){
        dispatch(editBrand(id,form,token));
        history.push(urls.BRANDS);
      }else{
        alert( "Please fill all the boxes." ); // todo : will pass this message from constents
      }
  }
  const temp = 0 ;
  useEffect(()=>{
    dispatch(brandById(token,"/"+id));
    dispatch(GetFabricType(token));
  },[temp])

  useEffect(()=>{
    setForm(brandDetail);
  },[brandDetail])
  
  function onUploadImage(data, callBack){
    return dispatch(uploadImage(data[0], token, callBack))
  }
  const onChangeListener = (key,value) => {
    setForm({...form,[key]:value});
  }
  return (
    <EditBrand
        submitHandler= {submitHandler}
        fabricTypesData = {fabricTypeList}
        onUploadImage={onUploadImage} 
        onChangeListener={onChangeListener}
        {...form}
        {...props}
        id={id}
    />
  )
}

EditBrandContainer.propTypes = {

};

export default EditBrandContainer;
export {
  EditBrandContainer
}
