import React, {useState, useEffect} from "react";
import { SettingsPage } from "../../pages/settings";
import {useDispatch, useSelector} from 'react-redux';
import { settingsFetch, settingAdd } from "./redux/settings.actions";

function SettingsContainer(props) {
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    // const Taxes = useSelector(state=>state.taxesData);
    const settingSelector = useSelector(state => state.settings);
    const [settings, setSettings] = useState(settingSelector);

    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(() => {
        dispatch(settingsFetch(token, ""))
    }, [])

    function onChnageSettings(chnageObj) {
        setSettings({
            ...settings,
            ...chnageObj
        })
    }

    function onSubmit() {
        dispatch(settingAdd({
            shipping_cost: settings.shipping_cost,
            min_cost_for_shipping: settings.min_cost_for_shipping
        }, token, () => {
            dispatch(settingsFetch(token, ""))
        }))
    }
    return(
        <SettingsPage
            settings={settings}
            onSubmit={onSubmit}
            onChnageSettings={onChnageSettings}
        />
    )
}

export default SettingsContainer;
export {
    SettingsContainer
}