import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Variables } from "../../pages/variable";
import {
  variableTable,
  variableSearch,
  variableDelete,
  variableDistinct
} from "./redux/variableTable.actions";
import { updateVariableCategory } from "../AddVariable/redux/AddVariable.actions";

function VariableTableContainer(props) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const variable = useSelector((state) => state.variable);
  const [distinctVariable, setdistinctVariable] = useState([])

  const token = {
    Authorization: `${userState.token}`,
  };

  useEffect(()=>{
    // dispatch(
    //   variableSearch({
    //     ...variable,
    //     search: "",
    //   })
    // );
    getVariableDistinct();
  },0)

  useEffect(() => {
    toPage(1);
  }, [variable.search]);

  useEffect(() => {
    if(!distinctVariable.length) return;
    dispatch(
      variableSearch({
        ...variable,
        search: distinctVariable[0]?.name,
      })
    );
  }, [distinctVariable])

  function deleteListener(id) {
    dispatch(variableDelete(id, token));
  }

  function getVariableDistinct() {
    dispatch(
      variableDistinct(token, "", setdistinctVariable)
    )
  }

  function toPage(page, limit) {
    let search = variable.search;

    let query = {};

    if (page) query.page = page;
    if (limit) query.limit = limit;
    if (search) query.name = search;

    let enocodeQuery = Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&");
    enocodeQuery = enocodeQuery ? "?" + enocodeQuery : "";

    dispatch(variableTable(token, enocodeQuery));
  }

  function updateListener(id, data) {
    dispatch(
      updateVariableCategory(id, data, token, () =>
        toPage(variable.pageInfo.current_page)
      )
    );
  }

  function onSearch(str) {
    dispatch(
      variableSearch({
        ...variable,
        search: str,
      })
    );
  }

  function onStatusChange(id) {
    return (id, status) => {
      updateListener(id, {
        status: status ? "active" : "inactive",
      });
    };
  }

  return (
    <Variables
      serachField={variable.search}
      distinctVariable={distinctVariable}
      userState={userState.user}
      onStatusChange={onStatusChange}
      onSearch={onSearch}
      deleteListener={deleteListener}
      toPage={toPage}
    />
  );
}

export default VariableTableContainer;
export { VariableTableContainer };
