import React from "react";
import Header from "../../components/header";
import Input from "../../components/input";
import TextArea from "../../components/textArea";
import SelectSection from "../../components/SelectSection";
import { taxEditPageConst } from "../../constant1";

function TaxEditPage(props) {
    taxEditPageConst.texEditPageHeaderConsts.buttons[1].onClickListener=props.submitHandler;
    return(
        <div className="wrapper">
            <div className="content-wrapper">
                <Header 
                    {...taxEditPageConst.texEditPageHeaderConsts}
                />
                <section class="content mb-5">
                    <div class="container-fluid">
                        <div class="row">
                            <section class="col-lg-8 connectedSortable">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <Input 
                                            value={props.name}
                                            placeholder={taxEditPageConst.inputTypeText.placeholder}
                                            type="text" 
                                            id="taxTitle"
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('name',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-6">
                                        <Input 
                                            value={props.percent}
                                            placeholder={taxEditPageConst.inputTypeNumber.placeholder} 
                                            type="number" 
                                            id="taxPercentage"
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('percent',value);
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-12">
                                        <TextArea 
                                            value={props.desc}
                                            rows="10"
                                            placeholder={taxEditPageConst.inputTypeTextArea.placeholder}
                                            onChangeListener={(value)=>{
                                                props.onChangeListener('desc',value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </section>
                            <section class="col-lg-4 connectedSortable">
                                <SelectSection
                                    value={props.status}
                                    heading={taxEditPageConst.selectSection.heading}
                                    options={taxEditPageConst.selectSection.array}
                                    onChangeListener={(value)=>{
                                        props.onChangeListener('status',value);
                                    }}
                                />
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </div>    
    )
}

export default TaxEditPage;
export{
    TaxEditPage
}