import React from "react";

function DropDownList({
    title,
    active,
    list,
    onClickHandler
}) {
  return (
    <div className="container-fluid">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title float-none">{title}</h3>
        </div>
        <div class="card-body style-box">
          {list?.map((obj) => {
            return (
              <div style={{cursor: 'pointer'}}
                class={"row favfabric styledefault" + ( active == obj.name ? " styleactive" : "")}
                onClick={e => {onClickHandler && onClickHandler(obj.name)}}
              >
                <div class="col-12">
                  <span class="fabName f-20">{obj.name}</span> <i className="fa fa-chevron-right float-right"></i>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DropDownList;
export {
    DropDownList
}