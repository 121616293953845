import {DealService} from '../../../services';
import {createAction} from 'redux-actions';
import {appDealTableSuccess, appDealTableError} from './dealsTable.actionNames';

const dealTableFetchSuccess = createAction(appDealTableSuccess,(userData)=>userData);
const dealTableFetchError = createAction(appDealTableError,(userLoginErr)=>userLoginErr);

function dealTable(token, page, limit, search){
    return (dispatch)=>{
        DealService.get(token, page, limit, search).then((resp)=>{
             dispatch(dealTableFetchSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

// TODO: offer delete need not to in reducers since not action performed particular

function dealDelete(id, token) {
    return (dispatch) => {
        DealService.delete(id, token).then((resp) => {
            dispatch(dealTable(token));
        }).catch((error) => {
            console.log(error);
        })
    }
}
function dealInfo(id, token, callBack) {
    return (dispatch) => {
        DealService.info(id, token).then((resp) => {
            // dispatch(dealTable(token));
            callBack(resp.data.data)
        }).catch((error) => {
            console.log(error);
        })
    }
}

function dealSearch(offer) {
    return (dispatch) => {
        dispatch(dealTableFetchSuccess(offer));
    }
}



export {dealTable ,dealSearch,dealInfo, dealDelete, dealTableFetchError, dealTableFetchSuccess}
