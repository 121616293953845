import { handleActions } from "redux-actions";
import { tailorAddError, tailorAddSuccess } from "./AddTailor.actions";

const defaultState = {};
// TODO: it looks like over engineering
const reducer = handleActions(
  {
    [tailorAddSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [tailorAddError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);

export { reducer };