import PropTypes from 'prop-types';
import React from 'react';

function StatusIndicator(props) {
    return(
        <span><i className={ ( props.status !== props.statusConst.activeLabel ) ? "fa fa-circle text-red" : "fa fa-circle text-success" }></i> <strong>{ props.status }</strong></span>
    )
}

StatusIndicator.propTypes = {
}
export default StatusIndicator;