import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Coupons} from "../../pages/tailor";
import { DiscountTableAction } from "../discount/redux/discount.action";
import queryString from "query-string";

function TailorCouponContainor(params) {
    
    // const querydata = queryString.parse(window.location.search);
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const CouponsState = useSelector(state=>state.discountCoupons);
    var rows = CouponsState?.rows;
    var pageInfo = CouponsState?.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };
    
    var temp =0;
    let enocodeQuery = window.location.search
    useEffect(()=>{
        dispatch(DiscountTableAction(token,enocodeQuery));
    },[temp])

    return(
        <Coupons
            enocodeQuery = {window.location.search}
            rows={rows}
            pageInfo={pageInfo}
        />
    )
}

export default TailorCouponContainor;
export {
    TailorCouponContainor
}