import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import List from "../../components/list";
import {img, topDiscountConst } from "../../constant";
import { coupon } from "./redux/discount.actions";

function DiscountContainer(props) {

    let queryString = `?startDate=${props.start?.startDate}&endDate=${props.end?.endDate}`

    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const couponState = useSelector(state=>state.topDiscount);
    
    const token = {
        'Authorization' : `${userState.token}`
    };
    useEffect(()=>{
        dispatch(coupon(token,queryString));
    },[queryString])
    
    const Obj = { key1 : "code", key2 : "id" , key3 : "discount_percent" };
    return <List
                {...topDiscountConst}
                { ...Obj }
                array={couponState.topDiscountCoupons}
                img={img.trendingUpImgURL}
            />;
}

export default DiscountContainer;
export {DiscountContainer};