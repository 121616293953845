import { handleActions } from "redux-actions";
import { 
  tailorTableFatchError, 
  tailorTableFatchSuccess,
  tailorDetailSuccess,
  tailorDetailError
} from "./tailor.actions";

const defaultState = {};
// TODO: it looks like over engineering
const reducer = handleActions(
  {
    [tailorTableFatchSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [tailorTableFatchError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [tailorDetailSuccess]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
    [tailorDetailError]: (state, action) => {
      return Object.assign({}, { ...state }, { ...action.payload });
    },
  },
  defaultState
);

export { reducer };