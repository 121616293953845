import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

function Input(props) {
  
  const changeValue = (e)=>{
    props.onChangeListener(props.type == "checkbox" ? e.target.checked : e.target.value);
  }

  return (
      <div className="form-label-group">
        <input
          disabled={props.disabled}
          style={{ width: "100%" }}
          placeholder={props.placeholder}
          type={props.type}
          min={props.min}
          max={props.max}
          maxLength={props.maxLength}
          defaultValue={props.defaultValue}
          value={props.value}
          onChange={changeValue}
          id={props.id}
          className="filled-in form-control "
        />
        <label for={props.id}>{props.placeholder}</label>
      </div>
  );
}

Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  onChangeListener: PropTypes.func,
};
Input.defaultProps = {
  onChangeListener: ()=>{}
}

export default Input;
