import {VariableCategoryService} from '../../../services';
import {createAction} from 'redux-actions';
import {appVariableTableSuccess, appVariableTableError} from './variableTable.actionNames';

const variableTableFetchSuccess = createAction(appVariableTableSuccess,(userData)=>userData);
const variableTableFetchError = createAction(appVariableTableError,(userLoginErr)=>userLoginErr);

function variableTable(token, enocodeQuery){
    return (dispatch)=>{
        VariableCategoryService.paginate(token, enocodeQuery).then((resp)=>{
             dispatch(variableTableFetchSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function variableList(token, enocodeQuery, callBack){
    return (dispatch)=>{
        VariableCategoryService.get(token, enocodeQuery).then((resp)=>{
                callBack(resp.data.data);
            }).catch((err)=>{
                console.log(err)
            })
    }
}

// TODO: offer delete need not to in reducers since not action performed particular

function variableDelete(id, token) {
    return (dispatch) => {
        VariableCategoryService.delete(id, token).then((resp) => {
            dispatch(variableTable(token));
        }).catch((error) => {
            console.log(error);
        })
    }
}

function variableSearch(offer) {
    return (dispatch) => {
        dispatch(variableTableFetchSuccess(offer));
    }
}

function variableDistinct(token, enocodeQuery, callBack){
    return (dispatch)=>{
        VariableCategoryService.distinct(token, enocodeQuery).then((resp)=>{
            //  dispatch(variableTableFetchSuccess(resp.data.data))
            callBack(resp.data.data);
            }).catch((err)=>{
                console.log(err)
            })
    }
}


export {variableDistinct, variableTable, variableList ,variableSearch, variableDelete, variableTableFetchError, variableTableFetchSuccess}
