import React from "react";
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";

function OrderTile(props) {
    return(
        <div className="row mt-4 pb-4 mb-3">
            
            <div className="col-sm-6 col-6">
                <span className="customerBox mb-0">                            
                    <h5>{props.titleValue}</h5>
                    <p>{props.TitleAndDescription}</p>
                </span>                         
            </div>
            <div className="col-sm-6 col-6">
                <span className="customerBox mb-0">                            
                    <h5>{props.linkTitleValue}</h5>
                    <p>{props.linkTitleAndDescription}</p>
                </span>                      
            </div>
        </div>
    )
}

OrderTile.propTypes={
    titleValue: PropTypes.string,
    linkTitleValue: PropTypes.string,
    TitleAndDescription : PropTypes.string,
    linkTitleAndDescription : PropTypes.string,
}
OrderTile.defaultProps = {
    titleValue: "Null",
    linkTitleValue: "Null"
}
export default OrderTile ;
export {OrderTile};