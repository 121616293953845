import React from "react";
import PropTypes from "prop-types";

function Condition(props) {
    if(props.priority === props.highLable){
       return <><i className="fa fa-circle text-danger"></i> <strong>{ props.priority }</strong></>
    }
    else{
        if(props.priority === props.mediumLable){
            return <><i className="fa fa-circle text-yellow"></i> <strong>{ props.priority }</strong></>
        }
        else{
            if(props.priority === props.lowLable){
                return <><i className="fa fa-circle text-info"></i> <strong>{ props.priority }</strong></>
            }
            else{
                return "error in priority.js file"
            }
        }
    }
}

function Priority(props) {
    return(
        <span>
            <Condition {...props.const} {...props.data}/>
        </span>
    )
}

Priority.propTypes = {
    const : PropTypes.shape({
            highLable : PropTypes.string
        }),
    priority : PropTypes.string,
}

export default Priority ;
export {Priority};