import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import LineChart from "../../components/lineGraph";
import CouponViewAll from "../../components/view_all";
import { 
  PendingTailorContainor, 
  MostOrderedFabricContainer,
  OrderComingFromLocationContainor,
  MostOrderedStylesContainor,
  HighLowFabricCostList,
  MeasurementType
} from "../../containers/dashboard";
import { PendingCoupon } from "../../containers/pendingCoupon";
import Header from "../../components/header";
import DiscountContainer from "../../containers/topDiscount";
import CustomersListContainer from "../../containers/top-customer";
import TailorContainer from "../../containers/tailorsList/tailor.container";
import Outstanding from "../../containers/dashboardOutStanding";
import { DashboardDataFatchAction, DashboardSalesTrendFatchAction } from "../../containers/dashboard/redux/dashboard.action";
import {
    lineGraph,
    coupon,
    complaint,
    img,
    headerDashboard,
    datePickerConst
  } from "../../constant";

function DefaultPath() {

  const dispatch = useDispatch();
  const userState = useSelector(state=>state.user);
  const user = userState.user;
  const dashboardResponseData = useSelector(state=>state.dashboardData);
  const salesTrendData = useSelector(state=>state?.salesTrendData?.salesTrend);
  const dashboardMeasurementData = dashboardResponseData?.measurementType;  
  const token = {
    'Authorization' : `${userState.token}`
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  let queryString = `?startDate=${startDate?.startDate}&endDate=${endDate?.endDate}`

  useEffect(()=>{
    if((startDate?.startDate !== undefined) && (endDate?.endDate !== undefined)){
      dispatch(DashboardDataFatchAction(token,queryString));
      dispatch(DashboardSalesTrendFatchAction(token,queryString));
    }
  },[queryString])

  return (
    <div class="content-wrapper">
     <Header
            title={headerDashboard.title}
            subTitle={headerDashboard.welcomeMessage + user?.full_name}
            buttons={[]}
            positionInterChange={true}
            {...datePickerConst}
            liftStateUpForHeader = {(e)=>{

              var obj = new Date(e.start);

              if(e.isStart){
                obj = new Date(e.start);
              }
              else{
                if(e.isStart === false){
                  obj = new Date(e.end);
                }
              }

              var date = obj.getDate();
              var month = obj.getMonth();
              var year = obj.getUTCFullYear();

              if(date<10){
                date="0"+date;
              }
              if(month<10){
                month="0"+month;
              }
              
              if(e.isStart){
                setStartDate({
                  startDate : year+"-"+month+"-"+date
                })
              }
              else{
                if(e.isStart === false){
                  setEndDate({
                    endDate : year+"-"+month+"-"+date
                  })
                }
              }
            }}
          /> 
       
    
    <section class="content mb-5">
      <div class="container-fluid">
      <div className="row">
        <div className="col-md-8">
          <div className="row row-flex dash-card">
            <div className="col-sm-6">
              <LineChart 
                chartData={salesTrendData} 
                {...lineGraph} 
              />
            </div>
            <div className="col-sm-6">
              <Outstanding
                start={startDate}
                end={endDate}
              />
            </div>
            <div className="col-sm-6">
              <MeasurementType
                start={startDate}
                end={endDate}
                dataObj={dashboardMeasurementData}
              />
            </div>
            <div className="col-md-6">
              <DiscountContainer
                start={startDate}
                end={endDate}
              />
            </div>
            <div className="col-md-6">
              <CustomersListContainer
                start={startDate}
                end={endDate}
              />
            </div>
            <div className="col-md-6">
              <TailorContainer
                  start={startDate} 
                  end={endDate} 
              />
            </div>
            <div className="col-md-6">
              <MostOrderedFabricContainer
                  start={startDate} 
                  end={endDate} 
                  array={dashboardResponseData?.fabrics}
              />
            </div>
            <div className="col-md-6">
              <HighLowFabricCostList
                  start={startDate} 
                  end={endDate} 
                  lowCostFabric= {dashboardResponseData?.low_cost_fabric}
                  highCostFabric= {dashboardResponseData?.high_cost_fabric}
              />
            </div>
            <div className="col-md-6">
              <OrderComingFromLocationContainor
                  start={startDate} 
                  end={endDate} 
                  array={dashboardResponseData?.order_coming_location}
              />
            </div>
            <div className="col-md-6">
              <MostOrderedStylesContainor
                  start={startDate} 
                  end={endDate} 
                  array={dashboardResponseData?.styles}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-sm-12">
              <PendingTailorContainor
                start={startDate}
                end={endDate}
                value={dashboardResponseData.tailor_pending}
               />
            </div>
            <div className="col-sm-12">
              <PendingCoupon
                start={startDate}
                end={endDate}
               />
            </div>
            <div className="col-sm-12">
              <CouponViewAll 
                {...complaint} 
                img={img.percentageImgURL} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    </div>  
  );
}

export default DefaultPath;