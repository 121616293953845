import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export function CheckboxSectionV1(props){

    var array = props.value;

    const OnClickFunction = (e)=>{
        if(e.target.checked){
            if(!array.includes(e.target.value)){
                array.push(e.target.value);
                props.onChangeListener(array);
            }
        }
        else{
            const index = array.indexOf(e.target.value);
            array.splice(index, 1);
            props.onChangeListener(array);
        }
    }
    return(
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">{props.heading}</h3>
            </div>
            <div className="card-body">
                <div className="row add-edit-checkbox">
                    {
                        props.rows?.map(( row,index )=>{
                        return ( 
                            <div key={index} className="col-12">
                                <input 
                                    className="filled-in" 
                                    name="group1" 
                                    type="checkbox" 
                                    onClick={OnClickFunction}
                                    id={"radio"+index}
                                    value={row?.name_en}
                                />
                                <label for={"radio"+index}>{ row?.name_en}</label>
                            </div>
                                // <div key={index} className={props.class1}  >
                                //     <input
                                //         className="filled-in" 
                                //         name="group1"
                                //         type="checkbox"
                                //         onClick={OnClickFunction}
                                //         value={row.id} 
                                //         id={"radio"+index}
                                //         checked={value==row.id ?true:""}
                                //     />
                                //     <label for={"radio"+index} class="mb-2">{ row?.name_en}</label>  
                                // </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

CheckboxSectionV1.propTypes = {
        heading : PropTypes.string,
        AddnewLabel : PropTypes.string,
        rows: PropTypes.array,
        class : PropTypes.string,
        onClickListner : PropTypes.func,       
}
CheckboxSectionV1.defaultProps = {
    rows: [],
    onClickListner: ()=>{}
}

export default CheckboxSectionV1;

