import React from 'react';
import PropTypes from "prop-types";

const Notification = (props) => {
    return (
        <div className="float-right mt-1" >
            <i className="fa fa-bell-o f-20"></i>
            <span className="badge badge-danger top-notification">{props.numberOfNotification ? props.numberOfNotification : ""}</span>
        </div>
    );
}
Notification.propTypes = {
    numberOfNotification : PropTypes.number
}
export default Notification;