import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {TailorPendingView} from "../../pages/tailor";
import { TailorDetail, TailorApprove,TailorUpdate } from "./redux/tailor.actions";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { urls } from "../../constants";

function TailorPendingViewContainor(props) {
    
    const history = useHistory();
    const data = queryString.parse(window.location.search);
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const TailerProfile = useSelector(state=>state.tailor?.tailerProfile);

    const token = {
        'Authorization' : `${userState.token}`
    };
    
    var temp =data.id;
    let enocodeQuery = Object.keys(data)
            .map((key) => data[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '/' + enocodeQuery : '';

    useEffect(()=>{
        dispatch(TailorDetail(token,enocodeQuery));
    },[temp])

    function editCommisionOrBillingCycle(id, data, callback) {
        dispatch(TailorApprove(id, data, token,callback));
    }

    function approve(id, data) {
        dispatch(TailorApprove(id, data, token,()=>{
            history.push(urls.TAILORS_PENDING);
        }));
    }

    let isEditPage;
    if(data.isEditPage){
        isEditPage= true;
    }
    else isEditPage= false;
    
    return(
        <TailorPendingView
            {...props}
            approve={approve}
            editCommisionOrBillingCycle={editCommisionOrBillingCycle}
            id= {data.id}
            isEditPage= {isEditPage}
            isTailorPage= {data.isTailorPage}
            {...TailerProfile}
            commOrCycle = {TailerProfile}
            history= {history}
        />
    )
}

export default TailorPendingViewContainor;
export {
    TailorPendingViewContainor
}