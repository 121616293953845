import {BaseService} from './base.service';
import {config} from '../environments';

class CustomerService extends BaseService{
    static get(headers,queryString){
        const url = super.prepareUrl(config.endpoints.customers,queryString);
        return  super.get(url,headers);
    }
    static put(id, data, headers) {
        const url = super.prepareUrl(config.endpoints.customers);
        return super.put(url + `/${id}`, data, headers);
    }
    static getMeasurement(headers,queryString){
        const url = super.prepareUrl(config.endpoints.measurement,queryString);
        return  super.get(url,headers);
    }
    static getCustomerOrders(headers,queryString){
        const url = super.prepareUrl(config.endpoints.order,queryString);
        return  super.get(url,headers);
    }
    static getCustomerReviews(headers,queryString){
        const url = super.prepareUrl(config.endpoints.review,queryString);
        return  super.get(url,headers);
    }
}

export default CustomerService;

export {CustomerService};
