import React from "react";
import { useTable } from 'react-table'
import PropTypes from "prop-types";
function ConditonButton(current_page,page_count,index){

    if(current_page == 1 && index<=current_page+1){
        return true;
    }
    else{
        if(current_page>1 && current_page-2==index || current_page-1==index || current_page==index ){
            return true;
        }
        else{
            if(page_count-1 == index ){
                return true;
            }
            else{
                return false;
            }
        }
    }
    
}
function ConditonDot(current_page, index){
    if(current_page == 1 &&  index>2 && index <6){
        return true;
    }
    else{
        if(current_page > 1 && index>current_page && index<current_page+4 ){
            return true;
        }
        else return false;
    }
}
function Pagination(props) {
    if(props.pageInfo) {
        return (
            <div className="pagination_rounded pr-4">
                <ul>
                    <li>
                        <a onClick={e => {props.pageInfo.current_page > 1 && props.toPage((props.pageInfo.current_page || 1) - 1 )}} className="prev"> <i className="fa fa-chevron-left" aria-hidden="true"></i> </a>
                    </li>
                    {
                        new Array(props.pageInfo.page_count || 1).fill(1).map((page, i) => {
                            if( ConditonButton(props.pageInfo.current_page,props.pageInfo.page_count,i) ){                          
                                return <li><a className={props.pageInfo.current_page ==i+1 ? "bg-info" : "" } onClick={e => props.toPage(i + 1)}>{i + 1}</a> </li>
                            }
                            else{
                                if(ConditonDot(props.pageInfo.current_page,i)){
                                    return <li><a>.</a></li>
                                }
                            }
                        }) 
                    }
                    <li>
                        <a onClick={e => { props.pageInfo.current_page < props.pageInfo.page_count && props.toPage((props.pageInfo.current_page) + 1 )}} className="next"> <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                    </li>
                </ul>
            </div>
        ) 
    } else return <></>
}
function Table(props) {

    const data = React.useMemo(props.tableData);

    const columns = React.useMemo(
        () => props.tableHeader,
    )
    const tableInstance = useTable({ columns, data });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = tableInstance
    return(
        <section className="content mb-5">
            <div className="container-fluid">
                <div className="row">
                    <section className="col-lg-12 connectedSortable">
                        <div className="card">
                            <div className="card-header border-0 pt-4">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3 className="card-title">{props.totalResultsLabel + props.ObjectLength}<span className="pl-4">{props.pedingOrderValue ? props.pendingOrdersLabel + props.pedingOrderValue:"" }</span></h3>
                                    </div>
                                    { !props.noSerach &&
                                        <div className="col-md-4">
                                            <div className="input-group custom-search">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="fa fa-search"></i></span>
                                                </div>
                                                <input type="text" onChange={(e) => props.onSearch(e.target.value)} className="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1"/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="card-body pl-0 pr-0">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered admintable border-0" {...getTableProps()} cellspacing="0" cellpadding="0">
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column,i) => (
                                                <th key={i}
                                                    {...column.getHeaderProps()}
                                                >
                                                    {column.render('Header')}
                                                </th>
                                                ))}
                                            </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {rows.map(row => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                {row.cells.map((cell,i) => {
                                                    return (
                                                    <td key={i}
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                    )
                                                })}
                                                </tr>
                                            )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination pageInfo={props.pageInfo} toPage={props.toPage}/>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
}
Table.propTypes = {
    onSearch: PropTypes.func,
    tableData : PropTypes.func,
    tableHeader : PropTypes.shape([
        {
            Header: PropTypes.string,
            accessor: PropTypes.string,
        },
    ]),
    ObjectLength: PropTypes.number,
    totalResultsLabel: PropTypes.string,
    pageInfo: PropTypes.object,
    toPage: PropTypes.func
}
Table.defaultProps = {
    ObjectLength: 0,
    tableHeader : [],
    tableData: ()=>[],
    onSearch : ()=>{}
}
export default Table;