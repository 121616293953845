import React from "react";
import Header from "../../components/header";
import {
  img,
  changePassword,
  notification,
  notificationFullComp,
  styleCheckboxData,
  imageUpload,
} from "../../constant";
import Input from "../../components/input";
import TextArea from "../../components/textArea";
import SelectSection from "../../components/SelectSection";
import CatogeryCheckBox from "../../components/CheckboxSection";
import ImageUpload from "../../components/imageUpload";
import { urls } from "../../constants";

function AddNewVariable({ onChange, data, onSubmitHandler, tailorList }) {
  return (
    <div class="content-wrapper">
      <Header
        title="Variable Category"
        subTitle="Add New"
        submitHandler={onSubmitHandler}
        buttons={[
          {
            name: "Back",
            url: urls.VARIABLES,
            isSymbolPresent: false,
          },
          {
            name: "Publish",
            url: "#publish",
            isSymbolPresent: false,
          },
        ]}
      />
      <section class="content mb-5">
        <div class="container-fluid">
          <div className="row">
            <div className="col-sm-9">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div class="col-lg-12">
                      <Input
                        onChangeListener={(val) => onChange({ name_en: val })}
                        placeholder="Name of Variable Category (EN)"
                        id="name_en"
                        value={data.name_en}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div class="col-lg-12">
                      <Input
                        onChangeListener={(val) => onChange({ name_ar: val })}
                        placeholder="Name of Variable Category (AR)"
                        id="name_ar"
                        value={data.name_ar}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-6  ">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Multiply</h3>
                    </div>
                    <div class="card-body">
                      <div class="row add-edit-checkbox">
                        <div class="col-lg-12">
                          <Input
                            value={data.multiple}
                            onChangeListener={(val) =>
                              onChange({ multiple: val })
                            }
                            placeholder={"Multiply"}
                            type={"checkbox"}
                            id="multiple"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6  ">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Quantity</h3>
                    </div>
                    <div class="card-body">
                      <div class="row add-edit-checkbox">
                        <div class="col-lg-12">
                          <Input
                            id="quantity"
                            type={"checkbox"}
                            placeholder={"Quantity"}
                            value={data.quantity}
                            onChangeListener={(val) =>
                              onChange({ quantity: val })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
              </div>
            </div>
            <div className="col-sm-3">
              <SelectSection
                onChangeListener={(val) => onChange({ user_id: val })}
                heading="Tailor"
                options={tailorList}
                value={data.user_id}
              />
              <SelectSection
                onChangeListener={(val) => onChange({ status: val })}
                heading="Status"
                options={["active", "inactive"]}
                value={data.status}
              />
              <SelectSection
                onChangeListener={(val) => onChange({ gender: val })}
                heading="Gender"
                options={["Men", "Women"]}
                value={data.gender}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AddNewVariable;
export { AddNewVariable };
