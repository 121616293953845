import React from 'react';
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';

const OrderInfo = (props) => {

    return (
        <>
                <div className="card">

                    <div className="card-header">
                        <h3 className="card-title">{props.title}</h3>
                    </div>

                    <div className="card-body">
                        
                        <h6>{props.name}</h6>
                        <p className="mb-0">{props.genderType}</p>
                        <p className="mb-0">{props.address}</p>                                            
                        
                        <div className="userAvatar">
                            <img src={props.profileImage} alt="profile Image" />
                        </div>

                        {props.children}

                    </div>
                </div>
        </>
    )
}

OrderInfo.propTypes = {
    title : PropTypes.string,
    name : PropTypes.string,
    genderType : PropTypes.string,
    address : PropTypes.string,
    placedOrderValue : PropTypes.number,
    labelOrders : PropTypes.string,
    labelPlaced : PropTypes.string,
    sizeSavedValue : PropTypes.number,
    labelSizes : PropTypes.string,
    labelSaved : PropTypes.string,
    profileImage : PropTypes.string
}
export default OrderInfo;
