import { TopCustomerService } from "../../../services";
import { createAction } from "redux-actions";
import { appCustomerListSuccess, appCustomerListError } from "./customer.actionNames";

const topCustomerListFatchSuccess = createAction(appCustomerListSuccess,(data)=>data);
const topCustomerListFatchError = createAction(appCustomerListError,(ErrorData)=>ErrorData);

function customer(token,queryString) {
    return(dispatch)=>{
        TopCustomerService.get(token,queryString).then((resp)=>{
            dispatch(topCustomerListFatchSuccess(resp.data.data))
           }).catch((err)=>{
               console.log(err)
           })
    }
}
export {
    customer,
    topCustomerListFatchSuccess,
    topCustomerListFatchError
}