import React from "react";
import NotificationAbc from "../../components/notificationFullComp";
import Notification from "../../components/notification";
import ChangePassword from "../../components/ChangePassword";
import Header from "../../components/header";
import ComplaintTile from "../../components/complaintTile";
import {img,changePassword,notification,notificationFullComp, complaintTile, activeDeactiveSelection, orderInfoTile } from '../../constant';
import Editor from "../../components/editor";
import ActiveDeactiveSelection from "../../components/statusActiveDeactiveSection";
import OrderInfo from "../../components/orderInfoTile";
import { urls } from "../../constants";
import queryString from "query-string";

function ComplaintsView(props) {
    const queryObject = queryString.parse(window.location.search);
    return(
        <>    
            <div  class="content-wrapper">
                {/* <NotificationAbc {...notificationFullComp} changePasswordPopUp={<ChangePassword {...changePassword} />} {...img} notification={<Notification {...notification} />}/> */}

                <Header 
                    title="Complaint"
                    subTitle = "203984023984"
                    buttons = {[
                        {
                            name : "BACK",
                            url : urls.CUSTOMER_COMPLAINTS+urls.QUERY_STRING_KEY_CUSTOMERiD+queryObject.customer_id,
                            isSymbolPresent : false,
                            type: "link"
                          },
                          {
                              name : "UPDATE",
                              url : urls.CUSTOMER_COMPLAINTS,
                              isSymbolPresent : false,
                              type: "button"
                            }
                    ]}
                />
                <section class="content mb-5">
        <div class="container-fluid">
                <div className="row">
                    <div className="col-sm-9">
                            <ComplaintTile {...complaintTile} />
                            <div className="row">
                        <div className="col-sm-7">
                            <ComplaintTile {...complaintTile} sendByUser={false} />
                        </div>
                        <div className="col-sm-7 offset-md-5">
                            <ComplaintTile {...complaintTile} />
                        </div>
                        <div className="col-sm-12">
                            <Editor />
                        </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <ActiveDeactiveSelection objData={["active","deactive"]} {...activeDeactiveSelection} />
                        <ActiveDeactiveSelection objData={["active","deactive"]} {...activeDeactiveSelection} />
                        <OrderInfo {...orderInfoTile} {...img} />
                    </div>
                </div>
            </div>
            </section></div>
        </>
    )
}

export default ComplaintsView;
export {
    ComplaintsView
};