import axios from "axios";
class HttpClient{

    static post(url,data,token){
        if(!url && !data){
            throw Error("url or data is not defined");
        }
        return axios.post(url,data,{headers:token});
    }
    static put(url,data,token){
        if(!url && !data){
            throw Error("url or data is not defined");
        }
        return axios.put(url,data,{headers:token});
    }
    static get(url,token){
        if(!url){
            throw Error("url is not defined");
        }
        return axios.get(url,{headers:token});
    }
    static delete(url,token){
        if(!url){
            throw Error("url is not defined");
        }
        return axios.delete(url,{headers:token});
    }
}


export default HttpClient;
export {HttpClient};