import React , { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Fabrics as FabricsPage} from "../../pages/fabrics";
import { fabricTable, fabricTableFatchSuccess, fabricDelete, EditFabric, distinctFabrics } from "./redux/fabricTable.actions";

function FabricTableContainer(props) {
    
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const fabric = useSelector(state=>state.fabric);
    const rows = fabric?.data?.rows;
    const pageInfo = fabric?.data?.pageInfo;
    const [distinctFabricList, setDistinctFabric] = useState([])
    
    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        getDistinctFabric();
        dispatch(
            fabricTableFatchSuccess({
            ...fabric,
            search: "",
          })
        );
    },0)

    useEffect(()=>{
        toPage(1);
    }, [fabric.search]);

    useEffect(() => {
        if(!distinctFabricList?.length) return;
        onSearch(distinctFabricList[0].name);
    }, [distinctFabricList])

    function toPage(page, limit) {
        let search = fabric.search;

        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.title = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';
        dispatch(fabricTable(token, enocodeQuery));
    }
    function onSearch(str) {
        dispatch(
            fabricTableFatchSuccess({
            ...fabric,
            search: str,
          })
        );
    }
    function deleteListener(id) {
        dispatch( fabricDelete(id, token, toPage, pageInfo) );
        console.log("done");
    }
    function onStatusChange(id,status) {
        dispatch(EditFabric(id, {
            status: status ? 'active' : 'inactive'
        },token, toPage, pageInfo));
    }
    
    function getDistinctFabric() {
        dispatch(distinctFabrics(token, setDistinctFabric));
      }
    return <FabricsPage 
                distinctFabricList={distinctFabricList}
                serachField={fabric.search}
                toPage={toPage} 
                onSearch={onSearch} 
                rows={rows} 
                pageInfo={ pageInfo }
                deleteListener={deleteListener}
                onStatusChange={onStatusChange}
            />
}

export default FabricTableContainer;
export {FabricTableContainer};