import React, { useEffect } from "react";
import { PayoutPage } from "../../pages/payout";
import {useDispatch, useSelector} from 'react-redux';
import { payoutFatchAction, payoutFatchSeccess } from "./redux/payout.actions";

function PayoutContainer(props) {
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const payout = useSelector(state=>state.payoutList);
    var rows = payout.rows;
    var pageInfo = payout.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        toPage(1);
    }, [payout.search]);

    function toPage(page, limit) {
        let search = payout.search;

        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.name = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';
        
        dispatch(payoutFatchAction(token, enocodeQuery));
    }
    function onSearch(str) {
        dispatch(
            payoutFatchSeccess({
            ...payout,
            search: str,
          })
        );
    }
    return(
        <PayoutPage
            toPage={toPage} 
            onSearch={onSearch} 
            pageInfo={pageInfo}
            rows={rows}
        />
    )
}

export default PayoutContainer;
export{
    PayoutContainer
}