import React from "react";
import { lineGraph, img, barChart, ratingAndInfo, tableHeaderDiscount, DiscountTableHeaderforCustomers } from "../../constant";
import Header from "../../components/header";
import LineChart from "../../components/lineGraph";
import BarChart from '../../components/barChart/index';
import RatingAndInfo from "../../components/ratingAndInfo";
import { DiscountCouponsColumsForList, DiscountColumsForCustomersList } from "../../components/table/columnsForTable";
import Table from "../../components/table";
import { urls } from "../../constants";
import { currency } from "../../constant1";
import Input from "../../components/input";
import TextArea from "../../components/textArea";

function PendingDiscountCouponView(props) {
    props.headerConst.buttons[1].onClickListener= props.discountApproved;
    const tailorMoreInfo = props.tailor?.tailor_more_infos ? props.tailor.tailor_more_infos[0]:{};
    return(
        <div  class="content-wrapper">
            <div className="row">
            <div className="col-sm-12">
                <Header 
                    {...props.headerConst}
                />
            </div>
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-8">
                        <div class="row">
                        <div class="col-sm-4">
                                <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">{props.bodyConst.brandDetailLabel}</h3>
                                </div>
                                <div class="card-body">
                                    <div><img alt="brand image" src={props.brand?.image_url_en} /></div>
                                    <p className="text-center f-18 mt-3 mb-0"><strong>{props.brand?.name_en}</strong></p>
                                </div>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-label-group">
                                        <Input 
                                            placeholder={props.bodyConst.couponForLabel}
                                            id="Couponfor"
                                            type="text"
                                            value= {props.brand_id === null ? "Style": "Brand"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-label-group">
                                        <Input 
                                            placeholder={props.bodyConst.couponTypeLabel}
                                            id="Coupontype"
                                            type="text"
                                            value = {props.discount_flat === null ? props.bodyConst.percentageLabel: props.bodyConst.valueLabel}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-label-group">
                                        <Input 
                                            placeholder= {props.bodyConst.discountPercentLabel}
                                            id="DiscountPercent"
                                            type="text"
                                            value = {props.discount_flat === null ? props.discount_percent +props.bodyConst.percentageSign: props.discount_flat+currency.currencyType}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-label-group">
                                        <Input 
                                            placeholder= {props.bodyConst.validFromLabel}
                                            id="validfrom"
                                            type="text"
                                            value={props.valid_from ? new Date(props.valid_from).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' }):"N-A"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-label-group">
                                        <Input 
                                            placeholder= {props.bodyConst.validToLabel}
                                            id="ValidTo"
                                            type="text"
                                            value={new Date(props.valid_till).toLocaleString('en-US',{  year: 'numeric', month: 'short', day: 'numeric' })}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-label-group">
                                        <TextArea
                                            placeholder= {props.bodyConst.couponDescriptionLabel}
                                            disabled={true}
                                            rows={6}
                                            id="CouponDescription"
                                            value={props.descriptions_en}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            </div>
                    </div> 
                    <div className="col-sm-4">
                        <RatingAndInfo 
                            {...props.bodyConst.userInfo}
                            img= {props.tailor?.profile_picture}
                            fullName= {props.tailor?.full_name}
                            AVGtime= {tailorMoreInfo?.avg_time}
                            totalnumberOfReviews= {props.tailor_reviews_count}
                            rating= {tailorMoreInfo?.rating}
                        />
                    </div>
                </div>
            </div>
             
            
            </div>
        </div>
    )
}
export default PendingDiscountCouponView;
export { PendingDiscountCouponView };