import React, {useState,useEffect} from "react";
import PropTypes from "prop-types";
import { TaxEditPage } from "../../pages/tax";
import { taxEdit, taxByIdAction } from "./redux/tax.actions";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import { urls } from "../../constants";
import queryString from "query-string";

function TaxEdit_containor(params) {

    const queryObj = queryString.parse(window.location.search);
    const history = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const taxesData = useSelector(state=>state.taxDetail);
    const [form, setForm] = useState({...taxesData});

    const onChangeListener = (key, value)=>{
        setForm({...form,[key]:value});
    }
    const token = { 
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        dispatch(taxByIdAction(token,"/"+queryObj.id));
    },[queryObj.id])

    const submitHandler = ()=>{
        dispatch( taxEdit( queryObj.id, form, token ) );
        history.push(urls.TAXES);
    }
    return(
        <TaxEditPage
            submitHandler={submitHandler}
            onChangeListener={onChangeListener}
            {...form}
        />
    )
}

TaxEdit_containor.propTypes = {

}

export default TaxEdit_containor;
export{
    TaxEdit_containor
}