import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../components/input';
import TextArea from '../../components/textArea';
import SelectSection from '../../components/SelectSection'
import Header from "../../components/header";
import { editBrand, detailBrand } from "../../constants";
import ImageUpload from "../../components/imageUpload";
import { imageUpload } from "../../constant";
import { FabricTypeCheckboxData, gender, statusArray } from "../../constant1";
import { DropDown } from "../../components/selectDropdown";
import { urls } from "../../constants";

function EditBrand(props) {
    editBrand.buttons[1].onClickListener = props.submitHandler;
    let header;
    if(props.disabled){
        editBrand.buttons[0].url= urls.BRAND_DETAIL+urls.QUERY_STRING_KEY_ID+props.id;
        detailBrand.buttons[1].url= urls.BRAND_EDIT+urls.QUERY_STRING_KEY_ID+props.id;
        header = detailBrand;
    }
    else {
        editBrand.buttons[0].url= urls.BRANDS;
        header= editBrand;
    };
    return (
      <div className="wrapper">    
        <div  className="content-wrapper">
            <Header 
              {...header }
            />    
        <section className="content mb-5">
        <div className="container-fluid">
            <div className='row'>
                <div className="col-sm-8 connectedSortable">
                    <div className="row">
                        <div className="col-sm-12">
                            <Input 
                                disabled={props.disabled}
                                value={props.name_en}
                                placeholder="Title of the brand" 
                                id="stitle1" 
                                defaultValue="" 
                                onChangeListener={(value)=>{
                                    props.onChangeListener('name_en',value);
                                }}
                                />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <DropDown
                                disabled={props.disabled}
                                value={props.fabric_type_id} 
                                {...FabricTypeCheckboxData} 
                                options={props.fabricTypesData}  
                                onChangeListener={(value)=>{
                                    props.onChangeListener('fabric_type_id',value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <TextArea
                                disabled={props.disabled}
                                value={props.descriptions_en} 
                                rows="10" 
                                placeholder="Brief description of the brand"
                                onChangeListener={(value)=>{
                                    props.onChangeListener('descriptions_en',value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 connectedSortable">
                <SelectSection 
                    disabled={props.disabled}
                    value={props.status} 
                    heading="Status" 
                    options={statusArray} 
                    onChangeListener={(value)=>{
                        props.onChangeListener('status',value);
                    }}
                />
                <SelectSection 
                    disabled={props.disabled}
                    value={props.gender}
                    heading="Gender" 
                    options={gender} 
                    onChangeListener={(value)=>{
                        props.onChangeListener('gender',value);
                    }}
                />      
                <ImageUpload
                    disabled={props.disabled}
                    image= {props.image_url_en}
                    {...imageUpload}
                    onChangeListener={(e) => {
                        props.onUploadImage(e, (url) => {
                            props.onChangeListener('image_url_en',url);
                        //   props.onChangeListener({
                        //     image_url_en: url,
                        //     image_url_ar: url,
                        //   });
                        });
                    }} 
                />
                </div>
            </div>
          </div>
          </section></div>
        </div>
    )
}

EditBrand.propTypes = {
  id:PropTypes.string,
  dataForEdit: PropTypes.object,
  submitHandler: PropTypes.func,
  setTitle: PropTypes.func,
  setDescription: PropTypes.func,
  setStatus: PropTypes.func
}

export default EditBrand

