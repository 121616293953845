import { handleActions } from "redux-actions";
import { 
    taxesFatchSeccess,
    taxesFatchError,
    taxFatchByIdSeccess,
    taxFatchByIdError
} from "./tax.actions";

const defaultState = {};

const reducer = handleActions(
    {
        [taxesFatchSeccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [taxesFatchError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
const reducerGetById = handleActions(
    {
        [taxFatchByIdSeccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [taxFatchByIdError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
export { reducer, reducerGetById };