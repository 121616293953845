import {config} from '../environments';
import {BaseService} from './base.service';


class TopTailor extends BaseService{
    static get(headers,queryString){
        const url = super.prepareUrl(config.endpoints.topTailors,queryString);
        return super.get(url,headers);
    }
    static post(data,headers){
        const url = super.prepareUrl(config.endpoints.topTailors);
        return super.post(url,data,headers);
    }
}

export default TopTailor;

export {TopTailor};
