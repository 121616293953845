import PropTypes from 'prop-types';
import React from 'react';

function ActiveDeactiveSelection(props) {
    return(
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">{props.statusLabel}</h3>
            </div>

            <div className="card-body">
                <div className="form-label-group">
                    <select className="form-control" id="type" required="" value={props.value} disabled={props.disable ? true : false}>
                        {
                            (props.objData).map((row)=>
                               <option>{row}</option> 
                            )
                        }
                    </select>
                </div>
            </div>
        </div>
    )
}

ActiveDeactiveSelection.propTypes = {
    option1Label : PropTypes.string,
    option2Label : PropTypes.string,
    statusLabel : PropTypes.string,
    selectStatusLabel : PropTypes.string,
    objData: PropTypes.object
}
ActiveDeactiveSelection.defaultProps = {
    objData: []
}
export default ActiveDeactiveSelection;