import { handleActions } from "redux-actions";
import { topCustomerListFatchSuccess, topCustomerListFatchError} from "./customer.actions";

const defaultState = {};

const reducer = handleActions(
    {
        [topCustomerListFatchSuccess] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        },
        [topCustomerListFatchError] : (state, action) => {
            return Object.assign({}, { ...state }, { ...action.payload }); 
        }
    },
    defaultState
)
export { reducer };