import React, {useState, useEffect}  from 'react';
import { img,circleChart,lineGraph,ratingAndInfo, tableHeaderTotelDiscount,globalPropsTotalDiscountCoupons} from "../../constant";
import Header from "../../components/header";
import RatingAndInfo from "../../components/ratingAndInfo";
import Input from "../../components/input";
import CardList from "../../components/cardList/index";
import LineGraph from "../../components/lineGraph";
import CircleGraph from "../../components/circleChart";
import Table from "../../components/table";
import { TopDiscountColums } from "../../components/table/columnsForTable";
import { 
    tailorProfile
 } from "../../constants/index";
import { urls } from "../../constants";
import { objectEach } from 'highcharts';
import { DropDown } from "../../components/selectDropdown";

function TailorPendingView(props) {

    let tailor_more_infos =props.commOrCycle?.tailor_more_infos ? props.tailor_more_infos[0] : {}; 

    const [brand, setBrand] = useState();
    const [isEdit, setIsEdit] = useState(!props.isEditPage);
    const [form, setForm]= useState();

    const onChangeListener = (key,value)=>{
        setForm({
            ...form,[key]:value
        })
    }
    useEffect(() => {
        setForm({
            commission: tailor_more_infos.commission,
            billing_cycle: tailor_more_infos.billing_cycle
        })
    }, [tailor_more_infos])

    useEffect(() => {
        props?.brands && setBrand(props?.brands[0]?.id);
    }, [props?.brands])

    var headerButtons;
    if(props.isTailorPage){
        headerButtons=[
            {name:"Approve", onClickListener: (e)=>{ props?.approve(props.id, {
                status: "active"
            })}, type: "button"},
            {name:"Back",url: urls.TAILOR_DETAILS,type: 'link'},
            {name:"Edit", onClickListener: ()=>{setIsEdit(!isEdit)}, type: "button"},
        ]
    }
    else{
        if(props.isEditPage===true && isEdit==false){
            headerButtons=[
                {name:"Cancel",onClickListener: ()=>{props.history.push(urls.TAILORS_PENDING)}, type: "button"},
                {name:"Update",onClickListener: ()=>{props?.editCommisionOrBillingCycle(props.id,form,()=>props.history.push(urls.TAILORS_PENDING))},type: 'button'},
            ]
        }
        else{
            if(props.isEditPage === false && isEdit===true){
                headerButtons=[
                    {name:"Approve", onClickListener: (e)=>{ props?.approve(props.id, {
                        status: "active"
                    })}, type: "button"},
                    {name:"Back",url: urls.TAILORS_PENDING,type: 'link'},
                    {name:"Edit", onClickListener: ()=>{setIsEdit(!isEdit)}, type: "button"},
                ]
            }
            else{
                if(props.isEditPage === false && isEdit===false){
                    headerButtons=[
                        {name:"Cancel",onClickListener: ()=>{setIsEdit(!isEdit)}, type: "button"},
                        {name:"Update",onClickListener: ()=>{props?.editCommisionOrBillingCycle(props.id,form);setIsEdit(!isEdit)},type: 'button'},
                    ]
                }
            }
        }
    }
    return(
        <div  class="content-wrapper">
            <Header
                {...tailorProfile}
                buttons={headerButtons}
            />
            <section class="content">
            <div class="container-fluid">
                <div className="row">
                    <div className="col-4">
                        <RatingAndInfo 
                            {...ratingAndInfo} 
                            img={props.profile_picture}
                            fullName = {props.full_name}
                        />
                    </div>
                    <div className="col-2">
                        <Input 
                            placeholder="Commision" 
                            type="number" 
                            id="commision"
                            value={form?.commission}
                            disabled={isEdit}
                            onChangeListener={(value)=>{
                                onChangeListener("commission",parseInt(value));
                            }}
                        />
                    </div>
                    <div className="col-2">
                        <DropDown 
                            disabled={isEdit}
                            value={form?.billing_cycle}
                            heading="Frequency"
                            options={[
                                {id:"7",name_en:"Weekly"},
                                {id:"14",name_en:"Fortnightly"},
                                {id:"30",name_en:"Monthly"}
                            ]}
                            onChangeListener={(value)=>{
                                onChangeListener("billing_cycle",parseInt(value));
                            }}
                        />
                    </div>
                </div>

                <CardList title="Portfolio" list={props.tailor_portfolios} />

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header"><h3 class="card-title float-left f-20">Fabrics</h3></div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="nav nav-tabs complaintTab">
                                            {
                                                props.fabric_types?.map((fabricTypeObj, i) => {
                                                    return (
                                                        <li class="nav-item">
                                                            <a class={"nav-link" + (i == 0 ? " active" : "" ) } 
                                                                data-toggle="tab" 
                                                                href={"#fabricType_"+fabricTypeObj.id}>
                                                                {fabricTypeObj?.name_en}
                                                            </a>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>


                                <div class="tab-content">
                                    {
                                        props.fabric_types?.map((fabricTypeObj, i) => {
                                            return (
                                                <div class={"tab-pane" + (i == 0 ? " active" : "")} id={"fabricType_"+fabricTypeObj.id}>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <CardList 
                                                                title="Brands" 
                                                                radio={true} value={brand} 
                                                                list={props.brands?.filter(brandObj => brandObj.fabric_type_id == fabricTypeObj.id)} 
                                                                onChangeHandler={val => {
                                                                    setBrand(val)
                                                                }}
                                                            />
                                                            <CardList 
                                                                title="Fabrics" 
                                                                list={props.fabrics?.filter(fabricObj => fabricObj.fabric_type_id == fabricTypeObj.id && fabricObj.brand_id == brand)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>







                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                        <div class="card-header"><h3 class="card-title float-left f-20">Styles</h3></div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="nav nav-tabs complaintTab">
                                            {   props?.styles?.map((styleObj, i) => {
                                                    return (
                                                        <li className="nav-item">
                                                            <a className={"nav-link" + (i==0 ? " active" : "")} data-toggle="tab" href={"#style" + styleObj?.id}>
                                                                {styleObj?.name_en}
                                                            </a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div> 
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tab-content">
                                            {
                                                props?.styles?.map((styleObj, i) => {
                                                    return (
                                                        <div className={"tab-pane" + (i == 0 ? " active" : "" )} id={"style" + styleObj?.id}>
                                                            <div className="row">
                                                                <div className="col-3"> 
                                                                    <div class="card">
                                                                        <div class="card-header">
                                                                            <h3 class="card-title float-left">{styleObj?.name_en}</h3>
                                                                        </div>
                                                                        <div class="card-body">
                                                                            <div class="bespkokestailor">
                                                                                <img src={styleObj?.image_url_en}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card">
                                                                        <div class="card-header">
                                                                            <h3 class="card-title float-left">Gender</h3>
                                                                        </div>
                                                                        <div class="card-body">
                                                                            {styleObj?.gender}
                                                                        </div>
                                                                    </div>
                                                                    <div class="card">
                                                                        <div class="card-header">
                                                                            <h3 class="card-title float-left">Cost</h3>
                                                                        </div>
                                                                        <div class="card-body">
                                                                            {styleObj?.cost}
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                                <div className="col-9">
                                                                    {(styleObj.gender == "Men" 
                                                                        ? props.variable_category_men 
                                                                        : props.variable_category_women)?.map((variableCategoryObj) => {
                                                                            return ( 
                                                                                <CardList 
                                                                                    title={variableCategoryObj?.name_en} 
                                                                                    list={styleObj.variables?.filter((Obj => variableCategoryObj.id == Obj.variable_category_id))} 
                                                                                />
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            </section>
    </  div>
    )
}

export default TailorPendingView;
export { TailorPendingView }
// tableHeaderTotelOrders