function ChartData(chartData=[]) {
    console.log(chartData);
    let arrayMonth=[];
    let arrayCustomerCount=[];
    let arraySalesAmount=[];

    chartData.map((row)=>{
        arrayMonth.push(row.month);
        arrayCustomerCount.push(row.customer_count);
        arraySalesAmount.push(row.sales_amount);
    }) 
    
    var Options = {
        chart: {
            type: 'line'
        },
        title: {
            text: ''
        },
      credits: {
            enabled: false
        },
       exporting: {
        enabled: false
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: arrayMonth
        },
        yAxis: {
            title: {
                text: ''
            }
        },
      
      legend: { 
        enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
        },
        series: [{
            name: 'Vipin',
            data: arraySalesAmount,
        color: '#f98436'
        }, {
            name: 'Mavi',
            data: arrayCustomerCount,
        color: '#968df3'
        }]
    }
    return Options;
}
// const Options = {
//     chart: {
//         type: 'line'
//     },
//     title: {
//         text: ''
//     },
//   credits: {
//         enabled: false
//     },
//    exporting: {
//     enabled: false
//     },
//     subtitle: {
//         text: ''
//     },
//     xAxis: {
//         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
//     },
//     yAxis: {
//         title: {
//             text: ''
//         }
//     },
  
//   legend: { 
//     enabled: false
//     },
//     plotOptions: {
//         line: {
//             dataLabels: {
//                 enabled: true
//             },
//             enableMouseTracking: false
//         }
//     },
//     series: [{
//         name: '',
//         data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
//     color: '#f98436'
//     }, {
//         name: '',
//         data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
//     color: '#968df3'
//     }]
// }

export{
    ChartData
};