import React , { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import BrandTable from "../../pages/brands";
import { brandTable, brandSearch, brandDelete, editBrand } from "./redux/brandTable.actions";
import { useHistory } from "react-router-dom";
import { urls } from "../../constants";

function BrandTableContainer(props) {

    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const brandState = useSelector(state=>state.brands);
    const rows = brandState.rows;
    const pageInfo = brandState.pageInfo;

    const token = {
        'Authorization' : `${userState.token}`
    };

    useEffect(()=>{
        dispatch(
            brandSearch({
            ...brandState,
            search: "",
          })
        );
    },0)
    useEffect(()=>{
        toPage(1);
    }, [brandState.search]);

    function toPage(page, limit) {
        
        let search = brandState.search;
        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.name = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';

        dispatch(brandTable(token, enocodeQuery));
    }

    function onSearch(str) {
        dispatch(
            brandSearch({
            ...brandState,
            search: str,
          })
        );
    }

    function deleteListener(id) {
        dispatch( brandDelete(id, token, toPage, pageInfo) );
    }

    function onStatusChange(id,status) {
        dispatch(editBrand(id, {
            status: status ? 'active' : 'inactive'
        },token, toPage, pageInfo));
    }
    return <BrandTable 
                urls= {urls}
                rows={rows} 
                pageInfo={pageInfo}
                onSearch={onSearch} 
                toPage={toPage} 
                deleteListener={deleteListener}
                onStatusChange={onStatusChange}
            />
}

export default BrandTableContainer;
export {BrandTableContainer};