import React,{ useEffect}  from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Size} from "../../pages/customers";
import { memberMeasurement } from "./redux/customer.action";
import queryString from "query-string";

function CustomersSize(props) {

    const querydata = queryString.parse(window.location.search);
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const memberSize = useSelector(state=>state.memberSize.data);
    const rows = memberSize?.rows;
    const pageInfo = memberSize?.pageInfo;

    const temp =0 ;
    
    const token = {
        'Authorization' : `${userState.token}`
    };
    const queryStr = `?family_member_id=${querydata.id}`;

    useEffect(()=>{ 
        dispatch(memberMeasurement(token,queryStr));
    },[temp])

    return(
        <Size 
            rows={rows}
            pageInfo={pageInfo}
            id={querydata.id}
            relation= {querydata.relation}
            customersId= {querydata.customer_id}
        />
    )
}

export default CustomersSize
export {
    CustomersSize
}