import PropTypes from "prop-types";
import React  from 'react';

function Row(props){
  const array = props.array;
  return array.map((row,index)=>{
      return(
        
          <div key={index} className="row dashtrend"> 
            <div className="col-md-7 col-7">
                <div className="float-left">
                    <h6 className="text-uppercase text-blue">{row[props.key1]}</h6>
                    <h4>{row[props.key3]}{props.offPersantage!== undefined ? props.offPersantage : ""}</h4>
                </div>
            </div>
            <div className="col-md-5 col-5">
                <img alt="Dashboard Section" src={props.img} className="float-left mt-2 mr-2" />
                <div className="float-left">
                    <h4>{row[props.key2]}{props.currencyType!== undefined ? props.currencyType : ""}</h4>
                    <p className="text-muted mb-0">{row[props.key4]}{props.persantageSign!== undefined ? props.persantageSign : ""}</p>
                </div>
            </div>
          </div>
        
      )
  })
}
function List(props){
  return(
    <section className=" connectedSortable">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{props.title}</h3>
        </div>
        <div  className="card-body pb-0">
        <Row array={props.array} img={props.img} {...props}/>
        </div>
      </div>
    </section>
  )
}

List.propTypes = {
  title: PropTypes.string,
  array: PropTypes.array,
  img: PropTypes.string
}

List.defaultProps = {
  array: []
}

export default List;