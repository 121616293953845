import {StyleService, CategoryService} from '../../../services';
import {createAction} from 'redux-actions';
import {appStylesFatchSuccess, appStylesFatchError } from './style.actionNames';

const stylesFatchSuccess = createAction(appStylesFatchSuccess,(StylesData)=>StylesData);
const stylesFatchError = createAction(appStylesFatchError,(StylesDataErr)=>StylesDataErr);

function StyleAction(token,enocodeQuery){
    return (dispatch)=>{
        StyleService.get(token,enocodeQuery).then((resp)=>{
             dispatch(stylesFatchSuccess(resp.data.data))
            }).catch((err)=>{
                console.log(err)
            })
    }
}
function styleDelete(id, token) {
    return (dispatch) => {
        StyleService.delete(id, token).then((resp) => {
            dispatch(StyleAction(token));
        }).catch((error) => {
            console.log(error);
        })
    }
}
function StyleInfo(id, token, callBack){
    return (dispatch)=>{
        StyleService.info(id, token).then((resp)=>{
             callBack(resp.data.data);
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function getCategory(token, enocodeQuery, callBack) {
    return (dispatch)=>{
        CategoryService.get(token, enocodeQuery).then((resp)=>{
             callBack(resp.data.data);
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function addStyle(data, token, callBack) {
    return (dispatch)=>{
        StyleService.post(data, token).then((resp)=>{
             callBack(resp.data.data);
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function updateStyle(id, data, token, callBack) {
    return (dispatch)=>{
        StyleService.put(id, data, token).then((resp)=>{
             callBack(resp.data.data);
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function distinctStyle(token, callBack) {
    return (dispatch)=>{
        StyleService.distinct(token).then((resp)=>{
             callBack(resp.data.data);
            }).catch((err)=>{
                console.log(err)
            })
    }
}

function actionSearch(style) {
    return stylesFatchSuccess(style);
}
export {distinctStyle, StyleAction, addStyle, updateStyle , getCategory ,StyleInfo, actionSearch, styleDelete, stylesFatchError, stylesFatchSuccess}