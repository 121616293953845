import React from "react";
import { Header } from "../../components/header";
import { taxesConst, tableHeaderTaxes } from "../../constant1";
import Table from "../../components/table";
import Input from "../../components/input";
import columnsForTaxes from "./columnsForTaxes";
// import { SettingsContainer } from "../../containers/settings";

function SettingsPage(props) {
    return(
      <div class="content-wrapper">
            <Header {...taxesConst.tablePageHeaderConsts} />
            {/* <SettingsContainer/> */}
            {/* <div class="row">
              <div class="col-md-4">
                <Input
                  id={"shipping_cost"}
                  value={props?.settings?.shipping_cost}
                  onChangeListener={(val) => {
                    props.onChnageSettings({
                      shipping_cost: val
                    })
                  }}
                  placeholder="Shipping Cost"
                />
              </div>
              <div class="col-md-4">
                <Input
                  id={"min_cost_for_shipping"}
                  value={props?.settings?.min_cost_for_shipping}
                  onChangeListener={(val) => {
                    props.onChnageSettings({
                      min_cost_for_shipping: val
                    })
                  }}
                  placeholder={"Shipping Cost neglect from price"}
                />
              </div>
              <div class="col-md-4">
                <button className="btn btn-secondary btn-radius mt-4">Update</button>
              </div>
            </div> */}
            <Table
              tableHeader={tableHeaderTaxes} 
              tableData= {()=>columnsForTaxes(props.rows, props.deleteListener, props.onStatusChange)}
              totalResultsLabel={taxesConst.tableConsts.totalResultsLabel}
              ObjectLength={props.pageInfo?.total}
              onSearch={props.onSearch} 
              toPage={props.toPage}
              pageInfo={props.pageInfo}
            /> 
        </div>
    )
}

export default SettingsPage;
export{
    SettingsPage
}