import React from "react";
import PropTypes from "prop-types";
import { totalStartsOfRating } from "../../constant";
import RatingStar from "../ratingStar";

function RatingAndInfo(props){
  return( 
      <div className="card">
        <div className="card-header">
            <h3 className="card-title">{props.title}</h3>
        </div>
        <div className="card-body">
          <h6>{props.fullName}</h6>
          <p className="mb-0"><span>{props.avgTimeLabel}</span> {props.AVGtime}</p>
          <div className="rating mb-2">
            <RatingStar rating = {props.rating} totalnumberOfReviews={props.totalnumberOfReviews} {...totalStartsOfRating} />
          </div>
          <div className="userAvatar">
              <img alt={props.ImgAlt} src={props.img} />
          </div>
          {props.children}
        </div>
      </div>  
  )
}

RatingAndInfo.propTypes = {
      avgTimeLabel : PropTypes.string,
      avgTimeValue : PropTypes.string,
      deliveredLabel : PropTypes.string,
      locationsApplicableLabel : PropTypes.string,
      name : PropTypes.string,
      numberOfRating : PropTypes.number,
      numberOfReviewer : PropTypes.string,
      ordersLabel : PropTypes.string,
      ordersDeliveredValue : PropTypes.string,
      ordersPendingValue : PropTypes.string,
      pendingLabel : PropTypes.string,
      ratingAndInfo : PropTypes.string,
      totalRating : PropTypes.number,
      title : PropTypes.string
}
export default RatingAndInfo;