import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { stylePageTableColoumDataForstyles } from "../../components/table/columnsForTable";
import {
  stylesPageHeader,
  stylesPageTableConst,
  stylePageTableHeaderDataForStyles,
} from "../../constant1";
import PropTypes from "prop-types";
import { DropDownList } from "../../components/DropDownList";

function Styles(props) {
  return (
    <>
      <div class="content-wrapper">
        <Header {...stylesPageHeader} />
        <div className="container-fluid">
          <div className="row">
            <div class="col-md-3">
              <DropDownList
                title="Styles"
                list={props.distictStyleList}
                onClickHandler={(val) => {
                  props.onSearch(val);
                }}
                active={props.serachField}
              />
            </div>
            <div className="col-md-9">
              <Table
                // onSearch={props.onSearch}
                onSearch={() => {}}
                noSerach={true}
                tableData={(e) =>
                  stylePageTableColoumDataForstyles(
                    props.rows,
                    props.deleteListener,
                    props.onStatusChange
                  )
                }
                tableHeader={stylePageTableHeaderDataForStyles}
                ObjectLength={props.pageInfo?.total}
                totalResultsLabel={stylesPageTableConst.totalResultsLabel}
                pageInfo={props.pageInfo}
                toPage={props.toPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
Styles.propTypes = {
  rows: PropTypes.array,
  pageInfo: PropTypes.object,
  onSearch: PropTypes.func,
  toPage: PropTypes.func,
  deleteListener: PropTypes.func,
};
export default Styles;
export { Styles };
