import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux';
import {AddFabricType} from "../../pages/fabrics";
import { addFabricType } from "./redux/fabricTable.actions";
import { urls } from "../../constants";
import { useHistory } from "react-router-dom";
import { uploadImage } from "../AddOffer/redux/AddOffer.actions";

function AddFabricTypeContainor(props) {
  
    const history = useHistory();

    const [title, setTitle] = useState("");
    const [status, setStatus] = useState('active'); // todo : pass it from the props where from we will pass data to Status selection
    const [imgUrl, setImgUrl] = useState(undefined);

    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);

    const token = {
      'Authorization' : `${userState.token}`
    };
    const submitHandler = (e) => {

        let data = {
          "name_en" :  title,
          "name_ar" :  title,
          "status" : status,
          "image_url_en" : imgUrl,
          "image_url_ar" : imgUrl
        }

        if( ( title !=="" ) && ( status !=="" ) && ( imgUrl !==undefined ) ){
          dispatch( addFabricType( data, token ) );
          history.push(urls.FABRICS);
        }else{
          alert( "Please fill all the boxes." ); // todo : will pass this message from constents
        }
    }
    
    function onChangeListener(data) {
      setImgUrl(data.image_url_en);
    }
    function onUploadImage(data, callBack){
      return dispatch(uploadImage(data[0], token, callBack))
    }
    return (
        <AddFabricType
            submitHandler= {submitHandler}
            setTitle = {(text)=>setTitle(text)}
            setStatus = {(text)=>setStatus(text)}
            setImgUrl = {(url)=>setImgUrl(url)}
            onUploadImage={onUploadImage} 
            onChangeListener={onChangeListener}
            image= {imgUrl}
        />
    )
}

AddFabricTypeContainor.propTypes = {

}

export default AddFabricTypeContainor