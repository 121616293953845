import { CustomerService } from "../../../services";
import { createAction } from "redux-actions";
import { 
    appCustomerTableSuccess, 
    appCustomerTableError, 
    appCustomerDetailSuccess, 
    appCustomerDetailError ,
    appMemberMeasurementSuccess,
    appMemberMeasurementError,
    appCustomerOrdersSuccess,
    appCustomerOrdersError,
    appCustomerReviewsSuccess,
    appCustomerReviewsError
} from "./customer.actionNames";

const CustomersFatchSuccess = createAction(appCustomerTableSuccess,(data)=>data);
const CustomersFatchError = createAction(appCustomerTableError,(ErrorData)=>ErrorData);

const CustomersDetailFatchSuccess = createAction(appCustomerDetailSuccess,(data)=>data);
const CustomersDetailFatchError = createAction(appCustomerDetailError,(ErrorData)=>ErrorData);

const memberMeasurementSuccess = createAction(appMemberMeasurementSuccess,(measurementData)=>measurementData);
const memberMeasurementError = createAction(appMemberMeasurementError,(ErrorMeasurementData)=>ErrorMeasurementData);

const customerOrdersSuccess = createAction(appCustomerOrdersSuccess,(customerOrdersData)=>customerOrdersData);
const customerOrdersError = createAction(appCustomerOrdersError,(ErrorCustomerOrdersData)=>ErrorCustomerOrdersData);

const customerReviewsSuccess = createAction(appCustomerReviewsSuccess,(customerReviewsData)=>customerReviewsData);
const customerReviewsError = createAction(appCustomerReviewsError,(ErrorCustomerReviewsData)=>ErrorCustomerReviewsData);

function CustomersAction(token,enocodeQuery) {
    return((dispatch)=>{
        CustomerService.get(token,enocodeQuery).then((resp)=>{
            dispatch(CustomersFatchSuccess(resp.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}
function CustomersDetailAction(token,queryString) {
    return((dispatch)=>{
        CustomerService.get(token,queryString).then((resp)=>{
            dispatch(CustomersDetailFatchSuccess(resp.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}
function CustomersStatusUpdate(id,data,token, toPage, pageInfo) {
    return((dispatch)=>{
        CustomerService.put(id,data,token).then((resp)=>{
            toPage(pageInfo.current_page);
           }).catch((err)=>{
               console.log(err);
           })
        })
}
function memberMeasurement(token,queryString) {
    return((dispatch)=>{
        CustomerService.getMeasurement(token,queryString).then((resp)=>{
            dispatch(memberMeasurementSuccess(resp.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}
function CustomersOrders(token,queryString) {
    return((dispatch)=>{
        CustomerService.getCustomerOrders(token,queryString).then((resp)=>{
            dispatch(customerOrdersSuccess(resp.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}
function CustomersReviews(token,queryString) {
    return((dispatch)=>{
        CustomerService.getCustomerReviews(token,queryString).then((resp)=>{
            dispatch(customerReviewsSuccess(resp.data))
           }).catch((err)=>{
               console.log(err);
           })
        })
}
export {
    CustomersAction,
    CustomersFatchSuccess,
    CustomersFatchError,
    CustomersDetailAction,
    CustomersDetailFatchSuccess,
    CustomersDetailFatchError,
    memberMeasurement,
    memberMeasurementSuccess,
    memberMeasurementError,
    CustomersOrders,
    customerOrdersSuccess,
    customerOrdersError,
    CustomersReviews,
    customerReviewsSuccess,
    customerReviewsError,
    CustomersStatusUpdate
}