import React from "react";
import Table from "../../components/table";
import Header from "../../components/header";
import { orderTableHeader, orders } from "../../constant1";
import OrdersColums from "./columsForOrderTable";

function Order(props) {

    return(
        <>    
            <div  class="content-wrapper">
                <Header
                    {...orders.tablePageHeaderConsts}
                />
                <Table 
                    onSearch={props.onSearch} 
                    tableData={()=> OrdersColums(orders.orderTableConsts.viewOrderLable,props.rows) } 
                    tableHeader={orderTableHeader} 
                    ObjectLength={props.pageInfo?.total} 
                    {...orders.orderTableConsts}
                    toPage={props.toPage}
                    // pedingOrderValue={"is this required at admin"}
                    pageInfo={props.pageInfo}
                />
            </div>
        </>
    )
}

export default Order;
export { Order };