import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {OptionsBarChart} from './barChartData';
import PropTypes from 'prop-types'; 

function BarChart(props) {
    return (
        <div>
                       <div className="card complaint-card h-100">
                           <div className="card-header">
                               <div className="row">
                                   <div className="col-md-6">
                                       <h3 className="card-title text-capitalize">{props.title}</h3>
                                   </div>
                               </div>
                           </div>
                           <div style={{overflow:"hidden"}} >
                               <div className="row">
                                   <div className="col-md-12 card-body pl-4 pr-4 pb-0 text-left">
                                       <HighchartsReact  Highcharts={Highcharts} options={OptionsBarChart} style={{ "width":"300" }}/>    
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
   )
}
BarChart.propTypes = {
    applicableForLabel : PropTypes.string,
    applicableForValue : PropTypes.string,
    categoryValue : PropTypes.string,
    categoryLabel : PropTypes.string,
    coustomerDiscountLabel : PropTypes.string,
    couponValueLabel : PropTypes.string,
    coustomerDiscountValue : PropTypes.string,
    couponValue : PropTypes.string,
    currencyLabel : PropTypes.string,
    maxOrderValueLabel : PropTypes.string,
    title : PropTypes.string,
    value : PropTypes.string
}
export default BarChart;