import React, { useState, useEffect } from "react";
import NotificationAbc from "../../components/notificationFullComp";
import Notification from "../../components/notification";
import ChangePassword from "../../components/ChangePassword";
import Header from "../../components/header";
import {
  img,
  changePassword,
  notification,
  notificationFullComp,
  categoryData,
  discountCheckboxData,
  discountCheckboxApplicableForData,
  applicableForData,
  imageUpload,
} from "../../constant";
import Input from "../../components/input";
import SelectSection from "../../components/SelectSection";
import CheckboxSection from "../../components/CheckboxSection";
import ChipSection from "../../components/ChipSection/ChipSection";
import queryString from "query-string";
import TextArea from "../../components/textArea";
import ImageUpload from "../../components/imageUpload";
import { useDispatch, useSelector } from "react-redux";
import { urls } from "../../constants";

function HeaderCondition(props) {
  if (props.id) {
    return (
      <Header
        title="Deal"
        subTitle="Edit"
        submitHandler={props.submitHandler}
        buttons={[
          {
            name: "BACK",
            url: urls.DEALS,
            isSymbolPresent: false,
          },
          {
            name: "UPDATE",
            isSymbolPresent: false,
          },
        ]}
      />
    );
  } else {
    return (
      <Header
        title="Deal"
        subTitle="Add New"
        submitHandler={props.submitHandler}
        buttons={[
          {
            name: "BACK",
            url: urls.DEALS,
            isSymbolPresent: false,
          },
          {
            name: "GENERATE",
            // url: urls.DEALS,
            isSymbolPresent: false,
          },
        ]}
      />
    );
  }
}

function DealAddAndEdit({
  tailorList,
  addOfferListener,
  updateOfferListener,
  deal,
}) {
  console.log(deal);
  const [title_en, setTitle_en] = useState(deal?.name_en || "");
  const [title_ar, setTitle_ar] = useState(deal?.name_ar || "");
  const [description_en, setDescription_en] = useState(deal?.desc_en || "");
  const [description_ar, setDescription_ar] = useState(deal?.desc_ar || "");
  const [percent, setPercent] = useState(deal?.percent || "");
  const [tailor_id, setTailor_id] = useState("");
  const [status, setStatus] = useState(deal?.status || "active"); // todo : pass it from the props where from we will pass data to Status selection
  const [gender, setGender] = useState("Men");
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const query = queryString.parse(window.location.search);

  useEffect(() => {
    setTitle_en(deal?.name_en || "");
    setTitle_ar(deal?.name_ar || "");
    setDescription_en(deal?.desc_en || "");
    setDescription_ar(deal?.desc_ar || "");
    setPercent(deal?.percent || "");
    setStatus(deal?.status || "active");
    setTailor_id(deal?.tailor_id || "active");
    setGender(deal?.gender);
  }, [deal]);

  const submitHandler = (e) => {
    let data = {
      name_en: title_en,
      name_ar: title_en,
      desc_en: description_en,
      desc_ar: description_en,
      percent: percent,
      status: status,
      flat: 100,
      tailor_id: tailor_id,
      gender: gender,
      // max_limit: 50,
      // image_url_en: "",
      // image_url_ar: "",
    };
    console.log(data);
    if (
      title_en !== "" &&
      // title_ar !== "" &&
      description_en !== "" &&
      // description_ar !== "" &&
      status !== "" &&
      gender !== null &&
      tailor_id != null
    ) {
      if (query?.id) {
        updateOfferListener(query.id, data);
      } else {
        addOfferListener(data);
      }
    } else {
      // todo : will pass this message from constents
      alert("Please fill all the boxes.");
      e?.preventDefault(e);
    }
  };

  return (
    <div className="wrapper">
      <div class="content-wrapper">
        <HeaderCondition id={query?.id} submitHandler={submitHandler} />
        <section class="content mb-5">
          <div class="container-fluid">
            <div className="row">
              <div className="col-sm-9">
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <Input
                            placeholder="Title of the Deal (En)"
                            id="stitle"
                            value={title_en}
                            onChangeListener={(val) => {
                              setTitle_en(val);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <Input
                            placeholder="Title of the Deal (Ar)"
                            id="stitle_ar"
                            value={title_ar}
                            onChangeListener={(val) => setTitle_ar(val)}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <Input
                            placeholder="Percent %"
                            id="percent"
                            type="number"
                            min={1}
                            max={100}
                            value={percent}
                            onChangeListener={(val) => {
                              setPercent(val);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <SelectSection
                        // disabled={false}
                        onChangeListener={
                          (val) => setGender(val)
                          // props.onChangeListener("user_id", val)
                        }
                        heading="Gender"
                        options={["Men", "Women"]}
                        value={gender}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <TextArea
                            rows="10"
                            placeholder="Brief description of the Deal (En)"
                            defaultValue={description_en}
                            onChangeListener={(val) => setDescription_en(val)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <TextArea
                            rows="10"
                            placeholder="Brief description of the Deal (Ar)"
                            defaultValue={description_ar}
                            onChangeListener={(val) => setDescription_ar(val)}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <SelectSection
                  // disabled={false}
                  onChangeListener={
                    (val) => setTailor_id(val)
                    // props.onChangeListener("user_id", val)
                  }
                  heading="Tailor"
                  options={tailorList}
                  value={tailor_id}
                />
                <SelectSection
                  heading="Status"
                  value={status}
                  options={["active", "inactive"]}
                  onChangeListener={(value) => setStatus(value)}
                />
                {/* <CheckboxSection rows={categoryData} {...discountCheckboxData} /> */}
                {/* <ImageUpload {...imageUpload} /> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default DealAddAndEdit;
export { DealAddAndEdit };
