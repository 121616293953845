import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/input";
import TextArea from "../../components/textArea";
import SelectSection from "../../components/SelectSection";
import OfferAddAndEdit from "../../pages/offer/offer.addAndEdit";
import DealAddAndEdit from "../../pages/deals/deals.addAndEdit";
import { addDeal, dealUpdate } from "../AddDeal/redux/AddDeal.actions";
import { dealInfo } from "../dealsTable/redux/dealsTable.actions";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { urls } from "../../constants/app";
import { TailorTable } from "../../containers/tailorTable/redux/tailor.actions";

function AddDealContainer(props) {
  const userState = useSelector((state) => state.user);
  const tailorState = useSelector((state) => state.tailor);
  const dispatch = useDispatch();
  const history = useHistory();

  const token = {
    Authorization: `${userState.token}`,
  };

  const [id, setId] = useState(null);

  const [deal, setDeal] = useState();

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query?.id) {
      setId(query?.id);
      getCategoryInfo(query?.id);
    }
  }, []);

  useEffect(() => {
    dispatch(TailorTable(token));
  }, []);

  async function getCategoryInfo(id) {
    return dispatch(dealInfo(id, token, setDeal));
  }

  function addDealListener(data) {
    return dispatch(addDeal(data, token, () => {
        history.push({
            pathname: urls.DEALS
        })
    }));
  }

  function updateDealListener(id, data) {
    return dispatch(dealUpdate(id, data, token, () => {
        history.push({
            pathname: urls.DEALS
        })
    }));
  }

  return (
    <DealAddAndEdit
      tailorList={tailorState?.rows}
      addOfferListener={addDealListener}
      updateOfferListener={updateDealListener}
      deal={deal}
    ></DealAddAndEdit>
  );
}

AddDealContainer.propTypes = {};

export default AddDealContainer;
