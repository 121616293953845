import React, {useState, useEffect} from "react";
import { SettingsPage } from "../../pages/tax";
import {useDispatch, useSelector} from 'react-redux';
import { taxesFatchAction, taxesFatchSeccess, taxDelete, taxEdit } from "./redux/tax.actions";

function TaxesContainor(props) {
    const dispatch = useDispatch();
    const userState = useSelector(state=>state.user);
    const Taxes = useSelector(state=>state.taxesData);
    var rows = Taxes?.rows;
    var pageInfo = Taxes?.pageInfo;
    
    const [settings, setSettings] = useState({});

    const token = {
        'Authorization' : `${userState.token}`
    };
    useEffect(()=>{
        dispatch(
            taxesFatchSeccess({
            ...Taxes,
            search: "",
          })
        );
    },0)
    useEffect(()=>{
        toPage(1);
    }, [Taxes.search]);

    function toPage(page, limit) {
        let search = Taxes.search;

        let query = {};

        if (page) query.page = page;
        if (limit) query.limit = limit;
        if (search) query.name = search;

        let enocodeQuery = Object.keys(query)
            .map((key) => key + "=" + query[key])
            .join("&");
        enocodeQuery = enocodeQuery ? '?' + enocodeQuery : '';
        
        dispatch(taxesFatchAction(token, enocodeQuery));
    }
    function onSearch(str) {
        dispatch(
            taxesFatchSeccess({
            ...Taxes,
            search: str,
          })
        );
    }
    function deleteListener(id) {
        dispatch( taxDelete(id, token, toPage, pageInfo) );
    }
    function onStatusChange(id,status) {
        dispatch(taxEdit(id, {
            status: status ? 'active' : 'inactive'
        },token, toPage, pageInfo));
    }

    function onChnageSettings(chnageObj) {
        setSettings({
            ...settings,
            ...chnageObj
        })
    }
    return(
        <SettingsPage
            settings={settings}
            onChnageSettings={onChnageSettings}
            toPage={toPage} 
            onSearch={onSearch} 
            rows={rows} 
            pageInfo={pageInfo}
            deleteListener={deleteListener}
            onStatusChange={onStatusChange}
        />
    )
}

export default TaxesContainor;
export {
    TaxesContainor
}